import { Flex, Spinner, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";

interface CustomLoadingProps {
  sourceName: string;
}

function CustomLoading({ sourceName }: CustomLoadingProps) {
  const loadingMessages = [
    `Loading ${sourceName.toLowerCase()}...`,
    "This may take few seconds, stay with us...",
    "Almost there...",
  ];

  // State
  const [currentMessage, setCurrentMessage] = useState(loadingMessages[0]);

  useEffect(() => {
    let messageIndex = 0;
    const intervalId = setInterval(() => {
      messageIndex = (messageIndex + 1) % loadingMessages.length;
      setCurrentMessage(loadingMessages[messageIndex]);
    }, 5000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceName]);

  return (
    <Flex
      p={6}
      w={"full"}
      h={"full"}
      justifyContent={"center"}
      alignItems={"center"}
      gap={3}
    >
      <Spinner
        thickness="2px"
        speed="1.5s"
        color="gray.400"
        emptyColor="gray.200"
      />
      <Text
        fontFamily="Roboto, sans-serif"
        fontWeight="500"
        color="gray.600"
        fontSize={[null, null, null, "sm", "md"]}
        textAlign="center"
      >
        {currentMessage}
      </Text>
    </Flex>
  );
}

export default CustomLoading;
