import { Flex } from "@chakra-ui/react";
import NavItem from "./NavItem";

export default function Nav() {
  return (
    <Flex gap={6} justify="center" align="center">
      <NavItem to="#about" text="About" />
      <NavItem to="#usecases" text="Use Cases" />
      <NavItem to="#contact" text="Contact" />
      <NavItem to="#pricing" text="Pricing" />
    </Flex>
  );
}
