import { Card, Flex } from "@chakra-ui/react";

import BotAvatar from "./BotAvatar";
import MarkedReply from "./MarkedReply";
import LoadingDots from "components/ui/LoadingDots";

import { removeBraces } from "../helpers";

interface LoadingBubbleProps {
  partialReply: string[];
  notifications: string[];
}

export default function LoadingBubble({
  partialReply,
  notifications,
}: LoadingBubbleProps) {
  return (
    <Flex w="100%" mt={4} mb={8} minH={"50px"} overflow={"hidden"}>
      <BotAvatar />

      <Card p={3} bg={"transparent"} boxShadow={"none"}>
        {/* partial bot reply without molecules or sources */}
        {!!partialReply?.length && (
          <Flex
            h={"100%"}
            w={"100%"}
            direction={"column"}
            gap={1.5}
            mb={!!partialReply?.length ? 5 : "0"}
          >
            {/* TODO: add typing character at the end of the streaming buffer */}
            <MarkedReply reply={removeBraces(partialReply)?.join("")} />
          </Flex>
        )}

        {/* notifications */}
        <Flex h={"100%"} w={"100%"} gap={3} align={"center"}>
          {/* dots indicators */}
          <LoadingDots />

          {/* notification message */}
          <Flex
            fontSize={"13px"}
            fontFamily={"Poppins, sans-serif"}
            lineHeight={"1.7"}
            color={"gray.500"}
          >
            {notifications?.at(-1) || ""}
          </Flex>
        </Flex>
      </Card>
    </Flex>
  );
}
