import {
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { HashLink as Link } from "react-router-hash-link";

import { TbProgressCheck, TbRectangleVertical } from "react-icons/tb";
import { hexToRgba } from "utils/helpers";

interface ResponsivenessInfoProps {
  isOpen: boolean;
  onClose: () => void;
}

function ResponsivenessInfo({ isOpen, onClose }: ResponsivenessInfoProps) {
  // Theme
  const { colors } = useTheme();
  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const color =
    colorMode === "light" ? colors.secondary["700"] : colors.secondary["300"];

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent
        alignSelf={"center"}
        overflowY={"auto"}
        minW={{ base: "95%", sm: "360px", md: "500px" }}
        maxW={{ base: "95%", sm: "440px", md: "500px" }}
        h={"fit-content"}
        my={"auto"}
        bg={"white"}
        borderRadius={"14px"}
        position={"absolute"}
        bottom={{ base: "24px", sm: "84px" }}
      >
        <ModalCloseButton display={{ base: "none", sm: "inline-block" }} />
        <ModalHeader
          display={"flex"}
          alignItems={"center"}
          gap={3}
          px={{ base: 4, md: 6 }}
          color={"whiteAlpha.900"}
          bg={"teal"}
          bgGradient={`linear(to-l, ${hexToRgba(
            colors.highlight["primary"],
            0.8
          )}, ${hexToRgba(color, 0.8)})`}
        >
          <Flex position={"relative"}>
            <Icon
              as={TbRectangleVertical}
              boxSize={["22px", "24px", "28px", null, null, null]}
            />
            <Icon
              position={"absolute"}
              bottom={"-3px"}
              right={"-3px"}
              as={TbProgressCheck}
              bg={"whiteAlpha.900"}
              boxSize={["15px", "16px", "18px", null, null, null]}
              borderRadius={"50%"}
              p={"1px"}
              color={"orange.400"}
            />
          </Flex>

          <Text fontSize={{ base: "14px", md: "16px" }} fontWeight={"500"}>
            Unlock Lakesai's full potential
          </Text>
        </ModalHeader>
        <ModalBody
          py={6}
          px={{ base: 4, md: 6 }}
          bg={"primary.100"}
          color={"primary.800"}
        >
          <Flex
            direction={"column"}
            gap={{ base: 2, md: 3 }}
            color={"black"}
            opacity={0.8}
            fontSize={{ base: "13px", md: "14px" }}
          >
            <Text>
              To fully experience Lakesai, kindly log in using your laptop.
              <br />
              We're currently working to make the mobile version accessible.
            </Text>
            <Text>
              If you have not yet registered, please{" "}
              <Link
                to="#contact"
                style={{ color: colors.secondary[500], fontWeight: "500" }}
                smooth
                onClick={onClose}
              >
                contact us
              </Link>{" "}
              to join the waiting list.
            </Text>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ResponsivenessInfo;
