import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
} from "@chakra-ui/react";
import { ChangeEvent } from "react";

interface MessageProps {
  message: string;
  isInvalid: boolean;
  onMessageChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

function VisitorMessage({ message, isInvalid, onMessageChange }: MessageProps) {
  return (
    <FormControl isInvalid={isInvalid} w={"100%"}>
      <FormLabel
        htmlFor="message"
        color={"secondary.700"}
        fontSize={"12px"}
        fontWeight={"500"}
        fontFamily={"Poppins, sans-serif"}
        lineHeight={1}
        mb={1}
      >
        Optional Message
      </FormLabel>
      <Textarea
        w={"100%"}
        name="message"
        value={message}
        onChange={onMessageChange}
        placeholder={"Leave a message."}
        resize={"none"}
        fontSize={"14px"}
        color={"gray.500"}
        _placeholder={{ fontSize: "14px", color: "gray.600" }}
        borderRadius={"6px"}
        bg={"background"}
        borderWidth={1}
        borderColor={"gray.200"}
        _hover={{ borderColor: "gray.300" }}
        _focusVisible={{ borderColor: "blue.300" }}
      />
      <FormErrorMessage
        fontSize={"12px"}
        color={"red.500"}
        fontFamily={"Poppins, sans-serif"}
      >
        Message is required. Should be longer than 3 characters.
      </FormErrorMessage>
    </FormControl>
  );
}

export default VisitorMessage;
