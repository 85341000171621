import { Box } from "@chakra-ui/react";

interface LineProps {
  hovered: boolean;
  top: string;
  left?: string;
  right?: string;
  delay: string;
}

function Line({ hovered, top, left, right, delay }: LineProps) {
  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const isLight = colorMode === "light";

  return (
    <Box
      position="absolute"
      inset="0"
      opacity={hovered ? 1 : 0}
      transitionDuration="0.15s"
      transition="opacity 0.35s"
      _before={{
        content: "''",
        position: "absolute",
        bg: isLight ? "gray.200" : "gray.300",
        transition: "transform 0.35s",
        left: "0",
        right: "0",
        top: top,
        height: "1px",
        transformOrigin: "0 50%",
        transform: hovered ? "scaleX(1)" : "scaleX(0)",
        transitionDelay: hovered ? 0 : delay,
      }}
      _after={{
        content: "''",
        position: "absolute",
        bg: isLight ? "gray.200" : "gray.300",
        transition: "transform 0.35s",
        top: "0",
        bottom: "0",
        left: left,
        right: right,
        width: "1px",
        transformOrigin: "50% 0",
        transform: hovered ? "scaleY(1)" : "scaleY(0)",
        transitionDelay: hovered ? 0 : delay,
      }}
    />
  );
}

export default Line;
