import {
  Box,
  SystemStyleObject,
  usePrefersReducedMotion,
  useTheme,
} from "@chakra-ui/react";
import { keyframes } from "@emotion/react";

import { hexToRgba } from "utils/helpers";

interface TileProps {
  top: string;
  left: string;
  height: string;
  width: string;
  sx?: SystemStyleObject | undefined;
}

export const tile = keyframes`
  0%, 12.5%, 100% { opacity: 1; }
  25%, 82.5% { opacity: 0; }`;

function Tile({ top, left, height, width, sx }: TileProps) {
  // Theme
  const { colors } = useTheme();

  // Animation
  const prefersReducedMotion = usePrefersReducedMotion();

  const tileAnimation = prefersReducedMotion
    ? undefined
    : `${tile} 8s infinite`;

  return (
    <Box
      position={"absolute"}
      bg={hexToRgba(colors.highlight.primary, 0.15)}
      animation={tileAnimation}
      opacity={0}
      top={top}
      left={left}
      h={height}
      w={width}
      sx={sx}
    />
  );
}

export default Tile;
