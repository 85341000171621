import { Flex, Image, Text, useBreakpointValue } from "@chakra-ui/react";

import { newNote } from "assets/bookmarks/newNote";

function NoNotes() {
  const notepadHeight = useBreakpointValue({
    lg: "calc(100vh - 154px)",
    xl: "calc(100vh - 162px)",
    "2xl": "calc(100vh - 178px)",
  });

  return (
    <Flex
      align={"center"}
      justify={"center"}
      gap={2}
      direction={"column"}
      h={`calc(${notepadHeight} - 194px)`}
    >
      {/* new note illustration */}
      <Image
        src={newNote}
        alt={"new note illustration"}
        w={"60%"}
        objectFit={"cover"}
      />

      <Text
        fontSize={"14px"}
        color={"gray.600"}
        fontFamily={"Poppins, sans-serif"}
      >
        Write your first note
      </Text>
    </Flex>
  );
}

export default NoNotes;
