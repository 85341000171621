import { AxiosError } from "axios";
import { User } from "redux/features/auth/authSlice";
import {
  containsUppercase,
  hasLowercase,
  containsNumber,
  containsSpecialCharacter,
} from "views/auth/helpers";

interface StylesObject {
  [key: string]: string;
}

export function stylesToString(stylesObj: StylesObject) {
  let styleString = "";

  for (const property in stylesObj) {
    if (stylesObj.hasOwnProperty(property)) {
      styleString += `${property}: ${stylesObj[property]}; `;
    }
  }

  return styleString.trim();
}

export function hexToRgba(hexCode: string, opacity = 1): string {
  // fallback color
  if (!hexCode) return "black";

  // Remove the # symbol from the hex code
  hexCode = hexCode.replace("#", "");

  // If the hex code is short, expand it to the full 6-digit format
  if (hexCode.length === 3) {
    hexCode = `${hexCode[0]}${hexCode[0]}${hexCode[1]}${hexCode[1]}${hexCode[2]}${hexCode[2]}`;
  }

  // Parse the hex code into RGB values
  const r = parseInt(hexCode.substring(0, 2), 16);
  const g = parseInt(hexCode.substring(2, 4), 16);
  const b = parseInt(hexCode.substring(4, 6), 16);

  // Calculate the opacity value
  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100;
  }

  // Return the RGBA color string
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export const errorHandler = (Error: any) => {
  let errorMessage = null;

  if (Error instanceof AxiosError) {
    // Handle AxiosError specifically
    if (Error.response && Error.response.data) {
      // NOTE: to delete this if-else check after implementing it on the server
      if (Error.response.data.message?.toLowerCase() === "session not found")
        errorMessage = "Conversation not found";
      else errorMessage = Error.response.data.message;
      // errorMessage = Error.response.data.message; // NOTE: to unmute

      if (
        Error.response.data?.detail?.toLowerCase() === "rate limit exceeded"
      ) {
        errorMessage = "Rate limit exceeded";
      }
    } else {
      errorMessage = Error.message;
    }
  } else {
    // Handle plain Error
    errorMessage = Error?.message;
  }
  return { message: errorMessage ?? "An unknown error occurred" };
};

export const validateFullName = (name: string) => {
  if (name) {
    const re = /^[a-zA-Z\-’']+( [a-zA-Z\-’']+)+$/;
    return re.test(name);
  } else {
    return false;
  }
};

export const validateEmail = (email: string) => {
  if (email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  } else {
    return false;
  }
};

export const validatePass = (pass: string) => {
  if (pass.length < 8) {
    return "Password should be 8 characters long.";
  } else if (!containsUppercase(pass)) {
    return "Password should contain at least one uppercase letter.";
  } else if (!hasLowercase(pass)) {
    return "Password should contain at least one lowercase letter.";
  } else if (!containsNumber(pass)) {
    return "Password should contain at least one number.";
  } else if (!containsSpecialCharacter(pass)) {
    return "Password should contain at least one special character.";
  } else {
    return "";
  }
};

// Get firstname and lastname from fullname
export const getName = (name: string, fullname: string) => {
  // name is either firstname or lastname
  let arr = fullname?.split(" ");

  if (name === "first") {
    return titleCase(arr[0]);
  } else {
    if (arr.length === 3) {
      // Combine last name and surname
      return titleCase(arr[1] + " " + titleCase(arr[2]));
    } else {
      return titleCase(arr[1]);
    }
  }
};

export const titleCase = (str: string) => {
  return str?.toLowerCase()?.replace(/\b\S/g, function (t) {
    return t?.toUpperCase();
  });
};

export const formatCurrency = (amount: number, currency: string) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency || "USD",
  });
  return formatter.format(amount);
};

export const formatNumber = (num: number) => {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return formatter.format(num);
};

export const setCurentUserLimit = () => {
  // Cache rate LIMIT count
  if (window.localStorage.getItem("limitStartTime")) {
    const limit = window.localStorage.getItem("limitCount");
    let currentLimit = limit ? JSON.parse(limit) : 0;
    currentLimit += 1;
    window.localStorage.setItem("limitCount", JSON.stringify(currentLimit));
  } else {
    window.localStorage.setItem("limitStartTime", JSON.stringify(new Date()));
    window.localStorage.setItem("limitCount", JSON.stringify(1));
  }
};

export const resetCurentUserLimit = () => {
  // Reset if cache rate LIMIT expires
  // Expiry is set at 24hrs
  if (window.localStorage.getItem("limitStartTime")) {
    const currentTime = new Date();
    const limitStartTime = window.localStorage.getItem("limitStartTime");
    if (limitStartTime) {
      const startTime = new Date(JSON.parse(limitStartTime));
      // Get hours that have passed
      const hoursPassed =
        (currentTime.getTime() - startTime.getTime()) / (1000 * 60 * 60);
      if (hoursPassed >= 6) {
        window.localStorage.removeItem("limitStartTime");
        window.localStorage.removeItem("limitCount");
      }
    }
  }
};

export const isWithinLimit = (user: User) => {
  // Refactor if RATE_LIMIT for premium or Teams is no longer unlimited
  // Assumes rate limit for FREE is 5 and for BASIC is 100
  const RATE_LIMIT = user?.user_type === "free" ? 5 : 100;

  // check current user limit
  const limit = window.localStorage.getItem("limitCount");
  const currentLimit = limit ? JSON.parse(limit) : 0;
  const UNLIMITED_RATES =
    user?.user_type === "premium" ||
    user?.user_type === "teams" ||
    user?.permissions?.includes("teams_invitee");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return true;
  }

  if (!UNLIMITED_RATES && currentLimit >= RATE_LIMIT) {
    return false;
  }

  return true;
};
