import { createContext, useCallback, useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";

import { Box, useBreakpointValue, useToast } from "@chakra-ui/react";
import { useSubmit } from "hooks";

import TextField from "components/chat/input/TextField";
import { CustomScrollBar } from "components/ui/CustomScrollBar";

import {
  ConversationProps,
  SessionProps,
  SourceProps,
} from "models/chat/MessageProps";

// services
import { generateBotResponse } from "services/chatbot.service";
import { useChatBotAPI } from "api/useChatBotAPI";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  errorHandler,
  isWithinLimit,
  resetCurentUserLimit,
  setCurentUserLimit,
} from "utils/helpers";
import RateLimitModal from "components/ui/RateLimitModal";
import useFetchSavedElements from "hooks/bookmarks/useFetchSavedElements";

interface BotReplyProps {
  message?: [];
  generated_text?: string;
  data?: { generated_text: string };
  compounds?: string[] | [];
  sources?: SourceProps[] | [];
  followup_questions?: string[] | [];
  human_attachments?: File[] | [];
  json_response?: string;
  human_audio?: Blob | null;
  question?: string;
}

const apologiesMessage =
  "Apologies, but I'm currently experiencing technical difficulties and I'm unable to assist you at the moment.\nPlease try again later.";

export const ChatbotContext = createContext({
  messages: [] as ConversationProps[],
  sessions: [] as SessionProps[],
  loadingSessions: undefined as boolean | undefined,
  loadingChat: false,
  chatError: undefined as Error | undefined,
  activeSession: "",
  waitingOnBot: undefined as boolean | undefined,
  attachedFiles: [] as File[],
  uploadedAudio: null as Blob | null,
  setAttachedFiles: (() => {}) as React.Dispatch<React.SetStateAction<File[]>>,
  setUploadedAudio: (() => {}) as React.Dispatch<
    React.SetStateAction<Blob | null>
  >,
});

export default function ChatbotView() {
  const { user } = useSelector(selectCurrentAuthData);

  // Hooks
  const toast = useToast();
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const messagesRef = useRef<HTMLDivElement>(null);

  // States
  const [questionOnWait, setQuestionOnWait] = useState<string>("");
  const [chatState, setChatState] = useState<ConversationProps[]>([]);
  const [showRateModal, setShowRateModal] = useState(false);

  useFetchSavedElements();
  resetCurentUserLimit();

  const [activeSession, setActiveSession] = useState<string>("");
  const [, setLoadingSessions] = useState<boolean>(false); // TODO: to check if it is useful..
  const [attachedFiles, setAttachedFiles] = useState<File[]>([]);
  const [uploadedAudio, setUploadedAudio] = useState<Blob | null>(null);

  // API
  const { fetchChatById, fetchSessions } = useChatBotAPI();

  const {
    isLoading: loadingChat,
    data: chatData,
    error: chatError,
  } = useQuery({
    queryKey: ["chatbot-session", id],
    queryFn: fetchChatById,
    enabled: !!id,
  });

  useEffect(() => {
    if (chatData) {
      setChatState(chatData);
    }
  }, [chatData]);

  // API - Fetch all sessions
  const {
    isLoading: sessionsIsLoading,
    data: _sessions,
    error: sessionsError,
  } = useQuery({
    queryKey: ["chatbot-sessions"],
    queryFn: fetchSessions,
  });

  useEffect(() => {
    setLoadingSessions(sessionsIsLoading);
  }, [sessionsIsLoading]);

  // Display errors
  useEffect(() => {
    if (sessionsError) {
      toast({
        description: errorHandler(sessionsError).message,
        status: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionsError]);

  function handleSubmit(
    question: string,
    files: File[] = [],
    audio: Blob,
    questionType: string
  ) {
    onSubmit({ question, files, audio, questionType });
  }

  const { onSubmit, loading: waitingOnBot } = useSubmit(
    useCallback(
      async ({
        question,
        files = [],
        audio,
        questionType,
      }: {
        question: string;
        files: File[];
        audio: Blob;
        questionType: string;
      }) => {
        setQuestionOnWait(question);

        const formData = new FormData();
        formData.append("inputs", question);
        formData.append("questionType", questionType);

        if (audio.size > 0) formData.append("audioFile", audio);
        files.forEach((file) => formData.append("file", file));
        if (id) formData.append("session_id", id);

        formData.append("message_id", "");
        setActiveSession(id ?? "new");

        let response: BotReplyProps = {};

        await generateBotResponse(formData)
          .then((res) => {
            response = res;
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              response = error.response;
            }
            if (error?.response?.data?.detail === "Rate limit exceeded") {
              setShowRateModal(true);
            }
          });

        setCurentUserLimit();
        const uploaded_audio =
          questionType === "text" ? null : uploadedAudio ?? null;

        // bot partial reply (paragraph only, without: sources, compounds, and followup questions)
        const reply =
          response?.generated_text ??
          response?.data?.generated_text ??
          apologiesMessage;

        const latestExchange: Partial<ConversationProps> = {
          messages: [
            {
              human: response.question ?? question,
              ai: reply,
              json_response: response.json_response,
            },
          ],
          sources: response.sources ?? [],
          compounds: response.compounds ?? [],
          followup_questions: response.followup_questions ?? [],
          human_attachments: response.human_attachments ?? attachedFiles,
          human_audio: response.human_audio ?? uploaded_audio,
        };
        setChatState((prev: ConversationProps[]) => {
          const updatedChat = [...prev, latestExchange];
          id && queryClient.setQueryData(["chatbot-session", id], updatedChat);
          return updatedChat as ConversationProps[];
        });

        if (!id) {
          setLoadingSessions(true);
          await queryClient.invalidateQueries({
            queryKey: ["chatbot-sessions"],
          });
          setLoadingSessions(false);
        }

        // in the messages has apologies its an error keep the file so user can resend also so it could show on the message body
        const errorInResponse = reply.toLowerCase().includes("apologies");
        if (!errorInResponse) {
          setAttachedFiles([]);
        }
        setQuestionOnWait("");
        setActiveSession("");
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [id, queryClient]
    )
  );

  function handleSendQuestion(question: string, type: string = "text") {
    if (!isWithinLimit(user)) {
      setShowRateModal(true);
      return;
    }

    if (!waitingOnBot) {
      handleSubmit(question, attachedFiles, uploadedAudio || new Blob(), type);
    }
  }

  useEffect(() => {
    if (_sessions) {
      if (_sessions?.length > 0) {
        location.pathname === "/chat" &&
          navigate(`/chat/${_sessions?.at(0)?.id}`);
      } else {
        navigate("/chat");
        setChatState([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_sessions]);

  const chatViewHeight = useBreakpointValue({
    lg: `calc(100vh - 100px)`, // 100 = (8x2) + 16 + 80 + 4: py + textfield + 4px offset
    xl: `calc(100vh - 108px)`, // 108 = (12x2) + 80 + 4
    "2xl": `calc(100vh - 116px)`, // 116 = (16x2) + 80 + 4
  });

  const chatViewStyle = {
    height: chatViewHeight,
    width: "99%", // NOTE: don't change this value! 100% as width, weirdly, blocks the window resize
    margin: "0 auto",
    padding: "0 10px 0 0",
  };

  return (
    <Box w={"100%"} position={"relative"}>
      <ChatbotContext.Provider
        value={{
          messages: chatState ?? [],
          sessions: _sessions ?? [],
          loadingSessions: sessionsIsLoading,
          loadingChat: loadingChat ?? false,
          chatError: chatError ?? undefined,
          waitingOnBot: waitingOnBot,
          activeSession: activeSession,
          attachedFiles: attachedFiles,
          uploadedAudio,
          setUploadedAudio,
          setAttachedFiles,
        }}
      >
        <CustomScrollBar
          style={chatViewStyle}
          scrollableNodeProps={{ ref: messagesRef }}
        >
          <Outlet
            context={{ questionOnWait, handleSendQuestion, messagesRef }}
          />
        </CustomScrollBar>

        {!loadingChat && !chatError && (
          <TextField onSendQuestion={handleSendQuestion} />
        )}

        {/* Rate limit modal */}
        <RateLimitModal
          isOpen={showRateModal}
          onClose={() => {
            window.localStorage.setItem(
              "initialRateLimitSeen",
              JSON.stringify(true)
            );
            setShowRateModal(false);
          }}
        />
      </ChatbotContext.Provider>
    </Box>
  );
}
