import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";

interface UseWSProps {
  waitingOnBot: boolean | undefined;
}

// used to persist streaming content on navigation
let reply: string[] = [];

const useWS = ({ waitingOnBot }: UseWSProps) => {
  // States
  const [notifications, setNotifications] = useState<string[]>([]);
  const [partialReply, setPartialReply] = useState<string[]>([]);

  // Auth
  const { user } = useSelector(selectCurrentAuthData);
  const HOST_URL = window.location.hostname;

  const WS_URL =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? `ws://127.0.0.1:8000/ws/chat/${user?.id}/`
      : `wss://${HOST_URL}/ws/chat/${user?.id}/`;

  let socket: WebSocket | null = null;

  // Handler
  function handleStreaming() {
    socket = new WebSocket(WS_URL);
    socket.onopen = () => {
      setPartialReply([...reply]); // keep previous streaming content when switching between existing chat and new chat
      setNotifications([]);
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const { message, action } = data;

      if (action === "notification") {
        setNotifications((list) => [...list, message]);
      } else if (action === "reply") {
        // processReplyChunk(message);
        setPartialReply((list) => {
          reply.push(message);
          return [...list, message];
        });
      }
    };

    socket.onclose = () => {};
  }

  // enable user engagement feature while waiting on bot
  useEffect(() => {
    if (waitingOnBot) {
      handleStreaming();
    } else {
      reply = []; // no need to store previous streaming content
    }

    // cleanup: close socket connection when all requested data received
    return () => {
      !!socket && socket.close();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitingOnBot]);

  return { notifications, partialReply };
};

export default useWS;
