import { useEffect, useMemo } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { selectCurrentSavedData } from "redux/features/bookmarks/savedSlice";

import { Box, Button, Flex, useDisclosure, useTheme } from "@chakra-ui/react";

import BookmarksBreadcrumb from "./Breadcrumb";
import Notepad from "components/library/bookmarks/notepad";
import Reports from "components/library/bookmarks/reports";
import NewFolderContent from "components/library/bookmarks/NewFolderContent";

import { hexToRgba } from "utils/helpers";
import { getLibraryFolder } from "../helpers";
import { useLastViewPath } from "hooks/library/persistPath";
import FolderAccessList from "components/library/bookmarks/accessList/FolderAccessList";

import { TbMessage, TbShare } from "react-icons/tb";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";

export default function Bookmarks() {
  // Hooks
  const { folderId } = useParams();
  const {
    isOpen: isNotepadOpen,
    onOpen: onOpenNotepad,
    onClose: onCloseNotepad,
  } = useDisclosure();

  const {
    isOpen: isReportsOpen,
    onOpen: onOpenReports,
    onClose: onCloseReports,
  } = useDisclosure();

  const {
    isOpen: isShareListOpen,
    onOpen: onShareListOpen,
    onClose: onShareListClose,
  } = useDisclosure();

  const { savedFolders } = useSelector(selectCurrentSavedData);
  const { user } = useSelector(selectCurrentAuthData);

  // Theme
  const { colors } = useTheme();

  const folder = getLibraryFolder(folderId, savedFolders);
  // TODO: review
  // const { getSpecificLibraryLastPath } = useLastViewPath();
  // const navigate = useNavigate();

  const hasFoldersToShow = useMemo(() => {
    let foldersPresent = false;
    if (window.location.pathname.includes("team")) {
      foldersPresent =
        savedFolders?.filter((x) => x?.isShared === true).length > 0;
    } else {
      foldersPresent = savedFolders?.length > 0;
    }
    return foldersPresent;
  }, [savedFolders]);

  // TODO: review
  // useEffect(() => {
  //   const lastPath = getSpecificLibraryLastPath("bookmarks");
  //   if (lastPath) {
  //     navigate(lastPath);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <>
      <Flex h={"100%"} direction={"column"} gap={4}>
        {/* Breadcrumb & Create Folder section */}
        <Flex align={"center"} gap={2} w={"100%"} justify={"space-around"}>
          {/* Breadcrumb */}
          <BookmarksBreadcrumb folder={folder} />

          {!!folder && (
            <Flex
              w={"50%"}
              maxW={"280px"}
              align={"center"}
              ml={"auto"}
              justify={"flex-end"}
              mr={1}
              gap={2}
            >
              {/* notes button */}
              <Button
                onClick={onOpenNotepad}
                leftIcon={<TbMessage size={"16px"} />}
                fontSize={"14px"}
                w={"fit-content"}
                h={"fit-content"}
                fontWeight={"500"}
                color={"blue.500"}
                borderColor={"blue.300"}
                borderWidth={1}
                borderStyle={"dashed"}
                bg={"transparent"}
                borderRadius={"30px"}
                transition={"all ease .2s"}
                py={1.5}
                px={4}
                gap={0}
                textAlign={"center"}
                _hover={{ bg: hexToRgba(colors.blue[300], 0.15) }}
                _focus={{ bg: hexToRgba(colors.blue[300], 0.15) }}
              >
                notes
              </Button>

              {/* reports button */}
              <Button
                onClick={onOpenReports}
                leftIcon={<HiOutlineDocumentDuplicate size={"16px"} />}
                fontSize={"14px"}
                w={"fit-content"}
                h={"fit-content"}
                fontWeight={"500"}
                color={"blue.500"}
                borderColor={"blue.300"}
                borderWidth={1}
                borderStyle={"dashed"}
                bg={"transparent"}
                borderRadius={"30px"}
                transition={"all ease .2s"}
                py={1.5}
                px={4}
                gap={0}
                textAlign={"center"}
                _hover={{ bg: hexToRgba(colors.blue[300], 0.15) }}
                _focus={{ bg: hexToRgba(colors.blue[300], 0.15) }}
              >
                reports
              </Button>

              {(folder.team_id?.length === 0 || !folder.team_id) &&
                folder?.access_list &&
                folder.access_list?.findIndex((x) => x.user_id === user.id) <
                  0 && (
                  <Button
                    onClick={onShareListOpen}
                    leftIcon={<TbShare size={"16px"} />}
                    fontSize={"14px"}
                    w={"fit-content"}
                    h={"fit-content"}
                    fontWeight={"500"}
                    color={"blue.500"}
                    borderColor={"blue.300"}
                    borderWidth={1}
                    borderStyle={"dashed"}
                    bg={"transparent"}
                    borderRadius={"30px"}
                    transition={"all ease .2s"}
                    py={1.5}
                    px={4}
                    gap={0}
                    textAlign={"center"}
                    _hover={{ bg: hexToRgba(colors.blue[300], 0.15) }}
                    _focus={{ bg: hexToRgba(colors.blue[300], 0.15) }}
                  >
                    share
                  </Button>
                )}
            </Flex>
          )}

          {/* Create Folder section */}
          {!folder && hasFoldersToShow && (
            <Flex w={"50%"} maxW={"280px"} align={"center"} ml={"auto"}>
              <NewFolderContent hasFolders={hasFoldersToShow} small={true} />
            </Flex>
          )}
        </Flex>

        <Box w={"100%"} h={"100%"}>
          <Outlet />
        </Box>
      </Flex>

      {/* notepad section */}
      <Notepad
        folder={folder}
        isOpen={isNotepadOpen}
        onClose={onCloseNotepad}
      />

      {/* reports section */}
      <Reports
        folder={folder}
        isOpen={isReportsOpen}
        onClose={onCloseReports}
      />

      <FolderAccessList
        folder={folder}
        isOpen={isShareListOpen}
        onClose={onShareListClose}
      />
    </>
  );
}
