import axios from "axios";

export const useLocationAPI = () => {
  const getLocation = async () => {
    const response = await axios.get("https://api.db-ip.com/v2/free/self");
    return response.data;
  };

  const getExchangeRates = async () => {
    const response = await axios.get("https://open.er-api.com/v6/latest/USD");
    return response.data;
  };

  return { getLocation, getExchangeRates };
};
