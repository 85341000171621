import { useNavigate } from "react-router-dom";
import { Flex, Heading, Text } from "@chakra-ui/react";
import { HashLink as Link } from "react-router-hash-link";

export default function Product() {
  // Navigation
  const navigate = useNavigate();

  return (
    <Flex
      direction={"column"}
      gap={{ base: 1, lg: 3 }}
      minW={{ base: "fit-content", md: "150px" }}
    >
      <Heading size={"md"}>Product</Heading>

      <Text
        fontSize={{ base: "13px", sm: "14px", lg: "16px" }}
        opacity={0.75}
        transition={"all 0.3s ease"}
        _hover={{ opacity: 1 }}
      >
        <Link to={"#usecases"} smooth>
          Use Cases
        </Link>
      </Text>

      <Text
        fontSize={{ base: "13px", sm: "14px", lg: "16px" }}
        opacity={0.75}
        transition={"all 0.3s ease"}
        _hover={{ opacity: 1 }}
      >
        <Link to={"#pricing"} smooth>
          Pricing
        </Link>
      </Text>

      <Text
        cursor={"pointer"}
        fontSize={{ base: "13px", sm: "14px", lg: "16px" }}
        opacity={0.75}
        transition={"all 0.3s ease"}
        _hover={{ opacity: 1, textDecoration: "none" }}
        onClick={() => navigate("/docs")}
      >
        Our API
      </Text>
    </Flex>
  );
}
