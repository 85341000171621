import { useState } from "react";
import { Flex, Icon, Td, Text, Tr, useClipboard } from "@chakra-ui/react";

import { MRNASequence } from "models/proteins";

import { FaCheck } from "react-icons/fa";
import { BiSolidCopy } from "react-icons/bi";

interface MRNASequenceRowProps {
  seq: MRNASequence;
}

function MRNASequenceRow({ seq }: MRNASequenceRowProps) {
  // States
  const [hasCopied, setHasCopied] = useState(false);
  const [show, setShow] = useState(false);

  // Hook
  const { onCopy } = useClipboard("");

  // Handler
  function handleCopy() {
    onCopy();
    setHasCopied(true);
  }

  return (
    <Tr
      _hover={{ bg: "gray.100" }}
      transition={"background 0.3s ease"}
      fontSize={[null, null, null, "12px", "12px", "14px"]}
      onMouseOver={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      {/* Sequence */}
      <Td
        fontSize={[null, null, null, "12px", "12px", "14px"]}
        borderColor={"gray.100"}
        maxW={"70%"}
        p={1.5}
      >
        <Flex maxW={{ lg: "450px", xl: "600px" }} align={"center"} gap={2}>
          <Text
            whiteSpace={"nowrap"}
            overflow={"hidden"}
            textOverflow={"ellipsis"}
          >
            {seq?.sequence}
          </Text>

          {show && (
            <Icon
              display={"inline"}
              color={hasCopied ? "highlight.primary" : "gray.500"}
              boxSize={"14px"}
              onClick={handleCopy}
              cursor={"pointer"}
              as={hasCopied ? FaCheck : BiSolidCopy}
              zIndex={10}
            />
          )}
        </Flex>
      </Td>

      {/* MFE */}
      <Td
        fontSize={[null, null, null, "12px", "12px", "14px"]}
        borderColor={"gray.100"}
        maxW={"10%"}
        p={1.5}
      >
        <Text
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
          maxW={{ lg: "55px", xl: "85px" }}
        >
          {seq?.mfe}
        </Text>
      </Td>

      {/* CAI */}
      <Td
        fontSize={[null, null, null, "12px", "12px", "14px"]}
        borderColor={"gray.100"}
        maxW={"10%"}
        p={1.5}
      >
        <Text
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
          maxW={{ lg: "55px", xl: "85px" }}
        >
          {seq?.cai}
        </Text>
      </Td>

      {/* Score */}
      <Td
        fontSize={[null, null, null, "12px", "12px", "14px"]}
        borderColor={"gray.100"}
        maxW={"10%"}
        p={1.5}
      >
        {seq?.combined_score ?? "--"}
      </Td>
    </Tr>
  );
}

export default MRNASequenceRow;
