import { environment } from "environments";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import { setCurentUserLimit } from "utils/helpers";
import { QueryKey } from "@tanstack/react-query";

export const useProteinsAPI = () => {
  const axiosPrivate = useAxiosPrivate();

  const fetchProteinById = async ({ queryKey }: { queryKey: QueryKey }) => {
    const [, id] = queryKey;
    const url = `${environment.BACKEND_API}/api/get_protein_by_id/${id}`;
    const response = await axiosPrivate.get(url);
    setCurentUserLimit();
    return response.data;
  };

  return { fetchProteinById };
};
