import { useDispatch, useSelector } from "react-redux";
import useGuideTour from "hooks/guideTour/useGuideTour";
import { Button, Icon } from "@chakra-ui/react";

import {
  onGuideClose,
  selectCurrentGuideData,
  setGuideOpen,
} from "redux/features/guide/guideSlice";

import { FaRegQuestionCircle } from "react-icons/fa";

function GuideButton() {
  // Hooks
  const dispatch = useDispatch();
  const { isGuideOpen } = useSelector(selectCurrentGuideData);
  const { getRoute, isHelpTourAvailable, isHighlighted } = useGuideTour();

  // Handler
  const handleClick = () => {
    if (isGuideOpen) {
      dispatch(onGuideClose());
    }

    if (!!isHelpTourAvailable) {
      dispatch(setGuideOpen({ route: getRoute() }));
    }
  };

  let highlighted = isHighlighted("help_tour");

  return (
    <>
      {!!isHelpTourAvailable ? (
        <Button
          id="help_tour"
          zIndex={highlighted ? 1350 : 999}
          bg={"secondary.600"}
          color={"background"}
          overflow={"hidden"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          h={"40px"}
          w={"40px"}
          py={"auto"}
          px={"10px"}
          borderRadius={"10px 10px 0 0"}
          alignSelf={"center"}
          fontWeight={"500"}
          fontSize={"14px"}
          letterSpacing={".05rem"}
          boxShadow={"0px 10px 10px rgba(0, 0, 0, 0.1)"}
          transition={"all 0.3s ease-out"}
          leftIcon={
            <Icon as={FaRegQuestionCircle} boxSize={"20px"} p={0} m={0} />
          }
          gap={2}
          _hover={{ w: "105px", opacity: 0.8, bg: "secondary.600" }}
          _focus={{ opacity: 1, bg: "secondary.600" }}
          onClick={handleClick}
        >
          Guide
        </Button>
      ) : (
        <></>
      )}
    </>
  );
}

export default GuideButton;
