import { axiosClient } from "api/axios";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentAuthData,
  setAccessToken,
  setUser,
} from "redux/features/auth/authSlice";

const useRefreshToken = () => {
  const dispatch = useDispatch();
  const { refreshToken } = useSelector(selectCurrentAuthData);
  const refresh = async () => {
    try {
      const response = await axiosClient.post(
        "/api/refresh",
        { refreshToken },
        {
          withCredentials: true,
        }
      );
      const { accessToken, user } = response.data;
      dispatch(setAccessToken(accessToken));
      dispatch(setUser(user));
      return { accessToken };
    } catch (error) {
      // dispatch(clearCredentials())
      return null;
    }
  };
  return refresh;
};

export default useRefreshToken;
