import { Link as RouterLink } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  AccordionButton,
  Box,
  Link,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Heading,
  chakra,
  useTheme,
} from "@chakra-ui/react";

import { hexToRgba } from "utils/helpers";
import { groupAssaysByKey } from "../helpers";
import { AssayHitsProps, ScreeningProps } from "models/assays/ScreeningProps";
import { indicationToColor } from "components/ui/helpers";

const StyledAccordionButton = chakra(AccordionButton, {
  baseStyle: {
    h: 12,
    _expanded: {
      bg: "primary.600",
      color: "white",
    },
  },
});

interface AssayTableProps {
  assays: (AssayHitsProps | ScreeningProps)[]; // Assays can be either AssayHitsProps or ScreeningProps
  isHit?: boolean;
}

function AssaysTable({ assays, isHit }: AssayTableProps) {
  // Theme
  const { colors } = useTheme();

  // Group assays by name and indication
  const groupedAssays = groupAssaysByKey(assays, "name"); // Assuming 'name' is the common key

  return (
    <Accordion allowMultiple defaultIndex={[]}>
      <AccordionItem>
        <Heading fontSize={"md"}>
          <AccordionButton
            color="secondary.600"
            pl={3}
            bg={hexToRgba(colors.secondary[300], 0.15)}
            h={14}
          >
            <Box
              as="span"
              flex="1"
              textAlign="left"
              fontSize={"md"}
              border={"none"}
            >
              {isHit ? "Activity data" : "Screening data"}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </Heading>

        <AccordionPanel
          p={0}
          pl={2}
          bg={useColorModeValue("gray.50", "background")}
        >
          {Object.entries(groupedAssays).map(([groupKey, assaysGroup], idx) => (
            <AccordionItem key={idx}>
              <Heading>
                <StyledAccordionButton>
                  <Box flex="1" textAlign="left">
                    {groupKey} {/*  common group key (e.g., assay name) */}
                  </Box>
                  <AccordionIcon />
                </StyledAccordionButton>
              </Heading>
              <AccordionPanel pb={4}>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th borderColor={"gray.100"} textAlign="left">
                        Assay Type
                      </Th>
                      <Th borderColor={"gray.100"} textAlign="left">
                        Indication
                      </Th>
                      {isHit ? (
                        <>
                          <Th borderColor={"gray.100"} textAlign="left">
                            Activity Type
                          </Th>
                          <Th borderColor={"gray.100"} textAlign="left">
                            Measurement
                          </Th>
                        </>
                      ) : (
                        <Th borderColor={"gray.100"} textAlign="left">
                          Screening Int
                        </Th>
                      )}
                      <Th borderColor={"gray.100"} textAlign="left">
                        Assay Source
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {assaysGroup.map((assay, index) => {
                      const url = assay?.url?.split("/");

                      return (
                        <Tr key={"assay-collection" + index} fontSize={13}>
                          <Td borderColor={"gray.100"} color={"secondary.600"}>
                            <Link
                              as={RouterLink}
                              target={"_blank"}
                              to={`/data/core/MoleculeLake/data/assays/${
                                url[url?.length - 1]
                              }`}
                            >
                              {assay.name}
                            </Link>
                          </Td>
                          <Td
                            borderColor={"gray.100"}
                            backgroundColor={indicationToColor(
                              assay.indication
                            )}
                            color={"black"}
                          >
                            {assay.indication}
                          </Td>
                          {isHit && "activity_type" in assay ? (
                            <>
                              <Td borderColor={"gray.100"}>
                                {assay.activity_type}
                              </Td>
                              <Td borderColor={"gray.100"}>
                                {assay.measurement}
                              </Td>
                            </>
                          ) : (
                            "screening_hit" in assay && (
                              <Td borderColor={"gray.100"} pr={1}>
                                <Text
                                  {...(assay.screening_hit ===
                                  "primary screening hit"
                                    ? { as: "b" }
                                    : {})}
                                >
                                  {assay.screening_hit}
                                </Text>
                              </Td>
                            )
                          )}
                          <Td borderColor={"gray.100"} color={"secondary.600"}>
                            {assay.assay_source}
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

export default AssaysTable;
