import { useState } from "react";
import { Flex, Heading, Text } from "@chakra-ui/react";

import DisclaimersModal from "components/home/DisclaimersModal";
import PrivacyPolicyModal from "components/home/PrivacyPolicyModal";

export default function Legal() {
  // State
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showDisclaimersModal, setShowDisclaimersModal] = useState(false);

  return (
    <>
      <Flex
        direction={"column"}
        gap={{ base: 1, lg: 3 }}
        minW={{ base: "fit-content", md: "150px" }}
      >
        <Heading size={"md"}>Legal</Heading>

        <Text
          cursor={"pointer"}
          fontSize={{ base: "13px", sm: "14px", lg: "16px" }}
          opacity={0.75}
          transition={"all 0.3s ease"}
          _hover={{ opacity: 1, textDecoration: "none" }}
          onClick={() => setShowPrivacy(true)}
        >
          Privacy Policy
        </Text>

        <Text
          cursor={"pointer"}
          fontSize={{ base: "13px", sm: "14px", lg: "16px" }}
          opacity={0.75}
          transition={"all 0.3s ease"}
          _hover={{ opacity: 1, textDecoration: "none" }}
          onClick={() => setShowDisclaimersModal(true)}
        >
          Disclaimers
        </Text>
      </Flex>

      {/* Modals */}
      <PrivacyPolicyModal
        isOpen={showPrivacy}
        onClose={() => setShowPrivacy(false)}
      />
      <DisclaimersModal
        isOpen={showDisclaimersModal}
        onClose={() => setShowDisclaimersModal(false)}
      />
    </>
  );
}
