import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NotificationProps } from "models/notifications/NotificationProps";
import { createSelector } from "reselect";

export interface NotificationsState {
  notifications: NotificationProps[];
  unreadCount: number;
  isLoading: boolean;
  error: string;
}

const initialState: NotificationsState = {
  notifications: [],
  unreadCount: 0,
  isLoading: false,
  error: "",
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotificationsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setNotifications: (state, action: PayloadAction<NotificationProps[]>) => {
      state.notifications = action.payload;
      state.unreadCount = action.payload.filter((n) => !n.is_read).length;
      state.isLoading = false;
    },
    addNotification: (state, action: PayloadAction<NotificationProps>) => {
      state.notifications.unshift(action.payload);
      if (!action.payload.is_read) {
        state.unreadCount += 1;
      }
    },
    deleteNotification: (state, action: PayloadAction<string>) => {
      state.notifications = state.notifications.filter(
        (n) => n.id !== action.payload
      );
    },
    markAsRead: (state, action: PayloadAction<string>) => {
      const notification = state.notifications.find(
        (n) => n.id === action.payload
      );
      if (notification && !notification.is_read) {
        notification.is_read = true;
        state.unreadCount -= 1;
      }
    },
    markAllAsRead: (state) => {
      state.notifications = state.notifications.map((notification) => ({
        ...notification,
        is_read: true,
      }));
      state.unreadCount = 0;
    },
    resetUnreadCount: (state) => {
      state.unreadCount = 0;
    },
    clearNotifications: (state) => {
      state.notifications = [];
      state.unreadCount = 0;
    },
  },
});

export const {
  setNotifications,
  addNotification,
  deleteNotification,
  markAsRead,
  markAllAsRead,
  resetUnreadCount,
  clearNotifications,
  setNotificationsLoading,
  setError,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;

const selectNotificationsState = (state: {
  notifications: NotificationsState;
}) => state.notifications;

export const selectCurrentNotificationsData = createSelector(
  [selectNotificationsState],
  (notifications) => ({
    notifications: notifications.notifications,
    unreadCount: notifications.unreadCount,
    isLoading: notifications.isLoading,
    error: notifications.error,
  })
);
