import { Dispatch, SetStateAction, useState } from "react";
import {
  Text,
  Flex,
  Icon,
  SimpleGrid,
  Button,
  Divider,
  useToast,
  Box,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";

import useAxiosPrivate from "hooks/auth/useAxiosPrivate";

import {
  selectCurrentAuthData,
  updateUserAttributes,
} from "redux/features/auth/authSlice";

import LastNameInput from "components/authforms/userDetailsInputs/LastNameInput";
import FirstNameInput from "components/authforms/userDetailsInputs/FirstNameInput";

import { IoMdSettings } from "react-icons/io";
import { RxUpdate } from "react-icons/rx";

interface UserDataProps {
  firstname: string;
  lastname: string;
}

export interface UserErrorsProps {
  firstname: string;
  lastname: string;
}
const GeneralSection = () => {
  // Auth
  const { user } = useSelector(selectCurrentAuthData);
  const isThirdPartyUser = user?.hasOwnProperty("identities");

  const u_firstname = user?.given_name ?? "";
  const u_lastname = user?.family_name ?? "";

  const toast = useToast();
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();

  // State
  const [isUpdating, setIsUpdating] = useState(false);

  const [userData, setUserData] = useState<UserDataProps>({
    firstname: u_firstname || "",
    lastname: u_lastname || "",
  });

  const [errors, setErrors] = useState<UserErrorsProps>({
    firstname: "",
    lastname: "",
  });

  // Handlers
  function handleChangeFirstName(input: string) {
    setUserData((prev) => ({ ...prev, firstname: input }));
  }

  function handleChangeLastName(input: string) {
    setUserData((prev) => ({ ...prev, lastname: input }));
  }

  function checkUserDataValidation(
    userData: UserDataProps,
    setErrors: Dispatch<SetStateAction<UserErrorsProps>>
  ) {
    const { firstname, lastname } = userData;

    // set error if first name is unvalid
    const isUnvalidFisrtName = firstname?.trim().length < 3;
    setErrors((prev: UserErrorsProps) => ({
      ...prev,
      firstname: isUnvalidFisrtName
        ? "Needs to be 8 characters long at least"
        : "",
    }));

    // set error if last name is unvalid
    const isUnvalidLastName = lastname?.trim().length < 3;
    setErrors((prev: UserErrorsProps) => ({
      ...prev,
      lastname: isUnvalidLastName
        ? "Needs to be 8 characters long at least"
        : "",
    }));

    return !isUnvalidFisrtName && !isUnvalidLastName;
  }

  function isFormDataUnchanged(newUser: UserDataProps) {
    const isUnchanged =
      u_firstname === newUser?.firstname && u_lastname === newUser?.lastname;

    return isUnchanged;
  }

  async function handleUpdateUserDetails() {
    // true if firstname and lastname are valid
    const isValidFormData = checkUserDataValidation(userData, setErrors);

    // if form data is invalid, don't send request
    if (!isValidFormData) return;

    // if user doesn't make any changes
    const isUnchagedFormData = isFormDataUnchanged(userData);

    if (isUnchagedFormData) {
      toast({
        position: "bottom-right",
        duration: 2000,
        render: () => (
          <Box color="white" p={3} bg={"secondary.600"} borderRadius={"6px"}>
            No changes to update
          </Box>
        ),
      });
      return;
    }

    setIsUpdating(true);

    const attributes = {
      given_name: userData?.firstname,
      family_name: userData?.lastname,
    };

    try {
      await axiosPrivate.put(`/api/profile`, {
        attributes,
      });

      dispatch(updateUserAttributes(attributes));

      toast({
        position: "bottom-right",
        duration: 2000,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={"highlight.primary"}
            borderRadius={"6px"}
          >
            Profile updated successfully
          </Box>
        ),
      });
    } catch (error) {
      toast({
        position: "bottom-right",
        duration: 2000,
        render: () => (
          <Box color={"white"} p={3} bg={"red"} borderRadius={"6px"}>
            Failed to update profile, try again
          </Box>
        ),
      });
    }

    setIsUpdating(false);
    setErrors({
      firstname: "",
      lastname: "",
    });
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      handleUpdateUserDetails();
    }
  }
  return (
    <Flex gap={2} direction={"column"}>
      <Flex gap={2} align={"center"}>
        <Icon as={IoMdSettings} boxSize={"18px"} opacity={0.8} />
        <Text fontWeight={"600"} lineHeight={1}>
          General
        </Text>
      </Flex>

      <Divider my={1} />

      <SimpleGrid templateColumns={"repeat(2, 1fr)"} columnGap={6}>
        <FirstNameInput
          error={errors?.firstname}
          value={userData?.firstname}
          isReadOnly={isThirdPartyUser}
          onKeyDown={handleKeyDown}
          onChange={handleChangeFirstName}
        />
        <LastNameInput
          error={errors?.lastname}
          value={userData?.lastname}
          isReadOnly={isThirdPartyUser}
          onKeyDown={handleKeyDown}
          onChange={handleChangeLastName}
        />
      </SimpleGrid>

      <Button
        type={"submit"}
        borderRadius={"30px"}
        bg={isThirdPartyUser ? "gray.500" : "highlight.primary"}
        color={"background"}
        h={"fit-content"}
        w={"fit-content"}
        py={2}
        px={5}
        fontSize={"14px"}
        fontWeight={"500"}
        isLoading={isUpdating}
        loadingText={"Updating..."}
        leftIcon={<RxUpdate size={"16px"} />}
        letterSpacing={".02rem"}
        _active={{ bg: "highlight.primary" }}
        _hover={{ bg: "highlight.primary" }}
        _focus={{ bg: "highlight.primary" }}
        _focusWithin={{ bg: "highlight.primary" }}
        opacity={isThirdPartyUser ? 0.7 : 1}
        pointerEvents={isThirdPartyUser ? "none" : "auto"}
        onClick={handleUpdateUserDetails}
      >
        Update
      </Button>
    </Flex>
  );
};

export default GeneralSection;
