import { Flex, Text, Icon, useRadioGroup } from "@chakra-ui/react";
import FeedbackType from "./FeedbackType";
import { Type, types } from "./helpers";
import { IconType } from "react-icons";

interface FeedbackTypesProps {
  selectedType: string;
  setSelectedType: (type: string) => void;
}

export default function FeedbackTypes({
  selectedType,
  setSelectedType,
}: FeedbackTypesProps) {
  const { getRadioProps } = useRadioGroup({
    name: "type",
    defaultValue: selectedType,
    onChange: setSelectedType,
  });

  return (
    <Flex direction="column" gap={3}>
      <Text
        fontSize="14px"
        color={"gray.600"}
        lineHeight="1.3"
        fontWeight={"500"}
      >
        What type of feedback would you like to provide?
      </Text>

      <Flex direction={"column"} gap={3}>
        {types.map((type: Type) => {
          const radio = getRadioProps({ value: type.name });
          const { name, isChecked } = radio;

          const icon: IconType = isChecked ? type.solid : type.outline;

          return (
            <FeedbackType key={type.name} {...radio} name={name}>
              <Icon as={icon} boxSize={4} />
              {type.name}
            </FeedbackType>
          );
        })}
      </Flex>
    </Flex>
  );
}
