import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";
import AccordionHeader from "components/ui/AccordionHeader";
import { frequentlyAsked } from "views/home/helpers";

export default function FAQ() {
  return (
    <Flex
      direction="column"
      align={"center"}
      maxW={"800px"}
      mx={"auto"}
      w={"85%"}
      my={"80px"}
      gap={10}
    >
      {/* Heading */}
      <Flex direction={"column"} textAlign={"center"} gap={4}>
        <Text fontSize={"md"} color="secondary.500" fontFamily={"monospace"}>
          {"Frequent Asked Questions".toUpperCase()}
        </Text>
        <Heading size={"lg"} fontFamily={"monospace"}>
          {"Your Questions, Answered"}
        </Heading>
      </Flex>

      <Accordion w={"full"} allowToggle>
        {frequentlyAsked &&
          frequentlyAsked?.map((q, idx) => (
            <AccordionItem border={"none"} my={1} key={"faq-" + idx}>
              <AccordionHeader text={q?.title} isBold />
              <AccordionPanel pb={4} color={"black"} opacity={0.8}>
                <Flex direction={"column"} gap={4}>
                  <Text fontSize={{ base: "sm", lg: "md" }}>
                    {q?.description}
                  </Text>
                </Flex>
              </AccordionPanel>
            </AccordionItem>
          ))}
      </Accordion>
    </Flex>
  );
}
