import { Button, Icon } from "@chakra-ui/react";
import { IconType } from "react-icons";

interface UserAccessProps {
  icon: IconType;
  state: string;
  onAccess: () => void;
  isLoading?: boolean;
}

function UserAccess({ icon, state, onAccess, isLoading }: UserAccessProps) {
  return (
    <Button
      py={2}
      px={{ base: 2, sm: 3 }}
      w={"fit-content"}
      h={"fit-content"}
      minH={"34px"}
      fontWeight={"500"}
      fontSize={{ base: "12px", md: "13px", lg: "16px" }}
      color={{ base: "gray.700", lg: "whiteAlpha.900" }}
      bg={{ base: "gray.100", lg: "whiteAlpha.300" }}
      letterSpacing={"0.03rem"}
      _hover={{ opacity: 0.8 }}
      _focus={{ opacity: 0.8 }}
      _focusWithin={{ opacity: 1 }}
      _active={{ opacity: 1 }}
      isLoading={isLoading}
      onClick={onAccess}
      transition={"all .3s ease"}
    >
      <Icon
        display={"inline-block"}
        fontSize={{ base: "xs", md: "sm", lg: "md" }}
        as={icon}
        mr={"2"}
      />
      {state}
    </Button>
  );
}

export default UserAccess;
