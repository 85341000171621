import { Flex } from "@chakra-ui/react";
import { HashLink as Link } from "react-router-hash-link";

interface NavItemProps {
  to: string;
  text: string;
}

export default function NavItem({ to, text }: NavItemProps) {
  return (
    <Flex
      position={"relative"}
      my={["2px", "4px", "6px"]}
      color="whiteAlpha.900"
      _after={{
        content: '""',
        display: "block",
        position: "absolute",
        bottom: "-5px",
        left: 0,
        height: "3px",
        width: "100%",
        opacity: 0,
        borderRadius: "10px",
        bg: "secondary.600",
        transition: "all 0.3s",
        pointerEvents: "none",
      }}
      _hover={{ _after: { opacity: 0.5 } }}
    >
      <Link to={to} smooth>
        {text}
      </Link>
    </Flex>
  );
}
