import { Divider, Flex, Spacer, Text } from "@chakra-ui/react";

import Legal from "components/home/footer/Legal";
import Product from "components/home/footer/Product";
import Company from "components/home/footer/Company";
import Copyright from "components/home/footer/Copyright";

export default function Footer() {
  return (
    <Flex
      minH={"650px"}
      bg="secondary.800"
      color="white"
      py={{ base: "60px", lg: "80px" }}
    >
      {/* Content */}
      <Flex direction={"column"} mx={"auto"} gap={8} p={6} w={"90%"}>
        <Flex
          direction={{ base: "column", lg: "row" }}
          gap={[6, 6, 2, 4, 8, 10]}
        >
          {/* Logo */}
          <Flex
            direction={"column"}
            align={{ base: "center", lg: "flex-start" }}
            justify={"flex-start"}
            flexWrap={"nowrap"}
          >
            <Text
              fontFamily={"monospace"}
              fontSize={{ base: "30px", lg: "60px" }}
              fontWeight={"500"}
              mb={-2}
            >
              Lakesai
            </Text>
            <Text
              fontWeight={"500"}
              fontSize={["12px", "14px", "16px", "18px", "18px", "20px"]}
              pt={{ base: "6px", lg: "0" }}
            >
              Accelerate your scientific discoveries
            </Text>
          </Flex>

          <Spacer display={{ base: "none", lg: "inline-block" }} />

          <Flex
            direction={{ base: "column", sm: "row" }}
            gap={[6, 2, 1, 4, 8, 10]}
            align={"start"}
            justify={{ base: "space-between", lg: "flex-start" }}
            pt={6}
          >
            <Company />
            <Product />
            <Legal />
          </Flex>
        </Flex>

        <Spacer />

        <Divider />

        <Copyright />
      </Flex>
    </Flex>
  );
}
