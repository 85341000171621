import { useState } from "react";
import { Flex, Icon, Text, useTheme } from "@chakra-ui/react";

import { hexToRgba } from "utils/helpers";
import { timeSince } from "../helpers";

import { FaRegTrashAlt } from "react-icons/fa";

export interface NoteProps {
  note_id: string;
  content: string;
  created_at: string;
}

interface Props extends NoteProps {
  onDeleteNote: () => void;
}

function Note({ note_id, content, created_at, onDeleteNote }: Props) {
  // State
  const [show, setShow] = useState<boolean>(false);

  // Theme
  const { colors } = useTheme();

  return (
    <Flex position={"relative"} gap={1}>
      <Flex
        direction={"column"}
        gap={2}
        bg={hexToRgba(colors.blue[300], 0.08)}
        p={2}
        borderColor={hexToRgba(colors.blue[300], 0.15)}
        borderWidth={1}
        borderRadius={"md"}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        w={"100%"}
      >
        <Text
          fontSize={"14px"}
          color={"gray.700"}
          lineHeight={"1.4"}
          w={"100%"}
        >
          {content}
        </Text>
        <Flex align={"center"} justify={"space-between"} w={"100%"}>
          <Text fontSize={"10px"} color={"secondary.500"} lineHeight={"1.4"}>
            {timeSince(new Date(created_at))}
          </Text>

          {show && (
            <Icon
              borderRadius={"sm"}
              onClick={(e) => {
                e.stopPropagation(); // Prevent the Box from being triggered
                onDeleteNote();
              }}
              cursor={"pointer"}
              as={FaRegTrashAlt}
              boxSize={"12px"}
              color={"gray.500"}
              _hover={{ color: "red.500" }}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Note;
