import { Flex, Icon, Text } from "@chakra-ui/react";
import { BsQuestionCircle } from "react-icons/bs";

interface FollowUpQuestionsProps {
  // questions: string[] | undefined; // NOTE: Added by Anitah, muted by Amin
  questions: string[];
  onSendFollowUpQuestion: (q: string) => void;
}

function FollowUpQuestions({
  questions,
  onSendFollowUpQuestion,
}: FollowUpQuestionsProps) {
  return (
    <Flex direction={"column"} gap={1.5} mt={4}>
      <Text fontSize={"14px"} fontWeight={"500"} color={"highlight.primary"}>
        Related Questions
      </Text>

      <Flex gap={2}>
        {/* NOTE: slice only first 2 questions to be displayed */}
        {questions?.slice(0, 2).map((q, index) => (
          <Flex
            key={index}
            gap={1.5}
            align={"stretch"}
            color={"gray.500"}
            borderWidth={1}
            borderColor="gray.300"
            borderRadius={4}
            p={2}
            w={"full"}
            cursor={"pointer"}
            _hover={{ color: "gray.500", borderColor: "gray.500" }}
          >
            <Icon as={BsQuestionCircle} h={4} w={4} mt={0.5} />
            <Text
              fontSize={"13px"}
              lineHeight={"1.3"}
              fontWeight={"500"}
              onClick={() => onSendFollowUpQuestion(q)}
            >
              {q}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}

export default FollowUpQuestions;
