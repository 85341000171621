import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";

import { Flex, Input, Text, useToast } from "@chakra-ui/react";

import FeedbackArea from "./FeedbackArea";
import SubmitButton from "./SubmitButton";
import FeedbackTypes from "./FeedbackTypes";
import AttachFileArea from "./AttachFileArea";
import UploadedFiles from "./UploadedFiles";
import {
  sendEmail,
  handleRemoveFile,
  handleFileChange,
  handleFileValidation,
  validTypes,
} from "./helpers";

const MAX_FILE_COUNT = 10;
const MAX_TOTAL_SIZE = 25;

interface SubmitFeedbackFormProps {
  setSent: (sent: boolean) => void;
  setHasError: (hasError: boolean) => void;
  hasError: boolean;
}

export interface FieldProps<T> {
  value: T;
  invalid: boolean;
  error: string;
}

export interface FormDataProps {
  files: FieldProps<File[]>;
}

function SubmitFeedbackForm({
  hasError,
  setSent,
  setHasError,
}: SubmitFeedbackFormProps) {
  // States
  const [message, setMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [selectedType, setSelectedType] = useState("Compliment");
  const [files, setFileUpload] = useState<FieldProps<File[]>>({
    value: [],
    invalid: false,
    error: "",
  });

  // Hooks
  const toast = useToast();
  const axiosPrivate = useAxiosPrivate();
  const { user } = useSelector(selectCurrentAuthData);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const fullName = user?.email?.split("@")[0] || "";

  return (
    <Flex
      as="form"
      direction="column"
      onSubmit={(e) =>
        sendEmail(
          e,
          message,
          files,
          user,
          fullName,
          setSubmitting,
          setSent,
          setMessage,
          setFileUpload,
          toast,
          axiosPrivate,
          MAX_TOTAL_SIZE,
          selectedType
        )
      }
    >
      <Input value={fullName} type="hidden" name="fullname" />
      <Input value={user?.email} type="hidden" name="email" />
      <FeedbackTypes
        selectedType={selectedType}
        setSelectedType={setSelectedType}
      />
      <Flex direction="column" mt={8} gap={2}>
        <FeedbackArea
          message={message}
          onMessageChange={(e) => setMessage(e.target.value)}
        />
        <AttachFileArea
          accept={validTypes.join(",")}
          handleFileChange={(e) =>
            handleFileChange(e, (filesList) =>
              handleFileValidation(
                filesList,
                MAX_FILE_COUNT,
                validTypes,
                files.value,
                setFileUpload
              )
            )
          }
          onButtonClick={() => inputRef.current?.click()}
          error={files.error}
        />
        <UploadedFiles
          files={files.value}
          handleRemoveFile={(fileName) =>
            handleRemoveFile(fileName, setFileUpload)
          }
        />
        <Text color={"gray.400"} fontSize={"12px"}>
          By pressing Submit, your feedback will be used to improve this
          Vitafluence product.
        </Text>
      </Flex>
      <SubmitButton
        submitting={submitting}
        text={submitting ? "Submitting..." : "Submit"}
      />
    </Flex>
  );
}

export default SubmitFeedbackForm;
