import { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  chakra,
} from "@chakra-ui/react";

import { useAssaysAPI } from "api/useAssaysAPI";
import { CoreContext } from "views/library/core";

import Loading from "components/ui/Loading";
import MainPanelError from "components/ui/MainPanelError";
import ActionButton from "components/buttons/ActionButton";
import ActivityChart from "components/assays/ActivityChart";
import { DetailedProtocol } from "components/assays/DetailedProtocol";

import FoldersModal from "components/library/bookmarks/modal/FoldersModal";
import { AssayChartItemProps, AssayDataProps } from "models/assays/AssayProps";

import { errorHandler } from "utils/helpers";
import { BsBookmarkCheck } from "react-icons/bs";
import { properties } from "components/assays/helpers";

export default function AssayView() {
  // Hooks
  const { sourceItemId: id } = useParams();
  const { setSourceItemName } = useContext(CoreContext);

  // States
  const [showSaveModal, setShowSaveModal] = useState(false);

  // APIs
  const { fetchAssaysById } = useAssaysAPI();

  // API
  // * Fetch assay
  const {
    isLoading: assayIsLoading,
    error: assayError,
    data: assay,
  } = useQuery({
    queryKey: ["assay", id],
    queryFn: fetchAssaysById,
    staleTime: 40 * 1000 * 60,
    gcTime: 30 * 1000 * 60,
  });

  useEffect(() => {
    if (assay) {
      setSourceItemName(assay?.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assay]);

  const chartData = useMemo(() => {
    if (id) {
      const assayData: AssayChartItemProps[] = (assay?.assay_data ?? []).map(
        (obj: AssayDataProps) => ({
          id: obj?.calibr_id ?? "",
          name: obj?.name ?? "",
          value: !!obj?.ac50 ? [obj?.ac50] : [],
          assay_type: obj?.assay_type ?? "",
          ac_precision: obj?.ac_precision ?? "",
          efficacy: !!obj?.efficacy ? [obj?.efficacy] : [],
          r_sq: !!obj?.r_sq ? [obj?.r_sq] : [],
          smiles: obj?.smiles ?? "",
        })
      );

      const groupedData: { [key: string]: AssayChartItemProps } = {};

      assayData.forEach((item: AssayChartItemProps) => {
        const {
          id,
          name,
          value,
          assay_type,
          ac_precision,
          efficacy,
          r_sq,
          smiles,
        } = item;

        if (!groupedData[id]) {
          groupedData[id] = {
            id,
            name,
            value: [],
            efficacy: [],
            r_sq: [],
            assay_type,
            ac_precision,
            smiles,
          };
        }

        if (value && Array.isArray(groupedData[id]?.value)) {
          groupedData[id]?.value?.push(...value);
        }

        if (efficacy && Array.isArray(groupedData[id]?.efficacy)) {
          groupedData[id]?.efficacy?.push(...efficacy);
        }

        if (r_sq && Array.isArray(groupedData[id]?.r_sq)) {
          groupedData[id]?.r_sq?.push(...r_sq);
        }
      });

      const uniqueObjects = Object.values(groupedData);

      const rmDups = uniqueObjects.map((item: AssayChartItemProps) => {
        const uniquePotencyValues = new Set(item.value);
        const uniqueEfficacyValues = new Set(item.efficacy);
        const uniqueRsqValues = new Set(item.r_sq);

        return {
          ...item,
          value: Array.from(uniquePotencyValues),
          efficacy: Array.from(uniqueEfficacyValues),
          r_sq: Array.from(uniqueRsqValues),
        };
      });

      return rmDups;
    } else return [];
  }, [id, assay]);

  const StyledText = chakra(Text, {
    baseStyle: {
      textDecoration: "none",
      color: "blue.500",
      transition: "color 0.3s",
      cursor: "pointer",
      fontSize: "15px",

      "&:hover": { color: "blue.300" },
    },
  });

  // if error
  if (!!assayError)
    return (
      <Flex h={"100%"} w={"100%"} my={10}>
        <MainPanelError errorMessage={errorHandler(assayError).message} />
      </Flex>
    );

  // if Loading
  if (assayIsLoading)
    return (
      <Flex
        direction={"column"}
        h={"100%"}
        w={"100%"}
        my={10}
        align={"center"}
        justify={"center"}
      >
        <Loading message="Loading assay data.." />
      </Flex>
    );

  return (
    <Box>
      {assay ? (
        <Card bg={"transparent"} boxShadow={"none"} p={0}>
          <CardHeader>
            {/* Header */}
            <Flex gap={5} align={"center"}>
              {/* Assay name & id */}
              <Flex
                w={"full"}
                justify={"space-between"}
                align={"center"}
                gap={2}
              >
                <Heading
                  size={[null, null, null, "sm", "sm", "md"]}
                  color={"highlight.primary"}
                  fontWeight="bold"
                  w={"90%"}
                  maxW={"700px"}
                >
                  {assay.name}
                </Heading>

                <Text
                  fontSize={[null, null, null, "sm", "sm", "md"]}
                  color="gray.400"
                  w={"fit-content"}
                  textAlign={"right"}
                >
                  {assay.assay_id}
                </Text>
              </Flex>

              {/* Save button */}
              <ActionButton
                aria-label={"Save assay"}
                label={"Save assay"}
                placement={"left"}
                icon={<BsBookmarkCheck />}
                onClick={() => setShowSaveModal(true)}
              />
            </Flex>

            {/* authors */}
            <Box my={2} w={"90%"}>
              <Text color="gray.400" fontSize={"14px"}>
                — {assay.authors}
              </Text>
            </Box>
          </CardHeader>

          <CardBody
            color={"gray.700"}
            fontSize={[null, null, null, "sm", "sm", "md"]}
          >
            <Card bg={"background"} p={4}>
              <Box w="90%" mb={4} color={"gray.700"}>
                <Text>{assay.summary}</Text>
              </Box>

              {/* properties */}
              <Flex alignItems="center">
                {assay.overview && (
                  <Box>
                    {properties?.map(([label, key]) => (
                      <Flex alignItems="center" key={label}>
                        <Text
                          flexWrap="wrap"
                          color={"gray.700"}
                          display={"flex"}
                          gap={1}
                        >
                          <Text fontWeight={"500"}>
                            <Text as={"span"} color={"blue.500"} mr={1}>
                              ⬦
                            </Text>
                            {label}:
                          </Text>

                          {["indication", "assay_type"].includes(key) ? (
                            <StyledText>
                              {assay.overview[
                                key as keyof typeof assay.overview
                              ] || ""}
                            </StyledText>
                          ) : (
                            assay.overview[
                              key as keyof typeof assay.overview
                            ] || ""
                          )}
                        </Text>
                      </Flex>
                    ))}
                  </Box>
                )}
              </Flex>
            </Card>

            <Tabs
              isLazy
              isManual
              variant="enclosed-colored"
              mt={8}
              height={"100vh"}
            >
              <TabList>
                <Tab
                  borderColor={"secondary.100"}
                  _selected={{ color: "white", bg: "secondary.400" }}
                >
                  Assay Data
                </Tab>
                <Tab
                  borderColor={"secondary.100"}
                  _selected={{ color: "white", bg: "secondary.400" }}
                >
                  Detailed Protocol
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel pl={0} mt={3}>
                  <ActivityChart chartData={chartData} />
                </TabPanel>
                <TabPanel pl={0}>
                  <DetailedProtocol assay={assay} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </CardBody>
        </Card>
      ) : (
        <Text textAlign={"center"}>No data for selected assay</Text>
      )}

      {/* Modal */}
      {id && (
        <FoldersModal
          isOpen={showSaveModal}
          onClose={() => setShowSaveModal(false)}
          payload={{
            saveElementPayload: {
              elementType: "ASSAY",
              content: {
                elementId: id,
              },
            },
            successMessage: `Assay is successfully saved.`,
          }}
        />
      )}
    </Box>
  );
}
