import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Flex, SimpleGrid } from "@chakra-ui/react";

import useWS from "hooks/chat/useWS";

import Loading from "components/ui/Loading";
import NewChatHeading from "./NewChatHeading";
import { ChatbotContext } from "views/chatbot";
import HumanMessage from "../question/HumanMessage";
import LoadingBubble from "../response/LoadingBubble";
import SuggestedQuestion, { SuggestedQuestionProps } from "./SuggestedQuestion";

import { suggestedQuestions } from "../helpers";

function NewChatPanel() {
  // Contexts
  const {
    waitingOnBot,
    activeSession,
    loadingSessions,
    attachedFiles,
    uploadedAudio,
  } = useContext(ChatbotContext);

  const {
    questionOnWait,
    messagesRef,
  }: {
    questionOnWait: string | undefined;
    messagesRef: React.RefObject<HTMLDivElement>;
  } = useOutletContext();

  // Hooks
  const { notifications, partialReply } = useWS({ waitingOnBot });

  // true if user asks question in new chat
  const isActiveSession = useMemo(
    () => activeSession === "new",
    [activeSession]
  );

  // States
  const [scrolledUp, setScrolledUp] = useState(false);

  // Refs
  const previousScrollTop = useRef(0); // Track previous scrollTop

  const isNewChat = !isActiveSession && !loadingSessions;
  const hasAttachedFiles = !!attachedFiles?.length;

  const hasAttachments = hasAttachedFiles || !!uploadedAudio;
  const hasQuestionOnWait = !!questionOnWait || hasAttachments;

  const canScroll = !!waitingOnBot || hasAttachedFiles;

  const isTransitionPhase = !isActiveSession && loadingSessions;
  const isActiveWaiting = !!waitingOnBot && isActiveSession; // when user waits on bot reply, and still in new chat route

  // Handlers
  function scrollToBottomWithBehavior(smoothScroll = true) {
    if (messagesRef.current) {
      const { scrollHeight } = messagesRef.current;

      smoothScroll
        ? messagesRef.current.scrollTo({
            top: scrollHeight,
            behavior: "smooth",
          })
        : (messagesRef.current.scrollTop = scrollHeight);
    }
  }

  function handleScrollUp() {
    if (canScroll) {
      const currentScrollTop = messagesRef.current!.scrollTop;

      if (currentScrollTop < previousScrollTop.current) {
        // Remove event listener after first scroll up
        messagesRef.current!.removeEventListener("scroll", handleScrollUp);

        setScrolledUp(true);
      }

      // Update previous scrollTop value for next check
      if (previousScrollTop.current !== currentScrollTop) {
        previousScrollTop.current = currentScrollTop;
      }
    }
  }

  // scroll up event listener
  useEffect(() => {
    const currentRef = messagesRef.current;
    if (currentRef && !scrolledUp && !!waitingOnBot) {
      currentRef.addEventListener("scroll", handleScrollUp);
    } else return;

    return () => {
      if (currentRef && !scrolledUp && !!waitingOnBot) {
        currentRef.removeEventListener("scroll", handleScrollUp);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesRef, waitingOnBot]);

  // smooth scroll on streaming reply data
  useEffect(() => {
    const autoScrollEnabled = !scrolledUp && partialReply.length;

    if (autoScrollEnabled) {
      scrollToBottomWithBehavior(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partialReply]);

  // smooth scroll on question submitted
  useEffect(() => {
    !!canScroll ? scrollToBottomWithBehavior(true) : setScrolledUp(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitingOnBot, hasAttachedFiles]);

  if (isNewChat) {
    return <Welcome />;
  }

  if (isTransitionPhase) {
    return (
      <Flex
        w={"100%"}
        mt={"calc(50vh - 25px)"}
        justify={"center"}
        align={"center"}
      >
        <Loading message="Loading conversation.." />
      </Flex>
    );
  }

  return (
    <>
      {/* submit qst in new chat */}
      {isActiveWaiting ? (
        <Flex
          direction={"column"}
          justify={"flex-end"}
          align={"flex-end"}
          h={"100%"}
          w={"900px"}
          maxW={"90%"}
          mx={"auto"}
          py={"24px"}
          position={"relative"}
          _focusVisible={{ border: "none", outline: "none" }}
        >
          {/* temporary conversation thread:
                question + partial reply + notifications */}
          <Flex direction={"column"} px={2} pr={6} w={"100%"}>
            <Flex w="100%" mt={4} pl={"48px"}>
              <Flex
                alignContent={"flex-start"}
                width={"fit-content"}
                display={"inline-block"}
                ml={"auto"}
              >
                {hasQuestionOnWait && (
                  <HumanMessage questionOnWait={questionOnWait} />
                )}
              </Flex>
            </Flex>
            <LoadingBubble
              partialReply={partialReply}
              notifications={notifications}
            />
          </Flex>
        </Flex>
      ) : (
        <></>
      )}
    </>
  );
}

export default NewChatPanel;

function Welcome() {
  // Context
  const { handleSendQuestion }: { handleSendQuestion: (q: string) => void } =
    useOutletContext();

  return (
    <Flex
      direction={"column"}
      align={"center"}
      justify={"flex-end"}
      gap={{ lg: "20px", xl: "40px" }}
      w={"900px"}
      maxW={[null, null, null, "90%", "85%", "100%"]}
      mx={"auto"}
      py={{ lg: 4, xl: 6 }}
      px={{ lg: 2, xl: 3 }}
      h={"100%"}
      position={"absolute"}
      inset={0}
    >
      {/* Heading with icon */}
      <Flex h={"100%"} align={"center"}>
        <NewChatHeading />
      </Flex>

      {/* suggested questions */}
      <SimpleGrid
        w={"100%"}
        mx={"auto"}
        templateColumns={[
          null,
          null,
          null,
          "repeat(1, 1fr)",
          "repeat(2, 1fr)",
          "repeat(2, 1fr)",
        ]}
        templateRows={[
          null,
          null,
          null,
          "repeat(4, 1fr)",
          "repeat(2, 1fr)",
          "repeat(2, 1fr)",
        ]}
        spacing={[null, null, null, "8px", "16px", "18px"]}
      >
        {suggestedQuestions.map(
          (
            item: Omit<SuggestedQuestionProps, "onSendSuggestedQuestion">,
            index: number
          ) => (
            <SuggestedQuestion
              key={index}
              question={item.question}
              header={item.header}
              onSendSuggestedQuestion={handleSendQuestion}
            />
          )
        )}
      </SimpleGrid>
    </Flex>
  );
}
