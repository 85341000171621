import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { Box, Flex, Icon, Text, useTheme, useToast } from "@chakra-ui/react";

import { LibFeatures } from "./helpers";
import { useLastViewPath } from "hooks/library/persistPath";
import { hexToRgba, resetCurentUserLimit } from "utils/helpers";
import useFetchSavedTools from "hooks/tools/useFetchSavedTools";
import useFetchSavedElements from "hooks/bookmarks/useFetchSavedElements";

import { FaCheckCircle } from "react-icons/fa";

export default function DataLibrary() {
  // Hooks
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const { colors } = useTheme();
  // const { getLastPath } = useLastViewPath(); // TODO: review

  useFetchSavedElements();
  useFetchSavedTools();
  resetCurentUserLimit();

  useEffect(() => {
    const toastMessage = (status: string) => {
      const themeColor =
        status === "success" ? "highlight.primary" : "gray.500";
      const message =
        status === "success"
          ? "Subscription plan changed successfully."
          : "Subscription plan successfully changed to Free.";

      toast({
        position: "bottom",
        duration: 10000,
        render: () => (
          <Flex
            borderRadius={"6px"}
            borderWidth={1}
            borderColor={themeColor}
            bg={themeColor}
            color={"white"}
            p={3}
            align={"center"}
            gap={2}
          >
            <Icon as={FaCheckCircle} boxSize={"18px"} />
            <Flex direction={"column"}>
              {status === "success" && (
                <Text fontSize={"16px"} fontWeight={"500"}>
                  Thank you!
                </Text>
              )}
              <Text
                fontFamily={"Poppins, sans-serif"}
                fontSize={{ lg: "13px", xl: "14px" }}
                lineHeight={"1.3"}
              >
                {message}
              </Text>
            </Flex>
          </Flex>
        ),
      });
    };

    const searchParams = new URLSearchParams(location.search);
    let params = searchParams.get("p");
    if (params) {
      toastMessage(params);
    }

    // TODO: review
    // const lastPath = getLastPath();
    // if (lastPath) {
    //   navigate(lastPath);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, toast]);

  return (
    <>
      <Flex
        w={"95%"}
        h={"100%"}
        mx={"auto"}
        maxW={"1200px"}
        direction={"column"}
        overflow={"hidden"}
      >
        {/* Tabs */}
        <Flex w={"100%"} justify={"center"} align={"center"} p={3}>
          <Flex w={"fit-content"} h={"fit-content"}>
            {LibFeatures.map((title, index) => {
              const isSelected = location.pathname.includes(
                title.toLowerCase()
              );
              const isRoot = location.pathname === "/data" && index === 0;
              const isActive = isSelected || isRoot;

              return (
                <Box
                  key={index}
                  py={2}
                  px={5}
                  cursor="pointer"
                  w={"fit-content"}
                  textAlign={"center"}
                  borderRadius={"99px"}
                  bg={
                    isActive ? hexToRgba(colors.blue[300], 0.15) : "transparent"
                  }
                  color={isActive ? "blue.500" : "gray.500"}
                  onClick={() => navigate(`/data/${title.toLowerCase()}`)}
                  transition={"all .3s ease"}
                >
                  <Text
                    fontSize={"14px"}
                    fontFamily={"Poppins, sans-serif"}
                    fontWeight={"500"}
                    letterSpacing={"-0.01rem"}
                    textTransform={"capitalize"}
                  >
                    {title}
                  </Text>
                </Box>
              );
            })}
          </Flex>
        </Flex>

        {/* Content */}
        <Box w={"100%"} h={"100%"}>
          <Outlet />
        </Box>
      </Flex>
    </>
  );
}
