import { Flex, Text } from "@chakra-ui/react";
import { getPaperColor } from "components/ui/helpers";
import { PaperProps } from "models/papers/PaperProps";

interface TypeAndYearProps {
  data: PaperProps;
}

interface PaperTypeProps {
  type: string;
}

export default function TypeAndYear({ data }: TypeAndYearProps) {
  return (
    <Flex
      justify={"space-between"}
      align={"center"}
      w={"full"}
      pb={2}
      mb={2}
      borderBottomColor={"gray.200"}
      borderBottomWidth={1}
    >
      {/* paper type */}
      <PaperType type={data?.paper_type ?? "Unclassified"} />

      {/* publication year */}
      <Text
        color={"gray.500"}
        fontSize={[null, null, null, "12px", "12px", "14px"]}
      >
        {data.year_published || null}
      </Text>
    </Flex>
  );
}

function PaperType({ type }: PaperTypeProps) {
  return (
    <Text
      fontSize={[null, null, null, "11px", "11px", "12px"]}
      fontWeight={"500"}
      textTransform={"uppercase"}
      lineHeight="1.3"
      w="fit-content"
      color={getPaperColor(type)}
      borderRadius="3px"
      padding="2px 4px"
    >
      {type ?? "Unclassified"}
    </Text>
  );
}
