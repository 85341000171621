import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { Flex, Text, Icon, Heading } from "@chakra-ui/react";

import { AxiosError } from "axios";
import { useUtrsAPI } from "api/useUtrsAPI";
import Loading from "components/ui/Loading";
import { CoreContext } from "views/library/core";
import { Utr } from "models/utrs";
import MainPanelError from "components/ui/MainPanelError";

import { errorHandler } from "utils/helpers";
import { BsFileEarmarkX } from "react-icons/bs";
import UtrDetail from "./UtrDetail";

function UtrsView() {
    // Hooks
    const { sourceItemId: id, source: source } = useParams();
    const { setSourceItemName } = useContext(CoreContext);
    const { fetchThreeUtrByById, fetchFiveUtrByById } = useUtrsAPI();
    console.log(id, 'id=====', source)
  // API
  const { isLoading, error, data } = useQuery<Utr>({
        queryKey: [source, id],
        queryFn: source === "five-utrs" ? fetchFiveUtrByById: fetchThreeUtrByById,
        staleTime: 40 * 1000 * 60,
        gcTime: 10 * 1000 * 60,
    });

    useEffect(() => {
        if (data) {
            setSourceItemName(data?.Ensemble_gene_Id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    if (error) {
        const errorStatus = (error as AxiosError)?.status;

        return (
            <Flex w={"100%"} h={"100%"} my={10}>
                {errorStatus === 404 ? (
                    // throws this error when utr does not exist
                    <Flex
                        h={"100%"}
                        w={"100%"}
                        align={"center"}
                        justify={"center"}
                        color={"gray.500"}
                        direction={"column"}
                        gap={6}
                    >
                        <Icon as={BsFileEarmarkX} boxSize={"40px"} />
                        <Text fontSize={"14px"} lineHeight={"1.28"} fontWeight={"500"}>
                            UTR not found
                        </Text>
                    </Flex>
                ) : (
                    // another error
                    <MainPanelError errorMessage={errorHandler(error)?.message} />
                )}
            </Flex>
        );
    }

    if (isLoading) {
        return (
            <Flex
                w={"100%"}
                h={"100%"}
                align={"center"}
                justify={"center"}
                color={"gray.500"}
                direction={"column"}
                my={10}
            >
                <Loading message={`Loading utr data...`} />
            </Flex>
        );
    }

    return (
        <Flex direction={"column"} gap={8}>
            <Flex gap={4} direction={"column"}>
                <Heading
                    as={"h4"}
                    fontSize={[null, null, null, "16px", "18px", "18px"]}
                    color={"secondary.600"}
                >
                    UTR Details
                </Heading>

                <Flex direction="row" gap={5} mb={5} align={"center"}>
                    <Flex direction={"column"} gap={2}>
                        <UtrDetail header="Gene ID" content={data?.Ensemble_gene_Id ?? ""} />
                        <UtrDetail header="Transcript ID" content={data?.Ensemble_transcript_Id ?? ""} />
                        <UtrDetail
                            header="Sequence"
                            content={data?.sequence ?? ""}
                            isSequence
                        />
                        <UtrDetail
                            header="Optimized"
                            content={data?.optimized ?? ""}
                            isOptimised
                        />
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}

export default UtrsView;
