import { CloseIcon } from "@chakra-ui/icons";
import { Box, Icon, Progress, Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentEmbeddingData,
  setProgress,
} from "redux/features/embedding/embeddingSlice";

const EmbeddingLoading = () => {
  // Hooks
  const dispatch = useDispatch();
  const { progress, error } = useSelector(selectCurrentEmbeddingData);

  // Handler
  function handleCloseProgress() {
    dispatch(setProgress(null));
  }

  // Theme
  const bg = !error && progress !== null ? "highlight.primary" : "red.500";

  return (
    <Box
      zIndex={999}
      position={"absolute"}
      bottom={error || progress !== null ? "10px" : "-80px"}
      h={"40px"}
      w={"100%"}
      transition={"all .4s"}
      px={[null, null, null, 3, 10]}
    >
      {(error || progress !== null) && (
        <Box
          bg={bg}
          textAlign="center"
          borderRadius="md"
          color={"gray.50"}
          _hover={{ opacity: 0.9 }}
          px={2}
          py={1}
        >
          <Text
            alignSelf={"center"}
            fontWeight={"500"}
            fontSize={"14px"}
            letterSpacing={".05rem"}
          >
            {!error && progress !== null
              ? `Embedding ${progress}%`
              : "Error embedding"}
          </Text>
          {progress !== null && (
            <Progress
              value={progress || 0}
              size="xs"
              colorScheme={progress && progress < 100 ? "pink" : "dark.800"}
              borderRadius={5}
              mt={1}
            />
          )}
          {/* Close icon */}
          {error && (
            <Icon
              as={CloseIcon}
              cursor={"pointer"}
              boxSize={"10px"}
              position="absolute"
              top={2}
              right={2}
              onClick={handleCloseProgress}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default EmbeddingLoading;
