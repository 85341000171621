import { Card, Flex } from "@chakra-ui/react";

import ReferencesList from "./ReferencesList";
import ReferencesHeader from "./ReferencesHeader";

import { ExtraPapersProps } from "../helpers";
import { PaperProps } from "models/papers/PaperProps";

interface ReferencesPanelProps {
  showPanel: boolean;
  showChart: boolean;
  titles: (ExtraPapersProps | undefined)[];
  data: PaperProps[];
}

function ReferencesPanel({
  showPanel,
  showChart,
  titles,
  data,
}: ReferencesPanelProps) {
  if (!showPanel) return <></>;

  return (
    <Flex
      as={Card}
      direction={"column"}
      bg={"gray.100"}
      width={`${!showChart ? "100%" : "25%"}`}
      maxWidth={`${!showChart ? "100%" : "30%"}`}
      gap={4}
      p={2.5}
      borderTop="2px solid"
      borderTopColor="secondary.700"
    >
      <ReferencesHeader titles={titles} data={data} />
      <ReferencesList titles={titles} />
    </Flex>
  );
}

export default ReferencesPanel;
