import { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { useQueries } from "@tanstack/react-query";
import { selectCurrentGuideData } from "redux/features/guide/guideSlice";
import { AxiosError } from "axios";

import {
  Box,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";

import Sources from "../sources";
import Proteins from "../proteins";
import Molecules from "../molecules";
import { BotMessageContext } from "../response/BotMessage";

import { useCompoundsAPI } from "api/useCompoundsAPI";
import useGuideTour from "hooks/guideTour/useGuideTour";
import { useLiteraturesAPI } from "api/useLiteraturesAPI";

import { ProteinProps, SourceProps } from "models/chat/MessageProps";
import { CompoundProps } from "models/compounds/CompoundProps";
import { PapersNetworkProps } from "../sources/helpers";
import { useProteinsAPI } from "api/useProteinsAPI";

interface ReferencesProps {
  sources: SourceProps[];
  molecules: string[] | undefined;
  proteins: string[] | undefined;
}

function References({ sources, molecules, proteins }: ReferencesProps) {
  // Context
  const { openAccordionIndex, resetOpenAccordionIndex } =
    useContext(BotMessageContext);

  // Hooks
  const { isGuideOpen, currentMode } = useSelector(selectCurrentGuideData);
  const { isHighlighted } = useGuideTour();
  const { fetchCompoundsByName } = useCompoundsAPI();
  const { fetchSimilarPapers } = useLiteraturesAPI();
  const { fetchProteinById } = useProteinsAPI();

  let sourcesTabHighlighted = isHighlighted("chat_sources");
  let moleculesTabHighlighted = isHighlighted("chat_molecules");
  const applyIsGuideOpenStyles =
    isGuideOpen && currentMode === "PAGE_INSTRUCTIONS";

  // API
  // * Fetch sources
  const sourcesQueries = useQueries({
    queries: sources.map((source: SourceProps) => ({
      queryKey: ["literatures", "network", source.id, source.db_type],
      queryFn: fetchSimilarPapers,
      staleTime: 40 * 1000 * 60, // After 40 mins, a refetch will be triggered the next time the query is accessed
      gcTime: 30 * 1000 * 60, // After 30 mins, remove unused cached data
      // retry: 1, // the query function will be retried once more in case of failure
    })),
  });

  //   * Fetch referenced compounds
  const moleculesQueries = useQueries({
    queries: (molecules || []).map((cmpd: string) => {
      return {
        queryKey: ["compounds-by-name", cmpd],
        queryFn: fetchCompoundsByName,
        staleTime: 40 * 1000 * 60,
        gcTime: 30 * 1000 * 60,
        // NOTE: retry and enabled don't fix the actual issue.
        // reminder: the issue was whenever user selects Molecules Tab, a re-fetch occurs for failed queries.
        // The fix takes place in parent component References: muting isLazy property in Tabs component
        // this prop. was causing the re-fetch for failed queries
        // -------
        // retry: (failureCount: any, error: any) => {
        //   const failedUrl = error?.config?.url;
        //   const newFailedQuery: string =
        //     failedUrl?.split("get_compound_by_name/")[1]?.toLowerCase() ?? "";

        //   setFailedQueries((p) => [...p, newFailedQuery]);
        //   return false;
        // },
        // enabled: !failedQueries?.includes(cmpd?.toLowerCase()), // Prevent refetching for failed compounds
      };
    }),
  });

  // Fetch proteins
  const proteinsQueries = useQueries({
    queries: (proteins || []).map((prot: string) => {
      return {
        queryKey: ["protein-by-name", prot],
        queryFn: fetchProteinById,
        staleTime: 40 * 1000 * 60,
        gcTime: 30 * 1000 * 60,
      };
    }),
  });

  const allSourcesFinished = sourcesQueries.every((query) => !query.isLoading);
  const allSourcesErrorsFound = sourcesQueries.every((query) => {
    const error = query.error as AxiosError;
    const errorStatus = error?.response?.status;

    // ignore 404 failed queries
    return errorStatus && errorStatus !== 404 && !!query.error ? true : false;
  });

  const allMolsFinished = moleculesQueries.every((query) => !query.isLoading);
  const allMolsErrorsFound = moleculesQueries.every((query) => {
    const error = query.error as AxiosError;
    const errorStatus = error?.response?.status;

    // ignore 404 failed queries
    return errorStatus && errorStatus !== 404 && !!query.error ? true : false;
  });

  const allProtsFinished = proteinsQueries.every((query) => !query.isLoading);
  const allProtsErrorsFound = proteinsQueries.every((query) => {
    const error = query.error as AxiosError;
    const errorStatus = error?.response?.status;

    // ignore 404 failed queries
    return errorStatus && errorStatus !== 404 && !!query.error ? true : false;
  });

  const sourcesData = useMemo(() => {
    return sourcesQueries
      .filter((query) => query.data !== undefined)
      .map((query) => query.data as PapersNetworkProps);
  }, [sourcesQueries]);

  const moleculesData = useMemo(() => {
    return moleculesQueries
      .filter((query) => !!query.data)
      .map((query) => query.data as CompoundProps);
  }, [moleculesQueries]);

  const proteinsData = useMemo(() => {
    return proteinsQueries
      .filter((query) => !!query.data)
      .map((query) => query.data as ProteinProps);
  }, [proteinsQueries]);

  const hasSources = sources && !!sources?.length && !!sourcesData?.length;
  const hasProteins = proteins && !!proteins?.length && !!proteinsData?.length;
  const hasMolecules =
    molecules && !!molecules?.length && !!moleculesData?.length;

  // If bot reply has no sources and no molecules
  if (!hasSources && !hasMolecules && !hasProteins) {
    return <></>;
  }

  return (
    <Tabs
      position="relative"
      variant="unstyled"
      index={!!openAccordionIndex ? 0 : undefined}
    >
      <TabList
        bg={"transparent"}
        color={"gray.500"}
        borderBottomWidth={1}
        borderColor={applyIsGuideOpenStyles ? "transparent" : "gray.200"}
      >
        {hasSources && (
          <Tab
            fontSize={"xs"}
            fontFamily={"Poppins, sans-serif"}
            bg={sourcesTabHighlighted ? "background" : "inherit"}
            _selected={{
              color: applyIsGuideOpenStyles ? "inherit" : "highlight.primary",
            }}
            onClick={resetOpenAccordionIndex}
            px={0}
            borderRadius={"5px"}
          >
            <Box id="chat_sources" px={3}>
              SOURCES
            </Box>
          </Tab>
        )}
        {hasMolecules && (
          <Tab
            fontSize={"xs"}
            fontFamily={"Poppins, sans-serif"}
            bg={moleculesTabHighlighted ? "background" : "inherit"}
            _selected={{
              color: applyIsGuideOpenStyles ? "inherit" : "highlight.primary",
            }}
            onClick={resetOpenAccordionIndex}
            px={0}
            borderRadius={"5px"}
          >
            <Box id="chat_molecules" px={3}>
              MOLECULES
            </Box>
          </Tab>
        )}
        {hasProteins && (
          <Tab
            fontSize={"xs"}
            fontFamily={"Poppins, sans-serif"}
            // bg={proteinsTabHighlighted ? "background" : "inherit"} // TODO:
            _selected={{
              color: applyIsGuideOpenStyles ? "inherit" : "highlight.primary",
            }}
            onClick={resetOpenAccordionIndex}
            px={0}
            borderRadius={"5px"}
          >
            <Box
              // id="chat_molecules" // TODO:
              px={3}
            >
              PROTEINS
            </Box>
          </Tab>
        )}
      </TabList>
      <TabIndicator
        mt={"-2px"}
        h="2px"
        bg={applyIsGuideOpenStyles ? "inherit" : "highlight.primary"}
        borderRadius="1px"
      />
      <TabPanels>
        {hasSources && (
          <TabPanel p={1}>
            <Sources
              sources={sourcesData}
              allFetched={allSourcesFinished}
              allFetchFailed={allSourcesErrorsFound}
            />
          </TabPanel>
        )}
        {hasMolecules && (
          <TabPanel p={1}>
            <Molecules
              molecules={moleculesData}
              allFetched={allMolsFinished}
              allFetchFailed={allMolsErrorsFound}
            />
          </TabPanel>
        )}
        {hasProteins && (
          <TabPanel p={1}>
            <Proteins
              proteins={proteinsData}
              allFetched={allProtsFinished}
              allFetchFailed={allProtsErrorsFound}
            />
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
}

export default References;
