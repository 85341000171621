import React from "react";
import {
  List,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  Icon,
  CardBody,
  Heading,
  Card,
  useTheme,
} from "@chakra-ui/react";

import { TbPdf } from "react-icons/tb";
import { MdClose } from "react-icons/md";
import { hexToRgba } from "utils/helpers";

interface File {
  name: string;
}

interface FilesModalProps {
  isOpen: boolean;
  files: File[];
  onClose: () => void;
  handleRemoveFile: (fileName: string) => void;
}

const FilesModal: React.FC<FilesModalProps> = ({
  isOpen,
  files,
  onClose,
  handleRemoveFile,
}) => {
  const { colors } = useTheme();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent
        w={"fit-content"}
        minW={"600px"}
        minH={"400px"}
        maxH={"600px"}
        borderRadius={"6px"}
        bg={"background"}
      >
        <ModalHeader>All files</ModalHeader>
        <ModalCloseButton />

        <ModalBody overflowY={"scroll"}>
          <List>
            {files.map((file: File, index: number) => (
              <Card
                key={index}
                p={"10px"}
                mt={"10px"}
                alignItems={"center"}
                justifyContent={"space-between"}
                borderRadius={"6px"}
                borderWidth={1}
                borderColor={hexToRgba(colors.highlight.primary, 0.16)}
                bg={hexToRgba(colors.highlight.primary, 0.12)}
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                variant="outline"
                _hover={{
                  textDecoration: "none",
                  bg: hexToRgba(colors.highlight.primary, 0.05),
                  cursor: "pointer",
                }}
              >
                <Icon
                  as={TbPdf}
                  bg="transparent"
                  color={"highlight.primary"}
                  boxSize="28px"
                  borderRadius="6px"
                  p="3px"
                />
                <Stack w={"100%"}>
                  <CardBody pl={"20px"} p={0}>
                    <Heading
                      size="sm"
                      fontWeight={"500"}
                      color={"highlight.primary"}
                    >
                      {" "}
                      {file.name}
                    </Heading>
                  </CardBody>
                </Stack>
                <Icon
                  as={MdClose}
                  bg="transparent"
                  color={"highlight.primary"}
                  boxSize="28px"
                  borderRadius="6px"
                  p="3px"
                  onClick={() => handleRemoveFile(file.name)}
                />
              </Card>
            ))}
          </List>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FilesModal;
