import { Flex, Text } from "@chakra-ui/react";

export interface SuggestedQuestionProps {
  question: string;
  header: string;
  onSendSuggestedQuestion: (q: string) => void;
}

function SuggestedQuestion({
  question,
  header,
  onSendSuggestedQuestion,
}: SuggestedQuestionProps) {
  return (
    <Flex
      direction={"column"}
      gap={2}
      borderWidth={1}
      borderColor={"gray.300"}
      p={2}
      borderRadius={8}
      transition={".2s ease all"}
      cursor={"pointer"}
      _hover={{
        color: "gray.700",
        bg: "lightBackground",
        borderColor: "gray.300",
        transition: ".2s ease all",
      }}
      onClick={() => onSendSuggestedQuestion(question)}
    >
      <Text
        fontWeight={"500"}
        fontSize={{ lg: "11px", xl: "13px" }}
        color={"highlight.primary"}
        lineHeight={1}
      >
        {header}
      </Text>
      <Text
        fontWeight={"500"}
        fontSize={{ lg: "10px", xl: "11px" }}
        color={"gray.600"}
        letterSpacing={".02rem"}
        lineHeight={"1.3"}
      >
        {question}
      </Text>
    </Flex>
  );
}

export default SuggestedQuestion;
