import { useState, ChangeEvent } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Box,
  Text,
  InputRightElement,
  InputGroup,
  useToast,
  Icon,
  Flex,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

import { isPasswordValid } from "views/auth/helpers";
import { axiosClient } from "api/axios";
import { useDispatch } from "react-redux";
import {
  setAccessToken,
  setRefreshToken,
  setUser,
} from "redux/features/auth/authSlice";

export default function ChangePassword({
  username,
  resetPasswordSession,
  setResetPasswordSession,
}: {
  username: string;
  resetPasswordSession: string | null;
  setResetPasswordSession: (val: string | null) => void;
}) {
  // Hooks
  const toast = useToast();
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const [passwordForm, setPasswordForm] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });

  // Handlers
  const handleInputPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setErrorMsg("");
    setPasswordForm({
      ...passwordForm,
      [name]: value,
    });
  };

  const handlePasswordToggle = (pass: "PASSWORD" | "CONFIRM_PASSWORD") => {
    if (pass === "PASSWORD") {
      setShowPassword({
        ...showPassword,
        password: !showPassword.password,
      });
    } else {
      setShowPassword({
        ...showPassword,
        confirmPassword: !showPassword.confirmPassword,
      });
    }
  };

  const handleSubmit = async () => {
    try {
      const { newPassword, confirmNewPassword } = passwordForm;

      if (!username || !newPassword) return;

      const passwordError = isPasswordValid(newPassword, confirmNewPassword);

      if (passwordError.length > 0) {
        setErrorMsg(passwordError);
        setSubmitting(false);
        return;
      }

      if (!resetPasswordSession) {
        setErrorMsg("No session, try login again!");
        setSubmitting(false);
        return;
      }
      setSubmitting(true);
      const response = await axiosClient.post(
        "/api/new_password_required",
        JSON.stringify({ username, newPassword, resetPasswordSession }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      setResetPasswordSession(null);
      const { user, accessToken, refreshToken } = response?.data;
      dispatch(setUser(user));
      dispatch(setAccessToken(accessToken));
      dispatch(setRefreshToken(refreshToken));

      toast({
        position: "bottom-right",
        duration: 1500,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={"highlight.primary"}
            borderRadius={"6px"}
          >
            Password was reset successfully
          </Box>
        ),
      });
    } catch (error: any) {
      // No response from server
      if (!error.response) {
        toast({
          description: "No server response!",
          status: "error",
        });
        return;
      }
      toast({
        description: error.response.data.message,
        status: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Flex direction={"column"} gap={4} w="100%" maxW="460px">
      <Flex direction="column" gap={4} w="100%">
        {/* new password input */}
        <FormControl id="newPassword" w="100%">
          <FormLabel
            htmlFor="newPassword"
            color={"secondary.700"}
            fontSize={"12px"}
            fontWeight={"500"}
            fontFamily={"Poppins, sans-serif"}
            lineHeight={1}
            mb={1}
          >
            New Password
          </FormLabel>
          <InputGroup>
            <Input
              w={"100%"}
              color={"gray.600"}
              fontSize={"14px"}
              type={showPassword.password ? "text" : "password"}
              name="newPassword"
              value={passwordForm.newPassword}
              onChange={handleInputPasswordChange}
              placeholder="Enter your new password"
              _placeholder={{ fontSize: "14px", color: "gray.600" }}
              borderRadius={"6px"}
              bg={"background"}
              borderWidth={1}
              borderColor={"gray.200"}
              _hover={{ borderColor: "gray.300" }}
              _focusVisible={{ borderColor: "blue.300" }}
            />
            <InputRightElement>
              <Icon
                boxSize={"14px"}
                as={showPassword.password ? ViewOffIcon : ViewIcon}
                color={"secondary.300"}
                cursor={"pointer"}
                onClick={() => handlePasswordToggle("PASSWORD")}
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>

        {/* confirm new password input */}
        <FormControl id="confirmNewPassword" w="100%">
          <FormLabel
            htmlFor="confirmNewPassword"
            color={"secondary.700"}
            fontSize={"12px"}
            fontWeight={"500"}
            fontFamily={"Poppins, sans-serif"}
            lineHeight={1}
            mb={1}
          >
            Confirm New Password
          </FormLabel>
          <InputGroup>
            <Input
              w={"100%"}
              color={"gray.600"}
              fontSize={"14px"}
              type={showPassword.confirmPassword ? "text" : "password"}
              name="confirmNewPassword"
              value={passwordForm.confirmNewPassword}
              onChange={handleInputPasswordChange}
              placeholder="Confirm your new password"
              _placeholder={{ fontSize: "14px", color: "gray.600" }}
              borderRadius={"6px"}
              bg={"background"}
              borderWidth={1}
              borderColor={"gray.200"}
              _hover={{ borderColor: "gray.300" }}
              _focusVisible={{ borderColor: "blue.300" }}
            />
            <InputRightElement>
              <Icon
                boxSize={"14px"}
                aria-label={
                  showPassword.confirmPassword
                    ? "Hide Password"
                    : "Show Password"
                }
                as={showPassword.confirmPassword ? ViewOffIcon : ViewIcon}
                color={"secondary.300"}
                cursor={"pointer"}
                onClick={() => handlePasswordToggle("CONFIRM_PASSWORD")}
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>

        {!!errorMsg && (
          <Text
            w={"100%"}
            fontSize={"sm"}
            color={"red.500"}
            textAlign={"center"}
            borderRadius={"6px"}
            bg={"rgba(255, 0, 0, .02)"}
            borderStyle={"dashed"}
            borderWidth={1}
            borderColor={"rgba(255, 0, 0, .35)"}
            p={3}
            mt={2}
          >
            {errorMsg}
          </Text>
        )}
      </Flex>

      <Button
        mt={4}
        py={"10px"}
        w={"100%"}
        h={"fit-content"}
        size={"sm"}
        type={"submit"}
        bg={"primary.400"}
        color={"primary.100"}
        onClick={handleSubmit}
        isLoading={submitting}
        loadingText={"Processing"}
        _hover={{ bg: "primary.500" }}
      >
        Reset Password
      </Button>
    </Flex>
  );
}
