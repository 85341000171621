import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import useAllowAccess from "hooks/auth/useAllowAccess";

interface RequireAuthProps {
    allowedTypes: string[];
    allowedPermissions?: string[];
}

const RequireAuth = ({ allowedTypes, allowedPermissions }: RequireAuthProps) => {
    const { user } = useSelector(selectCurrentAuthData);
    const { userHasRequiredPermissions, userIsAnAllowedTypes } = useAllowAccess()
    const location = useLocation();

    const userIsPermitted = allowedPermissions ? userHasRequiredPermissions(allowedPermissions) : false

    return (
        // roles?.find(role => allowedTypes?.includes(role))
        user && (userIsAnAllowedTypes(allowedTypes) || userIsPermitted)
            ? <Outlet />
            : user
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;