import { ChangeEvent, useState } from "react";
import {
  Text,
  Flex,
  Icon,
  SimpleGrid,
  Button,
  Divider,
  useToast,
  Box,
  GridItem,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import { RxUpdate } from "react-icons/rx";
import PasswordInput from "components/authforms/userDetailsInputs/PasswordInput";
import { errorHandler } from "utils/helpers";
import { HiOutlineShieldCheck } from "react-icons/hi";
import {
  setAccessToken,
  setRefreshToken,
  setUser,
} from "redux/features/auth/authSlice";

const SecuritySection = () => {
  const toast = useToast();
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();

  const [isUpdating, setIsUpdating] = useState(false);
  const [previousPassword, setPreviousPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errors, setErrors] = useState({ previous: "", new: "", confirm: "" });

  const [showPreviousPassword, setShowPreviousPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  const validatePassword = (password: string) => {
    if (password.length < 8)
      return "Password must be at least 8 characters long.";
    if (!/[A-Z]/.test(password))
      return "Password must contain at least one uppercase letter.";
    if (!/[a-z]/.test(password))
      return "Password must contain at least one lowercase letter.";
    if (!/[0-9]/.test(password))
      return "Password must contain at least one number.";
    if (!/[!@#$%^&*]/.test(password))
      return "Password must contain at least one special character.";
    return "";
  };

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "previousPassword") setPreviousPassword(value);
    if (name === "newPassword") setNewPassword(value);
    if (name === "confirmNewPassword") setConfirmNewPassword(value);
  };

  const handlePasswordChangeSubmit = async () => {
    // Validate new password
    const newPassError = validatePassword(newPassword);
    // Validate confirm new password
    const confirmPassError =
      newPassword !== confirmNewPassword ? "Passwords do not match." : "";

    // If there are any errors, set them and return early
    if (newPassError || confirmPassError) {
      setErrors({
        previous: "",
        new: newPassError,
        confirm: confirmPassError,
      });
      return;
    }

    // No errors, proceed with submission
    setIsUpdating(true);

    try {
      const response = await axiosPrivate.put(`/api/update_password`, {
        previousPassword,
        newPassword,
        confirmNewPassword,
      });

      const { user, accessToken, refreshToken } = response?.data;
      dispatch(setUser(user));
      dispatch(setAccessToken(accessToken));
      dispatch(setRefreshToken(refreshToken));

      toast({
        position: "bottom-right",
        duration: 2000,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={"highlight.primary"}
            borderRadius={"6px"}
          >
            Your password was successfully changed!
          </Box>
        ),
      });

      // Reset errors after successful submission
      setErrors({ previous: "", new: "", confirm: "" });
    } catch (error) {
      const errorMsg = errorHandler(error).message;
      toast({
        position: "bottom-right",
        duration: 2000,
        render: () => (
          <Box color={"white"} p={3} bg={"red"} borderRadius={"6px"}>
            {errorMsg}
          </Box>
        ),
      });
      setErrors({
        previous: errorMsg.includes("previous") ? errorMsg : "",
        new: errorMsg.includes("new") ? errorMsg : "",
        confirm: errorMsg.includes("confirm") ? errorMsg : "",
      });
    }

    setIsUpdating(false);
  };

  return (
    <Flex gap={2} direction={"column"}>
      <Flex gap={2} align={"center"}>
        <Icon as={HiOutlineShieldCheck} boxSize={"18px"} opacity={0.8} />
        <Text fontWeight={"600"} lineHeight={1}>
          Security
        </Text>
      </Flex>
      <Divider my={1} />
      <SimpleGrid templateColumns={"repeat(2, 1fr)"} columnGap={6}>
        <PasswordInput
          label="Previous password"
          name="previousPassword"
          placeholder="Your previous password"
          value={previousPassword}
          error={errors.previous}
          onChange={handleChangePassword}
          showPassword={showPreviousPassword}
          onChangeVisibility={() =>
            setShowPreviousPassword(!showPreviousPassword)
          }
        />
        <PasswordInput
          label="New password"
          name="newPassword"
          placeholder="Enter new password"
          value={newPassword}
          error={errors.new}
          onChange={handleChangePassword}
          showPassword={showNewPassword}
          onChangeVisibility={() => setShowNewPassword(!showNewPassword)}
        />
        <GridItem colSpan={2}>
          <PasswordInput
            name="confirmNewPassword"
            label="Confirm new password"
            placeholder="Confirm your new password"
            value={confirmNewPassword}
            error={errors.confirm}
            onChange={handleChangePassword}
            showPassword={showConfirmNewPassword}
            onChangeVisibility={() =>
              setShowConfirmNewPassword(!showConfirmNewPassword)
            }
          />
        </GridItem>
      </SimpleGrid>
      <Button
        type={"submit"}
        borderRadius={"30px"}
        bg={"highlight.primary"}
        color={"background"}
        h={"fit-content"}
        w={"fit-content"}
        py={2}
        px={5}
        fontSize={"14px"}
        fontWeight={"500"}
        isLoading={isUpdating}
        loadingText={"Updating..."}
        leftIcon={<RxUpdate size={"16px"} />}
        letterSpacing={".02rem"}
        _active={{ bg: "highlight.primary" }}
        _hover={{ bg: "highlight.primary" }}
        _focus={{ bg: "highlight.primary" }}
        _focusWithin={{ bg: "highlight.primary" }}
        onClick={handlePasswordChangeSubmit}
      >
        Change password
      </Button>
    </Flex>
  );
};

export default SecuritySection;
