import { Box, Heading, Icon, Stack, useTheme } from "@chakra-ui/react";

import { hexToRgba } from "utils/helpers";
import { MdClose } from "react-icons/md";

interface File {
  name: string;
}

interface FileProps {
  files: File[];
  handleRemoveFile: (fileName: string) => void;
}

function UploadedFiles({ files, handleRemoveFile }: FileProps) {
  // Theme
  const { colors } = useTheme();

  return (
    <>
      {files.map((file: File, index) => (
        <Box
          key={index}
          alignItems={"center"}
          justifyContent={"space-between"}
          cursor="pointer"
          color={"gray.800"}
          bg={hexToRgba(colors.gray[100], 0.4)}
          borderWidth={1}
          borderColor={"gray.200"}
          borderRadius="6px"
          fontSize="14px"
          height={"fit-content"}
          transition="background 0.2s ease"
          px={2}
          py={2}
          display={"flex"}
          gap={2}
          fontWeight={"200"}
        >
          <Stack w={"100%"}>
            <Box pl={"20px"} p={0}>
              <Heading size="xm" fontWeight={"400"}>
                {" "}
                {file.name}
              </Heading>
            </Box>
          </Stack>
          <Icon
            as={MdClose}
            bg="transparent"
            color={"highlight.primary"}
            boxSize="20px"
            borderRadius="6px"
            p="1px"
            onClick={() => handleRemoveFile(file.name)}
          />
        </Box>
      ))}
    </>
  );
}

export default UploadedFiles;
