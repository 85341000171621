import { Flex, Icon, Text } from "@chakra-ui/react";

import { hexToRgba } from "utils/helpers";

import { HiOutlineLightBulb } from "react-icons/hi";

function MobileLoginToast() {
  // ❌ TypeError: Cannot read properties of undefined (reading 'primary')
  // const { appTitleGradient } = GetGradients();
  // fallback
  const appTitleGradient = `linear(94.23deg, #6DAFB3 5%, #00C1B4 50%, #4196CE 80%)`;

  return (
    <Flex
      align={"center"}
      p={4}
      px={6}
      gap={4}
      bg={"#1D2440"}
      borderRadius={"6px"}
    >
      <Icon
        as={HiOutlineLightBulb}
        boxSize={"34px"}
        color={"#00C1B4"}
        bg={hexToRgba("#00C1B4", 0.2)}
        p={1}
        borderRadius={"6px"}
      />

      <Flex
        direction={"column"}
        gap={2}
        lineHeight={"1.35"}
        fontSize={"14px"}
        color="white"
      >
        <Text>
          Welcome to{" "}
          <Text
            as="span"
            fontWeight={"bold"}
            bgGradient={appTitleGradient}
            bgClip="text"
            cursor={"pointer"}
          >
            Lakesai
          </Text>
        </Text>

        <Text>
          To start your scientific discovery with Sai, your intelligent agent,
          please use a large-screen device.
        </Text>
        <Text>We're working to make it accessible on smaller devices.</Text>
      </Flex>
    </Flex>
  );
}

export default MobileLoginToast;
