import { Box, Card, Text } from "@chakra-ui/react";
import { GetGradients } from "utils/gradients";
import { formatDateTime } from "./helpers";

interface MessageProps {
  message: string;
  created_at: Date;
  given_name: string | undefined;
  family_name: string | undefined;
}

export default function OtherUserMessage({
  message,
  created_at,
  given_name,
  family_name,
}: MessageProps) {
  // Theme
  const { lGradient } = GetGradients();

  return (
    <Box>
      <Card
        p={4}
        borderRadius={"20px"}
        position="relative"
        boxShadow={"none"}
        bg={lGradient}
      >
        <Text
          color={"gray.700"}
          fontSize={{ lg: "14px", "2xl": "15px" }}
          display={"flex"}
          gap={3}
          textAlign={"left"}
        >
          {message}
        </Text>
      </Card>
      <Text color={"gray.500"} fontSize={"10px"} gap={3} textAlign={"left"}>
        {given_name} {family_name} <span style={{ paddingRight: 5 }}></span>{" "}
        {formatDateTime(new Date(created_at))}
      </Text>
    </Box>
  );
}
