import { useState, useMemo, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import * as echarts from "echarts";

import { Box, Flex, useTheme, chakra } from "@chakra-ui/react";

import { getAvg } from "./helpers";
import { noSmileImg } from "assets/noSmile";
import { stylesToString } from "../../utils/helpers";
import { AssayChartItemProps } from "models/assays/AssayProps";

interface VerticalBarProps {
  itemsPerPage?: number;
  data: AssayChartItemProps[];
}

const verticalChartStyle = {
  width: "100%",
  maxWidth: "1000px",
  height: "500px",
  margin: "0 auto",
};

function AssayVerticalCharts({ itemsPerPage = 10, data }: VerticalBarProps) {
  const chartRef = useRef(null);
  const navigate = useNavigate();

  // State
  const [itemOffset, setItemOffset] = useState(0);

  // Theme
  const { colors } = useTheme();

  const endOffset = itemOffset + itemsPerPage;

  const sorted = data
    ?.slice()
    .sort(
      (a, b) => !!a?.value && !!b?.value && getAvg(a?.value) - getAvg(b?.value)
    );

  const currentItems = sorted.slice(itemOffset, endOffset).reverse();
  const pageCount = Math.ceil(sorted.length / itemsPerPage);

  // compute average if multiple items, or get 1st item if arr has 1 item
  const potencyList = currentItems.map((item) => getAvg(item.value));

  const lowerBound: number = useMemo(
    () =>
      Math.min(...sorted.map((item) => !!item?.value && getAvg(item?.value))),
    [sorted]
  );

  const upperBound: number = useMemo(
    () =>
      Math.max(...sorted.map((item) => !!item?.value && getAvg(item?.value))),
    [sorted]
  );

  const StyledReactPaginate = useMemo(
    () =>
      chakra(ReactPaginate, {
        baseStyle: {
          listStyle: "none",
          display: "flex",
          gap: "10px",
          alignSelf: "flex-end",
          marginRight: "16px",

          li: {
            outline: `1px solid ${colors.primary[300]}`,
            borderRadius: "50%",
            width: "24px",
            height: "24px",
            lineHeight: "1",
            fontSize: "12px",
            cursor: "pointer",

            a: {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
            },

            "&:hover, &.selected": {
              outline: "none",

              a: {
                backgroundColor: colors.primary[400],
                color: "white",
              },
            },

            "&.disabled": {
              pointerEvents: "none",
              outline: `1px solid ${colors.neutral[400]}`,
              color: colors.neutral[400],
            },
          },
        },
      }),
    [colors]
  );

  // if cmp has no name, display its id
  // else if name is very long, trim it
  const namesList = useMemo(
    () =>
      currentItems.map((item) =>
        item.name === ""
          ? item.id
          : item.name.length < 27
          ? item.name
          : item.name.slice(0, 26) + "..."
      ),
    [currentItems]
  );

  const handlePageClick = (event: { selected: number }) => {
    if (data?.length === 0) return;
    const newOffset = (event.selected * itemsPerPage) % data?.length;
    setItemOffset(newOffset);
  };

  const visualMap = useMemo(() => {
    return {
      min: lowerBound,
      max: upperBound,
      formatter: function (value: number) {
        return value.toExponential(2);
      },
      range: [lowerBound, upperBound],
      calculable: true,
      realtime: true,
      itemWidth: 16,
      itemHeight: null,
      text: ["LESS POTENT", "MORE POTENT"],
      textStyle: { color: colors.gray[500] },
      hoverLink: true,
      inactiveColor: colors.neutral[200],
      precision: 0,
      orient: "horizontal",
      left: "center",
      top: "top",
      dimension: 0,
      inRange: {
        color: [colors.secondary[400], colors.primary[500]],
        seriesIndex: 0,
      },
    };
  }, [colors, lowerBound, upperBound]);

  const circles = useMemo(() => {
    return {
      name: "line",
      type: "line",
      lineStyle: {
        color: "transparent",
      },
      smooth: true,
      showAllSymbol: true,
      symbol: "circle",
      symbolSize: 12,
      symbolOffset: [6, 0],
      symbolKeepAspect: true,
      itemStyle: {
        borderColor: "source",
      },
      emphasis: {
        itemStyle: {
          borderColor: colors.primary[500],
          borderWidth: 3,
        },
      },
      label: {
        show: true,
        formatter: function ({ name }: { name: string }) {
          const currentItem: AssayChartItemProps | undefined =
            currentItems.find(
              (item: AssayChartItemProps) => item.name === name
            );
          return currentItem?.ac_precision ?? "";
        },
        position: "right",
        color: "source",
        fontWeight: "bold",
      },
      data: potencyList,
    };
  }, [potencyList, colors.primary, currentItems]);

  const dottedLines = useMemo(() => {
    return {
      type: "pictorialBar",
      symbol: "rect",
      symbolRepeat: true,
      symbolKeepAspect: true,
      symbolSize: [4, 1],
      symbolMargin: 2,
      silent: true,
      data: potencyList,
      color: colors.neutral[900],
      itemStyle: {
        color: colors.neutral[900],
      },
    };
  }, [potencyList, colors]);

  const axis = useMemo(() => {
    return {
      x: {
        name: "",
        type: "value",
        splitLine: { show: false },
        min: upperBound,
        max: lowerBound,

        axisLabel: {
          rotate: 45,
          color: colors.gray[500],
          formatter: function (value: number) {
            return value.toExponential(0);
          },
        },
      },
      y: {
        axisLabel: {
          // TODO: for some values, dotted line extends beyond its designated area
          // and spills over covering y-axis label
          padding: [0, 6, 0, 6],
        },

        type: "category",
        offset: 5,
        axisLine: {
          show: true,
          lineStyle: { color: colors.gray[500] },
          onZero: false,
        },
        axisTick: { show: false },
        silent: false,
        triggerEvent: true,
        data: namesList,
      },
    };
  }, [namesList, colors, lowerBound, upperBound]);

  const tooltip = useMemo(() => {
    let seriesIndexValue: number = 0;
    return {
      formatter: function ({
        color,
        name,
        value,
        seriesIndex,
      }: {
        color: string;
        name: string;
        value: number;
        seriesIndex: number;
      }) {
        const currentItem: AssayChartItemProps | undefined = currentItems.find(
          (item: AssayChartItemProps) => item.name === name
        );

        seriesIndexValue = seriesIndex;

        const tooltipStyle = {
          display: "flex",
          "flex-direction": "column",
          "justify-content": "center",
          "text-align": "left",
          gap: "0px",
          padding: "10px",
          "padding-bottom": "20px",
          margin: "0",
          background: colors.gray[100],
        };

        const tooltipTitleStyle = {
          display: "block",
          "font-weight": "bold",
          "font-size": "14px",
          "white-space": "normal",
          "text-align": "left",
          "padding-left": "10px",
          "margin-top": "16px",
          "margin-bottom": "10px",
          color: color,
        };

        const spacing = {
          "font-size": "12px",
          "padding-left": "10px",
          margin: "3px 0",
          color: colors.gray[500],
        };

        const sourceColor = {
          "font-weight": "400",
          color: color,
        };
        const primarySourceColor = {
          "font-weight": "bold",
          color: color,
        };

        const reducedOpacityColor = color?.replace(/[^,]+(?=\))/, "0.45");

        const smile = {
          "justify-content": "center",
          "align-items": "center",
          margin: "0 auto",
          "box-shadow": `${reducedOpacityColor} 0px 1px 4px`,
          "border-radius": "4px",
          overflow: "hidden",
          width: "200px",
        };

        const valueStyle = {
          "font-size": "12px",
          "line-height": "16px",
          "margin-right": "8px",
        };

        const boldStyle = {
          "font-size": "13px",
          "font-weight": "bold",
          "padding-left": "10px",
          margin: "4px 0",
        };

        const inlineTooltipStyle = stylesToString(tooltipStyle);
        const inlineTooltipTitleStyle = stylesToString(tooltipTitleStyle);
        const inlineSpacing = stylesToString(spacing);
        const inlineSourceColor = stylesToString(sourceColor);
        const inlinePrimarySourceColor = stylesToString(primarySourceColor);
        const inlineSmile = stylesToString(smile);
        const inlineValueStyle = stylesToString(valueStyle);
        const inlineBoldStyle = stylesToString(boldStyle);

        // const baseUrl = "data:image/png;base64,";
        const baseUrl = "data:image/svg+xml;base64,";

        // let smile_structure = baseUrl;
        let smile_structure = "";

        let compound: AssayChartItemProps | undefined = data.find(
          (cmpd: AssayChartItemProps) => cmpd?.id === currentItem?.id
        );

        if (!!compound && !!compound?.smiles) {
          smile_structure = btoa(
            window.RDKit.get_mol(compound?.smiles ?? "")?.get_svg() ?? ""
          );
        }

        const smile_src = !!smile_structure
          ? baseUrl + smile_structure
          : noSmileImg;

        const potencyLabel = currentItem?.assay_type || "potency";

        const potencyContent =
          !!currentItem?.value?.length && currentItem?.value?.length > 1
            ? currentItem?.value
                ?.map(
                  (val: number, index: number) =>
                    `<span key="${index}" style="${inlineValueStyle}">${val.toExponential(
                      2
                    )}</span>`
                )
                .join("")
            : null;

        const efficacyContent =
          !!currentItem?.efficacy?.length && currentItem?.efficacy?.length > 1
            ? currentItem?.efficacy
                ?.map(
                  (value: number, index: number) =>
                    `<span key="${index}" style="${inlineValueStyle}">${value.toFixed(
                      4
                    )}%</span>`
                )
                .join("")
            : null;

        const rsqContent =
          !!currentItem?.r_sq?.length && currentItem?.r_sq?.length > 1
            ? currentItem?.r_sq
                ?.map(
                  (value: number, index: number) =>
                    `<span key="${index}" style="${inlineValueStyle}">${value.toFixed(
                      4
                    )}</span>`
                )
                .join("")
            : null;

        // NOTE: add N/A as fallback value for tooltip content fields
        const tooltipContent = [
          // smile content
          `<span style="${inlineSmile}"><Image src=${smile_src} alt=${name} /></span>`,

          // compound name
          `<span style="${inlineTooltipTitleStyle}">${name}</span>`,

          // potency content - cmpd has 1 value
          !!potencyContent
            ? `<hr><span style="${inlineBoldStyle}"><span style="${inlinePrimarySourceColor}">${potencyLabel}:</span> ${
                value.toExponential(2) ?? "N/A"
              }</span><hr>`
            : null,

          // potency content - cmpd has multiple values
          !!currentItem?.value?.length && currentItem?.value?.length === 1
            ? `<span style="${inlineSpacing}"><span style="${inlineSourceColor}">${potencyLabel}:</span> ${
                currentItem?.value?.at(0)?.toExponential(2) ?? "N/A"
              }</span>`
            : null,
          !!potencyContent
            ? `<span style="${inlineSpacing}"><span style="${inlineSourceColor}">${potencyLabel}:</span> ${potencyContent}</span>`
            : null,
          !!potencyContent ? `<hr>` : null,

          // efficacy content
          !!currentItem?.efficacy?.length && currentItem?.efficacy?.length === 1
            ? `<span style="${inlineSpacing}"><span style="${inlineSourceColor}">efficacy:</span> ${
                !!currentItem?.efficacy?.at(0)
                  ? Number(currentItem?.efficacy?.at(0)?.toFixed(4)) + "%"
                  : "N/A"
              }</span>`
            : null,
          !!efficacyContent
            ? `<span style="${inlineSpacing}"><span style="${inlineSourceColor}">efficacy:</span> ${efficacyContent}</span>`
            : null,
          !!efficacyContent ? `<hr>` : null,

          // r² content
          !!currentItem?.r_sq?.length && currentItem?.r_sq?.length === 1
            ? `<span style="${inlineSpacing}"><span style="${inlineSourceColor}">r²:</span> ${
                Number(currentItem?.r_sq?.at(0)?.toFixed(4)) ?? "N/A"
              }</span>`
            : null,
          !!rsqContent
            ? `<span style="${inlineSpacing}"><span style="${inlineSourceColor}">r²:</span> ${rsqContent}</span>`
            : null,
          !!rsqContent ? `<hr>` : null,
        ].join("");

        return `<Flex style="${inlineTooltipStyle}">${tooltipContent}</Flex>`;
      },
      position: function (point: number[]) {
        // on compound name hovered, get relative position to compound name
        if (seriesIndexValue === 1) {
          if (point[1] < 200) {
            return ["25%", point[1]];
          }
          return ["25%", "15%"];
        }

        // on data point hovered, get relative position to data point
        if (point[0] < 350) {
          return "right";
        }
        return "left";
      },
      hideDelay: 1200,
      padding: 0,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItems, colors]);

  const getOptions = useMemo(
    () => ({
      visualMap,
      series: [circles, dottedLines],
      tooltip,
      grid: { containLabel: true },
      xAxis: axis.x,
      yAxis: axis.y,
      silent: false,
    }),
    [tooltip, axis, circles, dottedLines, visualMap]
  );

  useEffect(() => {
    const myChart = echarts.init(chartRef.current);

    // Configure and render the chart
    myChart.setOption({
      ...getOptions,
    });

    myChart.on("click", function (params: echarts.ECElementEvent) {
      const { value, componentType, yAxisIndex } = params;

      const existingItem: AssayChartItemProps | undefined = data?.find(
        (item: AssayChartItemProps) => item.name === value
      );

      if (existingItem && Object.keys(existingItem).length > 0) {
        if (componentType === "yAxis" && yAxisIndex === 0) {
          navigate(`/compounds/${existingItem.id}`);
        }
      }
    });

    myChart.on("mouseover", function (params: echarts.ECElementEvent) {
      if (
        params.componentType === "yAxis" &&
        params.event &&
        params.event.target
      ) {
        // NOTE: needs to ignore type check here
        // Found correspondant type Displayable class in "node_modules/echarts/types/dist/shared module"
        // but it is not exported. Also cannot copy the Displayable class declaration,
        // it depends on many other non-exported class declarations
        const element: any = params.event.target;

        if (element) {
          // Set the new color for the label when hovered
          element.setStyle("fill", colors.primary[300]);

          // Refresh the chart to apply the style changes
          myChart.getZr().refreshImmediately();
        }
      }

      if (params.value) {
        myChart.dispatchAction({
          type: "showTip",
          seriesIndex: 1,
          dataIndex: currentItems.findIndex(
            (item) => item.name === params.value
          ),
        });

        myChart.getZr().refreshImmediately();
      }
    });

    myChart.on("mouseout", function (params: echarts.ECElementEvent) {
      if (
        params.componentType === "yAxis" &&
        params.event &&
        params.event.target
      ) {
        // NOTE: same note as "mouseover" event
        const element: any = params.event.target;

        if (element) {
          element.setStyle("fill", colors.gray[500]);
          myChart.getZr().refreshImmediately();
        }

        if (params.value) {
          myChart.dispatchAction({ type: "hideTip" });
          myChart.getZr().refreshImmediately();
        }
      }
    });

    return () => {
      // Clean up the chart when the component is unmounted
      myChart.dispose();
    };
  }, [itemOffset, getOptions, navigate, data, currentItems, colors]);

  return (
    <Flex
      justify="center"
      direction="column"
      align="center"
      w="full"
      h="full"
      py={5}
      gap={2}
    >
      <Box ref={chartRef} style={verticalChartStyle}></Box>
      <StyledReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
      />
    </Flex>
  );
}

export default AssayVerticalCharts;
