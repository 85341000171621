import { Flex, Text } from "@chakra-ui/react";
import { SocialMediaIcon } from "../FooterLinks";
import { FaLinkedin } from "react-icons/fa";

function Copyright() {
  return (
    <Flex
      align={"center"}
      justify={"space-between"}
      w={"full"}
      flexWrap={"wrap"}
      gap={2}
    >
      <Text
        color={"white"}
        opacity={0.6}
        fontSize={{ base: "13px", sm: "14px", lg: "16px" }}
        lineHeight={1}
      >
        © {new Date().getFullYear()} Vitafluence.ai. All rights reserved.
      </Text>
      <Flex align={"center"} gap={4}>
        <SocialMediaIcon
          icon={FaLinkedin}
          link="https://www.linkedin.com/company/vitafluence/"
        />
      </Flex>
    </Flex>
  );
}

export default Copyright;
