import { Flex, Icon, Image, Text, useMediaQuery } from "@chakra-ui/react";
import { useState } from "react";
import { BsFileEarmarkPdf, BsFileEarmark, BsX } from "react-icons/bs";

interface TextFieldFilesProps {
  files: File[];
  onRemoveFile: (index: number) => void;
}

interface FilePreviewProps {
  file: File;
  index: number;
  onRemoveFile: (index: number) => void;
  truncateFileName: (name: string) => string;
}

interface ImagePreviewProps {
  file: File;
  index: number;
  onRemoveFile: (index: number) => void;
}

interface CloseIconProps {
  show: boolean;
  index: number;
  onRemoveFile: (index: number) => void;
}

export default function TextFieldFiles({
  files,
  onRemoveFile,
}: TextFieldFilesProps) {
  const [isMobileView] = useMediaQuery("(max-width: 991px)");

  if (!files.length) return null; // Handle empty arrays directly

  // Truncate filename if needed based on the view type
  const truncateFileName = (name: string) => {
    const maxLength = isMobileView ? 70 : 150;
    const [baseName, ext = ""] = name.split(/\.([^.]*)$/); // Handle extension safely
    if (name.length <= maxLength) return name;
    return `${baseName.slice(0, maxLength - ext.length - 4)}... .${ext}`;
  };

  return (
    <>
      {files.map((file, index) => (
        <Flex
          key={index}
          position="relative"
          align="center"
          bg="background"
          borderRadius="md"
          mt={3}
          ml="55px"
          w="90%"
        >
          {file.type.startsWith("image/") ? (
            <ImagePreview
              file={file}
              index={index}
              onRemoveFile={onRemoveFile}
            />
          ) : (
            <FilePreview
              file={file}
              index={index}
              onRemoveFile={onRemoveFile}
              truncateFileName={truncateFileName}
            />
          )}
        </Flex>
      ))}
    </>
  );
}

const ImagePreview = ({ file, index, onRemoveFile }: ImagePreviewProps) => {
  const [showCloseIcon, setShowCloseIcon] = useState(false);

  return (
    <Flex
      position="relative"
      onMouseEnter={() => setShowCloseIcon(true)}
      onMouseLeave={() => setShowCloseIcon(false)}
    >
      <Image
        src={URL.createObjectURL(file)}
        alt="Preview"
        boxSize="50px"
        borderRadius="md"
        objectFit="scale-down"
        mr={3}
      />
      <CloseIcon
        index={index}
        onRemoveFile={onRemoveFile}
        show={showCloseIcon}
      />
    </Flex>
  );
};

const FilePreview = ({
  file,
  index,
  onRemoveFile,
  truncateFileName,
}: FilePreviewProps) => {
  const [showCloseIcon, setShowCloseIcon] = useState(false);

  return (
    <Flex
      direction="row"
      align="center"
      bg="gray.50"
      p="5px"
      borderRadius="5px"
      maxHeight="46px"
      position={"relative"}
      onMouseEnter={() => setShowCloseIcon(true)}
      onMouseLeave={() => setShowCloseIcon(false)}
    >
      <Icon
        as={file.type === "application/pdf" ? BsFileEarmarkPdf : BsFileEarmark}
        boxSize={5}
        color={file.type === "application/pdf" ? "red.500" : "gray.500"}
        mr={3}
      />
      <Flex
        flex="1"
        align="center"
        justify="center"
        height="46px"
        overflow="hidden"
      >
        <Text
          fontSize="12px"
          fontWeight="bold"
          color="highlight.primary"
          noOfLines={2} // Cleaner way to handle truncation
          textOverflow="ellipsis"
        >
          {truncateFileName(file.name)}
        </Text>
      </Flex>

      <CloseIcon
        index={index}
        onRemoveFile={onRemoveFile}
        show={showCloseIcon}
      />
    </Flex>
  );
};

const CloseIcon = ({ index, onRemoveFile, show }: CloseIconProps) => (
  <Icon
    as={BsX}
    boxSize={5}
    position="absolute"
    top={-2}
    right={-1}
    cursor="pointer"
    color="gray.400"
    bg="background"
    borderRadius="10"
    borderWidth={1}
    display={show ? "flex" : "none"}
    onClick={() => onRemoveFile(index)}
  />
);
