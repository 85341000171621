import {
  Flex,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useTheme,
  useToast,
} from "@chakra-ui/react";
import ActionButton from "components/buttons/ActionButton";
import { environment } from "environments";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import { SavedAssayProps, SavedFolderProps } from "models/bookmarks/SavedProps";
import { useMemo } from "react";
import { BsFillCircleFill } from "react-icons/bs";
import { GoBookmarkSlash } from "react-icons/go";
import { LuExternalLink } from "react-icons/lu";
import { PiTestTube } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { selectCurrentPostsData } from "redux/features/posts/postsSlice";
import {
  selectCurrentSavedData,
  setSuccess,
} from "redux/features/bookmarks/savedSlice";
import { GetGradients } from "utils/gradients";
import { hexToRgba } from "utils/helpers";
import { stringToColor } from "components/ui/helpers";

interface Props {
  content: SavedAssayProps[];
}

interface DeleteElementProps {
  elementType: "COMPOUND" | "LITERATURE" | "ASSAY";
  content: {
    elementId: string | undefined;
  };
}

const cols = [
  { name: "Name", width: "35%" },
  { name: "ID", width: "10%" },
  { name: "Indication", width: "20%" },
  { name: "Types", width: "20%" },
  { name: "Actions", width: "15%" },
];

function Assays({ content }: Props) {
  // Hooks
  const toast = useToast();
  const dispatch = useDispatch();
  const { folderId } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const { user } = useSelector(selectCurrentAuthData);
  let { teamData } = useSelector(selectCurrentPostsData);
  const { savedFolders } = useSelector(selectCurrentSavedData);

  // Navigation
  const navigate = useNavigate();

  // Theme
  const { lGradient2 } = GetGradients();
  const { colors } = useTheme();

  const cleanItems = useMemo(
    () => content?.filter((item) => item !== undefined),
    [content]
  );

  // Handler: delete item from bookmark folder
  async function handleDeleteItem({
    elementType,
    content,
  }: DeleteElementProps) {
    if (!content) return;

    try {
      const newFolders = savedFolders.map((f: SavedFolderProps) => {
        if (f.id === folderId) {
          return {
            ...f,
            assays: f?.assays?.filter(
              (item: { assay_id: string; name: string }) => {
                return item.assay_id !== content.elementId;
              }
            ),
          };
        }

        return f;
      });

      dispatch(setSuccess(newFolders));

      await axiosPrivate.put(`${environment.BACKEND_API}/api/remove_elements`, {
        folderId,
        elementType,
        content,
      });
    } catch (error: any) {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 1500,
        position: "top-right",
      });
      console.log(error);
    }
  }

  return (
    <Flex direction={"column"} gap={4} w={"100%"}>
      <Flex align={"center"} gap={2}>
        <Flex
          bg={hexToRgba(colors.blue[300], 0.15)}
          borderRadius={"70% 30% 30% 70% / 60% 40% 60% 40%"}
          p={1.5}
        >
          <Icon as={PiTestTube} boxSize={"16px"} />
        </Flex>
        <Text fontSize={"14px"} fontWeight={"500"}>
          Assays
        </Text>
      </Flex>

      <TableContainer>
        <Table variant="simple" size="sm" position={"relative"}>
          {/* Head */}
          <Thead whiteSpace={"nowrap"}>
            <Tr overflow={"hidden"}>
              {cols.map((col, index) => {
                return (
                  <Th
                    textOverflow={"ellipsis"}
                    key={"assays-table-header-" + index}
                    isTruncated={col?.name === "Aliases"}
                    borderBottomColor={hexToRgba(colors.blue[300], 0.15)}
                    borderBottomWidth={1}
                    color={"blue.500"}
                    fontWeight={"500"}
                    textAlign={"center"}
                    w={col?.width}
                  >
                    {col?.name}
                  </Th>
                );
              })}
            </Tr>
          </Thead>

          {/* Body */}
          <Tbody
            whiteSpace={"nowrap"}
            overflow={"hidden"}
            textOverflow={"ellipsis"}
          >
            {cleanItems.map((assay, index) => {
              const indicationColor = stringToColor(
                assay?.overview?.indication ?? ""
              );
              const assayTypes = assay?.overview?.assay_type
                ?.split(",")
                .map((type: string) => type.trim());

              return (
                <Tr
                  key={"assay-row" + index}
                  _hover={{ bgGradient: lGradient2 }}
                  transition="all 0.25s ease"
                  whiteSpace={"nowrap"}
                  overflow={"hidden"}
                  textOverflow={"ellipsis"}
                  minH={"200px"}
                >
                  {/* Name */}
                  <Td
                    borderColor={"gray.100"}
                    w={"35%"}
                    maxW={"200px"}
                    minH={"200px"}
                    fontSize={"13px"}
                    p={1.5}
                    whiteSpace={"nowrap"}
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                  >
                    {assay?.name}
                  </Td>

                  {/* ID */}
                  <Td
                    borderColor={"gray.100"}
                    w={"10%"}
                    fontSize={"12px"}
                    p={1.5}
                  >
                    {assay?.assay_id}
                  </Td>

                  {/* Indication */}
                  <Td
                    borderColor={"gray.100"}
                    w={"20%"}
                    fontSize={"12px"}
                    p={1.5}
                    whiteSpace={"nowrap"}
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                  >
                    <Flex color={"primary.300"} align={"center"} gap={2}>
                      <Icon
                        as={BsFillCircleFill}
                        boxSize={3}
                        color={indicationColor}
                      />
                      <Text fontSize={"12px"} flexWrap="wrap">
                        {assay?.overview?.indication}
                      </Text>
                    </Flex>
                  </Td>

                  {/* Types */}
                  <Td
                    borderColor={"gray.100"}
                    w={"20%"}
                    fontSize={"12px"}
                    p={1.5}
                    py={5}
                  >
                    <Flex direction={"column"} gap={1}>
                      {assayTypes?.map((type: string, i: number) => {
                        const typeColor = stringToColor(type);

                        return (
                          <Flex
                            color="primary.300"
                            align={"center"}
                            gap={2}
                            key={i}
                          >
                            <Icon
                              as={BsFillCircleFill}
                              boxSize={3}
                              color={typeColor}
                            />
                            <Text
                              fontSize={"12px"}
                              flexWrap="wrap"
                              whiteSpace={"nowrap"}
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              maxW={{ lg: "150px", xl: "200px" }}
                            >
                              {type}
                            </Text>
                          </Flex>
                        );
                      })}
                    </Flex>
                  </Td>

                  {/* Action Buttons */}
                  <Td borderColor={"gray.100"} w={"15%"} isTruncated p={1.5}>
                    <Flex justify={"center"} align={"center"}>
                      {teamData?.owner === user?.id ||
                      savedFolders.find((x) => x?.id === folderId)?.user ===
                        user?.id ? (
                        <ActionButton
                          inBookmarks
                          aria-label={"Delete"}
                          label={"Delete"}
                          icon={<GoBookmarkSlash />}
                          onClick={() =>
                            handleDeleteItem({
                              elementType: "ASSAY",
                              content: { elementId: assay?.assay_id },
                            })
                          }
                        />
                      ) : (
                        <></>
                      )}

                      <ActionButton
                        inBookmarks
                        aria-label={"Open"}
                        label={"Open"}
                        icon={<LuExternalLink />}
                        onClick={() =>
                          navigate(
                            `/data/core/MoleculeLake/data/assays/${assay?.assay_id}`
                          )
                        }
                      />
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
}

export default Assays;
