import { ChangeEvent } from "react";
import {
  InputGroup,
  Button,
  useTheme,
  Input,
  FormControl,
  Text,
  Flex,
} from "@chakra-ui/react";

import { hexToRgba } from "utils/helpers";
import { ImAttachment } from "react-icons/im";

type UploadProps = {
  handleFileChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onButtonClick?: () => void;
  accept: string;
  error: string;
};

const AttachFileArea = ({
  handleFileChange,
  onButtonClick,
  accept,
  error,
}: UploadProps) => {
  // Theme
  const { colors } = useTheme();

  return (
    <Flex as="form" id="form-file-upload">
      <FormControl
        isRequired
        id="uploadFile"
        w="100%"
        mt="14px"
        textAlign={"center"}
      >
        <InputGroup
          id="file-upload"
          justifyContent={"center"}
          width={"fit-content"}
          justifyItems={"left"}
          color={"gray.700"}
        >
          {/* TODO: fix design issue. follow: https://www.chakra-ui.com/docs/components/file-upload */}
          <Input
            type="file"
            name="file-uploader-field"
            id="file-uploader-field"
            multiple={true}
            accept={accept}
            hidden={false}
            onChange={handleFileChange}
            style={{
              width: "100%",
              height: "24px",
              opacity: 0,
              position: "absolute",
              top: 0,
              zIndex: 1,
              cursor: "pointer",
            }}
          />

          <Button
            zIndex={0}
            backgroundColor={hexToRgba(colors.gray[300], 0.4)}
            onClick={onButtonClick}
            leftIcon={<ImAttachment color={colors.gray[500]} />}
            color={colors.gray[500]}
            size={"xs"}
            fontWeight={"500"}
          >
            Attach files
          </Button>
        </InputGroup>

        {/* has attachment error */}
        {!!error && (
          <Text
            textAlign={"center"}
            alignSelf={"center"}
            color={"red.500"}
            mt={"20px"}
            fontSize={"14px"}
          >
            {error}
          </Text>
        )}
      </FormControl>
    </Flex>
  );
};

export default AttachFileArea;
