import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";

import {
  Flex,
  Heading,
  Text,
  useMediaQuery,
  usePrefersReducedMotion,
} from "@chakra-ui/react";
import { keyframes } from "@emotion/react";

import ActionButton from "components/home/ActionButton";

const fadeDown = keyframes`
  0% {
    transform: translateY(-30px) scale(0.85);
    opacity: 0;
  }
  100% {
    transform: translateY(0px) scale(1);
    opacity: 1;
  }
`;

const fadeUp = keyframes`
  0% {
    transform: translateY(30px) scale(0.85);
    opacity: 0;
  }
  100% {
    transform: translateY(0px) scale(1);
    opacity: 1;
  }
`;

interface Props {
  setShowInfo?: Dispatch<SetStateAction<boolean>>;
}

export default function Content({ setShowInfo }: Props) {
  // Redux
  const { user } = useSelector(selectCurrentAuthData);
  const isUser = !!(user && user?.id);

  // Navigation
  const navigate = useNavigate();

  // Responsiveness
  const [isMobileView] = useMediaQuery("(max-width: 991px)");

  // Handler
  function handleClick() {
    if (isUser) {
      if (isMobileView) {
        setShowInfo && setShowInfo(true);
      } else {
        navigate("/chat");
      }
    } else {
      navigate("/login");
    }
  }

  // Animation
  const prefersReducedMotion = usePrefersReducedMotion();

  const fadeDownAnimation = prefersReducedMotion
    ? undefined
    : `${fadeDown} 1s ease-in-out`;

  const fadeUpAnimation = prefersReducedMotion
    ? undefined
    : `${fadeUp} 1.1s ease-in-out`;

  return (
    <Flex
      direction={"column"}
      justify={"center"}
      align={{ base: "center", lg: "flex-start" }}
      w={"fit-content"}
      h={{ base: "fit-content", lg: "100%" }}
      px={{ base: 0, lg: "40px", xl: "60px", "2xl": "80px" }}
      color={{ base: "gray.700", lg: "neutral.100" }}
      gap={4}
    >
      {/* salute */}
      <Flex
        w={"fit-content"}
        gap={2}
        textAlign={{ base: "center", lg: "left" }}
        align={"baseline"}
        animation={{ base: fadeDownAnimation, lg: "none" }}
        fontFamily={"monospace"}
        fontSize={["32px", "36px", "48px", "60px", "74px", "92px"]}
      >
        <Text letterSpacing={-2} color="secondary.600">
          Hi!
        </Text>
        <Text letterSpacing={-2}>I'm Sai</Text>
      </Flex>

      {/* description */}
      <Heading
        color={{ base: "secondary.700", lg: "whiteAlpha.900" }}
        textAlign={{ base: "center", lg: "left" }}
        size={["sm", "sm", "md", "md", "lg", "lg"]}
        letterSpacing={{ base: 0.5, lg: 4 }}
        fontWeight={{ base: "500", lg: "600" }}
        maxW={["300px", "300px", "400px", "500px", "600px", "700px"]}
        mb={{ base: "10px", sm: "20px", md: "20px", lg: "40px" }}
        animation={{ base: fadeUpAnimation, lg: "none" }}
      >
        Your AI Agent for scientific research
      </Heading>

      {/* action button */}
      <ActionButton text={"Get Started"} onClick={handleClick} />
    </Flex>
  );
}
