import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@chakra-ui/react';
import { useNotificationsAPI } from './useNotificationsAPI';
import { errorHandler } from 'utils/helpers';

export const useDeleteNotificationMutation = () => {
    const { removeNotification } = useNotificationsAPI();
    const toast = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (id: string) => {
            await removeNotification(id);
        },
        onSuccess: async () => {
            queryClient.refetchQueries({ queryKey: ['notifications'] })
        },
        onError: (error: any) => {
            toast({
                description: errorHandler(error).message,
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: "top-right"
            });
        },
    });
};