import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

interface AutoRestartModalProps {
  isOpen: boolean;
  onClose: () => void;
  setCancelReload: (value: boolean) => void;
  setShowAutoReload: (value: boolean) => void;
}

function AutoRestartModal({
  isOpen,
  onClose,
  setCancelReload,
  setShowAutoReload,
}: AutoRestartModalProps) {
  // Responsiveness
  const fromLeft = useBreakpointValue({ base: "5%", lg: "45%" });
  const maxW = useBreakpointValue({ base: "100%", lg: "400px", xl: "400px" });
  const width = useBreakpointValue({
    base: "100%",
    lg: "400px",
    xl: "400px",
  });

  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    if (!isOpen) return;

    const intervalId = setInterval(() => {
      setCountdown((prev) => {
        if (prev > 1) {
          return prev - 1;
        } else {
          clearInterval(intervalId);
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay bg={"transparent"} />
      <ModalContent
        p={1}
        top="2%"
        w={width}
        h={"75px"}
        maxW={maxW}
        left={fromLeft}
        bg={"background"}
        position="absolute"
        borderRadius={"12px"}
        transform="translateX(-50%)"
      >
        <ModalBody mb={4}>
          <Flex justify="space-between" align="center" mb={2}>
            <Flex direction="column" gap={2}>
              <Heading as="h4" size="sm" fontWeight={700}>
                New updates detected
              </Heading>

              <Text fontFamily={"Poppins, sans-serif"} fontSize={"12px"}>
                App auto-restarts in {countdown} seconds
              </Text>
            </Flex>

            <Flex align="center" gap={1} alignItems="flex-end">
              <Button
                type={"submit"}
                borderRadius={"30px"}
                bg={"red.500"}
                color={"background"}
                h={"fit-content"}
                w={"fit-content"}
                py={2}
                px={4}
                fontSize={"14px"}
                fontWeight={"500"}
                letterSpacing={".02rem"}
                _active={{ bg: "red.500" }}
                _hover={{ bg: "red.500" }}
                _focus={{ bg: "red.500" }}
                _focusWithin={{ bg: "red.500" }}
                onClick={() => {
                  setCancelReload(true);
                  setShowAutoReload(false);
                  onClose();
                }}
              >
                Cancel
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default AutoRestartModal;
