import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  Accordion,
  AccordionItem,
  AccordionPanel,
  Flex,
} from "@chakra-ui/react";
import AccordionHeader from "components/ui/AccordionHeader";

interface DisclaimersModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function DisclaimersModal({
  isOpen,
  onClose,
}: DisclaimersModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent
        alignSelf={"center"}
        overflowY={"auto"}
        minW={{ base: "90%", lg: "800px" }}
        maxW={{ base: "90%", lg: "800px" }}
        h={{
          lg: "calc(100vh - 16px)",
          xl: "calc(100vh - 24px)",
          "2xl": "calc(100vh - 32px)",
        }}
        my={"auto"}
        bg={"white"}
      >
        <ModalHeader
          display={"flex"}
          alignItems={"center"}
          color={"gray.700"}
          fontFamily={"Poppins, sans-serif"}
          justifyContent={"space-between"}
          borderBottomWidth={1}
          borderBottomColor={"gray.200"}
        >
          {/* header */}
          <Text fontSize={"16px"} fontWeight={"500"}>
            Disclaimers
          </Text>
        </ModalHeader>
        <ModalBody py={6}>
          <Accordion allowToggle defaultIndex={[0]}>
            {/* General Disclaimeration */}
            <AccordionItem border={"none"} my={1}>
              <AccordionHeader text={"General Disclaimer"} />
              <AccordionPanel
                pb={4}
                fontSize={{ base: "12px", lg: "14px" }}
                color={"black"}
                opacity={0.7}
              >
                <Flex direction={"column"} gap={4}>
                  <Text>
                    The information provided on this website is for general
                    informational purposes only. While we strive to keep the
                    information up-to-date and correct, we make no
                    representations or warranties of any kind, express or
                    implied, about the completeness, accuracy, reliability,
                    suitability, or availability of the information, products,
                    services, or related graphics contained on the website.
                  </Text>
                </Flex>
              </AccordionPanel>
            </AccordionItem>

            {/* Medical Advice Disclaimer */}
            <AccordionItem border={"none"} my={1}>
              <AccordionHeader text={"Medical Advice Disclaimer"} />

              <AccordionPanel
                pb={4}
                fontSize={{ base: "12px", lg: "14px" }}
                color={"black"}
                opacity={0.7}
              >
                <Flex direction={"column"} gap={4}>
                  <Text>
                    The content of this website is not intended to be a
                    substitute for professional medical advice, diagnosis, or
                    treatment. Always seek the advice of your physician or other
                    qualified health provider with any questions you may have
                    regarding a medical condition.
                  </Text>
                </Flex>
              </AccordionPanel>
            </AccordionItem>

            {/* No Guarantee Disclaimer */}
            <AccordionItem border={"none"} my={1}>
              <AccordionHeader text={"No Guarantee Disclaimer"} />

              <AccordionPanel
                pb={4}
                fontSize={{ base: "12px", lg: "14px" }}
                color={"black"}
                opacity={0.7}
              >
                <Flex direction={"column"} gap={2}>
                  <Text>
                    Our platform uses advanced technologies and methods for drug
                    discovery. However, we cannot guarantee the success of any
                    drug discovery or development effort. Drug discovery and
                    development is a complex process with many uncertainties.
                  </Text>
                </Flex>
              </AccordionPanel>
            </AccordionItem>

            {/* Third-Party Links Disclaimer */}
            <AccordionItem border={"none"} my={1}>
              <AccordionHeader text={"Third-Party Links Disclaimer"} />

              <AccordionPanel
                pb={4}
                fontSize={{ base: "12px", lg: "14px" }}
                color={"black"}
                opacity={0.7}
              >
                <Text>
                  This website may contain links to third-party websites or
                  services that are not owned or controlled by us. We have no
                  control over, and assume no responsibility for, the content,
                  privacy policies, or practices of any third-party websites or
                  services.
                </Text>
              </AccordionPanel>
            </AccordionItem>

            {/* Intellectual Property Disclaimer */}
            <AccordionItem border={"none"} my={1}>
              <AccordionHeader text={"Intellectual Property Disclaimer"} />
              <AccordionPanel
                pb={4}
                fontSize={{ base: "12px", lg: "14px" }}
                color={"black"}
                opacity={0.7}
              >
                <Flex direction={"column"} gap={4}>
                  <Text>
                    All intellectual property rights related to Lakesai and its
                    content are owned by us or our licensors. Any use of our
                    intellectual property without our express written consent is
                    prohibited.
                  </Text>
                </Flex>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
