import useAxiosPrivate from "hooks/auth/useAxiosPrivate";

export const useNotificationsAPI = () => {
  const axiosPrivate = useAxiosPrivate();

  const getNotifications = async () => {
    const response = await axiosPrivate.get(`/api/notifications`);
    return response.data;
  };

  const markNotificationsAsRead = async () => {
    const response = await axiosPrivate.put(`/api/notifications`);
    return response.data;
  };

  const removeNotification = async (id: string) => {
    const response = await axiosPrivate.delete(`/api/notification/${id}`);
    return response.data;
  };

  return { getNotifications, markNotificationsAsRead, removeNotification };
};
