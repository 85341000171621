import React, { createContext, useEffect, useState, ReactNode } from "react";
import { useSelector } from "react-redux";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";

export interface EmbeddingSocketContextProps {
  socket: WebSocket | null;
  setSocket: React.Dispatch<React.SetStateAction<WebSocket | null>>;
}

export const EmbeddingSocketContext =
  createContext<EmbeddingSocketContextProps>({
    socket: null,
    setSocket: () => {},
  });

export const EmbeddingSocketContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [socket, setSocket] = useState<WebSocket | null>(null);

  const { user } = useSelector(selectCurrentAuthData);

  useEffect(() => {
    if (!user) {
      socket?.close();
    }
  }, [user, socket]);

  return (
    <EmbeddingSocketContext.Provider value={{ socket, setSocket }}>
      {children}
    </EmbeddingSocketContext.Provider>
  );
};
