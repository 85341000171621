import {
  Flex,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  useTheme,
} from "@chakra-ui/react";
import { CustomScrollBar } from "components/ui/CustomScrollBar";
import { hexToRgba } from "utils/helpers";

const listStyle = {
  height: "100%",
  width: "100%",
  padding: 0,
};

function ChatSkeleton() {
  return (
    <CustomScrollBar style={listStyle}>
      <Flex
        direction={"column"}
        justify={"flex-end"}
        align={"flex-end"}
        h={"100%"}
        w={"900px"}
        maxW={"90%"}
        mx={"auto"}
        py={"24px"}
        position={"relative"}
        _focusVisible={{ border: "none", outline: "none" }}
      >
        <Flex direction={"column"} px={2} pr={6} w={"100%"}>
          <Flex direction={"column"} gap={2}>
            <Flex w="100%" mt={4} pl={"48px"}>
              <HumanMessageSkeleton />
            </Flex>

            <Flex w="100%" mt={4} mb={8} minH={"50px"} overflow={"hidden"}>
              {/* Avatar Skeleton */}
              <SkeletonCircle mr={2} size={"40px"} />

              <Flex direction={"column"} gap={4} w={"100%"}>
                {/* Bot Message Skeleton */}
                {/* <Skeleton h={"300px"} w={"100%"} borderRadius={"20px"} /> */}

                <Flex direction={"column"} gap={5} w={"100%"}>
                  {Array.from({ length: 5 }, (_, index) => (
                    <SkeletonText
                      noOfLines={1}
                      key={index}
                      w={index === 4 ? "60%" : "100%"}
                      skeletonHeight={"16px"}
                    />
                  ))}{" "}
                </Flex>

                <Flex direction={"column"} gap={5} w={"100%"}>
                  {Array.from({ length: 3 }, (_, index) => (
                    <SkeletonText
                      noOfLines={1}
                      key={index}
                      w={index === 2 ? "20%" : "100%"}
                      skeletonHeight={"16px"}
                    />
                  ))}{" "}
                </Flex>

                {/* Bot Message Actions Skeleton */}
                <Flex align={"center"} gap={2} ml={"auto"}>
                  {Array.from({ length: 4 }, (_, index) => (
                    <Skeleton
                      key={index}
                      h={"10px"}
                      w={"30px"}
                      borderRadius={5}
                    />
                  ))}{" "}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </CustomScrollBar>
  );
}

function HumanMessageSkeleton() {
  // Theme
  const { colors } = useTheme();

  return (
    <Skeleton
      speed={1.6}
      h={"55px"}
      w={"60%"}
      ml={"auto"}
      borderRadius={"20px"}
      startColor={hexToRgba(colors.secondary[100], 0.8)}
      endColor={colors.secondary[200]}
    />
  );
}

export default ChatSkeleton;
