import { Box } from "@chakra-ui/react";
import DOMPurify from "dompurify";
import { useEffect, useRef, useState } from "react";
import Loading from "./Loading";

interface Props {
  json_response: string;
}

interface HTMLDataProps {
  minified_html: string;
  script_data: {
    id: string;
    src: string | null;
    content: string;
  }[];
}

export default function HTMLRender({ json_response }: Props) {
  const loaded = useRef<boolean>(false);
  const containerRef = useRef<any>(null);

  const [htmlContent, setHtmlContent] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isVisible, setIsVisible] = useState(false);


  const formatHTMLResponse = () => {
    const htmlData = JSON.parse(json_response).output;
    const mySafeHTML: string | TrustedHTML = DOMPurify.sanitize(
      htmlData.minified_html,
      { ALLOWED_TAGS: ["div"] }
    );

    setHtmlContent(mySafeHTML);
    return htmlData;
  };

  const loadBodyScript = (htmlData: HTMLDataProps) => {    
    try {
      let bodyScript: any = null;

      bodyScript = document.getElementById(htmlData.script_data[1].id);
      if (bodyScript) bodyScript.remove();

      bodyScript = document.createElement("script");
      bodyScript.textContent = htmlData.script_data[1].content;
      bodyScript.async = false;
      bodyScript.id = htmlData.script_data[1].id;
      
      document.body.appendChild(bodyScript);
      setIsLoading(false);
      return bodyScript
    }
    catch {
      return null
    }
  }

  const loadHeadScript = (htmlData: HTMLDataProps) => {    
    let headScript: any = null;
    let bodyScript: any = null;

    headScript = document.getElementById(htmlData.script_data[0].id);
    if (headScript) headScript.remove();

    headScript = document.createElement("script");
    headScript.src = htmlData.script_data[0].src;
    headScript.async = false;
    headScript.id = htmlData.script_data[0].id;
    headScript.onload = () => {      
      bodyScript = loadBodyScript(htmlData);
    }

    document.head.appendChild(headScript);

    return {headScript, bodyScript}
  }

  useEffect(() => {
    let headScript: any = null;
    let bodyScript: any = null;
    
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    if (isVisible && loaded.current === false) {
      loaded.current = true;
      const htmlData = formatHTMLResponse();
      const results = loadHeadScript(htmlData);
      headScript = results.headScript;
      bodyScript = results.bodyScript;
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
      
      if (bodyScript?.parentNode) {
        document.body.removeChild(bodyScript);
      }

      if (headScript?.parentNode) {
        document.head.removeChild(headScript);
      }
    };
  }, [isVisible]);
  
  return (
    <Box ref={containerRef}>
      {htmlContent.length > 0 && (
        <Box
          p={4}
          borderWidth="1px"
          borderRadius="lg"
        >
          {isLoading && <Box style={{ zIndex: 9, position: "absolute", width: "50vw", height: "50vh", background: "background" }}>
            <Loading />
          </Box>
          }
          <div style={{ zIndex: 0 }} dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </Box>
      )}
    </Box>
  );
}
