import { RefObject, useState, useEffect } from "react";
import { Flex, Icon, useBreakpointValue } from "@chakra-ui/react";

import { useDebounce } from "hooks";
import { FaAnglesDown } from "react-icons/fa6";

interface ScrollDownButtonProps {
  onClick: () => void;
  messagesRef: RefObject<HTMLDivElement>;
}

function ScrollDownButton({ messagesRef, onClick }: ScrollDownButtonProps) {
  const [showScrollDownButton, setShowScrollDownButton] = useState(false);
  const debouncedShow = useDebounce(showScrollDownButton);

  function handleScroll() {
    const currentRef = messagesRef?.current;

    if (!!currentRef) {
      const {
        scrollHeight,
        scrollTop: currentScrollY,
        clientHeight,
      } = currentRef;

      // reach the bottom of the conversation
      const isAtBottom = scrollHeight - clientHeight <= currentScrollY + 2; // 2px added as offset

      setShowScrollDownButton(!isAtBottom);
    }
  }

  useEffect(() => {
    const currentRef = messagesRef.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
    } else return;

    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesRef]);

  // TODO: fix left position when left panel is closed
  const leftPosition = useBreakpointValue({
    lg: "calc(50% + 140px)",
    xl: "calc(50% + 150px)",
    "2xl": "calc(50% + 160px)",
  });

  return (
    <Flex
      w={8}
      h={8}
      justify={"center"}
      align={"center"}
      cursor={"pointer"}
      boxShadow={"rgba(0, 0, 0, 0.2) 0px 3px 8px"}
      bg={"background"}
      borderWidth={1}
      borderColor={"gray.100"}
      _hover={{
        transition: "all 0.2s ease",
        borderColor: "gray.300",
      }}
      p={0}
      zIndex={1000000}
      position={"fixed"}
      bottom={"120px"}
      left={leftPosition}
      transform={"translateX(-50%)"}
      borderRadius={{ base: 0, sm: "50%" }}
      backdropFilter={"blur(6px)"}
      opacity={debouncedShow ? 1 : 0}
      pointerEvents={debouncedShow ? "auto" : "none"}
      transition={"all .3s ease-in-out"}
      onClick={onClick}
    >
      <Icon as={FaAnglesDown} boxSize={3} />
    </Flex>
  );
}

export default ScrollDownButton;
