import { Flex, Heading, Text } from "@chakra-ui/react";
import Form from "components/home/contact/Form";

import ctaBg from "assets/cta-bg.png";

export default function Contact() {
  return (
    <Flex id={"contact"} py={"20px"}>
      <Flex
        borderRadius={{ base: "0", lg: "24px" }}
        backgroundImage={ctaBg}
        backgroundPosition={"center"}
        backgroundRepeat={"no-repeat"}
        backgroundSize={"cover"}
        maxW={{ base: "100%", lg: "900px", xl: "1000px" }}
        mx={"auto"}
        w={"100%"}
      >
        <Flex
          direction={{ base: "column", lg: "row" }}
          align={"center"}
          gap={["20px", "20px", "60px"]}
          py={"60px"}
          px={"40px"}
          w={"fit-content"}
          mx={"auto"}
        >
          {/* Heading */}
          <Flex
            direction={"column"}
            gap={{ base: 2, lg: 4 }}
            textAlign={{ base: "center", lg: "start" }}
          >
            <Text color="white" fontSize={"md"} fontFamily={"monospace"}>
              {"Contact Us".toUpperCase()}
            </Text>
            <Heading color="white" size={"lg"} fontFamily={"monospace"}>
              {"Reach Out Anytime"}
            </Heading>
          </Flex>

          <Form />
        </Flex>
      </Flex>
    </Flex>
  );
}
