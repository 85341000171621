/*
 * Access Token:
 *   - sent as JSON, we store it in memory but not in localstorage or cookie.
 *   - issued at Authorization, client uses for API access until expires. Verified with middleware, new token issued at Refresh reauest
 *
 * Refresh Token:
 *   - sent as httpOnly cookie, Not accessible via JavaScript, and must have expiry at some point
 *   - issued at Authorization, client uses to reauest new Access Token. verfied with endpoint & database. Must be allowed to expire or logout
 */
import axios from "axios";
import { environment } from "environments";

const BASE_URL = environment.BACKEND_API;

export const axiosClient = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});
