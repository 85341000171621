import { ReactNode } from "react";
import {
  Text,
  Stack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Flex,
  Icon,
  useClipboard,
} from "@chakra-ui/react";

import { IoCheckmarkOutline } from "react-icons/io5";
import { LuClipboard } from "react-icons/lu";
import { PiDna } from "react-icons/pi";

interface SequenceProps extends React.HTMLAttributes<HTMLElement> {
  node?: any;
  children: ReactNode;
}

function MarkedSequence({ node, children, ...props }: SequenceProps) {
  // Hook
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { hasCopied, onCopy } = useClipboard(children as string);

  const splitSequence = (seq: any, chunkSize: any) => {
    const regex = new RegExp(`.{1,${chunkSize}}`, "g");
    return seq?.match(regex);
  };

  const chunks = splitSequence(children, 60); // Change 50 to the desired chunk size

  if (!children || !(children as string)?.length) return <></>;

  return (
    <Stack
      spacing={1}
      borderRadius={"10px"}
      borderWidth={1}
      borderColor={"gray.200"}
      borderLeftColor={"#00C1B4"}
      borderLeftWidth={2}
      p={2}
      mb={4}
      mt={2}
      w={"fit-content"}
    >
      {chunks?.slice(0, 3)?.map((chunk: any, index: number) => (
        <Text key={index} fontFamily="monospace">
          {chunk}
        </Text>
      ))}

      <Button
        fontFamily={"Segoe UI, sans-serif"}
        w={"fit-content"}
        variant={"link"}
        fontWeight={"500"}
        fontSize={"14px"}
        mt={2}
        color={"#00C1B4"}
        onClick={onOpen}
      >
        Show full sequence
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay backdropFilter="blur(3px)" />
        <ModalContent
          alignSelf={"center"}
          p={1}
          w={"fit-content"}
          minW={"412px"}
          minH={"164px"}
          bg={"background"}
          borderRadius={"6px"}
        >
          <ModalHeader display={"flex"} alignItems={"center"} gap={2}>
            <Icon
              as={PiDna}
              bg={"highlight.primary"}
              color={"gray.50"}
              boxSize={"28px"}
              borderRadius={"6px"}
              p={"5px"}
            />
            <Text fontSize={"16px"} color={"gray.600"} fontWeight={"500"}>
              Sequence
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize={"14px"} color={"gray.600"}>
              {children}
            </Text>
          </ModalBody>

          <ModalFooter>
            <Flex
              color={hasCopied ? "highlight.primary" : "gray.500"}
              align={"center"}
              gap={2}
              p={1}
              borderRadius={"6px"}
              cursor={"pointer"}
              pointerEvents={hasCopied ? "none" : "auto"}
              _hover={{ bg: "lightBackground" }}
              onClick={onCopy}
            >
              <Icon
                as={hasCopied ? IoCheckmarkOutline : LuClipboard}
                boxSize={4}
              />
              <Text fontWeight={hasCopied ? "500" : "400"} fontSize="14px">
                {hasCopied ? `Copied` : `Copy`}
              </Text>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export default MarkedSequence;
