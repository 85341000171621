export interface AssayTypeProps {
  name: string;
  value: number;
  isChecked: boolean;
}

export function propertiesFormat(inputString: string): string {
  const words = inputString.split("_");
  const transformedWords = words.map((word, index) =>
    index > 0 ? word : `${word.charAt(0).toUpperCase()}${word.slice(1)}`
  );
  return transformedWords.join(" ");
}

export function getAvg(arr: number[] | undefined) {
  const arrLen = arr?.length ?? 0;
  if (!arrLen) return 0;
  else if (arrLen === 1) return arr?.[0] ?? 0;
  else return (arr ?? []).reduce((a, b) => a + b, 0) / arrLen;
}

export const properties = [
  ["Indication", "indication"],
  ["Assay type", "assay_type"],
  ["Components", "components"],
  ["Detection method", "detection_method"],
];
