import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Tag,
  Image,
  Flex,
  Tooltip,
  Text,
} from "@chakra-ui/react";
import Loading from "components/ui/Loading";
import MainPanelError from "components/ui/MainPanelError";
import { ProteinProps } from "models/chat/MessageProps";
import NoDataAvailable from "../references/NoDataAvailable";
import { useMemo, useState } from "react";
import ReadMoreButton from "../references/ReadMoreButton";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectCurrentGuideData } from "redux/features/guide/guideSlice";

import { CustomScrollBar } from "components/ui/CustomScrollBar";
import { CustomOverlay } from "components/library/core/ui/CustomOverlay";

interface Props {
  proteins: ProteinProps[];
  allFetched: boolean;
  allFetchFailed: boolean;
}

// limit nbr of proteins to show to 3 initially
const MAX_SLICE = 3;

function Proteins({ proteins, allFetched, allFetchFailed }: Props) {
  // State
  const [showAll, setShowAll] = useState(false);

  const proteinsToShow = showAll ? proteins : proteins?.slice(0, MAX_SLICE);

  // loading
  if (!allFetched)
    return (
      <Box my={6}>
        <Loading message={"Loading proteins..."} />
      </Box>
    );

  // Some proteins are not found; we ignore them and display the rest
  if (allFetchFailed) {
    return (
      <Box my={3}>
        <MainPanelError errorMessage={"Error loading proteins"} />
      </Box>
    );
  }

  return (
    <>
      {proteinsToShow && proteinsToShow?.length > 0 ? (
        <Flex direction={"column"}>
          <ProteinsTable items={proteinsToShow} />

          {/* read more/less */}
          {proteins.length > MAX_SLICE && (
            <ReadMoreButton
              label={
                showAll
                  ? "view less"
                  : `view more (+${proteins?.length - MAX_SLICE})`
              }
              onClick={() => setShowAll(!showAll)}
            />
          )}
        </Flex>
      ) : (
        <NoDataAvailable />
      )}
    </>
  );
}

export default Proteins;

interface ProteinsTableProps {
  items: ProteinProps[];
}

// Styles
const tableStyle = {
  height: "100%",
  width: "100%",
  padding: "0 0 6px 0",
};

function ProteinsTable({ items }: ProteinsTableProps) {
  // Hook
  const navigate = useNavigate();
  const { isGuideOpen, currentMode } = useSelector(selectCurrentGuideData);

  const cleanItems = useMemo(
    () => items.filter((item) => item !== undefined),
    [items]
  );

  // Handlers
  function handleClick(protein: ProteinProps) {
    // TODO: link
    navigate(`/data/core/mRNALake/data/proteins/${protein?.id}`);
  }

  const applyIsGuideOpenStyles =
    isGuideOpen && currentMode === "PAGE_INSTRUCTIONS";

  const protsCols = ["Entry Name", "Name", "Gene", "Sequence", "Organism"];

  return (
    <>
      <TableContainer pt={6}>
        <CustomScrollBar style={tableStyle}>
          <Table variant="simple" size="sm" position={"relative"}>
            {/* Head */}

            <Thead>
              <Tr>
                {protsCols.map((col, index) => {
                  return (
                    <Th
                      key={"prots-table-header-" + index}
                      borderColor={"gray.100"}
                      pl={2}
                    >
                      {applyIsGuideOpenStyles && <CustomOverlay />}
                      {col}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>

            {/* Body */}
            <Tbody>
              {cleanItems.map((prot, index) => {
                return (
                  <Tr
                    key={"prot-row" + index}
                    _hover={{ cursor: "pointer", bg: "gray.100" }}
                    transition="background 0.3s ease"
                    onClick={() => handleClick(prot)}
                  >
                    {/* Entry Name */}
                    <Td
                      borderColor={"gray.100"}
                      maxW={"15%"}
                      fontSize={"12px"}
                      py={4}
                      pl={2}
                    >
                      {prot?.entry_name}
                    </Td>

                    {/* Protein Name */}
                    <Td
                      borderColor={"gray.100"}
                      maxW={{ lg: "150px", xl: "200px", "2xl": "250px" }}
                      fontSize={"12px"}
                      py={4}
                      pl={2}
                    >
                      <Tooltip
                        label={prot?.protein_name}
                        bg={"gray.900"}
                        color={"gray.100"}
                        hasArrow
                        py={2}
                        px={3}
                        m={2}
                        maxW={[null, null, null, "300px", "320px", "340px"]}
                        borderRadius={"6px"}
                        fontSize={"12px"}
                        boxShadow={"none"}
                        arrowSize={8}
                      >
                        <Text
                          whiteSpace={"nowrap"}
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          fontSize={"12px"}
                        >
                          {prot?.protein_name}
                        </Text>
                      </Tooltip>
                    </Td>

                    {/* Gene Name */}
                    <Td
                      borderColor={"gray.100"}
                      maxW={"10%"}
                      fontSize={"12px"}
                      py={4}
                      pl={2}
                    >
                      {prot?.gene_name}
                    </Td>

                    {/* Protein Sequence */}
                    <Td
                      borderColor={"gray.100"}
                      maxW={{ lg: "200px", xl: "250px" }}
                      fontSize={"12px"}
                      py={4}
                      pl={2}
                    >
                      <Text
                        whiteSpace={"nowrap"}
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                        fontSize={"12px"}
                      >
                        {prot?.protein_sequence}
                      </Text>
                    </Td>

                    {/* Organism */}
                    <Td
                      borderColor={"gray.100"}
                      maxW={"100px"}
                      fontSize={"12px"}
                      py={4}
                      pl={2}
                    >
                      <Text
                        whiteSpace={"nowrap"}
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                        fontSize={"12px"}
                      >
                        {prot?.organism}
                      </Text>{" "}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </CustomScrollBar>
      </TableContainer>
    </>
  );
}
