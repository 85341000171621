import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentSavedData } from "redux/features/bookmarks/savedSlice";

import { Flex, useBreakpointValue } from "@chakra-ui/react";

import Assays from "./Assays";
import Molecules from "./Molecules";
import Literature from "./Literature";
import BotMessages from "./BotMessages";
import EmptyFolder from "./EmptyFolder";
import Loading from "components/ui/Loading";
import LinkToView from "components/buttons/LinkToView";
import NoResultsFound from "components/ui/NoResultsFound";
import { CustomScrollBar } from "components/ui/CustomScrollBar";

import {
  SavedAssayProps,
  SavedBotMessagesProps,
  SavedCompoundProps,
  SavedLiteratureProps,
} from "models/bookmarks/SavedProps";
import { getLibraryFolder } from "views/library/helpers";

import { MdOutlineBookmarks } from "react-icons/md";

export default function FolderContent() {
  // Hooks
  const { folderId } = useParams();
  const { loading, savedFolders } = useSelector(selectCurrentSavedData);

  const folder = getLibraryFolder(folderId, savedFolders);
  let molecules: SavedCompoundProps[] = [];
  let assays: SavedAssayProps[] = [];
  let literature: SavedLiteratureProps[] = [];
  let botMessages: SavedBotMessagesProps[] = [];

  if (folder) {
    molecules = folder?.compounds ?? [];
    assays = folder?.assays ?? [];
    literature = folder?.literatures ?? [];
    botMessages = folder?.botMessages ?? [];
  }

  // if folder has no contnet
  const isEmpty =
    !assays?.length &&
    !molecules?.length &&
    !literature?.length &&
    !botMessages?.length;

  // Responsiveness: ~992px, ~1280px, ~1536px
  const listHeight = useBreakpointValue({
    lg: "calc(100vh - 154px)",
    xl: "calc(100vh - 162px)",
    "2xl": "calc(100vh - 178px)",
  });

  // Style
  const contentStyle = {
    height: listHeight,
    width: "100%",
    padding: "6px 12px 40px 0",
  };

  if (loading) {
    return <Loading message="Loading content..." />;
  }

  if (!folder) {
    return (
      <Flex
        direction={"column"}
        gap={6}
        my={"auto"}
        h={"100%"}
        justify={"center"}
        align={"align"}
      >
        <Flex h={"fit-content"} w={"100%"}>
          <NoResultsFound sourceName={"folder"} />
        </Flex>
        <Flex h={"fit-content"} w={"fit-content"} mx={"auto"}>
          <LinkToView
            name={"Back to Bookmarks"}
            icon={MdOutlineBookmarks}
            link={"/data/bookmarks"}
          />
        </Flex>
      </Flex>
    );
  }

  if (isEmpty) {
    return <EmptyFolder />;
  }

  return (
    <CustomScrollBar style={contentStyle}>
      <Flex direction={"column"} gap={10}>
        {!!molecules?.length && <Molecules content={molecules} />}
        {!!assays?.length && <Assays content={assays} />}
        {!!literature?.length && <Literature content={literature} />}
        {!!botMessages?.length && <BotMessages content={botMessages} />}
      </Flex>
    </CustomScrollBar>
  );
}
