import {
  ChangeEvent,
  KeyboardEvent,
  MutableRefObject,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import {
  selectCurrentSavedData,
  setSuccess,
} from "redux/features/bookmarks/savedSlice";

import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  Text,
  Textarea,
  useBreakpointValue,
  useTheme,
  useToast,
} from "@chakra-ui/react";

import NoNotes from "./NoNotes";
import { NoteProps } from "./Note";
import NotesList from "./NotesList";

import { environment } from "environments";
import { errorHandler, hexToRgba } from "utils/helpers";

import { SavedFolderProps } from "models/bookmarks/SavedProps";
import { TbMessage } from "react-icons/tb";
import AddNoteButton from "./AddNoteButton";

interface NotepadProps {
  folder: SavedFolderProps | undefined;
  isOpen: boolean;
  onClose: () => void;
}

function Notepad({ folder, isOpen, onClose }: NotepadProps) {
  // States
  const [noteContent, setNoteContent] = useState("");
  const [saving, setSaving] = useState(false);

  // Hooks
  const { folderId } = useParams();
  const { savedFolders } = useSelector(selectCurrentSavedData);
  const btnRef: MutableRefObject<HTMLElement | null> = useRef(null);
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const toast = useToast();

  // Theme
  const { colors } = useTheme();

  // Handlers
  async function handleAddNote() {
    if (!noteContent || noteContent?.length === 0) return;

    try {
      setSaving(true);

      const response = await axiosPrivate.put(
        `${environment.BACKEND_API}/api/saved_elements`,
        {
          elementType: "NOTES",
          content: {
            note: noteContent ?? "",
            elementId: "",
          },
          folderId,
        }
      );

      const data = response.data as SavedFolderProps;

      const newFolders = savedFolders?.map((f: SavedFolderProps) => {
        return f.id === data.id ? data : f;
      });

      dispatch(setSuccess(newFolders));

      // reset text area value
      setNoteContent("");
    } catch (error) {
      toast({
        description: errorHandler(error).message,
        status: "error",
        position: "top-right",
      });
    } finally {
      setSaving(false);
    }
  }

  function handleKeyDown(event: KeyboardEvent<HTMLTextAreaElement>) {
    const trimmedNoteContent = noteContent?.trim();

    // Check if the trimmed string is not empty
    if (trimmedNoteContent !== "") {
      if (event.key === "Enter") {
        // don't break line when 'Enter' key pressed
        event.preventDefault();

        handleAddNote();
      }
    }
  }

  function handleInputChange(e: ChangeEvent<HTMLTextAreaElement>) {
    // Block input change while saving another note
    if (!saving) {
      // remove line break characters when pasting note
      const trimmedContent = e.target.value.replace(/(\r\n|\n|\r)/gm, "");

      setNoteContent(trimmedContent);
    }
  }

  const notes = useMemo(() => {
    let reversed: NoteProps[] = [];

    if (folder?.notes && folder?.notes?.length > 0) {
      reversed = [...folder?.notes].reverse();
    }

    return reversed;
  }, [folder]);

  const notepadHeight = useBreakpointValue({
    lg: "calc(100vh - 154px)",
    xl: "calc(100vh - 162px)",
    "2xl": "calc(100vh - 178px)",
  });

  return (
    <>
      {folder ? (
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay
            backdropFilter={"blur(3.5px)"}
            bg={"rgba(0,0,0,0.1)"}
          />

          <DrawerContent
            bg={"background"}
            h={notepadHeight}
            w={"320px"}
            maxW={"320px"}
            borderRadius={"12px 0 0 12px"}
            p={0}
            alignSelf={"center"}
            borderColor={"gray.100"}
            borderWidth={1}
            boxShadow={"rgba(0, 0, 0, 0.2) 0px 3px 8px"}
          >
            <DrawerCloseButton size={"sm"} color={"gray.400"} />

            <DrawerHeader
              p={3}
              borderBottomColor={hexToRgba(colors.blue[300], 0.15)}
              borderBottomWidth={1}
            >
              <Flex align={"center"} gap={2} color={"gray.600"}>
                <Icon as={TbMessage} boxSize={4} />
                <Text fontSize={"14px"}>Bookmark notes</Text>
              </Flex>
            </DrawerHeader>

            <DrawerBody p={3} display={"flex"} flexDir={"column"} gap={4}>
              {/* has no notes */}
              {notes?.length > 0 ? <NotesList notes={notes} /> : <NoNotes />}

              {/* enter note here */}
              <Textarea
                name="note-box"
                value={noteContent}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                  handleInputChange(e)
                }
                onKeyDown={handleKeyDown}
                placeholder="Type new note..."
                _placeholder={{ color: "gray.500" }}
                fontSize={"14px"}
                resize="none"
                minHeight="60px"
                maxHeight="60px"
                bg={hexToRgba(colors.gray[400], 0.15)}
                borderRadius={"12px"}
                _focus={{
                  outline: "none",
                  borderWidth: 1,
                  boxShadow: "md",
                  overflow: "auto",
                }}
                _focusVisible={{ outline: "none" }}
              />
            </DrawerBody>

            {/* add note button */}
            <AddNoteButton onAddNote={handleAddNote} saving={saving} />
          </DrawerContent>
        </Drawer>
      ) : (
        <></>
      )}
    </>
  );
}

export default Notepad;
