import {
  Flex,
  Heading,
  Icon,
  SimpleGrid,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";

import curvedLinks from "assets/home/usecases/curvedLinks.svg";
import dottedBg from "assets/home/usecases/bg.webp";
import { UseCase, useCases } from "views/home/helpers";

interface Props {
  useCase: UseCase;
}

interface GridProps {
  data: UseCase[];
}

function UseCases() {
  return (
    <Flex
      id="usecases"
      direction={"column"}
      maxW={"1440px"}
      mx={"auto"}
      w={"95%"}
      my={"40px"}
      py={"40px"}
      gap={6}
      backgroundImage={dottedBg}
      backgroundRepeat={"no-repeat"}
      backgroundPosition={"top"}
      backgroundSize={"100%"}
    >
      {/* Heading */}
      <Flex direction={"column"} textAlign={"center"} gap={4} mb={10}>
        <Text fontSize={"md"} color="secondary.500" fontFamily={"monospace"}>
          {"Example Use Cases".toUpperCase()}
        </Text>
        <Heading size={"lg"} fontFamily={"monospace"}>
          {"Sai Helps Where You Need"}
        </Heading>
      </Flex>

      <Flex direction={{ base: "column-reverse", lg: "column" }} gap={0}>
        {/* use cases cards */}
        <UseCasesGird data={useCases} />

        {/* curved links */}
        <Flex
          display={{ base: "none", lg: "flex" }}
          direction="column"
          backgroundImage={curvedLinks}
          backgroundRepeat={"no-repeat"}
          backgroundPosition={"top center"}
          backgroundSize={"70%"}
          aspectRatio={5.4}
        />

        {/* description card */}
        <Overview />
      </Flex>
    </Flex>
  );
}

export default UseCases;

function UseCaseIcon({ useCase }: Props) {
  return (
    <Flex
      position={"relative"}
      _before={{
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: { base: "20px", lg: "26px", xl: "34px", "2xl": "40px" },
        height: { base: "20px", lg: "26px", xl: "34px", "2xl": "40px" },
        borderRadius: "50%",
        bg: useCase?.colors?.at(0),
        opacity: 0.8,
      }}
      minH={{ base: "60px", lg: "80px" }}
      w={"100%"}
    >
      <Flex
        w={"fit-content"}
        h={"fit-content"}
        p={3}
        borderRadius={"50%"}
        position={"absolute"}
        top={{ base: "4px", lg: "6px", xl: "8px", "2xl": "10px" }}
        left={{ base: "4px", lg: "6px", xl: "8px", "2xl": "10px" }}
        align={"center"}
        justify={"center"}
        borderColor={"white"}
        borderWidth={1}
        background="rgba(255, 255, 255, 0.22)"
        boxShadow="0 4px 30px rgba(0, 0, 0, 0.1)"
        backdropFilter="blur(5px)"
      >
        <Icon
          as={useCase?.icon}
          boxSize={{ base: "20px", lg: "26px", xl: "34px", "2xl": "40px" }}
          color={useCase?.colors?.at(0)}
        />
      </Flex>
    </Flex>
  );
}

function UseCasesGird({ data }: GridProps) {
  // Responsiveness: ~992px, ~1280px, ~1536px
  const templateColumns = useBreakpointValue({
    base: "repeat(auto-fill, minmax(320px, 1fr))",
    lg: "repeat(3, 1fr)",
    xl: "repeat(3, 1fr)",
    "2xl": "repeat(3, 1fr)",
  });

  return (
    <SimpleGrid
      templateColumns={templateColumns}
      spacing={{ base: 4, xl: 6, "2xl": 8 }}
      p={1}
    >
      {data?.map((useCase, index) => (
        <Flex
          key={index}
          direction={"column"}
          gap={4}
          p={{ base: 6, lg: 4 }}
          bg={"white"}
          color={"gray.700"}
          boxShadow={"md"}
          borderRadius={"16px"}
          maxW={"460px"}
          w={{ base: "100%", lg: "fit-content" }}
          mx={{ base: "auto", lg: "0" }}
        >
          <UseCaseIcon useCase={useCase} />
          <Flex direction={"column"} gap={2}>
            <Heading
              as="h5"
              fontFamily={"Poppins, sans-serif"}
              fontSize={"16px"}
              fontWeight={"600"}
              lineHeight={"1.4"}
              w={{ base: "100%", lg: "83%", xl: "100%" }}
              color={useCase?.colors?.at(1)}
            >
              {useCase?.title}
            </Heading>

            <Text color="gray.600" lineHeight={"1.4"}>
              {useCase?.description}
            </Text>
          </Flex>
        </Flex>
      ))}
    </SimpleGrid>
  );
}

function Overview() {
  return (
    <Flex
      direction={"column"}
      justify={"center"}
      align={"center"}
      gap={4}
      w={"fit-content"}
      h={"fit-content"}
      textAlign={"center"}
      maxW={"580px"}
      mx={"auto"}
      mt={{ base: 0, lg: "-12px" }}
      mb={{ base: "30px", lg: 0 }}
      p={6}
      bg={"neutral"}
      borderRadius={"16px"}
      boxShadow={{ base: "none", lg: "md" }}
    >
      <Text
        fontFamily={"Poppins, sans-serif"}
        fontWeight={"600"}
        color={"secondary.700"}
      >
        Unlock AI-driven research with our intelligent assistant <b>Sai</b>
      </Text>
      <Text color="gray.600">
        Designed to assist researchers and professionals in rapidly gathering,
        synthesizing, and analyzing vast amounts of information across diverse
        fields, <b>Sai</b> delivers critical insights with precision.
        Effortlessly handle time-consuming tasks, so you can focus on
        breakthroughs that matter.
      </Text>
    </Flex>
  );
}
