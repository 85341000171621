import { useContext, useEffect, useRef, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentGuideData,
  updateCurrentModalPosition,
} from "redux/features/guide/guideSlice";
import ExportChat from "components/chat/exportChart/ExportChat";

import { Flex, Icon, Text } from "@chakra-ui/react";

import { ChatbotContext } from ".";
import useWS from "hooks/chat/useWS";

import ChatSkeleton from "components/chat/ChatSkeleton";
import HumanMessage from "components/chat/question/HumanMessage";
import LoadingBubble from "components/chat/response/LoadingBubble";
import MessageBubble from "components/chat/exchange/MessageBubble";

import { ConversationProps } from "models/chat/MessageProps";

import { errorHandler } from "utils/helpers";
import { MdOutlineHideSource } from "react-icons/md";
import ScrollDownButton from "components/ui/ScrollDownButton";

export default function ChatPanel() {
  // Contexts
  const {
    messages,
    waitingOnBot,
    loadingChat,
    chatError,
    activeSession,
    attachedFiles,
    uploadedAudio,
  } = useContext(ChatbotContext);

  const {
    questionOnWait,
    messagesRef,
  }: {
    questionOnWait: string | undefined;
    messagesRef: React.RefObject<HTMLDivElement>;
  } = useOutletContext();

  // States
  const [scrolledUp, setScrolledUp] = useState(false);

  // Refs
  const previousScrollTop = useRef(0); // Track previous scrollTop

  // extract session id from url
  const { id } = useParams();

  // Hooks
  const dispatch = useDispatch();
  const { notifications, partialReply } = useWS({ waitingOnBot });
  const { isGuideOpen, currentModalPosition, currentMode } = useSelector(
    selectCurrentGuideData
  );

  const isActiveSession = activeSession === id;
  const hasAttachedFiles = !!attachedFiles?.length;

  const hasAttachments = hasAttachedFiles || !!uploadedAudio;
  const hasQuestionOnWait = !!questionOnWait || hasAttachments;

  const applyIsGuideOpenStyles =
    isGuideOpen && currentMode === "PAGE_INSTRUCTIONS";

  const canScroll = !!waitingOnBot || hasAttachedFiles;

  // Handler: Guide
  function scrollToElement(element: any) {
    const rect = element.getBoundingClientRect();
    const modalHeight = 300; // Adjust based on your modal's height

    let scrollTo = element.offsetTop - modalHeight / 2 + rect.height / 2;
    const currentRef = messagesRef.current;

    if (currentRef) {
      const maxScroll = currentRef.scrollHeight - currentRef.clientHeight;
      scrollTo = Math.min(Math.max(scrollTo, 0), maxScroll);

      currentRef.scrollTo({
        top: scrollTo,
        behavior: "smooth",
      });
    }
  }

  useEffect(() => {
    if (
      isGuideOpen &&
      currentModalPosition &&
      currentMode === "PAGE_INSTRUCTIONS"
    ) {
      const element = document.querySelector(`#${currentModalPosition.id}`);
      if (element && messagesRef.current) {
        const newTop = scrollToElement(element);
        dispatch(
          updateCurrentModalPosition({ ...currentModalPosition, top: newTop })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGuideOpen]);

  // Handlers
  function scrollToBottomWithBehavior(smoothScroll = true) {
    // DO NOT SCROLL DOWN IF HELP TOUR IS OPEN
    if (isGuideOpen) return;

    if (messagesRef.current) {
      const { scrollHeight } = messagesRef.current;

      smoothScroll
        ? messagesRef.current.scrollTo({
            top: scrollHeight,
            behavior: "smooth",
          })
        : (messagesRef.current.scrollTop = scrollHeight);
    }
  }

  function handleScrollUp() {
    if (canScroll) {
      const currentScrollTop = messagesRef.current!.scrollTop;

      if (currentScrollTop < previousScrollTop.current) {
        // Remove event listener after first scroll up
        messagesRef.current!.removeEventListener("scroll", handleScrollUp);

        setScrolledUp(true);
      }

      // Update previous scrollTop value for next check
      if (previousScrollTop.current !== currentScrollTop) {
        previousScrollTop.current = currentScrollTop;
      }
    }
  }

  // scroll up event listener
  useEffect(() => {
    const currentRef = messagesRef.current;
    if (currentRef && !scrolledUp && !!waitingOnBot) {
      currentRef.addEventListener("scroll", handleScrollUp);
    } else return;

    return () => {
      if (currentRef && !scrolledUp && !!waitingOnBot) {
        currentRef.removeEventListener("scroll", handleScrollUp);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesRef, waitingOnBot]);

  // smooth scroll on streaming reply data
  useEffect(() => {
    const autoScrollEnabled = !scrolledUp && partialReply.length;

    if (autoScrollEnabled) {
      scrollToBottomWithBehavior(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partialReply]);

  // instant scroll to bottom on select different session
  useEffect(() => {
    scrollToBottomWithBehavior(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  // instant scroll to bottom on initial render
  useEffect(() => {
    messagesRef.current && scrollToBottomWithBehavior(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // smooth scroll on question submitted
  useEffect(() => {
    !!canScroll ? scrollToBottomWithBehavior(true) : setScrolledUp(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitingOnBot, hasAttachedFiles]);

  /* waiting for session content */
  if (loadingChat) {
    return <ChatSkeleton />;
  }

  // has error
  if (!loadingChat && !!chatError) {
    return (
      <Flex
        w={"100%"}
        direction={"column"}
        gap={3}
        justify={"center"}
        align={"center"}
        color={"yellow.500"}
        mt={"calc(50vh - 75px)"}
      >
        <Icon as={MdOutlineHideSource} boxSize={8} />
        <Text fontSize={"14px"} fontWeight={"500"}>
          {errorHandler(chatError)?.message}
        </Text>
      </Flex>
    );
  }

  return (
    <>
      {/* TODO: has issues, to be addressed & tested thoroughly */}
      {/* <ExportChat conversations={messages} /> */}

      <Flex
        direction={"column"}
        justify={"flex-end"}
        align={"flex-end"}
        h={"100%"}
        w={"900px"}
        maxW={"90%"}
        mx={"auto"}
        py={"24px"}
        position={"relative"}
        zIndex={applyIsGuideOpenStyles ? 1300 : 1}
        _focusVisible={{ border: "none", outline: "none" }}
      >
        {/* messages content */}
        {!loadingChat &&
          !chatError &&
          (messages.length > 0 || waitingOnBot) && (
            <>
              <Flex
                direction={"column"}
                pr={4}
                w={"100%"}
                _focusVisible={{ border: "none", outline: "none" }}
              >
                {messages.map(
                  (conversation: ConversationProps, index: number) => (
                    <MessageBubble
                      key={"message" + index}
                      content={conversation ?? undefined}
                      isLastMessage={index === messages.length - 1}
                      messagesRef={messagesRef}
                    />
                  )
                )}
              </Flex>

              {/* streaming - partial reply */}
              {waitingOnBot && isActiveSession && (
                <Flex direction={"column"} px={2} pr={6} w={"100%"}>
                  <Flex w="100%" mt={4} pl={"48px"}>
                    <Flex
                      alignContent={"flex-start"}
                      width={"fit-content"}
                      display={"inline-block"}
                      ml={"auto"}
                    >
                      {hasQuestionOnWait && (
                        <HumanMessage questionOnWait={questionOnWait} />
                      )}
                    </Flex>
                  </Flex>
                  <LoadingBubble
                    partialReply={partialReply}
                    notifications={notifications}
                  />
                </Flex>
              )}
            </>
          )}
      </Flex>

      {/* TODO: to refactor & improve, follow chatGPT behavior */}
      {!applyIsGuideOpenStyles && (
        <ScrollDownButton
          messagesRef={messagesRef}
          onClick={() => scrollToBottomWithBehavior()}
        />
      )}
    </>
  );
}
