import {
  FormControl,
  FormErrorMessage,
  Input,
  useTheme,
} from "@chakra-ui/react";
import { ChangeEvent } from "react";
import { hexToRgba } from "utils/helpers";

interface NameFormControlProps {
  name: string;
  isInvalid: boolean;
  onNameChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

function NameFormControl({
  name,
  isInvalid,
  onNameChange,
}: NameFormControlProps) {
  // Theme
  const { colors } = useTheme();

  return (
    <FormControl isRequired isInvalid={isInvalid}>
      <Input
        name="name"
        value={name}
        onChange={onNameChange}
        placeholder="Name"
        minW={["100%", "100%", "180px", "200px", "220px", "220px"]}
        fontSize={{ base: "12px", md: "14px" }}
        color={"white"}
        _placeholder={{ fontSize: "14px", color: "whiteAlpha.700" }}
        borderRadius={"24px"}
        bg={hexToRgba(colors.white, 0.08)}
        borderWidth={1}
        borderColor={"blue.500"}
        _hover={{ borderColor: "blue.600" }}
        _focusVisible={{ borderColor: "white" }}
      />
      <FormErrorMessage color={"whiteAlpha.700"}>
        Name is required. Should be longer than 3 characters
      </FormErrorMessage>
    </FormControl>
  );
}

export default NameFormControl;
