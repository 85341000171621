import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

import {
  Flex,
  Image,
  Heading,
  useBreakpointValue,
  Icon,
  useTheme,
  Box,
} from "@chakra-ui/react";

import useNavigationTabs from "hooks/useNavigationTabs";
import ChatHistory from "./ChatHistory";
import AvatarMenu from "./UserMenu";
import NavItem from "./Item";

import BetaTag from "components/ui/Beta";
import { hexToRgba } from "utils/helpers";

import { BsReverseLayoutSidebarReverse } from "react-icons/bs";
import { ThemeProps } from "components/userMenu/Appearance";
import useAllowAccess from "hooks/auth/useAllowAccess";
import { PERMISSIONS, TYPES } from "utils/premissions";
import { IoMdNotificationsOutline } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  markAllAsRead,
  resetUnreadCount,
  selectCurrentNotificationsData,
} from "redux/features/notifications/notificationsSlice";
import { useMarkNotificationAsRead } from "api/notifications/useMarkNotificationAsReadMutation";
import NotificationsList from "components/ui/NotificationsList";

export default function Nav({ onToggle }: ThemeProps) {
  const { userIsAnAllowedTypes, userHasRequiredPermissions } = useAllowAccess();

  // State
  const [isNavOpen, setIsNavOpen] = useState(true);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const notificationsRef = useRef<HTMLDivElement>(null);

  // Hooks
  const { navItems } = useNavigationTabs();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { unreadCount } = useSelector(selectCurrentNotificationsData);
  const { mutate: markAllNotificationsAsRead } = useMarkNotificationAsRead();

  // Theme
  const { colors } = useTheme();
  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const isLight = colorMode === "light";
  const borderColor = isLight ? "transparent" : "dark.600";
  const notificationBorderColor = isLight
    ? hexToRgba(colors.blue[600], 0.2)
    : "dark.800";

  // Responsiveness
  const sidebarWidth = useBreakpointValue({
    lg: "260px",
    xl: "280px",
    "2xl": "300px",
  });

  // motion variants
  const sidebarVariants = {
    open: {
      width: sidebarWidth,
      opacity: 1,
      display: "block",
      transition: { duration: 0.4 },
    },
    closed: {
      width: "0px",
      opacity: 0,
      transitionEnd: {
        display: "none",
      },
      transition: { duration: 0.4 },
    },
  };

  const buttonVariants = {
    open: {
      width: "40px",
      opacity: 1,
      display: "block",
      transition: { duration: 0.2, delay: 0.4 },
    },
    closed: {
      width: "0px",
      opacity: 0,
      transitionEnd: {
        display: "none",
      },
      transition: { duration: 0.2 },
    },
  };

  const handleCloseNotificationsMenu = () => {
    setIsNotificationsOpen(false);
    dispatch(markAllAsRead());
    markAllNotificationsAsRead();
  };

  const onNotificationsMenuClick = () => {
    if (isNotificationsOpen) {
      handleCloseNotificationsMenu();
    } else {
      unreadCount > 0 && dispatch(resetUnreadCount());
      setIsNotificationsOpen(true);
    }
  };

  // Click outside handler
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        notificationsRef.current &&
        !notificationsRef.current.contains(event.target as Node)
      ) {
        handleCloseNotificationsMenu();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const NavabarToggleButton = (
    <Icon
      as={BsReverseLayoutSidebarReverse}
      boxSize={8}
      borderRadius={"6px"}
      p={2}
      bg={hexToRgba(colors.primary[500], 0.06)}
      color={"primary.500"}
      cursor={"pointer"}
      transition={"all .3s ease"}
      _hover={{ bg: hexToRgba(colors.primary[500], 0.12) }}
      onClick={() => setIsNavOpen(!isNavOpen)}
    />
  );

  const NotificationsBell = () => (
    <Flex position="relative">
      <Icon
        as={IoMdNotificationsOutline}
        boxSize={8}
        borderRadius={"6px"}
        p={1.5}
        bg={hexToRgba(colors.primary[500], 0.06)}
        color={"primary.500"}
        cursor={"pointer"}
        transition={"all .3s ease"}
        _hover={{ bg: hexToRgba(colors.primary[500], 0.12) }}
        onClick={onNotificationsMenuClick}
      />
      {unreadCount > 0 && (
        <Box
          position="absolute"
          top="-6px"
          right="-6px"
          bg="red.500"
          color="white"
          borderRadius="full"
          display="flex"
          justifyContent="center"
          alignItems="center"
          fontSize="10px"
          minWidth={unreadCount > 99 ? "22px" : "18px"}
          height="18px"
          px={unreadCount > 99 ? "4px" : "0px"}
        >
          {unreadCount > 99 ? "99+" : unreadCount}
        </Box>
      )}
    </Flex>
  );

  return (
    <>
      <motion.div
        initial={isNavOpen ? "closed" : "open"}
        animate={isNavOpen ? "closed" : "open"}
        variants={buttonVariants}
      >
        <Flex mt={[null, null, null, "8px", "20px", "16px"]}>
          {NavabarToggleButton}
        </Flex>
      </motion.div>

      <motion.div
        initial={isNavOpen ? "open" : "closed"}
        animate={isNavOpen ? "open" : "closed"}
        variants={sidebarVariants}
      >
        <Flex
          direction={"column"}
          h={"100%"}
          w={sidebarWidth}
          p={4}
          borderRadius={"10px"}
          borderColor={borderColor}
          bg={"background"}
          borderWidth={1}
          justify={"center"}
          gap={4}
        >
          {/* Logo and home */}
          <Flex
            align={"center"}
            justify={"space-between"}
            mb={{ lg: 2, xl: 3, "2xl": 4 }}
          >
            <Flex gap={2} align={"center"}>
              <Image
                h={[null, null, null, "26px", "30px", "34px"]}
                src={
                  isLight
                    ? "https://reacto.s3.eu-central-1.amazonaws.com/logo/green.svg"
                    : "https://reacto.s3.eu-central-1.amazonaws.com/logo/white.svg"
                }
                opacity={isLight ? 0.8 : 0.4}
                onClick={() => navigate("/")}
                _hover={{ cursor: "pointer" }}
              />

              {/* Company name + version tag */}
              <Flex direction={"column"}>
                <Heading
                  size={{ lg: "sm", xl: "md" }}
                  color={"highlight.primary"}
                  opacity={0.6}
                >
                  Lakesai
                </Heading>

                <BetaTag />
              </Flex>
            </Flex>

            {/* notifications bell + close sidebar icon*/}
            <Flex align={"center"} columnGap={2}>
              <NotificationsBell />
              {NavabarToggleButton}
            </Flex>
          </Flex>

          {isNotificationsOpen && (
            <Box
              ref={notificationsRef}
              position="absolute"
              top="70px"
              left={1}
              boxShadow="lg"
              borderRadius={"xl"}
              bg={"background"}
              borderColor={notificationBorderColor}
              borderWidth={1}
              zIndex={999}
              py={3}
              px={2}
              width={`calc(${sidebarWidth} - 8px)`}
            >
              <NotificationsList />
            </Box>
          )}

          {/* Main navs */}
          <Flex direction={"column"} gap={1}>
            {navItems.map((item, index) => {
              // true if user is a team member
              const isTeamMember =
                userIsAnAllowedTypes([TYPES.TEAMS]) ||
                userHasRequiredPermissions([PERMISSIONS.TEAMS_INVITEE]);

              // true if nav item is `Team`
              const isTeamNavItem = item?.name === "Team";

              if (isTeamNavItem) {
                if (isTeamMember) return <NavItem key={index} item={item} />;
                // NOTE: to avoid "Each child in a list should have a unique "key" prop" warning,
                // use Fragment instead of <></>, as it provides key prop
                else return <Fragment key={index} />;
              } else {
                return <NavItem key={index} item={item} />;
              }
            })}
          </Flex>

          {/* chats */}
          <ChatHistory />

          {/* User Avatar & Menu */}
          <AvatarMenu onToggle={onToggle} />
        </Flex>
      </motion.div>
    </>
  );
}
