import {
  CompoundProps,
  ReducedCompoundProps,
} from "models/compounds/CompoundProps";
import { PaperProps } from "models/papers/PaperProps";
import { SavedElementProps } from "models/bookmarks/SavedProps";
import _ from "lodash";
import { AssayHitsProps, ScreeningProps } from "models/assays/ScreeningProps";

// general
export const parseParam = (param: string | null): string => {
  return (param?.split(",") ?? []).join(",");
};

// --------------------- Auxiliar Functions for Compound -----------------------
export const getAliases = (aliases: string[]) => {
  if (aliases) {
    const filteredAliases = aliases.filter((str: string) => str.length <= 10);
    const sortedAliases = filteredAliases.sort(
      (a: string, b: string) => a.length - b.length
    );
    return sortedAliases.slice(0, 3);
  }

  return [];
};

type getSmilesImageSrcProps = CompoundProps | SavedElementProps;

const isValidMol = (mol: any) => !!mol;

const MOL_DETAILS = {
  bondLineWidth: 2,
  addStereoAnnotation: true,
  explicitMethyl: true,
};

const getMolDetails = (mol: any, qmol: any) => {
  if (isValidMol(mol) && isValidMol(qmol)) {
    const subStructHighlightDetails = JSON.parse(
      mol.get_substruct_matches(qmol)
    );
    const subStructHighlightDetailsMerged = !_.isEmpty(
      subStructHighlightDetails
    )
      ? subStructHighlightDetails.reduce(
          (acc: any, { atoms, bonds }: { atoms: any; bonds: any }) => ({
            atoms: [...acc.atoms, ...atoms],
            bonds: [...acc.bonds, ...bonds],
          }),
          { bonds: [], atoms: [] }
        )
      : subStructHighlightDetails;
    return JSON.stringify({
      ...MOL_DETAILS,
      ...subStructHighlightDetailsMerged,
    });
  } else {
    return JSON.stringify({
      ...MOL_DETAILS,
    });
  }
};

export const getSmilesImageSrc = (
  cmpd: getSmilesImageSrcProps,
  inBookmarks?: boolean
) => {
  const chemProps = inBookmarks
    ? (cmpd as SavedElementProps).chemical_props
    : (cmpd as CompoundProps).chemical_props;

  const smiles = inBookmarks
    ? chemProps?.canonical_smiles
    : chemProps?.isomeric_smiles;
  const smilesStructure = inBookmarks
    ? chemProps?.canonical_smiles_structure
    : chemProps?.isomeric_smiles_structure;

  if (!!smiles && cmpd?.substructure_match && window.RDKit) {
    const mol = window.RDKit.get_mol(smiles || "invalid");
    const qmol = window.RDKit.get_qmol(cmpd?.substructure_match || "invalid");

    const svg = btoa(
      mol?.get_svg_with_highlights(getMolDetails(mol, qmol)) ?? ""
    );
    mol?.delete();
    qmol?.delete();

    return `data:image/svg+xml;base64,${svg}`;
  }
  if (smilesStructure) {
    return `data:image/png+svg;base64,${smilesStructure}`;
  }

  return "ex.png";
};

export const getIndications = (cmpd: CompoundProps) => {
  const items = cmpd.assays_hits?.map((hit) => {
    return hit.indication;
  });
  const unique = new Set(items);
  return [...unique];
};

// --------------------- Auxiliars -------------------------
export const stringToColor = (str: string) => {
  const colors = [
    "#df8e98",
    "#FFC0CB",
    "#C3E6CB",
    "#ADD8E6",
    "#52CEBE",
    "#B7A8F1",
    "#CBF2FF",
  ];
  const hash = [...str].reduce((h, c) => c.charCodeAt(0) + ((h << 5) - h), 0);
  const colorIndex = Math.abs(hash) % colors.length;
  return colors[colorIndex];
};

export const capitalise = (s: string) => {
  if (!s) return "";
  return s?.charAt(0)?.toUpperCase() + s?.slice(1);
};

// --------------------- Auxiliar Functions for Compounds List -------------------------
export const findAliasWithLetter = (letter: string, aliases: string[]) => {
  const match = aliases.find((alias: string) => {
    return alias.toLowerCase()[0] === letter.toLowerCase() && alias.length < 14;
  });

  return match;
};

export function getChartYears(data: PaperProps[]) {
  const papersWithValidYear: PaperProps[] = (data || []).filter(
    (paper: PaperProps) =>
      typeof paper.year_published === "number" && paper.year_published > 0
  );

  const unique: number[] = papersWithValidYear.map(
    (paper: PaperProps) => paper.year_published as number
  );

  const valid: number[] = unique?.sort((a: number, b: number) => a - b);

  const sorted: number[] = Array.from(new Set(valid)).sort(
    (a: number, b: number) => a - b
  );

  return sorted;
}

export const getNodeOptions = (
  reference_lens_id: string | undefined,
  item: PaperProps
) => {
  // Origin
  if (reference_lens_id === item.lens_id) {
    return {
      bg: "#E062AE",
      category: 0,
    };
  }

  // referenced in
  if (item.references?.find((ref: any) => ref.lens_id === reference_lens_id)) {
    return {
      bg: "#63B3ED",
      category: 1,
    };
  }

  // References
  return {
    bg: "#FFA62B",
    category: 2,
  };
};

export function adjustCompoundName(cmpd: CompoundProps | ReducedCompoundProps) {
  if (cmpd?.name && cmpd?.name !== "(no name)") {
    return cmpd?.name;
  }

  return cmpd?.compound_id;
}

// --------------------- Phases -------------------------
export const phases = [
  "Preclinical",
  "Phase 1",
  "Phase 2",
  "Phase 3",
  "Pre-registered",
  "Registered",
  "Launched",
];

export const texts = [" ", "I", "II", "III", " ", " ", " "];

// --------------------- Assays -------------------------

// Group assays by a common key (e.g., name and indication)
export function groupAssaysByKey(
  assays: (AssayHitsProps | ScreeningProps)[],
  key: string
) {
  return assays.reduce<Record<string, (AssayHitsProps | ScreeningProps)[]>>(
    (acc, assay) => {
      const groupKey = (assay as any)[key];
      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }
      acc[groupKey].push(assay);
      return acc;
    },
    {}
  );
}

// --------------------- Molecules Table -------------------------
export default interface HelperProps {
  title: string;
  description: string;
  image?: string;
  highlight?: string[];
}

export const molsCols = ["Molecule", "ID", "Name", "Aliases", "Assay Hits"];

export const activityDataHelper: HelperProps[] = [
  {
    title: "Assay",
    description:
      "Assay refers to a method or procedure used in the laboratory to analyze and measure the presence, concentration, or activity of a substance or biological component of interest.",
  },
  {
    title: "Assay Type",
    description:
      "Assays can vary widely based on the specific purpose and the nature of the substance being studied. They could involve techniques such as biochemical assays, cell-based assays, or molecular assays.",
  },
  {
    title: "Indication",
    description:
      "Refers to the specific purpose or reason for conducting the assay. It outlines the intended use or what the assay is designed to detect, measure, or analyze. ",
  },
  {
    title: "Activity Type",
    description: "",
  },
  {
    title: "Measurement",
    description:
      "Refers to the outcomes or results obtained from a scientific assay or experimental test that indicate a potential positive response or activity. The measurement within an assay is the crucial outcome that provides data or results regarding the substance or process being studied.",
  },
  {
    title: "Assay Source",
    description: "Refers to the entity that carried out the assays.",
  },
];

export const chemPropsHelper: HelperProps[] = [
  {
    title: "Formula",
    description:
      "The formula of a compound represents the types and number of atoms present in a molecule. The formula provides the elemental symbols and the ratio of atoms in the compound. ",
  },
  {
    title: "InChi & InChiKey",
    description:
      "InChi stands for International Chemical Identifier and it is a structure-based chemical identifier. As a standard identifier for chemical databases, InChI is essential for enabling effective information management across chemistry. InChI identifiers describe chemical substances in terms of layers of information - the atoms and their bond connectivity, tautomeric information, isotope information, stereochemistry and electronic charge. The InChIKey is a fixed length (27 character) condensed digital representation of the InChI that is not designed to be human-understandable.\n In summary: InChI is a structure-based identifier, strictly unique, and non-proprietary, open source and freely accessible; and InChIKey is a hashed version of InChI which allows for a compact representation and for searching in standard search engines like Google.",
    image: "",
  },
  {
    title: "Smiles",
    description:
      'SMILES stands for Simplified Molecular Input Line Entry System, and is a string notation to encode the structure of a chemical compound in a simple and concise line of text. A canonicalization algorithm exists to generate one special generic SMILES among all valid possibilities; this special one is known as the "canonical SMILES". SMILES written with isotopic and chiral specifications are collectively known as "isometric SMILES", while "esoteric SMILES" refer to less common, unconventional, or non-standardized representations, potentially involving hidden or less obvious details within the SMILES notation.',
  },
  {
    title: "Chirality",
    description:
      "Chirality refers to the property of asymmetry in molecules, where a molecule and its mirror image are not superimposable. This phenomenon occurs due to the spatial arrangement of atoms within the molecule, resulting in left-handed and right-handed versions known as enantiomers. Possible values for chirality are: \n Achiral: A molecule is considered achiral if it is superimposable on its mirror image. In other words, it lacks handedness and does not have distinct left-handed and right-handed versions. Achiral molecules typically possess elements of symmetry that allow them to be superimposed on their mirror images. \nChiral: Chiral molecules are non-superimposable on their mirror images. They have distinct left-handed and right-handed versions known as enantiomers. Chirality arises when a molecule lacks a plane of symmetry or a center of symmetry, resulting in asymmetry in its three-dimensional structure. \nProchiral: Prochiral compounds are not chiral themselves but can be converted into chiral molecules. This happens under the influence of an external asymmetric influence, such as a chiral catalyst or reagent, which causes the creation of a chiral center or a chiral environment. \nRacemate: A racemate is formed when equal amounts of two enantiomers of a chiral molecule are combined. As a result, the overall mixture does not exhibit optical activity because the effects of the two enantiomers cancel each other out.",
    highlight: ["Achiral:", "Chiral:", "Prochiral:", "Racemate:"],
  },
];

export const externalSourcesHelper: HelperProps[] = [
  {
    title: "Sources",
    description:
      "Different sources providing information on the current compound. In the left the name of the source, and at the right the id of the compound in the given sources. Some links are included.",
    image: "",
  },
];

export const papersHelper: HelperProps[] = [
  {
    title: "Research Papers",
    description:
      "The chart shows the papers that reference the compound. It divides the papers into years, and shows number of papers per year. Note that sometimes the year in unknown and if clicked on it, links to the papers network view displaying all research papers that reference the original one.",
    image: "",
  },
];

export const pharmaPropsHelper: HelperProps[] = [
  {
    title: "Origin",
    description:
      "Compound origin refers to the source or the way a particular compound is obtained or derived. It typically indicates whether a compound is naturally occurring or chemically synthesized.",
  },
  {
    title: "Phases",
    description:
      "Phases are designed to assess the safety, efficacy, and overall viability of the drug. The phases can vary slightly depending on the region and regulatory agency, but the general phases are as follows: preclinical, first-in-human, clinical efficacy, large scale clinical trials, post marketing surveillance.",
    image: "",
  },
  {
    title: "Pharmacokinetics",
    description:
      "These attributes describe how the body processes the molecule, including absorption, distribution, metabolism, and excretion (ADME). Key parameters include: - Bioavailability: The fraction of the administered dose that reaches the systemic circulation. - Half-life: The time it takes for half of the drug to be eliminated from the body. - Clearance: The rate at which the drug is removed from the body.",
  },
  {
    title: "Pharmacodynamics",
    description:
      "These attributes describe how the molecule interacts with its target(s) and produces its effects. Key parameters include: - Potency: The concentration of the drug required to produce a specific effect. - Efficacy: The maximal effect that can be achieved with the drug. - Binding Affinity: The strength of the interaction between the drug and its target.",
  },
  {
    title: "Pharmacogenetics",
    description:
      "Information on how a person's genetic makeup can affect their response to the drug.",
  },
  {
    title: "Route of Administration",
    description:
      "Method by which a drug or medication is introduced into the body. Common routes of administration include oral (by mouth), intravenous (into a vein), intramuscular (into a muscle), subcutaneous (under the skin), transdermal (through the skin), and so on.",
  },
  {
    title: "Modes of Application",
    description:
      "Refers to the many ways in which a substance is applied or used, not necessarily related to drug administration. (e.g., as a cream, ointment, or gel) or how a medical device is used for drug delivery (e.g., an inhaler or a patch).",
  },
  {
    title: "Dosing Regimen",
    description:
      "Guidelines for how the drug should be administered, including dosing frequency, duration of treatment, and dosage adjustments for different patient populations.",
  },
  {
    title: "Indications & Medical conditions treated",
    description:
      "Indications are approved uses of a drug, it describes the medical condition or disease that the drug is intended to treat, prevent, or manage. While the term medical conditions treated can include a more comprehensive list of conditions, both approved and off-label, for which a drug might be prescribed or used in clinical practice.",
  },
  {
    title: "Target",
    description:
      "Refers to a specific molecule, usually a protein or nucleic acid (such as DNA or RNA), in the body that a drug or a compound interacts with. The interaction between the compound and its target can lead to various biological effects. These effects can range from the alteration of a biochemical process to the modulation of a specific biological function.",
  },
  {
    title: "Mechanism of Action",
    description:
      "Specific function or purpose of the drug within a treatment regimen or therapeutic context.",
  },
  {
    title: "Side Effects",
    description:
      "Side effects are unintended or secondary effects that occur in addition to the intended therapeutic effects of a medication, treatment, or substance. They can manifest as a result of the intended use of a drug, interaction with other substances, individual variability in response, or the body's reaction to the compound. ",
  },
  {
    title: "Toxicity",
    description:
      "Information about the molecule's potential for adverse effects or toxicity. ",
  },
  {
    title: "Pharmaceutical Formulation",
    description:
      "Details about how the drug is formulated for administration, including dosage form (e.g., tablet, capsule, injection), excipients, and stability.",
  },
  {
    title: "Pregnancy Category",
    description:
      "Also known as the pregnancy risk category, is a classification system used to inform healthcare providers about the potential risks associated with using a particular drug or compound during pregnancy.",
  },
];

export const moaPropsHelper: HelperProps[] = [
  {
    title: "MoA Category",
    description:
      "Broad classification of the Mechanism of Action (MoA), categorizing it into general roles or types of interactions.",
  },
  {
    title: "MoA",
    description:
      "Specific Mechanism of Action detailing the precise biological or chemical activity of a substance.",
  },
  {
    title: "Parent MoA",
    description:
      "Hierarchical classification that provides broader context or parent categories leading to the specific MoA, showing its place within a larger framework.",
  },
  {
    title: "Data Source",
    description:
      "Indication of the source of the data, typically referring to a database or an identifier within a database.",
  },
];

export const screeningDataPropsHelper: HelperProps[] = [
  {
    title: "Assay",
    description:
      "Assay refers to a method or procedure used in the laboratory to analyze and measure the presence, concentration, or activity of a substance or biological component of interest.",
  },
  {
    title: "Assay Type",
    description:
      "Assays can vary widely based on the specific purpose and the nature of the substance being studied. They could involve techniques such as biochemical assays, cell-based assays, or molecular assays.",
  },
  {
    title: "Indication",
    description:
      "Refers to the specific purpose or reason for conducting the assay. It outlines the intended use or what the assay is designed to detect, measure, or analyze. ",
  },
  {
    title: "Screening Hit",
    description: "Specifies if the screening was a hit or not.",
  },
  {
    title: "Assay Source",
    description: "Refers to the entity that carried out the assays.",
  },
];

export const similarSummaryPropsHelper: HelperProps[] = [
  {
    title: "Molecule",
    description:
      "2D visual representation of the canonical or isometric SMILES.",
  },
  {
    title: "ID = InChiKey",
    description:
      "The id is the same as the InChiKey of the compound. InChI is a structure-based identifier, strictly unique, and non-proprietary, open source and freely accessible; and InChIKey is a hashed version of InChI which allows for a compact representation and for searching in standard search engines.",
  },
  {
    title: "Name & Aliases",
    description:
      "We try to curate the database and show for name the generic name of a compound. Aliases of a chemical compound refer to the various names or identifiers used to represent that compound across different contexts, disciplines, or databases. Chemical compounds can have multiple names due to differences in nomenclature systems, language variations, historical origins, or specific applications. Aliases can be chemical names, trade names, abbreviations and acronyms.",
  },
  {
    title: "Assay Hits",
    description:
      "Refers to the outcomes or results obtained from a scientific assay or experimental test that indicate a potential positive response or activity",
  },
  {
    title: "Data density",
    description:
      "Represents the amound of data available for the given compound. the fields meassured are 'Pharmacokinetics', 'Pharmacodynamics', 'Molecule', 'Efficacy', 'Safety', 'Synthesis', 'Mechanism of Action', 'Interactions', 'Affinity', and 'Repurposing'. ",
    image: "",
  },
  {
    title: "Similarity Score",
    description:
      "The similarity between original compound and displayed is meassured by the tanimoto similarity score. The score ranges from 0 to 1, with 0 indicating no similarity between the sets and 1 indicating complete similarity. Higher Tanimoto coefficients suggest greater similarity between the sets being compared. \n \ntanimoto_score = |A ∩ B| / |A ∪ B|",
    highlight: ["tanimoto_score = |A ∩ B| / |A ∪ B|"],
  },
];
