import ReactDOM from "react-dom/client";
import "./index.css";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";

// store
import { store } from "./redux/store";
import { Provider } from "react-redux";

// theme
import { ChakraProvider } from "@chakra-ui/react";
import AppRoutes from "./routes";
import { environment } from "environments";
import { EmbeddingSocketContextProvider } from "contexts/EmbeddingSocketContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import * as serviceWorker from "./serviceWorker";
import { RDKitModule } from "@rdkit/rdkit";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";

// Disable react devTools in production
if (process.env.NODE_ENV === "production") disableReactDevTools();

const stripePromise = loadStripe(environment.STRIPE_PUBLISHABLE_KEY);

// reactQuery Client
const queryClient = new QueryClient();

//  Declare the RDKitModule property
declare global {
  interface Window {
    RDKit: RDKitModule;
  }
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ChakraProvider>
        <EmbeddingSocketContextProvider>
          <Elements stripe={stripePromise}>
            <AppRoutes />
          </Elements>
        </EmbeddingSocketContextProvider>
      </ChakraProvider>

      {/* React Query DevTools */}
      <ReactQueryDevtools
        initialIsOpen={false}
        buttonPosition={"bottom-right"}
      />
    </QueryClientProvider>
  </Provider>
);

serviceWorker.unregister();
