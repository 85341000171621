import { Box, Flex, Tooltip, useTheme } from "@chakra-ui/react";
import { phases, texts } from "./helpers";

interface PhaseChartProps {
  selected: string;
}

interface PhaseSVGProps {
  text: string;
  selected?: boolean;
  disabled?: boolean;
}

export default function PhaseChart({ selected }: PhaseChartProps) {
  const hieghestPhaseKey = phases.findIndex((value) => value === selected);

  return (
    <Flex>
      {phases.map((phase, index) => {
        return (
          <Tooltip
            key={"phase-" + index}
            label={phase}
            placement={"bottom"}
            bg={"gray.900"}
            color={"gray.100"}
            hasArrow
            p={2}
            m={2}
            maxW={[null, null, null, "200px", "220px", "240px"]}
            borderRadius={"4px"}
            fontSize={"12px"}
            boxShadow={"none"}
            arrowSize={8}
          >
            <Box>
              <PhaseSVG
                text={texts[index]}
                selected={phase === selected}
                disabled={selected === "" || hieghestPhaseKey < index}
              />
            </Box>
          </Tooltip>
        );
      })}
    </Flex>
  );
}

function PhaseSVG({ text, selected, disabled }: PhaseSVGProps) {
  const { colors } = useTheme();

  const svgStyles = {
    fill: disabled
      ? colors.neutral[300]
      : selected
      ? colors.primary[600]
      : colors.primary[200],
  };

  const textStyles = {
    fill: disabled
      ? colors.neutral[600]
      : selected
      ? colors.primary[200]
      : colors.primary[400],
  };

  return (
    <svg _ngcontent-c24="" height="22px" width="36px" style={svgStyles}>
      <g _ngcontent-c24="" transform="translate(0, 0)">
        <path
          _ngcontent-c24=""
          className="chevron completed ng-star-inserted"
          d="M1.2,0c-1.1,0-1.5,0.8-1,1.7l4.4,7.5c0.6,1,0.6,2.5,0,3.5l-4.4,7.5c-0.6,1-0.1,1.7,1,1.7h24
      c1.1,0,2.5-0.8,3-1.7l4.4-7.5c0.6-1,0.6-2.5,0-3.5l-4.4-7.5c-0.6-1-1.9-1.7-3-1.7H1.2z"
        />

        <text _ngcontent-c24="" y="17" x="14" style={textStyles}>
          {text}
        </text>
      </g>
    </svg>
  );
}
