import { Flex } from "@chakra-ui/react";

import { AiOutlineHome } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import { BiMessageSquareDetail } from "react-icons/bi";
import { GrCircleQuestion } from "react-icons/gr";
import { TbLayoutGrid } from "react-icons/tb";

import { useEffect, useState } from "react";
import { GetGradients } from "utils/gradients";
import MobileNavItem from "./MobileNavItem";

export default function MobileNav() {
  // theme
  const { lGradient2 } = GetGradients();

  // States
  const [show, setShow] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [activeNav, setActiveNav] = useState("#");

  // Handler
  function controlNavbar() {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        setShow(false);
      } else {
        setShow(true);
      }
      setLastScrollY(window.scrollY);
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastScrollY]);

  return (
    <Flex
      bg={"whiteAlpha.900"}
      bgGradient={lGradient2}
      boxShadow={{
        base: "unset",
        sm: "rgba(0, 0, 0, 0.2) 0px 3px 8px",
      }}
      justify={"center"}
      w={{ base: "100%", sm: "max-content" }}
      px={{ base: 1.5, sm: 7 }}
      pb={{ base: 1.5, sm: 3 }}
      pt={1.5}
      zIndex={101}
      position={"fixed"}
      left={"50%"}
      bottom={show ? { base: 0, sm: "0.5rem" } : "-5rem"}
      transform={"translateX(-50%)"}
      gap={2}
      borderRadius={{ base: 0, sm: "50px" }}
      backdropFilter={"blur(6px)"}
      transition={"bottom 1s ease"}
    >
      <MobileNavItem
        to="#"
        title={"Home"}
        icon={AiOutlineHome}
        onClick={() => setActiveNav("#")}
        active={activeNav}
      />
      <MobileNavItem
        to="#about"
        title={"About"}
        icon={GrCircleQuestion}
        onClick={() => setActiveNav("#about")}
        active={activeNav}
      />
      <MobileNavItem
        to="#usecases"
        title={"Use Cases"}
        icon={AiOutlineUser}
        onClick={() => setActiveNav("#usecases")}
        active={activeNav}
      />
      <MobileNavItem
        to="#contact"
        title={"Contact"}
        icon={BiMessageSquareDetail}
        onClick={() => setActiveNav("#contact")}
        active={activeNav}
      />
      <MobileNavItem
        to="#pricing"
        title={"Pricing"}
        icon={TbLayoutGrid}
        onClick={() => setActiveNav("#pricing")}
        active={activeNav}
      />
    </Flex>
  );
}
