import { Text, Flex } from "@chakra-ui/react";

// Define a type for the countdown state
interface TimeRemaining {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

// Define the props for the Countdown component
interface CountdownProps {
  timeRemaining: TimeRemaining;
}

function Countdown({ timeRemaining }: CountdownProps) {
  const { days, hours, minutes, seconds } = timeRemaining;

  return (
    <Flex
      direction={"column"}
      align={"center"}
      justify={"center"}
      bg={{ base: "transparent", lg: "white" }}
      borderRadius={{ base: 0, lg: "40px" }}
      boxShadow={{ base: "none", lg: "md" }}
      px={{ base: 6, lg: 6, xl: 8 }}
      py={{ base: 1.5, lg: 1.5, xl: 2 }}
    >
      <Flex
        gap={2}
        fontSize={{ base: "xl", lg: "lg", xl: "xl" }}
        fontFamily={"monospace"}
        color={"secondary.700"}
      >
        <Flex direction={"column"} justify={"center"} align={"center"}>
          <Text>{String(days).padStart(2, "0")}</Text>
          <Text
            lineHeight={1}
            fontSize={{ base: "xs", xl: "sm" }}
            color="secondary.400"
          >
            Days
          </Text>
        </Flex>

        <Text>:</Text>

        <Flex direction={"column"} justify={"center"} align={"center"}>
          <Text>{String(hours).padStart(2, "0")}</Text>
          <Text
            lineHeight={1}
            fontSize={{ base: "xs", xl: "sm" }}
            color="secondary.400"
          >
            Hours
          </Text>
        </Flex>

        <Text>:</Text>

        <Flex direction={"column"} justify={"center"} align={"center"}>
          <Text>{String(minutes).padStart(2, "0")}</Text>
          <Text
            lineHeight={1}
            fontSize={{ base: "xs", xl: "sm" }}
            color="secondary.400"
          >
            Min
          </Text>
        </Flex>

        <Text>:</Text>

        <Flex direction={"column"} justify={"center"} align={"center"}>
          <Text>{String(seconds).padStart(2, "0")}</Text>
          <Text
            lineHeight={1}
            fontSize={{ base: "xs", xl: "sm" }}
            color="secondary.400"
          >
            Sec
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Countdown;
