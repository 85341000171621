import { Flex, Image, Text } from "@chakra-ui/react";
import AssayVerticalCharts from "components/assays/AssayVerticalCharts";

import noDataImg from "assets/no-data.png";
import { AssayChartItemProps } from "models/assays/AssayProps";

interface ActivityChartProps {
  chartData: AssayChartItemProps[];
}

function ActivityChart({ chartData }: ActivityChartProps) {
  // has no chart data
  if (!chartData?.length) {
    return (
      <Flex
        direction={"column"}
        gap={4}
        justify={"center"}
        align={"center"}
        minH={"360px"}
      >
        <Image src={noDataImg} w={"200px"} alt="no chart to render" />
        <Text color={"gray.500"}>No available data...</Text>
      </Flex>
    );
  }

  return <AssayVerticalCharts itemsPerPage={10} data={chartData} />;
}

export default ActivityChart;
