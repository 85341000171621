import { ChangeEvent, useState } from "react";
import { Flex, Stack, useToast } from "@chakra-ui/react";

import emailjs from "@emailjs/browser";

import SendButton from "./SendButton";
import { ContactParams } from "environments";
import NameFormControl from "./NameFormControl";
import EmailFormControl from "./EmailFormControl";
import MessageFormControl from "./MessageFormControl";
import SuccessMessage from "./SuccessMessage";

import { checkFormDataValidation } from "views/home/helpers";

export interface ErrorsProps {
  name: boolean;
  email: boolean;
  message: boolean;
}

export interface FormDataProps {
  name: string;
  email: string;
  message: string;
}

export default function Form() {
  // States
  const [sending, setSending] = useState(false);
  const [formData, setFormData] = useState<FormDataProps>({
    name: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState<ErrorsProps>({
    name: false,
    email: false,
    message: false,
  });

  // Hooks
  const toast = useToast();

  // Handlers
  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedName = event.target.value;
    setFormData((prev) => ({ ...prev, name: updatedName }));
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedEmail = event.target.value;
    setFormData((prev) => ({ ...prev, email: updatedEmail }));
  };

  const handleMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const updatedMessage = event.target.value;
    setFormData((prev) => ({ ...prev, message: updatedMessage }));
  };

  // NOTE: type not provided by emailJS
  async function sendEmail(e: any) {
    e.preventDefault();

    const isValidFormData = checkFormDataValidation(formData, setErrors);

    // if form data is invalid, don't send
    if (!isValidFormData) return;

    const { SERVICE_ID, TEMPLATE_ID, PUBLIC_KEY } = ContactParams;

    try {
      setSending(true);
      await emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY);

      toast({
        duration: 4000,
        render: () => <SuccessMessage />,
      });
    } catch (error) {
      toast({
        description: "Failed to send subscription request. Please try again.",
        status: "warning",
      });
    } finally {
      setSending(false);
    }

    // Clear form inputs and reset formData state
    e.target.reset();
    setFormData({ name: "", email: "", message: "" });
  }

  return (
    <Stack
      spacing={3}
      w={["95%", "400px", "500px", "550px", "550px", "550px"]}
      as="form"
      onSubmit={sendEmail}
    >
      <MessageFormControl
        message={formData.message}
        isInvalid={errors.message}
        onMessageChange={handleMessageChange}
      />

      <Flex gap={2}>
        <NameFormControl
          name={formData.name}
          isInvalid={errors.name}
          onNameChange={handleNameChange}
        />

        <EmailFormControl
          email={formData.email}
          isInvalid={errors.email}
          onEmailChange={handleEmailChange}
        />

        <SendButton sending={sending} />
      </Flex>
    </Stack>
  );
}
