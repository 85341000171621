import { useCallback, useEffect, useState } from "react";

interface SubmitResult<T = any> {
  onSubmit(arg: T): void;
  loading?: boolean;
  error?: Error;
}

export function useSubmit<T = any>(
  source: (arg: T) => Promise<any>, // Adjust the source function signature
  onFinish?: () => void
): SubmitResult<T> {
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<Error>();
  const onSubmit = useCallback(
    (arg: T) => {
      // Accept an argument for onSubmit
      setLoading(true);

      source(arg)
        .then(() => {
          if (!!onFinish) onFinish();
        })
        .catch((error) => {
          setError(error);
          console.log({ error });
        })
        .finally(() => setLoading(false));
    },
    [onFinish, source]
  );

  return { onSubmit, loading, error };
}

export const useDebounce = <T>(value: T, delay = 200) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(timeout);
  }, [value, delay]);

  return debouncedValue;
};
