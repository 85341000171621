import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { ChangeEvent } from "react";

interface NameProps {
  name: string;
  isInvalid: boolean;
  onNameChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

function VisitorName({ name, isInvalid, onNameChange }: NameProps) {
  return (
    <FormControl isRequired isInvalid={isInvalid} w="100%">
      <FormLabel
        htmlFor="name"
        color={"secondary.700"}
        fontSize={"12px"}
        fontWeight={"500"}
        fontFamily={"Poppins, sans-serif"}
        lineHeight={1}
        mb={1}
      >
        Full name
      </FormLabel>
      <Input
        w={"100%"}
        color={"gray.500"}
        fontSize={"14px"}
        type={"text"}
        name="name"
        value={name}
        onChange={onNameChange}
        placeholder="Enter your name"
        _placeholder={{ fontSize: "14px", color: "gray.600" }}
        borderRadius={"6px"}
        bg={"background"}
        borderWidth={1}
        borderColor={"gray.200"}
        _hover={{ borderColor: "gray.300" }}
        _focusVisible={{ borderColor: "blue.300" }}
      />
      <FormErrorMessage
        fontSize={"12px"}
        color={"red.500"}
        fontFamily={"Poppins, sans-serif"}
      >
        Name is required. Should be longer than 3 characters
      </FormErrorMessage>
    </FormControl>
  );
}

export default VisitorName;
