export const LMIC = [
  { name: "Afghanistan", countryCode: "AF" },
  { name: "Albania", countryCode: "AL" },
  { name: "Algeria", countryCode: "DZ" },
  { name: "Angola", countryCode: "AO" },
  { name: "Argentina", countryCode: "AR" },
  { name: "Armenia", countryCode: "AM" },
  { name: "Azerbaijan", countryCode: "AZ" },
  { name: "Bangladesh", countryCode: "BD" },
  { name: "Belarus", countryCode: "BY" },
  { name: "Belize", countryCode: "BZ" },
  { name: "Benin", countryCode: "BJ" },
  { name: "Bhutan", countryCode: "BT" },
  { name: "Bolivia", countryCode: "BO" },
  { name: "Bosnia and Herzegovina", countryCode: "BA" },
  { name: "Botswana", countryCode: "BW" },
  { name: "Brazil", countryCode: "BR" },
  { name: "Burkina Faso", countryCode: "BF" },
  { name: "Burundi", countryCode: "BI" },
  { name: "Cabo Verde", countryCode: "CV" },
  { name: "Cambodia", countryCode: "KH" },
  { name: "Cameroon", countryCode: "CM" },
  { name: "Central African Republic", countryCode: "CF" },
  { name: "Chad", countryCode: "TD" },
  { name: "China (People's Republic of)", countryCode: "CN" },
  { name: "Colombia", countryCode: "CO" },
  { name: "Comoros", countryCode: "KM" },
  { name: "Democratic Republic of Congo", countryCode: "CD" },
  { name: "Congo", countryCode: "CG" },
  { name: "Costa Rica", countryCode: "CR" },
  { name: "Côte d'Ivoire", countryCode: "CI" },
  { name: "Cuba", countryCode: "CU" },
  { name: "Djibouti", countryCode: "DJ" },
  { name: "Dominica", countryCode: "DM" },
  { name: "Dominican Republic", countryCode: "DO" },
  { name: "Ecuador", countryCode: "EC" },
  { name: "Egypt", countryCode: "EG" },
  { name: "El Salvador", countryCode: "SV" },
  { name: "Equatorial Guinea", countryCode: "GQ" },
  { name: "Eritrea", countryCode: "ER" },
  { name: "Eswatini", countryCode: "SZ" },
  { name: "Ethiopia", countryCode: "ET" },
  { name: "Fiji", countryCode: "FJ" },
  { name: "Gabon", countryCode: "GA" },
  { name: "Gambia", countryCode: "GM" },
  { name: "Georgia", countryCode: "GE" },
  { name: "Ghana", countryCode: "GH" },
  { name: "Grenada", countryCode: "GD" },
  { name: "Guatemala", countryCode: "GT" },
  { name: "Guinea", countryCode: "GN" },
  { name: "Guinea-Bissau", countryCode: "GW" },
  { name: "Guyana", countryCode: "GY" },
  { name: "Haiti", countryCode: "HT" },
  { name: "Honduras", countryCode: "HN" },
  { name: "India", countryCode: "IN" },
  { name: "Indonesia", countryCode: "ID" },
  { name: "Iran", countryCode: "IR" },
  { name: "Iraq", countryCode: "IQ" },
  { name: "Jamaica", countryCode: "JM" },
  { name: "Jordan", countryCode: "JO" },
  { name: "Kazakhstan", countryCode: "KZ" },
  { name: "Kenya", countryCode: "KE" },
  { name: "Kiribati", countryCode: "KI" },
  { name: "Democratic People's Republic of Korea", countryCode: "KP" },
  { name: "Kosovo", countryCode: "XK" },
  { name: "Kyrgyzstan", countryCode: "KG" },
  { name: "Lao People's Democratic Republic", countryCode: "LA" },
  { name: "Lebanon", countryCode: "LB" },
  { name: "Lesotho", countryCode: "LS" },
  { name: "Liberia", countryCode: "LR" },
  { name: "Libya", countryCode: "LY" },
  { name: "North Macedonia", countryCode: "MK" },
  { name: "Madagascar", countryCode: "MG" },
  { name: "Malawi", countryCode: "MW" },
  { name: "Malaysia", countryCode: "MY" },
  { name: "Maldives", countryCode: "MV" },
  { name: "Mali", countryCode: "ML" },
  { name: "Marshall Islands", countryCode: "MH" },
  { name: "Mauritania", countryCode: "MR" },
  { name: "Mauritius", countryCode: "MU" },
  { name: "Mexico", countryCode: "MX" },
  { name: "Micronesia", countryCode: "FM" },
  { name: "Moldova", countryCode: "MD" },
  { name: "Mongolia", countryCode: "MN" },
  { name: "Montenegro", countryCode: "ME" },
  { name: "Montserrat", countryCode: "MS" },
  { name: "Morocco", countryCode: "MA" },
  { name: "Mozambique", countryCode: "MZ" },
  { name: "Myanmar", countryCode: "MM" },
  { name: "Namibia", countryCode: "NA" },
  { name: "Nauru", countryCode: "NR" },
  { name: "Nepal", countryCode: "NP" },
  { name: "Nicaragua", countryCode: "NI" },
  { name: "Niger", countryCode: "NE" },
  { name: "Nigeria", countryCode: "NG" },
  { name: "Niue", countryCode: "NU" },
  { name: "Pakistan", countryCode: "PK" },
  { name: "Panama", countryCode: "PA" },
  { name: "Papua New Guinea", countryCode: "PG" },
  { name: "Paraguay", countryCode: "PY" },
  { name: "Peru", countryCode: "PE" },
  { name: "Philippines", countryCode: "PH" },
  { name: "Rwanda", countryCode: "RW" },
  { name: "Saint Helena", countryCode: "SH" },
  { name: "Samoa", countryCode: "WS" },
  { name: "São Tomé and Príncipe", countryCode: "ST" },
  { name: "Senegal", countryCode: "SN" },
  { name: "Serbia", countryCode: "RS" },
  { name: "Sierra Leone", countryCode: "SL" },
  { name: "Solomon Islands", countryCode: "SB" },
  { name: "Somalia", countryCode: "SO" },
  { name: "South Africa", countryCode: "ZA" },
  { name: "South Sudan", countryCode: "SS" },
  { name: "Sri Lanka", countryCode: "LK" },
  { name: "Saint Lucia", countryCode: "LC" },
  { name: "Saint Vincent and the Grenadines", countryCode: "VC" },
  { name: "Sudan", countryCode: "SD" },
  { name: "Suriname", countryCode: "SR" },
  { name: "Syrian Arab Republic", countryCode: "SY" },
  { name: "Tajikistan", countryCode: "TJ" },
  { name: "Tanzania", countryCode: "TZ" },
  { name: "Thailand", countryCode: "TH" },
  { name: "Timor-Leste", countryCode: "TL" },
  { name: "Togo", countryCode: "TG" },
  { name: "Tokelau", countryCode: "TK" },
  { name: "Tonga", countryCode: "TO" },
  { name: "Tunisia", countryCode: "TN" },
  { name: "Turkey", countryCode: "TR" },
  { name: "Turkmenistan", countryCode: "TM" },
  { name: "Tuvalu", countryCode: "TV" },
  { name: "Uganda", countryCode: "UG" },
  { name: "Ukraine", countryCode: "UA" },
  { name: "Ukraine", countryCode: "UA" },
  { name: "Uzbekistan", countryCode: "UZ" },
  { name: "Vanuatu", countryCode: "VU" },
  { name: "Venezuela", countryCode: "VE" },
  { name: "Vietnam", countryCode: "VN" },
  { name: "Wallis and Futuna", countryCode: "WF" },
  { name: "West Bank and Gaza Strip", countryCode: "PS" },
  { name: "Yemen", countryCode: "YE" },
  { name: "Zambia", countryCode: "ZM" },
  { name: "Zimbabwe", countryCode: "ZW" },
];
