import { createSlice } from "@reduxjs/toolkit";
import { ToolsStateProps } from "models/tools/ToolsProps";
import { createSelector } from "reselect";

const initialState = {
  loading: false,
  error: "",
  savedTools: [],
};

const toolsSlice = createSlice({
  name: "tools",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
      state.error = "";
    },
    setFail: (state, action) => {
      state.error = action.payload;
      state.savedTools = [];
      state.loading = false;
    },
    setSuccess: (state, action) => {
      state.savedTools = action.payload;
      state.error = "";
      state.loading = false;
    },
  },
});

export const { setSuccess, setLoading, setFail } = toolsSlice.actions;

export default toolsSlice.reducer;

const selectToolsState = (state: { tools: ToolsStateProps }) => state.tools;

export const selectCurrentSavedToolsData = createSelector(
  [selectToolsState],
  (tools) => ({
    loading: tools.loading,
    error: tools.error,
    savedTools: tools.savedTools,
  })
);
