import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";

import {
  setSuccess,
  setFail,
  setLoading,
  selectCurrentSavedData,
} from "redux/features/bookmarks/savedSlice";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";

import { environment } from "environments";
import { SavedFolderProps } from "models/bookmarks/SavedProps";

const useFetchSavedElements = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const axiosPrivate = useAxiosPrivate();
  const { loading, error, savedFolders } = useSelector(selectCurrentSavedData);

  useEffect(() => {
    const fetchSavedElements = async () => {
      dispatch(setLoading());

      try {
        const response = await axiosPrivate.get(
          `${environment.BACKEND_API}/api/saved_elements`
        );

        // Sort bookmarks alphabetically (asc.)
        const sortedFolders = [...response?.data]?.sort(
          (a: SavedFolderProps, b: SavedFolderProps) => {
            const a_name: string | undefined = a?.name;
            const b_name: string | undefined = b?.name;

            if (a_name && b_name) {
              return a_name.localeCompare(b_name);
            }
            return 1;
          }
        );

        dispatch(setSuccess(sortedFolders));
      } catch (error) {
        dispatch(setFail("An error occurred"));
        toast({
          title: "An error occurred",
          status: "error",
          duration: 1500,
        });
      }
    };

    fetchSavedElements();
  }, [axiosPrivate, dispatch, toast]);

  return { loading, error, savedFolders };
};

export default useFetchSavedElements;
