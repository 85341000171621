import { Flex, Icon, Text } from "@chakra-ui/react";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { hexToRgba } from "utils/helpers";

import { FaUsers } from "react-icons/fa";
import { IoMdStar } from "react-icons/io";
import { MdWorkspacePremium } from "react-icons/md";

interface Props {
  isUser?: boolean;
  toFree?: boolean;
  toEnterprise?: boolean;
}

function SuccessToast({ isUser, toFree, toEnterprise }: Props) {
  return (
    <Flex
      align={"center"}
      p={4}
      px={6}
      gap={4}
      bg={"#1D2440"}
      borderRadius={"6px"}
    >
      <Icon
        as={
          !isUser
            ? MdOutlineMarkEmailRead
            : toFree
            ? IoMdStar
            : toEnterprise
            ? FaUsers
            : MdWorkspacePremium
        }
        boxSize={"34px"}
        color={"#00C1B4"}
        bg={hexToRgba("#00C1B4", 0.2)}
        p={1}
        borderRadius={"6px"}
      />

      {isUser ? (
        <Flex
          direction={"column"}
          gap={2}
          lineHeight={"1.35"}
          fontSize={"14px"}
          color="white"
        >
          {toFree ? (
            <Text>Subscription successfully changed to Free</Text>
          ) : (
            <Text>Request for Enterprise Plan submitted successfully</Text>
          )}
        </Flex>
      ) : (
        <Flex
          direction={"column"}
          gap={2}
          lineHeight={"1.35"}
          fontSize={"14px"}
          color="white"
        >
          <Text>
            We received your <strong>Entreprise Plan</strong> request.
          </Text>

          <Text>We'll get back to you in 2-4 business days.</Text>
        </Flex>
      )}
    </Flex>
  );
}

export default SuccessToast;
