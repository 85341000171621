import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Flex, Text, useBreakpointValue } from "@chakra-ui/react";

import { deleteSession, update_session } from "services/chatbot.service";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { categorizeSessions } from "views/chatbot/helpers";
import { useChatBotAPI } from "api/useChatBotAPI";

import { SessionProps } from "models/chat/MessageProps";

import ChatDate from "components/chat/history/ChatDate";
import ChatItem from "components/chat/history/ChatItem";
import DeleteModal from "components/ui/DeleteModal";
import NoChats from "components/chat/history/NoChats";
import RenameModal from "components/chat/history/RenameModal";
import SessionsSkeleton from "components/chat/history/SessionsSkeleton";

import Loading from "components/ui/Loading";
import { CustomThickScrollBar } from "components/ui/CustomScrollBar";
import ChatHistoryError from "./ChatHistoryError";
import BackgroundShadowEffect from "components/ui/BackgroundShadowEffect";
import useAllowAccess from "hooks/auth/useAllowAccess";
import { PERMISSIONS, TYPES } from "utils/premissions";

export default function ChatHistory() {
  // hooks
  const { userIsAnAllowedTypes, userHasRequiredPermissions } = useAllowAccess();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  // Auth
  const { id } = useParams();

  // States
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [selectedChatTitle, setSelectedChatTitle] = useState("");

  // Handlers
  async function handleDeleteSession(session_id: string) {
    setShowDeleteModal(true);
  }

  // API
  const { fetchSessions } = useChatBotAPI();

  // Fetch all sessions
  const {
    isLoading: loadingSessions,
    data: sessions,
    error: sessionsError,
  } = useQuery({
    queryKey: ["chatbot-sessions"],
    queryFn: fetchSessions,
  });

  async function handleConfirmDeleteSession(session_id: string) {
    setDeleting(true);

    try {
      await deleteSession(session_id);
      session_id === id && navigate("/chat");
      queryClient.setQueryData(
        ["chatbot-sessions"],
        (oldData: SessionProps[]) => {
          return oldData.filter(
            (session: SessionProps) => session.id !== session_id
          );
        }
      );
      setDeleting(false);
    } catch (error) {
      console.error(error, "error while deleting a session");
    }
    setShowDeleteModal(false);
  }

  async function handleUpdateChatTitle(title: string) {
    setShowRenameModal(true);
    setUpdating(true);

    try {
      const payload = { title };
      if (id) await update_session(id, payload);
      queryClient.setQueryData(["chatbot-sessions"], (oldData: any) => {
        return oldData.map((s: SessionProps) => {
          if (s.id === id) return { ...s, title };
          return s;
        });
      });

      setUpdating(false);
    } catch (error) {
      console.error(error, "error while updating chat title");
    }
    setShowRenameModal(false);
  }

  const handleRenameChatTitle = (open: boolean, title: string) => {
    setShowRenameModal(open);
    setSelectedChatTitle(title);
  };

  // user with fresh start or deleted all previous chats
  const hasNoChats = !sessions || !sessions.length;

  // true if user is a team member
  const isTeamMember =
    userIsAnAllowedTypes([TYPES.TEAMS]) ||
    userHasRequiredPermissions([PERMISSIONS.TEAMS_INVITEE]);

  // Responsiveness: ~992px, ~1280px, ~1536px
  // NOTE: to do not delete calculations below
  // NOTE: change these values depending on nbr of nav items
  // - 2 nav items: Library, Assistant: {lg: 257, xl: 274, 2xl: 286}
  // - 3 nav items: Team, Library, Assistant: {lg: 302, xl: 320, 2xl: 336}
  const chatHistoryHeight = useBreakpointValue({
    lg: `calc(100vh - ${isTeamMember ? 302 : 257}px)`, // (2.py) + (2.py) + logo + navItems* + avatar + gaps + mb
    xl: `calc(100vh - ${isTeamMember ? 320 : 274}px)`,
    "2xl": `calc(100vh - ${isTeamMember ? 336 : 286}px)`,
  });

  const listStyle = {
    height: chatHistoryHeight,
    width: "100%",
    padding: "0 6px 2px 0",
  };

  if (
    typeof loadingSessions === "boolean" &&
    !loadingSessions &&
    !!sessionsError
  ) {
    return <ChatHistoryError />;
  }

  return (
    <>
      <Flex
        direction={"column"}
        w={"100%"}
        h={"100%"}
        gap={[null, null, null, 4, 5, 6]}
      >
        {/* sessions list */}
        <Flex position={"relative"} h={"100%"} w={"100%"}>
          <CustomThickScrollBar style={listStyle}>
            {!!loadingSessions && <SessionsSkeleton />}

            {typeof loadingSessions === "boolean" &&
              !loadingSessions &&
              !sessionsError &&
              hasNoChats && <NoChats />}

            {typeof loadingSessions === "boolean" &&
              !loadingSessions &&
              !sessionsError &&
              !hasNoChats &&
              Object.entries(categorizeSessions(sessions ?? []) ?? {})?.map(
                ([date, list], index) =>
                  list.length > 0 && (
                    <Box mb={4} key={index}>
                      {/* chat date */}
                      <ChatDate date={date} />
                      {/* available chats within correspondat date */}
                      {list.map((session) => {
                        // Remove asterisk from title text
                        const cleanedTitle = session.title?.replace(
                          /\*\*/g,
                          ""
                        );
                        return (
                          <ChatItem
                            id={session?.id}
                            key={session?.id}
                            title={cleanedTitle}
                            isDisabled={false}
                            isSelected={session?.id === id}
                            onDeleteSession={handleDeleteSession}
                            onUpdateChatTitle={() =>
                              handleRenameChatTitle(true, cleanedTitle)
                            }
                          />
                        );
                      })}
                    </Box>
                  )
              )}
          </CustomThickScrollBar>

          {/* background shadow effect at the bottom of sessions list */}
          {sessions && sessions.length > 1 && <BackgroundShadowEffect />}
        </Flex>
      </Flex>

      {/* Delete Chat Modal */}
      <RenameModal
        existingTitle={selectedChatTitle}
        updating={updating}
        isOpen={showRenameModal}
        onClose={() => setShowRenameModal(false)}
        onUpdateChatTitle={handleUpdateChatTitle}
      />

      {/* Delete Chat(s) Modal */}
      <DeleteModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={() => handleConfirmDeleteSession(id ?? "")}
        header={"Delete chat"}
      >
        {deleting ? (
          <Loading message={"Deleting chat.."} />
        ) : (
          <Text fontFamily={"Poppins, sans-serif"} fontSize={"14px"}>
            {"This chat will no longer be available"}
          </Text>
        )}
      </DeleteModal>
    </>
  );
}
