import { Box, useTheme } from "@chakra-ui/react";
import { hexToRgba } from "utils/helpers";

interface ShineProps {
  hovered: boolean;
}

function Shine({ hovered }: ShineProps) {
  // Theme
  const { colors } = useTheme();

  return (
    <Box
      borderRadius={"inherit"}
      position={"absolute"}
      inset={0}
      zIndex={1}
      overflow={"hidden"}
      opacity={hovered ? 1 : 0}
      transition={"opacity 0.5s"}
      transitionDelay={"0s"}
      _before={{
        content: "''",
        w: "150%",
        pb: "150%",
        borderRadius: "50%",
        position: "absolute",
        left: "50%",
        bottom: "55%",
        filter: "blur(35px)",
        opacity: "0.3",
        transform: "translateX(-50%)",
        backgroundImage: `conic-gradient(from 225deg at 50% 50%, ${hexToRgba(
          colors.highlight.primary,
          0.3
        )} 0deg, ${colors.highlight.primary} 25deg, transparent 285deg, ${
          colors.background
        } 345deg, ${hexToRgba(colors.highlight.primary, 0.3)} 360deg)`,
      }}
    />
  );
}

export default Shine;
