import { Document, Image, Link, Page, Text, View } from "@react-pdf/renderer";
import {
  SavedAssayProps,
  SavedBotMessagesProps,
  SavedCompoundProps,
  SavedLiteratureProps,
} from "models/bookmarks/SavedProps";
import { documentStyles } from "./helpers";
import AssaysToExport from "./AssaysToExport";
import MoleculesToExport from "./MoleculesToExport";
import LiteratureToExport from "./LiteratureToExport";
import BotMessagesToExport from "./BotMessagesToExport";
import { link } from "assets/bookmarks/reports/icons";

interface SectionHeaderProps {
  src: string;
  name: string;
}

function BookmarkToExport({
  assays,
  molecules,
  literature,
  botMessages,
  folderName,
}: {
  assays: SavedAssayProps[];
  molecules: SavedCompoundProps[];
  literature: SavedLiteratureProps[];
  botMessages: SavedBotMessagesProps[];
  folderName: string;
}) {
  const bookmarkPath = window.location?.href;

  return (
    <Document>
      <Page size="A4" style={documentStyles.page} wrap>
        <View style={documentStyles.container}>
          {/* report header */}
          <View style={documentStyles.headerContainer}>
            <Text style={documentStyles.header}>
              {bookmarkPath && `${folderName} Report`}
            </Text>
            <Link src={bookmarkPath}>
              <Image src={link} style={{ width: "14px", height: "14px" }} />
            </Link>
          </View>

          {/* report content */}
          <View style={documentStyles.content}>
            {/* assays */}
            <AssaysToExport assays={assays} />

            {/* molecules */}
            <MoleculesToExport molecules={molecules} />

            {/* literature */}
            <LiteratureToExport literature={literature} />

            {/* bot messages */}
            <BotMessagesToExport botMessages={botMessages} />
          </View>
        </View>
      </Page>
    </Document>
  );
}

export default BookmarkToExport;

export function SectionHeader({ src, name }: SectionHeaderProps) {
  return (
    <View style={documentStyles.headerContainer}>
      <View
        style={{
          backgroundColor: "rgba(99, 179, 237, 0.2)",
          borderRadius: "6px",
          padding: "4px",
        }}
      >
        <Image src={src} style={{ width: "14px", height: "14px" }} />
      </View>
      <Text style={documentStyles.sectionHeader}>{name}</Text>
    </View>
  );
}
