import { useSelector } from "react-redux";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";

const useAllowAccess = () => {
  const { user } = useSelector(selectCurrentAuthData);

  const userHasRequiredPermissions = (permissions: string[]) =>
    permissions.some((item) => user?.permissions.includes(item));

  const userIsAnAllowedTypes = (types: string[]) =>
    types.includes(user?.user_type);

  return { userHasRequiredPermissions, userIsAnAllowedTypes };
};

export default useAllowAccess;
