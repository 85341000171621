import { ReactNode } from "react";
import { Table, Tbody, Td, Text, Tr } from "@chakra-ui/react";

import ChemicalProps from "models/compounds/ChemicalProps";
import { capitalise } from "components/molecules/helpers";

interface ChemPropsTableProps {
  chemProperties: ChemicalProps;
  origin?: string;
}

interface TableRowProps {
  text: string;
  item?: string | ReactNode;
  fontSize?: string;
  isLast?: boolean;
}

export default function ChemPropsTable({
  chemProperties,
  origin,
}: ChemPropsTableProps) {
  const {
    formula,
    inchi,
    inchikey,
    isomeric_smiles,
    canonical_smiles,
    chirality, // chirality possible values (4): chiral, achiral, racemate, ''
  } = chemProperties;

  return (
    <Table variant="simple" size="sm">
      <Tbody>
        <TableRow text="Formula" item={formula} />
        <TableRow text="Inchi" item={inchi} fontSize={inchi ? "xs" : "sm"} />
        <TableRow text="Inchi Key" item={inchikey} />
        <TableRow
          text="Isomeric Smiles"
          item={isomeric_smiles}
          fontSize={isomeric_smiles ? "xs" : "sm"}
        />
        <TableRow
          text="Canonical Smiles"
          item={canonical_smiles}
          fontSize={canonical_smiles ? "xs" : "sm"}
        />
        <TableRow text="Chirality" item={capitalise(chirality ?? "")} />
        <TableRow text="Origin" item={origin} isLast />
      </Tbody>
    </Table>
  );
}

export function TableRow({ text, item, fontSize, isLast }: TableRowProps) {
  return (
    <Tr wordBreak={"break-word"}>
      <Td
        py={3}
        px={1}
        borderBottomColor={isLast ? "transparent" : "gray.100"}
        wordBreak={"break-word"}
        minW={"95px"}
      >
        <Text
          fontSize={[null, null, null, "xs", "sm", "sm"]}
          as="b"
          wordBreak={"break-word"}
          w={"100%"}
          display={"inline-block"}
        >
          {text}
        </Text>
      </Td>
      <Td
        maxW="100%"
        borderBottomColor={isLast ? "transparent" : "gray.100"}
        fontSize={fontSize || "md"}
      >
        {item || "No data"}
      </Td>
    </Tr>
  );
}
