import { useState } from "react";
import {
  FormControl,
  Input,
  Button,
  useToast,
  Box,
  Flex,
  FormLabel,
} from "@chakra-ui/react";
import { axiosClient } from "api/axios";

export default function ResendNewPassword({ username }: { username: string }) {
  // Hooks
  const toast = useToast();

  // State
  const [submitting, setSubmitting] = useState(false);

  // Handler
  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      await axiosClient.post(
        "/api/resend_new_password",
        JSON.stringify({ username }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      toast({
        position: "bottom-right",
        duration: 1000,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={"highlight.primary"}
            borderRadius={"6px"}
          >
            New password resent successfully
          </Box>
        ),
      });
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error: any) {
      setSubmitting(false);
      // No response from server
      if (!error.response) {
        toast({
          description: "No server response!",
          status: "error",
        });
        return;
      }
      toast({
        description: error.response.data.message,
        status: "error",
      });
    }
  };

  return (
    <Flex direction={"column"} gap={4} w="100%" maxW="460px">
      <FormControl id="username" w="100%">
        <FormLabel
          htmlFor="username"
          color={"secondary.700"}
          fontSize={"12px"}
          fontWeight={"500"}
          fontFamily={"Poppins, sans-serif"}
          lineHeight={1}
          mb={1}
        >
          New Password
        </FormLabel>

        <Input
          disabled
          w={"100%"}
          color={"gray.600"}
          fontSize={"14px"}
          type="text"
          name="username"
          value={username}
          placeholder="Enter your new password"
          _placeholder={{ fontSize: "14px", color: "gray.600" }}
          borderRadius={"6px"}
          bg={"background"}
          borderWidth={1}
          borderColor={"gray.200"}
          _hover={{ borderColor: "gray.300" }}
          _focusVisible={{ borderColor: "blue.300" }}
        />
      </FormControl>

      <Button
        mt={4}
        py={"10px"}
        w={"100%"}
        h={"fit-content"}
        size={"sm"}
        type={"submit"}
        bg={"primary.400"}
        color={"primary.100"}
        onClick={handleSubmit}
        isLoading={submitting}
        loadingText={"Processing"}
        _hover={{ bg: "primary.500" }}
      >
        Resend New Password
      </Button>
    </Flex>
  );
}
