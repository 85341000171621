import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Table,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Text,
  Tr,
  VStack,
  chakra,
  Flex,
  Heading,
} from "@chakra-ui/react";

import { CompoundProps } from "models/compounds/CompoundProps";
import { capitalise } from "components/molecules/helpers";
import { categoryToColor } from "components/ui/helpers";
import PkTable from "components/molecules/PkTable";
import Subtitle from "components/ui/Subtitle";

const StyledAccordionButton = chakra(AccordionButton, {
  baseStyle: {
    h: 12,
    _expanded: {
      bg: "primary.600",
      color: "white",
    },
  },
});

interface PharmaAccordionProps {
  compound: CompoundProps;
}

export default function PharmaAccordion({ compound }: PharmaAccordionProps) {
  const gvk_data_categories = Array.isArray(compound?.gvk_data?.category)
    ? compound?.gvk_data?.category
    : [];
  const wiki_data_roles = compound?.wiki_data?.role ?? [];
  const integrity_data_roles = compound?.integrity_data?.category ?? [];

  const indications = [
    ...gvk_data_categories,
    ...wiki_data_roles,
    ...integrity_data_roles,
  ];

  const hasPkData =
    !!compound?.citeline_data?.pk && !!compound?.citeline_data?.pk.length;

  function getCatColor(cat: string) {
    return gvk_data_categories && gvk_data_categories?.includes(cat)
      ? "gray.100"
      : "gray.700";
  }

  function getCatBg(cat: string) {
    return gvk_data_categories && gvk_data_categories?.includes(cat)
      ? categoryToColor(cat)
      : "gray.200";
  }

  return (
    <Accordion allowMultiple>
      {/* Roles & Indications */}
      <AccordionItem>
        <Heading>
          <StyledAccordionButton>
            <Box as="span" flex="1" textAlign="left">
              Indications
            </Box>
            <AccordionIcon />
          </StyledAccordionButton>
        </Heading>
        <AccordionPanel pb={4}>
          {/* Tags */}
          <VStack spacing={4} align={"stretch"}>
            <Flex mt={2} gap={3} wrap={"wrap"}>
              {indications.map((cat: string, idx: number) => (
                <Tag
                  key={"cat" + idx}
                  color={getCatColor(cat)}
                  bg={getCatBg(cat)}
                  cursor={"pointer"}
                  transition={"all .3s ease"}
                  _hover={{
                    boxShadow: "md",
                    transition: "all .3s ease",
                  }}
                >
                  <TagLabel>{capitalise(cat)}</TagLabel>
                </Tag>
              ))}
            </Flex>

            <HStack>
              <Box display="inline-block">
                <Flex gap={4} direction={"column"}>
                  <Table
                    variant="unstyled"
                    size={"sm"}
                    style={{ width: "auto" }}
                  >
                    <Tbody>
                      <Tr>
                        <Td
                          wordBreak={"break-word"}
                          py={3}
                          px={1}
                          minW={"120px"}
                        >
                          <Subtitle w={"100%"} as="b">
                            Medical conditions treated
                          </Subtitle>
                        </Td>
                        {compound?.wiki_data?.medical_condition_treated ? (
                          <Td display="inline-block">
                            {compound?.wiki_data?.medical_condition_treated
                              ?.map((cond) => capitalise(cond))
                              .join(" · ") ?? "No Data"}
                          </Td>
                        ) : (
                          <Td>
                            <Text>No data available</Text>
                          </Td>
                        )}
                      </Tr>
                    </Tbody>
                  </Table>
                </Flex>
              </Box>
            </HStack>
          </VStack>
        </AccordionPanel>
      </AccordionItem>

      {/* Pharmaceutical Formulation  */}
      <AccordionItem>
        <Heading>
          <StyledAccordionButton>
            <Box as="span" flex="1" textAlign="left">
              Pharmaceutical Formulation
            </Box>
            <AccordionIcon />
          </StyledAccordionButton>
        </Heading>
        <AccordionPanel pb={4}>
          <VStack spacing={4} align="stretch">
            {/* Props */}
            <Box display="inline-block">
              <Table variant="unstyled" size={"sm"} style={{ width: "auto" }}>
                <Tbody>
                  <Tr>
                    <Td>
                      <Subtitle as="b">Route of Administration</Subtitle>
                    </Td>
                    <Td>
                      {compound?.gvk_data?.administration_route?.join(" · ") ??
                        "No data"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Subtitle as="b">Mode of Appliaction</Subtitle>
                    </Td>
                    <Td>No data</Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Subtitle as="b">Dosage</Subtitle>
                    </Td>
                    <Td>No data</Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Subtitle as="b">Pregnancy Category</Subtitle>
                    </Td>
                    <Td>
                      <VStack spacing={2} align="stretch">
                        <Text fontSize={"sm"}>
                          {Array.isArray(
                            compound?.wiki_data?.pregnancy_category
                          )
                            ? compound?.wiki_data?.pregnancy_category?.join(
                                " · "
                              )
                            : "No data provided"}
                        </Text>
                      </VStack>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </VStack>
        </AccordionPanel>
      </AccordionItem>

      {/* Pharmacodynamics */}
      <AccordionItem>
        <Heading>
          <StyledAccordionButton>
            <Box as="span" flex="1" textAlign="left">
              Pharmacodynamics
            </Box>
            <AccordionIcon />
          </StyledAccordionButton>
        </Heading>
        <AccordionPanel pb={4}>
          <VStack spacing={2} align="stretch">
            {/* Table */}
            <Flex gap={4} direction={"column"}>
              <Table variant="unstyled" size={"sm"} style={{ width: "auto" }}>
                <Tbody>
                  <Tr>
                    <Td>
                      <Subtitle as="b">Mechanism of Action</Subtitle>
                    </Td>
                    <Td>
                      {/* Tags */}
                      <Flex gap={2} align={"flex-start"}>
                        {Array.isArray(
                          compound?.integrity_data?.action_mechanism
                        ) &&
                          compound?.integrity_data?.action_mechanism?.map(
                            (moa, index) => (
                              <Tag
                                key={"moa-tag-" + index}
                                color={"gray.700"}
                                bg={"gray.200"}
                                p={1}
                                maxW={300}
                              >
                                {moa}
                              </Tag>
                            )
                          )}
                      </Flex>
                      {/* Description */}
                      {/* CHANGE: when compounds data gets updated, then it will always be an array? */}
                      {Array.isArray(compound?.gvk_data?.action_mechanism) ? (
                        compound?.gvk_data?.action_mechanism.map(
                          (moa, index) => (
                            <Text key={"moa-" + index}>{moa}</Text>
                          )
                        )
                      ) : (
                        <Text>{compound?.gvk_data?.action_mechanism}</Text>
                      )}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Subtitle as="b">Targets</Subtitle>
                    </Td>
                    <Td>
                      {compound.citeline_data?.target_name ? (
                        <Flex gap={2} align={"flex-start"}>
                          {compound?.citeline_data?.target_name?.map(
                            (target, index) => (
                              <Tag
                                key={"target-tag-" + index}
                                color={"gray.700"}
                                bg={"gray.200"}
                                p={1}
                                maxW={300}
                              >
                                {target}
                              </Tag>
                            )
                          )}
                        </Flex>
                      ) : (
                        <Text>No data</Text>
                      )}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Subtitle as="b">Pathways</Subtitle>
                    </Td>
                    <Td>No data</Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Subtitle as="b">Safety</Subtitle>
                    </Td>
                    <Td>No data</Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Subtitle as="b">SAR</Subtitle>
                    </Td>
                    <Td fontSize={"md"}>Coming soon...</Td>
                  </Tr>
                </Tbody>
              </Table>
            </Flex>
          </VStack>
        </AccordionPanel>
      </AccordionItem>

      {/* Pharmacokinetics */}
      <AccordionItem>
        <Heading>
          <StyledAccordionButton>
            <Box as="span" flex="1" textAlign="left">
              Pharmacokinetics
            </Box>
            <AccordionIcon />
          </StyledAccordionButton>
        </Heading>
        <AccordionPanel pb={4}>
          <VStack spacing={5} align="stretch">
            {/* Concept */}

            {hasPkData ? (
              <VStack spacing={4} align="stretch">
                {/* Props */}
                <Flex gap={4} direction={"column"}>
                  <Table
                    variant="unstyled"
                    size={"sm"}
                    style={{ width: "auto" }}
                  >
                    <Tbody>
                      <Tr>
                        <Td>
                          <Subtitle as="b">PK Data</Subtitle>
                        </Td>
                        <Td>
                          <PkTable rows={compound?.citeline_data?.pk} />
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Flex>
              </VStack>
            ) : (
              <VStack spacing={4} align="stretch">
                <Text fontSize={"md"}>Coming soon...</Text>
              </VStack>
            )}
          </VStack>
        </AccordionPanel>
      </AccordionItem>

      {/* Pharmacogenetics */}
      <AccordionItem>
        <Heading>
          <StyledAccordionButton>
            <Box as="span" flex="1" textAlign="left">
              Pharmacogenetics
            </Box>
            <AccordionIcon />
          </StyledAccordionButton>
        </Heading>
        <AccordionPanel pb={4}>
          <VStack spacing={2} align="stretch">
            <Text fontSize={"md"}>Coming soon... </Text>
          </VStack>
        </AccordionPanel>
      </AccordionItem>

      {/* Contraindications & Side Effects */}
      <AccordionItem>
        <Heading>
          <StyledAccordionButton>
            <Box as="span" flex="1" textAlign="left">
              Contraindications & Side Effects
            </Box>
            <AccordionIcon />
          </StyledAccordionButton>
        </Heading>
        <AccordionPanel pb={4}>
          <VStack spacing={2} align="stretch">
            <Text fontSize={"md"}>Coming soon... </Text>
          </VStack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
