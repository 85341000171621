import { ChangeEvent, useRef } from "react";
import {
  Text,
  FormControl,
  InputGroup,
  Input,
  Divider,
  FormLabel,
  Textarea,
  Flex,
} from "@chakra-ui/react";

import MembersNumberInput from "./MembersNumberInput";
import Lmic from "components/home/pricing/Lmic";

interface RequestFormProps {
  name: string;
  members: number;
  country: string;
  message: string;
  isLmic: string;
  setName: (value: string) => void;
  setMembers: (value: number) => void;
  setCountry: (value: string) => void;
  setMessage: (value: string) => void;
  setIsLmic: (value: string) => void;
}

export default function RequestForm({
  name,
  setName,
  members,
  setMembers,
  country,
  setCountry,
  message,
  setMessage,
  isLmic,
  setIsLmic,
}: RequestFormProps) {
  // Ref
  const inputRef = useRef<HTMLInputElement>(null);

  // Handlers
  const handleInputChange = (value: number) => {
    setMembers(value);
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onMsgChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };

  return (
    <Flex direction={"column"} gap={4}>
      <FormControl id="institutionName" isRequired>
        <FormLabel
          htmlFor={"institutionName"}
          color={"secondary.700"}
          fontSize={"12px"}
          fontWeight={"500"}
          fontFamily={"Poppins, sans-serif"}
          lineHeight={1}
          mb={1}
        >
          Institution Name
        </FormLabel>
        <InputGroup>
          <Input
            value={name}
            onChange={handleNameChange}
            placeholder="Enter institution name"
            _placeholder={{ fontSize: "14px", color: "gray.400" }}
            ref={inputRef}
            bg={"background"}
            color={"gray.600"}
            fontSize={"16px"}
            type={"text"}
            w={"100%"}
            name={"institutionName"}
          />
        </InputGroup>
      </FormControl>

      <MembersNumberInput members={members} changeNumber={handleInputChange} />

      <Lmic
        isLmic={isLmic}
        setIsLmic={setIsLmic}
        country={country}
        setCountry={setCountry}
      />

      <FormControl mt={4} id={"description"}>
        <FormLabel
          htmlFor={"description"}
          color={"secondary.700"}
          fontSize={"12px"}
          fontWeight={"500"}
          fontFamily={"Poppins, sans-serif"}
          lineHeight={1}
          mb={1}
        >
          Optional Message
        </FormLabel>
        <Textarea
          w={"100%"}
          name={"description"}
          value={message}
          onChange={onMsgChange}
          placeholder={"Leave a message."}
          resize={"none"}
          fontSize={"14px"}
          color={"gray.500"}
          _placeholder={{ fontSize: "14px", color: "gray.600" }}
          borderRadius={"6px"}
          bg={"background"}
          borderWidth={1}
          borderColor={"gray.200"}
          _hover={{ borderColor: "gray.300" }}
          _focusVisible={{ borderColor: "blue.300" }}
        />
      </FormControl>

      <Divider mt={6} />
      <Text
        fontFamily={"Poppins, sans-serif"}
        fontSize={"13px"}
        color={"gray.500"}
        mt={2}
      >
        We will process your request as soon as possible
      </Text>
    </Flex>
  );
}
