import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { SimpleGrid } from "@chakra-ui/react";

import { CoreContext } from "views/library/core";
import LayerCard from "components/library/core/layers/LayerCard";
import { ToolProps } from "models/tools/ToolsProps";

export interface LayersProps {
  id: string;
  name: string;
  description: string;
  icon: string;
  tools: ToolProps[];
  created_at: Date;
  date_modified: Date;
}

export default function CoreLayersList() {
  // Hooks
  const navigate = useNavigate();

  // Handler
  function openLayer(path: string) {
    navigate(`/data/core/${path}`);
  }

  const { layers } = useContext(CoreContext);

  return (
    <SimpleGrid templateColumns={"repeat(auto-fit, 270px)"} gap={"24px"} p={1}>
      {layers?.map((source: LayersProps, index: number) => (
        <LayerCard
          key={index}
          source={source}
          onClick={() => openLayer(source.name)}
        />
      ))}
    </SimpleGrid>
  );
}
