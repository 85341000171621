// Handlers
export function getMethodColor(name: string) {
  switch (name) {
    case "get":
      return "#00A2FF";
    case "post":
      return "#4ACB91";
    case "put":
      return "#FBA12F";
    case "delete":
      return "#F93F3E";

    default:
      return "#00A2FF";
  }
}
