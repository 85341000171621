import { CompoundProps } from "models/compounds/CompoundProps";
import { SavedFolderProps } from "models/bookmarks/SavedProps";
import { IconType } from "react-icons";

import { ImLab } from "react-icons/im";
import { TbDna } from "react-icons/tb";
import { GiDna2 } from "react-icons/gi";
import { SiMoleculer } from "react-icons/si";
import { PiNewspaperFill } from "react-icons/pi";

export interface SearchResults {
  data: any[];
  currentPage: number;
  hasMore: boolean;
}

export interface TableColumn {
  header: string;
  accessor: string;
  displayFormat?: "IMAGE" | "TAGS" | "BOOL" | "LIST" | "STRING" | "NUMBER";
  filterable?: boolean;
  sortable?: boolean;
}

export interface Table {
  columns: TableColumn[];
}

export interface FetchResponse {
  newData: any[];
  currentPage: number;
  hasMore: boolean;
}

export interface SourceProps {
  key:
    | "MOLECULES"
    | "LITERATURE"
    | "ASSAYS"
    | "PROPRIETARY"
    | "PROTEINS"
    | "THREE-UTRS"
    | "FIVE-UTRS"
    | "PROTEIN-SEQUENCES";
  path: string;
  description: string;
  icon: IconType;
  source: string;
  color: string;
  table: Table;
}

export interface ProteinsProps {
  key: "PROTEIN_SEQUENCES";
  path: string;
  source: string;
  table: Table;
}

export interface ProprietarySourceProps {
  key: "PROPRIETARY";
  path: string;
  description: string;
  icon: IconType;
  source: string;
  color: string;
  table: Table;
}
export const LibFeatures = ["Core", "Bookmarks"];

export const coreSources: SourceProps[] = [
  {
    key: "MOLECULES",
    path: "molecules",
    description: "Molecules Database comprises over 12000 life molecules.",
    icon: SiMoleculer,
    source: "ReframeDB",
    color: "secondary.400",
    table: {
      columns: [
        { header: "Molecule", accessor: "smiles", displayFormat: "IMAGE" },
        {
          header: "Id",
          accessor: "compound_id",
          filterable: true,
          sortable: true,
          displayFormat: "STRING",
        },
        {
          header: "Name",
          accessor: "name",
          filterable: true,
          sortable: true,
          displayFormat: "STRING",
        },
        {
          header: "Aliases",
          accessor: "aliases",
          displayFormat: "LIST",
          filterable: true,
        },
      ],
    },
  },
  {
    key: "ASSAYS",
    path: "assays",
    description: "Assays Database comprises the assays conducted by ReframeDB.",
    icon: ImLab,
    source: "ReframeDB",
    color: "highlight.primary",
    table: {
      columns: [
        {
          header: "Id",
          accessor: "assay_id",
          sortable: true,
          displayFormat: "STRING",
        },
        {
          header: "Name",
          accessor: "name",
          filterable: true,
          sortable: true,
          displayFormat: "STRING",
        },
        {
          header: "Indication",
          accessor: "overview.indication",
          filterable: true,
          sortable: true,
          displayFormat: "TAGS",
        },
        {
          header: "Assay Type",
          accessor: "overview.assay_type",
          displayFormat: "LIST",
          filterable: true,
        },
      ],
    },
  },
  {
    key: "LITERATURE",
    path: "literature",
    description:
      "Collection of over 1M research papers from the scientific communities work on drugs & molecules.",
    icon: PiNewspaperFill,
    source: "Pubmed, NIH, Nature, ACS",
    color: "primary.300",
    table: {
      columns: [
        {
          header: "Title",
          accessor: "title",
          filterable: true,
          sortable: true,
        },
        { header: "Year", accessor: "year_published", filterable: true },
        {
          header: "Authors",
          accessor: "authors",
          displayFormat: "TAGS",
          filterable: true,
        },
        {
          header: "Type",
          accessor: "paper_type",
          displayFormat: "TAGS",
          filterable: true,
        },
        { header: "Access", accessor: "is_open_access", displayFormat: "BOOL" },
        { header: "_id", accessor: "id" },
      ],
    },
  },
];

export const MRNASources: SourceProps[] = [
  {
    key: "PROTEINS",
    path: "proteins",
    description:
      "Collection of Proteins enriched with mRNA sequences with measurements for evaluation",
    icon: TbDna,
    source: "Vitafluence",
    color: "secondary.400",
    table: {
      columns: [
        {
          header: "Entry Name",
          accessor: "entry_name",
          displayFormat: "STRING",
        },
        {
          header: "Name",
          accessor: "protein_name",
          displayFormat: "STRING",
        },
        {
          header: "Gene",
          accessor: "gene_name",
          displayFormat: "STRING",
        },
        {
          header: "Sequence",
          accessor: "protein_sequence",
          displayFormat: "STRING",
        },
        {
          header: "Organism",
          accessor: "organism",
          displayFormat: "STRING",
        },
      ],
    },
  },
  {
    key: "LITERATURE",
    path: "literature",
    description: "Literature for Proteins",
    icon: PiNewspaperFill,
    source: "Vitafluence",
    color: "primary.300",
    table: {
      columns: [
        {
          header: "Title",
          accessor: "title",
          filterable: true,
          sortable: true,
        },
        { header: "Year", accessor: "year_published", filterable: true },
        {
          header: "Authors",
          accessor: "authors",
          displayFormat: "TAGS",
          filterable: true,
        },
        {
          header: "Type",
          accessor: "paper_type",
          displayFormat: "TAGS",
          filterable: true,
        },
        { header: "Access", accessor: "is_open_access", displayFormat: "BOOL" },
        { header: "_id", accessor: "id" },
      ],
    },
  },
  {
    key: "THREE-UTRS",
    path: "three-utrs",
    description: "Standard 3'UTR sequences for usage in mRNA design",
    icon: GiDna2,
    source: "Vitafluence",
    color: "highlight.primary",
    table: {
      columns: [
        {
          header: "Gene Id",
          accessor: "Ensemble_gene_Id",
          displayFormat: "STRING",
        },

        {
          header: "Transcript Id",
          accessor: "Ensemble_transcript_Id",
          displayFormat: "STRING",
        },

        {
          header: "Sequence",
          accessor: "sequence",
          displayFormat: "STRING",
        },
      ],
    },
  },
  {
    key: "FIVE-UTRS",
    path: "five-utrs",
    description: "Optimized and unoptimized 5'UTR sequences for usage in mRNA design",
    icon: GiDna2,
    source: "Vitafluence",
    color: "highlight.primary",
    table: {
      columns: [
        {
          header: "Gene Id",
          accessor: "Ensemble_gene_Id",
          displayFormat: "STRING",
        },

        {
          header: "Transcript Id",
          accessor: "Ensemble_transcript_Id",
          displayFormat: "STRING",
        },

        {
          header: "Sequence",
          accessor: "sequence",
          displayFormat: "STRING",
        },
      ],
    },
  },
];

export const getCoreSource = (path: string | undefined) => {
  return coreSources.find((cs) => cs.path === path);
};

export function getMRNASource(path: string | undefined) {
  return MRNASources.find((ms) => ms.path === path);
}

export const getLibraryFolder = (
  folderId: string | undefined,
  folders: SavedFolderProps[]
) => {
  return folders.find((f) => f.id === folderId);
};

export const getImageSrc = (
  row: any,
  key:
    | "MOLECULES"
    | "LITERATURE"
    | "ASSAYS"
    | "PROPRIETARY"
    | "PROTEINS"
    | "THREE-UTRS"
    | "FIVE-UTRS"
    | "PROTEIN-SEQUENCES"
) => {
  // Get image source for molecules
  if (key === "MOLECULES") {
    const chemProps = (row as CompoundProps).chemical_props;
    const smiles = chemProps?.isomeric_smiles;
    const smilesStructure = chemProps?.isomeric_smiles_structure;

    if (!!smiles && window.RDKit) {
      const mol = window.RDKit.get_mol(smiles || "invalid");
      const svg = btoa(window.RDKit.get_mol(smiles ?? "")?.get_svg() ?? "");

      mol?.delete();

      return `data:image/svg+xml;base64,${svg}`;
    }
    if (smilesStructure) {
      return `data:image/png+svg;base64,${smilesStructure}`;
    }
  }

  return "https://via.placeholder.com/120x120.png/FFFFFF";
};

export function getValueByAccessor(row: any, accessor: string) {
  return accessor.split(".").reduce((acc, part) => acc && acc[part], row);
}

export function returnValueAsAnArray(value: string | string[]) {
  if (typeof value === "string") {
    return value.split(",").map((str) => str.trim());
  }
  return value;
}

export const proprietarySources: SourceProps[] = [
  {
    key: "PROPRIETARY",
    path: "literature",
    description:
      "Proprietary Database for uploading literature papers, embedded in chat assistance for easy retrieval.",
    icon: PiNewspaperFill,
    source: ": You",
    color: "secondary.400",
    table: {
      columns: [
        { header: "Name", accessor: "title", filterable: true, sortable: true },
        { header: "Year", accessor: "year_published", filterable: true },
        {
          header: "Authors",
          accessor: "authors",
          displayFormat: "TAGS",
          filterable: true,
        },
        {
          header: "Type",
          accessor: "paper_type",
          displayFormat: "TAGS",
          filterable: true,
        },
        {
          header: "Embeding",
          accessor: "is_processing",
          displayFormat: "BOOL",
        },
        { header: "Access", accessor: "team_access", displayFormat: "BOOL" },
        { header: "_id", accessor: "id" },
      ],
    },
  },
];

export const getProprietarySource = (path: string | undefined) => {
  return proprietarySources.find((cs) => cs.path === path);
};
