import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { ChangeEvent } from "react";

interface EmailProps {
  email: string;
  isInvalid: boolean;
  onEmailChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

function VisitorEmail({ email, isInvalid, onEmailChange }: EmailProps) {
  return (
    <FormControl isRequired isInvalid={isInvalid} w="100%">
      <FormLabel
        htmlFor="email"
        color={"secondary.700"}
        fontSize={"12px"}
        fontWeight={"500"}
        fontFamily={"Poppins, sans-serif"}
        lineHeight={1}
        mb={1}
      >
        Email address
      </FormLabel>
      <Input
        w={"100%"}
        type="email"
        name="email"
        value={email}
        onChange={onEmailChange}
        placeholder="Enter your email"
        fontSize={"14px"}
        color={"gray.500"}
        _placeholder={{ fontSize: "14px", color: "gray.600" }}
        borderRadius={"6px"}
        bg={"background"}
        borderWidth={1}
        borderColor={"gray.200"}
        _hover={{ borderColor: "gray.300" }}
        _focusVisible={{ borderColor: "blue.300" }}
      />
      <FormErrorMessage
        fontSize={"12px"}
        color={"red.500"}
        fontFamily={"Poppins, sans-serif"}
      >
        Email is required. Sould be valid email address.
      </FormErrorMessage>
    </FormControl>
  );
}

export default VisitorEmail;
