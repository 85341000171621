import {
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { ChangeEventHandler } from "react";
import { LuEye, LuEyeOff } from "react-icons/lu";

interface PasswordInputProps {
  label: string;
  name: string;
  value: string;
  placeholder: string;
  error: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  showPassword: boolean;
  onChangeVisibility: () => void;
}

function PasswordInput({
  label,
  name,
  value,
  placeholder,
  error,
  onChange,
  showPassword,
  onChangeVisibility,
}: PasswordInputProps) {
  return (
    <FormControl w="100%">
      <FormLabel
        htmlFor="password"
        color={"secondary.700"}
        fontSize={"12px"}
        fontWeight={"500"}
        fontFamily={"Poppins, sans-serif"}
        lineHeight={1}
        mb={1}
      >
        {label}
      </FormLabel>

      <InputGroup>
        <Input
          type={showPassword ? "text" : "password"}
          color={"gray.500"}
          fontSize={"14px"}
          name={name}
          id={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          width="100%"
        />
        <InputRightElement>
          <Icon
            as={showPassword ? LuEyeOff : LuEye}
            name={name}
            color={"secondary.600"}
            cursor={"pointer"}
            onClick={onChangeVisibility}
          />
        </InputRightElement>
      </InputGroup>
      <Text
        mt={0.5}
        minH={4}
        fontSize={"12px"}
        fontFamily={"Poppins, sans-serif"}
        lineHeight={"1.3"}
        flexWrap={"wrap"}
        color={"red.600"}
      >
        {error}
      </Text>
    </FormControl>
  );
}

export default PasswordInput;
