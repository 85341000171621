import { useState } from "react";
import {
  Badge,
  Box,
  Button,
  FormControl,
  HStack,
  Icon,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { CustomScrollBar } from "components/ui/CustomScrollBar";
import EditModal from "./EditModal";
import DeleteModal from "components/ui/DeleteModal";
import Loading from "components/ui/Loading";
import { MemberProps, TeamProps } from "models/posts/PostProps";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTeamsAPI } from "api/useTeamsAPI";
import { errorHandler } from "utils/helpers";
import { BsPen } from "react-icons/bs";
import PendingInvites from "./PendingInvites";

interface DataProps {
  members: {
    user: MemberProps[];
    permission: string;
  }[];
  isOwner: boolean;
  teamData: TeamProps;
}

interface HeaderProps {
  text: string;
  index: number;
}

interface RowProps {
  user: MemberProps;
  permission: string;
  index: number;
  teamData: TeamProps;
  isOwner: boolean;
  onOpenModal: (id: string, permission: string) => void;
  onCloseModal: (memberId: string) => void;
}

const tableStyle = {
  height: "94%",
  width: "100%",
  padding: "0",
};

const headers = [
  "No.",
  "First Name",
  "Last Name",
  "Email",
  "Role",
  "Permission",
  "Action",
];

const TableData = ({ members, isOwner, teamData }: DataProps) => {
  // Hooks
  const toast = useToast();
  const queryClient = useQueryClient();
  const { removeTeamMember, editTeam } = useTeamsAPI();

  // States
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedMember, setSelectedMember] = useState<string>("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [permission, setPermission] = useState("");

  const updateHeaders = () => {
    if (headers[headers.length - 1] === "Action") {
      headers.pop();
    }

    if (isOwner) {
      headers.push("Action");
    }
  };

  updateHeaders();

  const removeMemberMutation = useMutation({
    mutationFn: (memberId: string) => removeTeamMember("member", memberId),
    onSuccess: () => {
      toast({
        position: "top-right",
        duration: 1500,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={"highlight.primary"}
            borderRadius={"6px"}
          >
            Team member removed successfully
          </Box>
        ),
      });
      setLoading(false);
      setShowDeleteModal(false);
      queryClient.invalidateQueries({ queryKey: ["team"] });
    },
    onError: (error: any) => {
      toast({
        description: errorHandler(error).message,
        status: "error",
        position: "top-right",
      });
      setLoading(false);
    },
  });

  const editPermissionMutation = useMutation({
    mutationFn: (memberId: string) =>
      editTeam({
        operation: "member",
        memberId: memberId,
        permission: permission,
      }),
    onSuccess: () => {
      toast({
        position: "top-right",
        duration: 1500,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={"highlight.primary"}
            borderRadius={"6px"}
          >
            Team member permission updated successfully
          </Box>
        ),
      });
      setLoading(false);
      setShowEditModal(false);
      queryClient.invalidateQueries({ queryKey: ["team"] });
    },
    onError: (error: any) => {
      toast({
        description: errorHandler(error).message,
        status: "error",
        position: "top-right",
      });
      setLoading(false);
    },
  });

  // Handlers
  const openEditMemberModal = (memberId: string, permission: string) => {
    if (!isOwner || memberId === teamData.owner) return;
    setPermission(permission);
    setSelectedMember(memberId);
    setShowEditModal(true);
  };

  const openRemoveMemberModal = (memberId: string) => {
    setSelectedMember(memberId);
    setShowDeleteModal(true);
  };

  const closeRemoveMemberModal = () => {
    setSelectedMember("");
    setShowDeleteModal(false);
  };

  const closeEditMemberModal = () => {
    setSelectedMember("");
    setShowEditModal(false);
  };

  const handleConfirmRemove = () => {
    setLoading(true);
    removeMemberMutation.mutate(selectedMember);
  };

  const handleConfirmEdit = () => {
    setLoading(true);
    editPermissionMutation.mutate(selectedMember);
  };

  return (
    <TableContainer height="88%" style={{ position: "relative" }}>
      <CustomScrollBar style={tableStyle}>
        <Table variant="simple" size="sm">
          {members?.length > 0 ? (
            <>
              <Thead>
                <Tr>
                  {headers.map((text, index) => (
                    <Header
                      key={"table-header" + index}
                      text={text}
                      index={index}
                    />
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {members?.map((row: any, index: number) => (
                  <Row
                    user={row?.user}
                    permission={row?.permission}
                    index={index}
                    key={"table-row" + index}
                    isOwner={isOwner}
                    teamData={teamData}
                    onOpenModal={openEditMemberModal}
                    onCloseModal={openRemoveMemberModal}
                  />
                ))}
              </Tbody>
            </>
          ) : (
            <Text color={"gray.500"} marginTop={20} textAlign={"center"}>
              You do not have any team member yet. Start by inviting a team
              member to collaborate.
            </Text>
          )}
        </Table>
      </CustomScrollBar>

      <PendingInvites isOwner={isOwner} />

      <DeleteModal
        isOpen={showDeleteModal}
        onClose={closeRemoveMemberModal}
        onConfirm={handleConfirmRemove}
        header={"Remove team member"}
      >
        {loading ? (
          <Loading message={"Removing team member ..."} />
        ) : (
          <Text fontSize={"14px"} mt={4}>
            {"Are you sure you want to remove this team member?"}
          </Text>
        )}
      </DeleteModal>

      <EditModal
        isOpen={showEditModal}
        onClose={closeEditMemberModal}
        onConfirm={handleConfirmEdit}
        header={"Edit Permission"}
      >
        {loading ? (
          <Loading message={"Saving member permission ..."} />
        ) : (
          <FormControl id="permission">
            <Text color={"gray.600"} mt={2}>
              Please select the member's folder permission:
            </Text>
            <RadioGroup onChange={setPermission} value={permission}>
              <Stack direction="row">
                <Radio colorScheme="secondary" value="read" color={"gray.600"}>
                  <Text color="gray.600">Read</Text>
                </Radio>
                <Radio colorScheme="secondary" value="write" color={"gray.600"}>
                  <Text color="gray.600">Write</Text>
                </Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
        )}
      </EditModal>
    </TableContainer>
  );
};

export default TableData;

function Header({ text, index }: HeaderProps) {
  return (
    <Th
      key={"table-header" + index}
      py={2}
      px={2}
      textAlign={"left"}
      position="sticky"
      top={0}
      zIndex={1}
      bg="gray.100"
      borderLeftRadius={index === 0 ? "md" : "none"}
      borderRightRadius={index === headers.length - 1 ? "md" : "none"}
      borderRight={index !== headers.length - 1 ? "1px solid" : "none"}
      borderRightColor={"gray.200"}
    >
      {text}
    </Th>
  );
}

function Row({
  user,
  permission,
  index,
  teamData,
  isOwner,
  onOpenModal,
  onCloseModal,
}: RowProps) {
  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const isLight = colorMode === "light";

  return (
    <Tr
      key={"table-row" + index}
      py={5}
      transition="background 0.3s ease"
      _hover={{ cursor: "pointer", bg: "gray.100" }}
    >
      <Td key={"number" + index} borderColor={"gray.100"} p={1.5} isTruncated>
        {index + 1}.
      </Td>
      <Td
        key={"given_name" + user.given_name + index}
        borderColor={"gray.100"}
        p={1.5}
        isTruncated
      >
        {user.given_name}
      </Td>
      <Td
        key={"family_name" + user.family_name + index}
        borderColor={"gray.100"}
        p={1.5}
        isTruncated
      >
        {user.family_name}
      </Td>
      <Td
        key={"email" + user.email + index}
        borderColor={"gray.100"}
        p={1.5}
        isTruncated
      >
        {user.email}
      </Td>
      <Td
        key={"role" + user.first_login + index}
        borderColor={"gray.100"}
        p={1.5}
        isTruncated
      >
        <Badge color={isLight ? "primary" : "white"}>
          {user.id !== teamData.owner ? "Member" : "Admin"}
        </Badge>
      </Td>
      <Td
        key={"permission" + user.first_login + index}
        borderColor={"gray.100"}
        p={1.5}
        isTruncated
      >
        <HStack onClick={() => onOpenModal(user?.id, permission)}>
          <Badge variant="outline" color={isLight ? "primary" : "white"}>
            {permission}
          </Badge>
          {isOwner && user.id !== teamData.owner && (
            <Icon as={BsPen} color={"gray.500"} cursor={"pointer"} />
          )}
        </HStack>
      </Td>
      {isOwner && (
        <Td
          key={"date" + user.first_login + index * 2}
          borderColor={"gray.100"}
          p={1.5}
          isTruncated
        >
          {user.id !== teamData.owner && (
            <Button
              size="sm"
              borderWidth={1}
              color={"red.500"}
              bg={"transparent"}
              borderRadius={"100px"}
              borderColor={"transparent"}
              _hover={{ borderColor: "red.500" }}
              onClick={() => onCloseModal(user?.id)}
            >
              Remove
            </Button>
          )}
        </Td>
      )}
    </Tr>
  );
}
