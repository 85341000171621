import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  Accordion,
  AccordionItem,
  AccordionPanel,
  Flex,
  List,
  ListItem,
} from "@chakra-ui/react";
import Bullet from "../ui/Bullet";
import AccordionHeader from "components/ui/AccordionHeader";

interface PrivacyPolicyModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function PrivacyPolicyModal({
  isOpen,
  onClose,
}: PrivacyPolicyModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent
        bg={"background"}
        alignSelf={"center"}
        overflowY={"auto"}
        minW={{ base: "90%", lg: "800px" }}
        maxW={{ base: "90%", lg: "800px" }}
        h={{
          lg: "calc(100vh - 16px)",
          xl: "calc(100vh - 24px)",
          "2xl": "calc(100vh - 32px)",
        }}
        my={"auto"}
      >
        <ModalHeader
          display={"flex"}
          alignItems={"center"}
          color={"gray.700"}
          fontFamily={"Poppins, sans-serif"}
          justifyContent={"space-between"}
          borderBottomWidth={1}
          borderBottomColor={"gray.200"}
        >
          {/* header */}
          <Text fontSize={"16px"} fontWeight={"500"}>
            Privacy Policy
          </Text>
        </ModalHeader>

        <ModalBody py={6}>
          <Accordion allowToggle defaultIndex={[0]}>
            {/* Introduction */}
            <AccordionItem border={"none"} my={1}>
              <AccordionHeader text={"Introduction"} />
              <AccordionPanel
                pb={4}
                fontSize={{ base: "12px", lg: "14px" }}
                color={"gray.700"}
              >
                <Flex direction={"column"} gap={4}>
                  <Text>
                    Thank you for choosing to be part of our community at
                    Lakesai platform. We are committed to protecting your
                    personal information and right to privacy governed by the
                    principles of General Data Protection Regulation (GDPR). If
                    you have any questions or concerns about this privacy
                    notice, or our practices with regards to your personal
                    information, please contact us at{" "}
                    <Text
                      as={"a"}
                      color={"blue.500"}
                      href="mailto:connect@vitafluence.ai"
                    >
                      connect@vitafluence.ai
                    </Text>
                  </Text>
                  <Text>
                    When you visit our website, use our mobile application or
                    any of our services, we appreciate that you are trusting us
                    with your personal information. In this privacy notice, we
                    seek to explain to you in the clearest way possible what
                    information we collect, how we use it and what rights you
                    have in relation to it. Please take some time to read though
                    it carefully, as it is important. If there are any terms in
                    this privacy notice that you do not agree with, please
                    inform us immediately.
                  </Text>
                </Flex>
              </AccordionPanel>
            </AccordionItem>

            {/* What data do we collect? */}
            <AccordionItem border={"none"} my={1}>
              <AccordionHeader text={"What data do we collect?"} />

              <AccordionPanel
                pb={4}
                fontSize={{ base: "12px", lg: "14px" }}
                color={"gray.700"}
              >
                <Flex direction={"column"} gap={4}>
                  <Flex direction={"column"} gap={2}>
                    <Text>
                      Lakesai collects personal data for the purpose of
                      providing you access to millions of molecules. Upon your
                      request and expression of consent, your data might also be
                      shared with third parties who also offer other
                      complementary services. It is removed upon your withdrawal
                      of consent or your request to terminate these services:
                    </Text>
                    <List listStyleType={"none"}>
                      <ListItem>
                        <Text>
                          <Bullet />
                          Personal identification information: name, email
                          address, phone number etc.
                        </Text>
                      </ListItem>
                      <ListItem>
                        <Text>
                          <Bullet />
                          Lakesai may collect personal medical data when you use
                          some of our services. In this case we are guided by
                          regulations ensuring duly informed consent is
                          explicitly received before we collect such data
                        </Text>
                      </ListItem>
                    </List>
                  </Flex>
                  <Flex direction={"column"} gap={2}>
                    <Text>
                      If you leave a message on the Website, your name and email
                      address will also be saved in cookies. These are for your
                      convenience so that you do not have to fill in your
                      details again when you leave another comment. These
                      cookies will be saved on your computer until you delete
                      them
                    </Text>
                    <List listStyleType={"none"}>
                      <ListItem>
                        <Text>
                          <Bullet />
                          IP and browser user agent string: This data is
                          collected when you leave a comment
                        </Text>
                      </ListItem>
                      <ListItem>
                        <Text>
                          <Bullet />
                          Retention period: The aforementioned data is retained
                          indefinitely so we can recognize and approve any
                          follow-up comments automatically instead of holding
                          them in a moderation queue
                        </Text>
                      </ListItem>
                    </List>
                  </Flex>
                </Flex>
              </AccordionPanel>
            </AccordionItem>

            {/* How will we use your data? */}
            <AccordionItem border={"none"} my={1}>
              <AccordionHeader text={"How will we use your data?"} />

              <AccordionPanel
                pb={4}
                fontSize={{ base: "12px", lg: "14px" }}
                color={"gray.700"}
              >
                <Flex direction={"column"} gap={2}>
                  <Text>Lakesai collects your data so that we can:</Text>
                  <List listStyleType={"none"}>
                    <ListItem>
                      <Text>
                        <Bullet />
                        Anonymously use the data to improving the website as
                        well as for research and publication purposes
                      </Text>
                    </ListItem>
                    <ListItem>
                      <Text>
                        <Bullet />
                        The knowledge that we get from any research will be
                        published through scientific journals so other
                        interested people may learn from our research.
                        Confidential information will not be shared
                      </Text>
                    </ListItem>
                    <ListItem>
                      <Text>
                        <Bullet />
                        This may include pseudonymized testimonials
                      </Text>
                    </ListItem>
                  </List>
                </Flex>
              </AccordionPanel>
            </AccordionItem>

            {/* How do we store your data? */}
            <AccordionItem border={"none"} my={1}>
              <AccordionHeader text={"How do we store your data?"} />

              <AccordionPanel
                pb={4}
                fontSize={{ base: "12px", lg: "14px" }}
                color={"gray.700"}
              >
                <Text>
                  Lakesai securely stores your data at our cloud data storage
                  facilities hosted in the cloud in region Frankfurt. We
                  strictly adhere to the GDPR principle of storage limitation
                  and data retention policies; therefore, we erase or anonymize
                  any personal data that is no longer needed. A client also has
                  the right to revoke access and request us to delete all their
                  personal data at any given time
                </Text>
              </AccordionPanel>
            </AccordionItem>

            {/* Marketing */}
            <AccordionItem border={"none"} my={1}>
              <AccordionHeader text={"Marketing"} />
              <AccordionPanel
                pb={4}
                fontSize={{ base: "12px", lg: "14px" }}
                color={"gray.700"}
              >
                <Flex direction={"column"} gap={4}>
                  <Text>
                    Lakesai would like to send you information about products
                    and services of ours that we think you might like, as well
                    as those of our partners.
                  </Text>
                  <Text>
                    If you have agreed to receive marketing, you may always opt
                    out at a later date. You have the right at any time to stop
                    Lakesai from contacting you for marketing purposes or giving
                    your data to other members of the Vitafluence.ai Company. If
                    you no longer wish to be contacted for marketing purposes,
                    you will be able to make the changes in your profile on our
                    website
                  </Text>
                </Flex>
              </AccordionPanel>
            </AccordionItem>

            {/* What are your data protection rights? */}
            <AccordionItem border={"none"} my={1}>
              <AccordionHeader text={"What are your data protection rights?"} />
              <AccordionPanel
                pb={4}
                fontSize={{ base: "12px", lg: "14px" }}
                color={"gray.700"}
              >
                <Flex direction={"column"} gap={4}>
                  <Flex direction={"column"} gap={2}>
                    <Text>
                      Our team would like to make sure you are fully aware of
                      all of your data protection rights. Every user is entitled
                      to the following:
                    </Text>
                    <List listStyleType={"none"}>
                      <ListItem>
                        <Text>
                          <Bullet />
                          The right to access- You have the right to request
                          copies of your personal data from us
                        </Text>
                      </ListItem>
                      <ListItem>
                        <Text>
                          <Bullet />
                          The right to rectification - You have the right to
                          request that Lakesai correct any information you
                          believe is inaccurate. You also have the right to
                          request Lakesai to complete the information you
                          believe is incomplete
                        </Text>
                      </ListItem>
                      <ListItem>
                        <Text>
                          <Bullet />
                          The right to erasure - You have the right to request
                          that we erase your personal data, under certain
                          conditions
                        </Text>
                      </ListItem>
                      <ListItem>
                        <Text>
                          <Bullet />
                          The right to restrict processing - You have the right
                          to request that Lakesai restrict the processing of
                          your personal data, under certain conditions
                        </Text>
                      </ListItem>
                      <ListItem>
                        <Text>
                          <Bullet />
                          The right to object to processing - You have the right
                          to object to Lakesai's processing of your personal
                          data, under certain conditions
                        </Text>
                      </ListItem>
                      <ListItem>
                        <Text>
                          <Bullet />
                          The right to data portability - You have the right to
                          request that Lakesai transfer the data that we have
                          collected to another organization, or directly to you,
                          under certain conditions
                        </Text>
                      </ListItem>
                    </List>
                  </Flex>
                  <Text>
                    If you make a request, we have one month to respond to you.
                    If you would like to exercise any of these rights, please
                    contact us at our email{" "}
                    <Text
                      as={"a"}
                      color={"blue.500"}
                      href="mailto:connect@vitafluence.ai"
                    >
                      connect@vitafluence.ai
                    </Text>
                  </Text>
                </Flex>
              </AccordionPanel>
            </AccordionItem>

            {/* How do we use cookies? */}
            <AccordionItem border={"none"} my={1}>
              <AccordionHeader text={"How do we use cookies?"} />

              <AccordionPanel
                pb={4}
                fontSize={{ base: "12px", lg: "14px" }}
                color={"gray.700"}
              >
                <Flex direction={"column"} gap={4}>
                  <Text>
                    Cookies are text files placed on your computer to collect
                    standard Internet log information and visitor behavior
                    information. When you visit our websites, we may collect
                    information from you automatically through cookies or
                    similar technology For further information, visit{" "}
                    <Text
                      as={"a"}
                      color={"blue.500"}
                      href="https://www.allaboutcookies.org/"
                      target="_blank"
                    >
                      allaboutcookies.org
                    </Text>
                  </Text>
                  <Flex direction={"column"} gap={2}>
                    <Text>
                      Lakesai uses cookies in a range of ways to improve your
                      experience on our website, including:
                    </Text>
                    <List listStyleType={"none"}>
                      <ListItem>
                        <Text>
                          <Bullet />
                          Keeping you signed in
                        </Text>
                      </ListItem>
                      <ListItem>
                        <Text>
                          <Bullet />
                          Understanding how you use our website
                        </Text>
                      </ListItem>
                    </List>
                  </Flex>
                </Flex>
              </AccordionPanel>
            </AccordionItem>

            {/* What types of cookies do we use? */}
            <AccordionItem border={"none"} my={1}>
              <AccordionHeader text={"What types of cookies do we use?"} />

              <AccordionPanel
                pb={4}
                fontSize={{ base: "12px", lg: "14px" }}
                color={"gray.700"}
              >
                <Flex direction={"column"} gap={2}>
                  <Text>
                    There are a number of different types of cookies, however,
                    our website uses:
                  </Text>
                  <List listStyleType={"none"}>
                    <ListItem>
                      <Text>
                        <Bullet />
                        Functionality - Lakesai uses these cookies so that we
                        recognize you on our website and remember your
                        previously selected preferences. These could include
                        what language you prefer and location you are in. A mix
                        of first-party and third-party cookies are used
                      </Text>
                    </ListItem>
                    <ListItem>
                      <Text>
                        <Bullet />
                        Advertising - Lakesai uses these cookies to collect
                        information about your visit to our website, the content
                        you viewed, the links you followed and information about
                        your browser, device, and your IP address. Lakesai may
                        reserve the right to share some limited aspects of this
                        data with third parties for advertising purposes. We may
                        also share online data collected through cookies with
                        our advertising partners. This means that when you visit
                        another website, you may be shown advertising based on
                        your browsing patterns on our website
                      </Text>
                    </ListItem>
                  </List>
                </Flex>
              </AccordionPanel>
            </AccordionItem>

            {/* How to manage cookies? */}
            <AccordionItem border={"none"} my={1}>
              <AccordionHeader text={"How to manage cookies?"} />

              <AccordionPanel
                pb={4}
                fontSize={{ base: "12px", lg: "14px" }}
                color={"gray.700"}
              >
                <Text>
                  You can set your browser not to accept cookies, and the above
                  website tells you how to remove cookies from your browser.
                  However, in a few cases, some of our website features may not
                  function as a result.
                </Text>
              </AccordionPanel>
            </AccordionItem>

            {/* Privacy policies of other websites */}
            <AccordionItem border={"none"} my={1}>
              <AccordionHeader text={"Privacy policies of other websites"} />

              <AccordionPanel
                pb={4}
                fontSize={{ base: "12px", lg: "14px" }}
                color={"gray.700"}
              >
                <Text>
                  Our site may, from time to time, contain links to and from
                  third party websites. If you follow a link to any of these
                  websites, please note that these websites have their own
                  privacy policies and that we do not accept any responsibility
                  or liability for these policies. Please check these policies
                  before you submit any personal data to these websites.
                </Text>
              </AccordionPanel>
            </AccordionItem>

            {/* Changes to our privacy policy */}
            <AccordionItem border={"none"} my={1}>
              <AccordionHeader text={"Changes to our privacy policy"} />

              <AccordionPanel
                pb={4}
                fontSize={{ base: "12px", lg: "14px" }}
                color={"gray.700"}
              >
                <Text>
                  Lakesai keeps its privacy policy under regular review, any
                  changes we may make to our privacy policy in the future will
                  be posted on this page and, where appropriate, notified to you
                  by e-mail. Please check back frequently to see any updates or
                  changes to our privacy policy. This privacy policy was last
                  updated on 30 January 2024.
                </Text>
              </AccordionPanel>
            </AccordionItem>

            {/* How to contact us? */}
            <AccordionItem border={"none"} my={1}>
              <AccordionHeader text={"How to contact us?"} />

              <AccordionPanel
                pb={4}
                fontSize={{ base: "12px", lg: "14px" }}
                color={"gray.700"}
              >
                <Text>
                  If you have any questions about Lakesai's privacy policy, the
                  data we hold on you, or you would like to exercise one of your
                  data protection rights, please do not hesitate to contact us
                  at{" "}
                  <Text
                    as={"a"}
                    color={"blue.500"}
                    href="mailto:connect@vitafluence.ai"
                  >
                    connect@vitafluence.ai
                  </Text>
                </Text>
              </AccordionPanel>
            </AccordionItem>

            {/* How to contact the appropriate authority? */}
            <AccordionItem border={"none"} my={1}>
              <AccordionHeader
                text={"How to contact the appropriate authority?"}
              />

              <AccordionPanel
                pb={4}
                fontSize={{ base: "12px", lg: "14px" }}
                color={"gray.700"}
              >
                <Text>
                  Should you wish to report a complaint or if you feel that
                  Lakesai has not addressed your concern in a satisfactory
                  manner, you may contact the Data Commissioner's Office
                </Text>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
