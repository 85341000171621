import { Box, Flex, Text, useBreakpointValue } from "@chakra-ui/react";

interface CircleStepProps {
  stepNumber: string;
  direction?: "left" | "right";
  color?: string;
}

function CircleStep({
  stepNumber,
  direction = "left",
  color = "#2bafcc",
}: CircleStepProps) {
  const size = useBreakpointValue({ base: "80px", lg: "100px", xl: "130px" });
  const circleSize = useBreakpointValue({
    base: "60px",
    lg: "80px",
    xl: "100px",
  });
  const arrowSize = useBreakpointValue({
    base: "16px",
    lg: "20px",
    xl: "30px",
  });
  const fontSize = useBreakpointValue({ base: "24px", lg: "34px", xl: "42px" });
  const subFontSize = useBreakpointValue({
    lg: "16px",
    xl: "16px",
  });

  const semiCircleStyle = {
    borderRadius:
      direction === "left" ? `${size} 0 0 ${size}` : `0 ${size} ${size} 0`,
    backgroundColor: color,
    clipPath: direction === "left" ? "inset(0 50% 0 0)" : "inset(0 0 0 50%)",
  };

  // SVG points for the arrow based on direction
  const arrowPoints =
    direction === "left" ? "10,50 60,90 60,10" : "90,50 40,10 40,90";

  return (
    <Flex
      position={"relative"}
      w={size}
      h={size}
      align={"center"}
      justify={"center"}
    >
      {/* Semi-Circle background */}
      <Box
        position="absolute"
        width={size}
        height={size}
        sx={semiCircleStyle}
        zIndex={1}
        left={direction === "left" ? 0 : "auto"}
        right={direction === "right" ? 0 : "auto"}
      />

      {/* SVG for the arrow */}
      <Box
        as="svg"
        position="absolute"
        width={arrowSize}
        height={arrowSize}
        zIndex={1}
        left={
          direction === "left"
            ? { base: "-8px", lg: "-10px", xl: "-15px" }
            : "auto"
        }
        right={
          direction === "right"
            ? { base: "-8px", lg: "-10px", xl: "-15px" }
            : "auto"
        }
        viewBox="0 0 100 100"
      >
        <polygon points={arrowPoints} fill={color} />
      </Box>

      {/* Circle with step number */}
      <Flex
        align={"center"}
        justify={"center"}
        direction={"column"}
        w={circleSize}
        h={circleSize}
        borderRadius="50%"
        bg={"neutral.100"}
        boxShadow={"0 8px 16px rgba(0, 0, 0, 0.1)"}
        fontSize={fontSize}
        color={color}
        fontWeight={"bold"}
        zIndex={2}
      >
        <Text>0{stepNumber}</Text>
        <Text display={{ base: "none", xl: "inline" }} fontSize={subFontSize}>
          Step
        </Text>
      </Flex>
    </Flex>
  );
}

export default CircleStep;
