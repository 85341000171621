export function timeSince(date: Date): string {
  // measure the timezone offset in millisecs
  const timezoneOffset = date.getTimezoneOffset() * 60 * 1000;

  // measure the elapsed time in secs from note creation to the current time
  const seconds = Math.floor(
    (new Date().getTime() - date.getTime() + timezoneOffset) / 1000
  );

  let interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " yr ago";
  }

  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " mo ago";
  }

  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " d ago";
  }

  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " h ago";
  }

  interval = seconds / 60;
  if (interval > 1) {
    const pluralSuffix = Math.floor(interval) > 1 ? "s" : "";
    return Math.floor(interval) + ` min${pluralSuffix} ago`;
  }

  return "now";
}
