import { Box, Flex, Heading, SimpleGrid, Text, Image } from "@chakra-ui/react";
import { features, FeatureProps } from "views/home/helpers";

import f1 from "assets/home/features/f1.png";
import f2 from "assets/home/features/f2.png";
import f3 from "assets/home/features/f3.png";
import f4 from "assets/home/features/f4.png";
import f5 from "assets/home/features/f5.png";

const ftImages = [f1, f2, f3, f4, f5];

export default function Features() {
  return (
    <Flex
      direction="column"
      maxW={"1440px"}
      mx={"auto"}
      w={"95%"}
      my={"80px"}
      gap={10}
    >
      {/* Heading */}
      <Flex direction={"column"} textAlign={"center"} gap={4}>
        <Text fontSize={"md"} color="secondary.500" fontFamily={"monospace"}>
          {"AI-Driven Capabilities".toUpperCase()}
        </Text>
        <Heading size={"lg"} fontFamily={"monospace"}>
          {"Be AI Empowered"}
        </Heading>
      </Flex>

      {/* Features */}
      <SimpleGrid
        columns={{ base: 1, md: 2, xl: 3 }}
        spacing={{ base: "50px", lg: "64px" }}
        mx={"auto"}
      >
        {features?.map((ft: FeatureProps, idx) => (
          <Flex
            direction={"column"}
            maxW={{ base: "460px", lg: "100%" }}
            gap={4}
            key={idx}
          >
            <Box
              bg={"#F7F9FB"}
              borderRadius={"12px"}
              overflow={"hidden"}
              w={"100%"}
              h={{ base: "260px", md: "300px" }}
              boxShadow={"md"}
            >
              {/* Image with transition */}
              <Flex
                w={"100%"}
                h={"100%"}
                overflow={"hidden"}
                borderRadius={"12px"}
                transition="opacity 0.3s ease-in-out"
                opacity={0.8}
                _hover={{ opacity: 1 }}
              >
                <Image
                  src={ftImages?.at(idx)}
                  alt={`Feature ${idx + 1}`}
                  objectFit={"fill"}
                  w={"100%"}
                  h={"100%"}
                />
              </Flex>
            </Box>

            <Flex direction={"column"} gap={2}>
              <Heading size={"md"} fontFamily={"monospace"} lineHeight={"1.3"}>
                {ft?.name}
              </Heading>
              <Text fontSize={{ base: "sm", lg: "md" }} lineHeight={"1.4"}>
                {ft?.description}
              </Text>
            </Flex>
          </Flex>
        ))}
      </SimpleGrid>
    </Flex>
  );
}
