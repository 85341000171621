import axios from "axios";
import { environment } from "../environments";

export const useVersionAPI = () => {
  const getApiVersion = async () => {
    const url = `${environment.BACKEND_API}/api/get_version`;
    const response = await axios.get(url);
    return response.data;
  };

  const isApiVersionLatest = (data: { version: string }) => {
    const apiVersionString = localStorage.getItem("apiVersion");

    const apiVersion =
      apiVersionString && apiVersionString !== "undefined"
        ? JSON.parse(apiVersionString)
        : null;

    if (apiVersion && apiVersion.version !== data.version) {
      return true;
    }

    return false;
  };

  const setApiVersion = (data: { version: string }) => {
    if (data.version !== "Unknown") {
      // If no api version available in localStorage, set it
      localStorage.removeItem("apiVersion");
      localStorage.setItem("apiVersion", JSON.stringify(data));
    }
  };

  return { getApiVersion, isApiVersionLatest, setApiVersion };
};
