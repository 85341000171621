import Realistic from "react-canvas-confetti/dist/presets/realistic";

export interface PositionsProps {
  x: number;
  y: number;
}

export interface ConfettiProps {
  active: boolean;
  colors: string[];
  position: PositionsProps;
}

export default function Confetti({ data }: { data: ConfettiProps }) {
  const { colors, position } = data;
  return (
    <Realistic
      style={{
        width: "500px",
        height: "500px",
        position: "fixed",
        top: position.y,
        left: position.x,
        transform: `translate(-50%,-50%)`,
        zIndex: 4,
      }}
      decorateOptions={() => {
        return {
          colors: colors,
          decay: 0.8,
          particleCount: 30,
          spread: 360,
          gravity: 0.9,
          ticks: 200,
          drift: -1,
          startVelocity: 30,
        };
      }}
      autorun={{ speed: 3, duration: 1 }}
    />
  );
}
