import { Button } from "@chakra-ui/react";

interface SendButtonProps {
  sending: boolean;
}

function SendButton({ sending }: SendButtonProps) {
  return (
    <Button
      minW={"100px"}
      fontSize={["12px", "12px", "14px", "14px", "15px", "16px"]}
      w={["100%", "100%", "40%", "40%", "40%", "40%"]}
      bg={"blue.900"}
      color={"white"}
      borderRadius={"24px"}
      type="submit"
      transition={"all .3s ease"}
      isLoading={sending}
      loadingText="sending..."
      _loading={{ color: "white" }}
      _hover={{ bg: "black" }}
      _active={{ bg: "black" }}
    >
      Send
    </Button>
  );
}

export default SendButton;
