import { Avatar, Flex, Text, useTheme } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";

interface UserAvatarProps {
  small?: boolean;
}

function UserAvatar({ small }: UserAvatarProps) {
  // Auth
  const { user } = useSelector(selectCurrentAuthData);

  // Theme
  const { colors } = useTheme();

  return (
    <Flex
      gap={2}
      align={"center"}
      whiteSpace={"nowrap"}
      overflow={"hidden"}
      textOverflow={"ellipsis"}
    >
      <Avatar
        name={user ? user.given_name || user.email : ""}
        background={`linear-gradient(to right bottom, ${colors.secondary[400]} 0%, ${colors.secondary[200]} 100%)`}
        size={small ? "xs" : "sm"}
        color={"white"}
      />

      <Text
        fontSize={
          small ? { lg: "11px", xl: "12px" } : { lg: "13px", xl: "14px" }
        }
        fontWeight={"500"}
        color={small ? "gray.600" : "gray.500"}
        textTransform={"capitalize"}
        whiteSpace={"nowrap"}
        overflow={"hidden"}
        textOverflow={"ellipsis"}
        paddingBottom={2}
        paddingTop={2}
        lineHeight={1}
      >
        {user?.given_name + " " + user?.family_name}
      </Text>
    </Flex>
  );
}

export default UserAvatar;
