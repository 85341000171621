import {
  Button,
  Flex,
  Icon,
  Text,
  usePrefersReducedMotion,
  useTheme,
} from "@chakra-ui/react";
import { keyframes } from "@emotion/react";

import { MdArrowRightAlt } from "react-icons/md";

interface MainButtonProps {
  text: string;
  onClick: () => void;
}

const fadeIn = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
  `;

const gradient = keyframes`
 0%{background-position: 0% 92%}
  50%{background-position: 100% 9%}
  100%{background-position: 0% 92%}
`;

function ActionButton({ text, onClick }: MainButtonProps) {
  // Theme
  const { colors } = useTheme();

  // Animation
  const prefersReducedMotion = usePrefersReducedMotion();

  const gradientAnimation = prefersReducedMotion
    ? undefined
    : `${gradient} 4s ease-in-out infinite`;

  const fadeInAnimation = prefersReducedMotion
    ? undefined
    : `${fadeIn} 1.5s ease-in-out`;

  // combine both animations
  const btnAnimation = prefersReducedMotion
    ? undefined
    : `${fadeInAnimation}, ${gradientAnimation}`;

  return (
    <Button
      m={0}
      mx={0}
      py={{ base: "20px", md: "24px" }}
      px={{ base: "3px", md: "6px" }}
      w={{ base: "170px", sm: "180px", md: "220px" }}
      fontSize={{ base: "xs", md: "sm", lg: "md" }}
      color={"whiteAlpha.900"}
      borderRadius={"30px"}
      letterSpacing={".05rem"}
      transition={"all .3s ease"}
      background={`-webkit-linear-gradient(225deg, ${colors.secondary[400]}, ${colors.secondary[500]}, ${colors.secondary[600]}) 0% 0% / 300% 300%`}
      backgroundSize="200% 200%"
      animation={btnAnimation}
      onClick={onClick}
      display="flex"
      justifyContent="space-between"
      textAlign={{ base: "left", md: "center" }}
      _hover={{
        background: `-webkit-linear-gradient(225deg, ${colors.secondary[600]}, ${colors.secondary[700]}, ${colors.secondary[800]}) 0% 0% / 300% 300%`,
      }}
      _focus={{
        background: `-webkit-linear-gradient(225deg, ${colors.secondary[600]}, ${colors.secondary[700]}, ${colors.secondary[800]}) 0% 0% / 300% 300%`,
      }}
    >
      <Text pl={"30px"}>{text.toUpperCase()}</Text>
      <MovingArrowCircle />
    </Button>
  );
}

const MovingArrowCircle = () => {
  const moveAnimation = keyframes`
  0% { transform: translateX(0); }
  50% { transform: translateX(-2px); } 
  100% { transform: translateX(0); }
`;

  return (
    <Flex
      width={{ base: "30px", md: "40px" }}
      height={{ base: "30px", md: "40px" }}
      borderRadius="50%"
      bg={{ base: "transparent", md: "whiteAlpha.300" }}
      borderWidth={{ base: 1, md: 0 }}
      borderColor={{ base: "whiteAlpha.500", md: "transparent" }}
      color={"whiteAlpha.900"}
      align="center"
      justify="center"
      animation={`${moveAnimation} 1s linear infinite`}
    >
      <Icon
        as={MdArrowRightAlt}
        boxSize={{ base: "14px", sm: "16px", md: "18px", lg: "20px" }}
      />
    </Flex>
  );
};

export default ActionButton;
