import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentPostsData } from "redux/features/posts/postsSlice";

import { Box, Flex, useToast } from "@chakra-ui/react";

import Loading from "components/ui/Loading";
import { errorHandler } from "utils/helpers";
import { useTeamsAPI } from "api/useTeamsAPI";
import { TeamMemberActivityProps } from "models/posts/PostProps";
import ActivityTable from "components/team/members/ActivityTable";

function ActivityPanel() {
  // Hooks
  const toast = useToast();
  const { teamData } = useSelector(selectCurrentPostsData);
  const { fetchTeamMembersActivity } = useTeamsAPI();

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [teamActivityData, setTeamActivityData] = useState<
    TeamMemberActivityProps[]
  >([]);

  const loadTeamActivityData = async () => {
    await fetchTeamMembersActivity(teamData.id)
      .then((response) => {
        setTeamActivityData(response);
      })
      .catch((error) => {
        toast({
          description: errorHandler(error).message,
          status: "error",
          position: "top-right",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (teamData?.id && !teamActivityData?.length && !isLoading) {
      setIsLoading(true);
      loadTeamActivityData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamData]);

  if (isLoading) {
    return (
      <Flex
        h={"100%"}
        w={"100%"}
        align={"center"}
        justify={"center"}
        color={"gray.500"}
        direction={"column"}
      >
        <Loading message="Loading team activity data ..." />
      </Flex>
    );
  }

  return (
    <Box w={"100%"}>
      <ActivityTable teamActivities={teamActivityData} />
    </Box>
  );
}

export default ActivityPanel;
