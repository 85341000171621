import { Button, DrawerFooter, Icon, Text, useTheme } from "@chakra-ui/react";
import { TbFileDownload } from "react-icons/tb";
import { hexToRgba } from "utils/helpers";

interface Props {
  url: string;
  isDisabled: boolean;
}

function DownloadReportButton({ url, isDisabled }: Props) {
  // Theme
  const { colors } = useTheme();

  return (
    <DrawerFooter px={3} pb={3} pt={0} border={"none"}>
      <Button
        as={"a"}
        href={url ?? ""}
        download={"bookmark-report.pdf"}
        display={"flex"}
        alignItems={"center"}
        gap={2}
        px={3}
        py={2}
        size={"sm"}
        fontSize={"14px"}
        w={"fit-content"}
        h={"fit-content"}
        fontWeight={"500"}
        color={"blue.500"}
        borderColor={"blue.300"}
        borderWidth={1}
        borderStyle={"dashed"}
        borderRadius={"30px"}
        transition={"all ease .2s"}
        isDisabled={isDisabled}
        pointerEvents={isDisabled ? "none" : "auto"}
        bg={hexToRgba(colors.blue[300], 0.15)}
        _hover={{ bg: hexToRgba(colors.blue[300], 0.25) }}
        _focus={{ bg: hexToRgba(colors.blue[300], 0.25) }}
      >
        <Icon boxSize={"16px"} as={TbFileDownload} />
        <Text>Download</Text>
      </Button>
    </DrawerFooter>
  );
}

export default DownloadReportButton;
