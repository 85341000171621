import { useNavigate } from "react-router-dom";
import { Box, Flex, Text, useMediaQuery } from "@chakra-ui/react";

import MobileHeader from "./MobileHeader";
import Nav from "components/home/header/Nav";
import LogIn from "components/home/header/LogIn";
import Content from "components/home/header/Content";
import Promotion from "components/home/header/Promotion";
import {
  FollowUs,
  LinkedInButton,
} from "components/home/header/FollowLinkedIn";

import Bg from "assets/home/header/bg.svg";

export default function Header() {
  // Hooks
  const navigate = useNavigate();

  // Responsiveness
  const [isMobileView] = useMediaQuery("(max-width: 991px)");

  if (isMobileView) return <MobileHeader />;

  return (
    <Box
      display={{ base: "none", lg: "block" }}
      w={"100%"}
      minH={"min(100vh, 100%)"}
      position={"relative"}
      backgroundImage={Bg}
      aspectRatio={1.25}
      backgroundRepeat={"no-repeat"}
      zIndex={1}
    >
      {/* Main Header Container */}
      <Box flex={1} h={"69%"} zIndex={1} position={"relative"}>
        {/* logo + nav + heading + action button */}
        <Box
          position={"absolute"}
          top={0}
          right={0}
          left={0}
          h={"84%"}
          w={"100%"}
          maxW={"1440px"}
          mx={"auto"}
          p={{ lg: "40px", xl: "60px", "2xl": "80px" }}
        >
          <Flex
            w={"100%"}
            h={"100%"}
            position={"relative"}
            direction={"column"}
          >
            {/* logo + nav */}
            <Flex align={"center"} justify={"space-between"}>
              {/* logo */}
              <Text
                fontFamily={"monospace"}
                fontSize={{ base: "lg", md: "xl", lg: "2xl" }}
                color={"neutral.100"}
                fontWeight={"500"}
                cursor={"pointer"}
                onClick={() => navigate("/")}
              >
                Lakesai
              </Text>

              {/* nav */}
              <Flex align="center" gap={6}>
                <Nav />
                <LogIn />
              </Flex>
            </Flex>

            <Content />
          </Flex>
        </Box>

        {/* video container */}
        <Flex
          position={"absolute"}
          borderRadius={"50%"}
          aspectRatio={1}
          bottom={"17.3%"}
          right={"11.3%"}
          w={"24.4%"}
        >
          {/* video content */}
          <Box
            objectFit={"cover"}
            objectPosition={"top center"}
            autoPlay
            muted
            loop
            as="video"
            src={"https://reacto.s3.eu-central-1.amazonaws.com/landing.webm"}
            borderRadius={"50%"}
            boxShadow={"rgba(0, 193, 180, 0.4) 0px 2px 8px 0px"}
            w={"95%"}
            aspectRatio={1}
            mx={"auto"}
            my={"auto"}
          ></Box>
        </Flex>

        {/* promo text + countdown */}
        <Flex
          align={"center"}
          position={"absolute"}
          bottom={0}
          right={"24%"}
          w={"fit-content"}
          h={"16%"}
        >
          <Promotion />
        </Flex>
      </Box>

      {/* Social Container */}
      <Flex
        position={"absolute"}
        right={0}
        bottom={"31%"}
        w={"10.5%"}
        h={"69%"}
      >
        {/* Social Centent */}

        <Flex w={"100%"} h={"100%"} position={"relative"}>
          <Flex
            position={"absolute"}
            right={0}
            left={0}
            top={0}
            w={"100%"}
            h={"69.5%"}
            align={"flex-end"}
            justify={"center"}
          >
            <FollowUs />
          </Flex>
          <Flex
            position={"absolute"}
            right={0}
            left={0}
            bottom={0}
            w={"100%"}
            h={"30.5%"}
            align={"center"}
            justify={"center"}
          >
            <LinkedInButton />
          </Flex>
        </Flex>
      </Flex>

      {/* Companies: trusted by */}
      {/* NOTE: to do not remove this comment */}
      {/* <Box flex={1} h={"31%"} zIndex={1} position={"relative"}>
        <Flex w={"100%"} h={"100%"} pl={{ lg: "32%", xl: "30%" }}>
          <Companies />
        </Flex>
      </Box> */}
    </Box>
  );
}
