import { useEffect, useState } from "react";
import { Flex, Icon, Text, useTheme, useToast } from "@chakra-ui/react";

import GuideButton from "../guideTour/GuideButton";
import FeedbackModal from "../feedback/FeedbackModal";
import FeedbackButton from "../feedback/FeedbackButton";

import { IoCheckmarkCircle } from "react-icons/io5";

function HelpButtons() {
  // States
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedbackSent, setFeedbackSent] = useState(false);
  const [hasFeedbackError, setHasFeedbackError] = useState(false);

  // Hooks
  const toast = useToast();

  // Theme
  const { colors } = useTheme();
  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const isLight = colorMode === "light";

  useEffect(() => {
    if (feedbackSent) {
      toast({
        position: "bottom-left",
        duration: 2000,
        render: () => (
          <Flex
            align={"center"}
            borderRadius={"6px"}
            p={3}
            py={5}
            gap={4}
            bg={isLight ? "#F0F9F8" : colors.dark[400]}
            borderWidth={1}
            borderColor={`rgba(0, 162, 142, ${isLight ? 0.3 : 0})`}
            borderLeftColor={"#00A28E"}
            borderLeftWidth={3}
            borderTopLeftRadius={0}
            borderBottomLeftRadius={0}
          >
            <Icon as={IoCheckmarkCircle} boxSize={5} color={"#00A28E"} />
            <Text fontSize={{ lg: "14px", xl: "15px" }} color={"gray.600"}>
              We received your feedback
            </Text>
          </Flex>
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackSent]);

  return (
    <>
      <Flex
        display={"flex"}
        gap={2}
        align={"center"}
        w={"175px"}
        position={"absolute"}
        top={"50%"}
        right={"-69px"}
        transform={"translateY(calc(-50% - 70px))  rotate(-90deg)"}
        zIndex={1350}
      >
        {/* Tour button */}
        <GuideButton />

        {/* Feedback button */}
        <FeedbackButton
          handleClick={() => {
            setShowFeedbackModal(true);
            setFeedbackSent(false);
          }}
        />
      </Flex>

      <FeedbackModal
        isOpen={showFeedbackModal}
        onClose={() => {
          setShowFeedbackModal(false);
          setHasFeedbackError(false);
        }}
        sent={feedbackSent}
        setSent={setFeedbackSent}
        hasError={hasFeedbackError}
        setHasError={setHasFeedbackError}
      />
    </>
  );
}

export default HelpButtons;
