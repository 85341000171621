import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
  Flex,
  Heading,
} from "@chakra-ui/react";

import { MRNASequence } from "models/proteins";
import MRNASequenceRow from "./MRNASequenceRow";

import { mRNASeqCols } from "./helpers";
import { CustomScrollBar } from "components/ui/CustomScrollBar";

interface MRNASequencesProps {
  data: MRNASequence[];
}

// Styles
const tableStyle = {
  height: "100%",
  width: "100%",
  padding: "0",
};

function MRNASequences({ data }: MRNASequencesProps) {
  return (
    <>
      {!!data && data?.length ? (
        <Flex gap={4} direction={"column"}>
          <Heading
            as={"h4"}
            fontSize={[null, null, null, "16px", "18px", "18px"]}
            color={"secondary.600"}
          >
            MRNA Sequences
          </Heading>
          <TableContainer>
            <CustomScrollBar style={tableStyle}>
              <Table variant="simple" size="sm" position={"relative"}>
                {/* Head */}
                <Thead>
                  <Tr>
                    {mRNASeqCols?.map((seq, index) => {
                      return (
                        <Th
                          pl={1.5}
                          key={"mrna-seq-table-header-" + index}
                          borderColor={"gray.100"}
                          maxW={seq?.maxW}
                          fontSize={[null, null, null, "12px", "12px", "14px"]}
                        >
                          {seq?.header}
                        </Th>
                      );
                    })}
                  </Tr>
                </Thead>

                {/* Body */}
                <Tbody>
                  {data?.map((seq: MRNASequence, index: number) => (
                    <MRNASequenceRow key={"mrna-seq-row" + index} seq={seq} />
                  ))}
                </Tbody>
              </Table>
            </CustomScrollBar>
          </TableContainer>
        </Flex>
      ) : (
        <></>
      )}
    </>
  );
}

export default MRNASequences;
