/*
 * query keys:
 *   /assays      ->  ["assays"]
 *   /assays/AhJDS59     ->  ["assays", AhJDS59]
 *   /assays?filter=any   -> ["assays", { filter: 'any' }]
 *   /assays/AHY89A/indications -> ["assays", AhJDS59, "indications"]
 */

import { QueryKey } from "@tanstack/react-query";
import { environment } from "environments";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import { setCurentUserLimit } from "utils/helpers";

export const useAssaysAPI = () => {
  const axiosPrivate = useAxiosPrivate();

  const fetchAllAssaysNames = async () => {
    const url = `${environment.BACKEND_API}/api/get_all_assays_names`;
    return await axiosPrivate.get(url).then((res) => res.data);
  };

  const fetchAssaysById = async ({ queryKey }: { queryKey: QueryKey }) => {
    const [, id] = queryKey;
    const url = `${environment.BACKEND_API}/api/get_assay_by_id/${id}`;
    const response = await axiosPrivate.get(url);
    setCurentUserLimit();
    return response.data;
  };

  const fetchAssaysSearch = async ({
    queryKey,
  }: {
    queryKey: [
      string,
      {
        query: string | null;
        page: string | number | null;
        itemsPerPage: string | number | null;
      }
    ];
  }) => {
    const [, params] = queryKey;
    const { query, page, itemsPerPage } = params;
    if (!query) return [];
    const url = `${environment.BACKEND_API}/api/get_assays_by_query`;
    return await axiosPrivate
      .get(url, { params: { query, page, itemsPerPage } })
      .then((res) => res.data);
  };

  return { fetchAllAssaysNames, fetchAssaysById, fetchAssaysSearch };
};
