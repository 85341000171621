import React, { useState, ChangeEvent } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Box,
  Heading,
  Text,
  InputRightElement,
  InputGroup,
  useToast,
  Icon,
  Flex,
  useTheme,
  FormHelperText,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

import { isPasswordValid } from "views/auth/helpers";
import { GetGradients } from "utils/gradients";
import { axiosClient } from "api/axios";
import { hexToRgba } from "utils/helpers";

import { verifyCode } from "services/user.service";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import { BsPatchCheckFill } from "react-icons/bs";

export default function ResetPasswordForm() {
  // Hooks
  const toast = useToast();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  // Theme
  const { colors } = useTheme();

  // States
  const [stage, setStage] = useState(1); // 1: Forgot Password, 2: Verification Code, 3: Reset Password
  const [formData, setFormData] = useState({
    username: "",
    newPassword: "",
    confirmNewPassword: "",
    verificationCode: "",
  });
  const [submitting, setSubmitting] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [resetSuccess, setResetSuccess] = useState<null | string>(null);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  // Theme
  const { appTitleGradient } = GetGradients();

  // Handlers
  const handleInputsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setErrorMsg("");
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePasswordToggle = (pass: "PASSWORD" | "CONFIRM_PASSWORD") => {
    if (pass === "PASSWORD") {
      setShowPassword({
        ...showPassword,
        password: !showPassword.password,
      });
    } else {
      setShowPassword({
        ...showPassword,
        confirmPassword: !showPassword.confirmPassword,
      });
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    setErrorMsg("");
    const { username, newPassword, confirmNewPassword, verificationCode } =
      formData;
    let response = null;
    let message = "";

    try {
      if (stage === 1) {
        if (!username) return;

        // Request to send verification code
        response = await axiosClient.post(
          "/api/forgot_password",
          JSON.stringify({ username })
        );

        message =
          "Verification code sent successfully. Please check your email.";
        setStage(2);
      } else if (stage === 2) {
        setIsVerifying(true);
        // Verify the code
        if (!verificationCode || verificationCode.length !== 6) {
          setErrorMsg("Invalid verification code");
          setIsVerifying(false);
          return;
        }

        const payload = { verificationCode, username };

        await verifyCode(payload)
          .then((res) => {
            setIsVerifying(false);
            setStage(3);
          })
          .catch((error) => {
            toast({
              description: error.response.data.message
                ? error.response.data.message
                : "An error occurred while verifying code",
              status: "error",
              position: "top-right",
            });
            setIsVerifying(false);
          });
      } else {
        //? Resetting new password
        if (!username || !newPassword) return;

        const passwordError = isPasswordValid(newPassword, confirmNewPassword);
        if (passwordError) {
          setErrorMsg(passwordError);
          setSubmitting(false);
          return;
        }
        response = await axiosPrivate.post(
          "/api/reset_password",
          JSON.stringify({
            username,
            newPassword,
            confirmNewPassword,
          })
        );

        // message = response.data.message;
        setResetSuccess(response.data);
        setTimeout(() => {
          navigate("/login", { replace: true });
        }, 3000);
      }
      message &&
        toast({
          position: "bottom-right",
          duration: 3000,
          render: () => (
            <Box
              color="white"
              p={3}
              bg={"highlight.primary"}
              borderRadius={"6px"}
            >
              {message}
            </Box>
          ),
        });
    } catch (error: any) {
      if (!error.response) {
        toast({
          description: "No server response!",
          status: "error",
        });
        return;
      }
      toast({
        description: error.response.data.message,
        status: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const renderInputs1 = (
    <FormControl id="username" w={"100%"}>
      <FormLabel
        htmlFor="username"
        color={"secondary.700"}
        fontSize={"12px"}
        fontWeight={"500"}
        fontFamily={"Poppins, sans-serif"}
        lineHeight={1}
        mb={1}
      >
        Username
      </FormLabel>
      <Input
        w={"100%"}
        color={"gray.600"}
        fontSize={"14px"}
        type="text"
        name="username"
        value={formData.username}
        onChange={handleInputsChange}
        onKeyDown={handleKeyDown}
        placeholder="Enter your username"
        _placeholder={{ fontSize: "14px", color: "gray.600" }}
        borderRadius={"6px"}
        bg={"background"}
        borderWidth={1}
        borderColor={"gray.200"}
        _hover={{ borderColor: "gray.300" }}
        _focusVisible={{ borderColor: "blue.300" }}
      />
    </FormControl>
  );

  const renderInputs2 = (
    <FormControl id="verificationCode" w={"100%"}>
      <FormLabel
        htmlFor={"verificationCode"}
        color={"secondary.700"}
        fontSize={"12px"}
        fontWeight={"500"}
        fontFamily={"Poppins, sans-serif"}
        lineHeight={1}
        mb={1}
      >
        Verification Code
      </FormLabel>
      <Input
        w={"100%"}
        color={"gray.600"}
        fontSize={"14px"}
        type={"text"}
        name={"verificationCode"}
        value={formData.verificationCode}
        onChange={handleInputsChange}
        onKeyDown={handleKeyDown}
        placeholder="Enter the verification code"
        _placeholder={{ fontSize: "14px", color: "gray.600" }}
        borderRadius={"6px"}
        bg={"background"}
        borderWidth={1}
        borderColor={"gray.200"}
        _hover={{ borderColor: "gray.300" }}
        _focusVisible={{ borderColor: "blue.300" }}
      />
      <FormHelperText>
        The verification code has been sent to the email address provided.
      </FormHelperText>
    </FormControl>
  );

  const renderInputs3 = (
    <Flex direction={"column"} gap={4} w={"100%"}>
      <FormControl id="newPassword" w={"100%"}>
        <FormLabel
          htmlFor={"newPassword"}
          color={"secondary.700"}
          fontSize={"12px"}
          fontWeight={"500"}
          fontFamily={"Poppins, sans-serif"}
          lineHeight={1}
          mb={1}
        >
          New Password
        </FormLabel>
        <InputGroup>
          <Input
            w={"100%"}
            color={"gray.600"}
            fontSize={"14px"}
            type={showPassword.password ? "text" : "password"}
            name={"newPassword"}
            value={formData.newPassword}
            onChange={handleInputsChange}
            placeholder={"Enter your new password"}
            _placeholder={{ fontSize: "14px", color: "gray.600" }}
            borderRadius={"6px"}
            bg={"background"}
            borderWidth={1}
            borderColor={"gray.200"}
            _hover={{ borderColor: "gray.300" }}
            _focusVisible={{ borderColor: "blue.300" }}
          />
          <InputRightElement>
            <Icon
              boxSize={"14px"}
              as={showPassword.password ? ViewOffIcon : ViewIcon}
              name={"password"}
              color={"secondary.300"}
              cursor={"pointer"}
              onClick={() => handlePasswordToggle("PASSWORD")}
            />
          </InputRightElement>
        </InputGroup>
      </FormControl>

      <FormControl id={"confirmNewPassword"} w={"100%"}>
        <FormLabel
          htmlFor={"confirmNewPassword"}
          color={"secondary.700"}
          fontSize={"12px"}
          fontWeight={"500"}
          fontFamily={"Poppins, sans-serif"}
          lineHeight={1}
          mb={1}
        >
          Confirm New Password
        </FormLabel>
        <InputGroup>
          <Input
            w={"100%"}
            color={"gray.600"}
            fontSize={"14px"}
            type={showPassword.confirmPassword ? "text" : "password"}
            name={"confirmNewPassword"}
            value={formData.confirmNewPassword}
            onChange={handleInputsChange}
            onKeyDown={handleKeyDown}
            placeholder={"Confirm your new password"}
            _placeholder={{ fontSize: "14px", color: "gray.600" }}
            borderRadius={"6px"}
            bg={"background"}
            borderWidth={1}
            borderColor={"gray.200"}
            _hover={{ borderColor: "gray.300" }}
            _focusVisible={{ borderColor: "blue.300" }}
          />
          <InputRightElement>
            <Icon
              boxSize={"14px"}
              as={showPassword.confirmPassword ? ViewOffIcon : ViewIcon}
              name={"confirmPassword"}
              color={"secondary.300"}
              cursor={"pointer"}
              onClick={() => handlePasswordToggle("CONFIRM_PASSWORD")}
            />
          </InputRightElement>
        </InputGroup>
      </FormControl>
    </Flex>
  );

  return (
    <Flex
      direction="column"
      justify="center"
      align={{ base: "center", lg: "flex-start" }}
      gap={4}
      px={{ base: 4, md: 6, lg: 10 }}
      py={10}
      w={{ base: "100%", lg: "400px" }}
      minH={{ base: "100vh", lg: "unset" }}
      borderRadius={{ base: 0, lg: "10px" }}
      bg={hexToRgba(colors.white, 0.95)}
      boxShadow={{ base: "none", lg: "0 8px 32px 0 rgba( 31, 38, 80, 0.37 )" }}
      backdropFilter={"blur( 4.5px)"}
      borderWidth={1}
      borderColor={hexToRgba(colors.black, 0.18)}
      mx={{ base: "auto", lg: "0" }}
    >
      <Flex
        textAlign={{ base: "center", lg: "left" }}
        direction={"column"}
        gap={6}
        maxW={{ base: "380px", md: "410px" }}
      >
        <Flex direction={"column"} gap={2}>
          <Heading fontSize={{ base: "18px", md: "20px" }} color={"gray.600"}>
            Welcome to{" "}
            <Text
              as="span"
              fontWeight={"bold"}
              bgGradient={appTitleGradient}
              bgClip="text"
              cursor={"pointer"}
              onClick={() => navigate("/")}
            >
              Lakesai
            </Text>
          </Heading>

          <Text
            fontSize={{ base: "13px", md: "14px" }}
            color={"gray.600"}
            display={{ base: "inline-block", lg: "none" }}
            fontFamily={"Poppins, sans-serif"}
            mx={"auto"}
          >
            Your intelligent agent for accelerating scientific discoveries.
          </Text>
        </Flex>

        <Heading
          fontSize={{ base: "14px", md: "16px" }}
          color={"secondary.700"}
          mt={4}
        >
          {resetSuccess
            ? "You will be redirected to login page."
            : stage === 2
            ? "Enter verification code"
            : stage === 3
            ? "Choose another password"
            : "Please enter your username or email"}{" "}
        </Heading>
      </Flex>

      {resetSuccess ? (
        <Flex
          w="100%"
          bg="green.100"
          color="green.700"
          p={2}
          borderRadius="md"
          alignItems="center"
        >
          <Icon as={BsPatchCheckFill} boxSize={5} mr={3} />
          <Text fontWeight="medium">{resetSuccess}</Text>
        </Flex>
      ) : (
        <Flex direction={"column"} gap={4} w="100%" maxW="460px">
          <Flex direction="column" gap={4} w="100%">
            {/* Inputs */}
            {stage === 2
              ? renderInputs2
              : stage === 3
              ? renderInputs3
              : renderInputs1}

            {/* Error message */}
            {!!errorMsg && (
              <Text
                w={"100%"}
                fontSize={"sm"}
                color={"red.500"}
                textAlign={"center"}
                borderRadius={"6px"}
                bg={"rgba(255, 0, 0, .02)"}
                borderStyle={"dashed"}
                borderWidth={1}
                borderColor={"rgba(255, 0, 0, .35)"}
                p={3}
                mt={2}
              >
                {errorMsg}
              </Text>
            )}
          </Flex>

          {/* Submit button */}
          <Button
            mt={4}
            py={"10px"}
            w={"100%"}
            h={"fit-content"}
            size={"sm"}
            type={"submit"}
            bg={"primary.400"}
            color={"primary.100"}
            onClick={handleSubmit}
            isLoading={submitting || isVerifying}
            loadingText={"Processing"}
            _hover={{ bg: "primary.500" }}
          >
            {stage === 2
              ? "Continue"
              : stage === 3
              ? "Reset Password"
              : "Get Verification Code"}
          </Button>
        </Flex>
      )}
    </Flex>
  );
}
