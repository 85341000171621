import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { OntologyProps } from "models/compounds/OntologyProps";

interface ontologyTableProps {
  ontologies: OntologyProps[];
}

function Ontologies({ ontologies }: ontologyTableProps) {
  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th borderColor={"gray.100"} textAlign="left">
            MoA Category
          </Th>
          <Th borderColor={"gray.100"} textAlign="left">
            MoA
          </Th>
          <Th borderColor={"gray.100"} textAlign="left">
            Parent MoA
          </Th>
          <Th borderColor={"gray.100"} textAlign="left">
            Data Source
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {ontologies?.map((ontology, index) => (
          <Tr key={"screening-collection" + index} fontSize={13}>
            <Td borderColor={"gray.100"}>{ontology.moa_category}</Td>
            <Td borderColor={"gray.100"} color={"secondary.600"} p={2}>
              {ontology.moa}
            </Td>
            <Td borderColor={"gray.100"} p={2}>
              {ontology.parent_moa}
            </Td>
            <Td borderColor={"gray.100"} p={2}>
              {ontology.data_source}
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

export default Ontologies;
