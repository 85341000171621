import { Box } from "@chakra-ui/react";

export const CustomOverlay = () => {
  return (
    <Box
      borderRadius={"md"}
      bg={"rgba(0, 0, 0, 0.2)"}
      position={"absolute"}
      top={0}
      left={0}
      right={0}
      bottom={0}
      pointerEvents={"none"}
      zIndex={1300}
      border={"none"}
    />
  );
};
