import { Flex, Heading, Highlight, Text, useTheme } from "@chakra-ui/react";
import ActionButton from "components/home/ActionButton";

import { hexToRgba } from "utils/helpers";

export default function AboutUs() {
  // Theme
  const { colors } = useTheme();

  return (
    <Flex
      direction={"column"}
      align={"center"}
      maxW={"1440px"}
      mx={"auto"}
      w={"95%"}
      gap={10}
    >
      {/* Heading */}
      <Flex direction={"column"} textAlign={"center"} gap={4}>
        <Text fontSize={"md"} color="secondary.500" fontFamily={"monospace"}>
          {"About Us".toUpperCase()}
        </Text>
        <Heading size={"lg"} fontFamily={"monospace"}>
          {"We are powered by Vitafluence"}
        </Heading>
      </Flex>

      {/* Paragraph */}
      <Flex justify="center" align="center">
        <Text
          as={"span"}
          fontSize={{ base: "sm", lg: "md" }}
          maxW="660px"
          textAlign={"center"}
        >
          <Highlight
            query={["driving force", "healthcare", "technology"]}
            styles={{
              px: 2,
              py: 0.5,
              rounded: "full",
              bg: hexToRgba(colors.highlight["primary"], 0.2),
              lineHeight: "1.8",
            }}
          >
            Vitafluence is the driving force behind Lakesai. We strive to
            innovative and transform the way healthcare harnesses AI. Our team
            of experts is dedicated to delivering unparalleled support and
            cutting-edge technology, ensuring Lakesai empowers you to unlock new
            levels of efficiency and insight.
          </Highlight>
        </Text>
      </Flex>

      {/* Action */}
      <ActionButton
        text={"Visit Website"}
        onClick={() => {
          window.open("https://www.vitafluence.ai", "_blank");
        }}
      />
    </Flex>
  );
}
