import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosPrivate from "hooks/auth/useAxiosPrivate";
import {
  selectCurrentSavedData,
  setSuccess,
} from "redux/features/bookmarks/savedSlice";

import {
  Flex,
  Icon,
  Image,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useTheme,
  useToast,
} from "@chakra-ui/react";

import { environment } from "environments";
import { CompoundProps } from "models/compounds/CompoundProps";
import {
  SavedCompoundProps,
  SavedFolderProps,
} from "models/bookmarks/SavedProps";
import { GoBookmarkSlash } from "react-icons/go";
import { LuExternalLink } from "react-icons/lu";
import { PiGraph } from "react-icons/pi";
import ActionButton from "components/buttons/ActionButton";

import {
  adjustCompoundName,
  getAliases,
  getSmilesImageSrc,
} from "components/molecules/helpers";
import { hexToRgba } from "utils/helpers";
import { GetGradients } from "utils/gradients";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { selectCurrentPostsData } from "redux/features/posts/postsSlice";

interface Props {
  content: SavedCompoundProps[];
}

interface DeleteElementProps {
  elementType: "COMPOUND" | "LITERATURE" | "ASSAY" | "BOTMESSAGE";
  content: {
    elementId: string | undefined;
  };
}

interface SmilesProps {
  cmpd: CompoundProps;
}

interface AliasesTagsProps {
  aliases: string[];
}

const cols = [
  { name: "Molecule", width: "20%" },
  { name: "ID", width: "15%" },
  { name: "Name", width: "35%" },
  { name: "Aliases", width: "15%" },
  { name: "Actions", width: "15%" },
];

function Molecules({ content }: Props) {
  // Hooks
  const toast = useToast();
  const dispatch = useDispatch();
  const { folderId } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const { user } = useSelector(selectCurrentAuthData);
  let { teamData } = useSelector(selectCurrentPostsData);
  const { savedFolders } = useSelector(selectCurrentSavedData);

  // Theme
  const { lGradient2 } = GetGradients();
  const { colors } = useTheme();

  // Navigation
  const navigate = useNavigate();

  // Handler: delete item from bookmark folder
  async function handleDeleteItem({
    elementType,
    content,
  }: DeleteElementProps) {
    if (!content) return;

    try {
      const newFolders = savedFolders.map((f: SavedFolderProps) => {
        if (f.id === folderId) {
          return {
            ...f,
            compounds: f?.compounds?.filter(
              (item: { compound_id: string; name: string }) =>
                item.compound_id !== content.elementId
            ),
          };
        }

        return f;
      });

      dispatch(setSuccess(newFolders));

      await axiosPrivate.put(`${environment.BACKEND_API}/api/remove_elements`, {
        folderId,
        elementType,
        content,
      });
    } catch (error: any) {
      toast({
        title: error.response.data.message,
        status: "error",
        duration: 1500,
        position: "top-right",
      });
      console.log(error);
    }
  }

  const cleanItems = useMemo(
    () => content?.filter((item) => item !== undefined),
    [content]
  );

  return (
    <Flex direction={"column"} gap={4}>
      <Flex align={"center"} gap={2}>
        <Flex
          bg={hexToRgba(colors.blue[300], 0.15)}
          borderRadius={"70% 30% 30% 70% / 60% 40% 60% 40%"}
          p={1.5}
        >
          <Icon as={PiGraph} boxSize={"16px"} />
        </Flex>
        <Text fontSize={"14px"} fontWeight={"500"}>
          Molecules
        </Text>
      </Flex>

      <TableContainer>
        <Table variant="simple" size="sm" position={"relative"}>
          {/* Head */}
          <Thead>
            <Tr>
              {cols.map((col, index) => {
                return (
                  <Th
                    key={"cpmds-table-header-" + index}
                    isTruncated={col?.name === "Aliases"}
                    borderBottomColor={hexToRgba(colors.blue[300], 0.15)}
                    borderBottomWidth={1}
                    color={"blue.500"}
                    fontWeight={"500"}
                    textAlign={"center"}
                    w={col?.width}
                  >
                    {col?.name}
                  </Th>
                );
              })}
            </Tr>
          </Thead>

          {/* Body */}
          <Tbody>
            {cleanItems.map((cmpd, index) => (
              <Tr
                key={"cmpd-row" + index}
                _hover={{ bgGradient: lGradient2 }}
                transition="all 0.25s ease"
              >
                {/* Molecule */}
                <Smiles cmpd={cmpd as CompoundProps} />

                {/* ID */}
                <Td
                  borderColor={"gray.100"}
                  w={"15%"}
                  fontSize={"12px"}
                  p={1.5}
                >
                  {cmpd?.compound_id}
                </Td>

                {/* Name */}
                <Td borderColor={"gray.100"} w={"35%"} p={1.5} isTruncated>
                  <Text
                    textTransform={"capitalize"}
                    whiteSpace={"nowrap"}
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                    fontSize={"13px"}
                    maxW={"250px"}
                  >
                    {adjustCompoundName(cmpd)}
                  </Text>
                </Td>

                {/* Aliases */}
                <Td borderColor={"gray.100"} w={"15%"} p={1.5} isTruncated>
                  <AliasesTags aliases={cmpd?.aliases ?? []} />
                </Td>

                {/* Action Buttons */}
                <Td borderColor={"gray.100"} w={"15%"} isTruncated p={1.5}>
                  <Flex justify={"center"} align={"center"}>
                    {teamData?.owner === user?.id ||
                    savedFolders.find((x) => x?.id === folderId)?.user ===
                      user?.id ? (
                      <ActionButton
                        inBookmarks
                        aria-label={"Delete"}
                        label={"Delete"}
                        icon={<GoBookmarkSlash />}
                        onClick={() =>
                          handleDeleteItem({
                            elementType: "COMPOUND",
                            content: { elementId: cmpd?.compound_id },
                          })
                        }
                      />
                    ) : (
                      <></>
                    )}

                    <ActionButton
                      inBookmarks
                      aria-label={"Open"}
                      label={"Open"}
                      icon={<LuExternalLink />}
                      onClick={() =>
                        navigate(
                          `/data/core/MoleculeLake/data/molecules/${cmpd?.compound_id}`
                        )
                      }
                    />
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
}

export default Molecules;

function Smiles({ cmpd }: SmilesProps) {
  return (
    <Td borderColor={"gray.100"} w={"20%"}>
      <Image
        src={getSmilesImageSrc(cmpd)}
        boxSize={"100%"}
        maxHeight={"80px"}
        maxWidth={"80px"}
        objectFit={"cover"}
        fallbackSrc="https://via.placeholder.com/120x120.png/FFFFFF"
        alt={cmpd?.chemical_props?.formula}
        boxShadow={"md"}
        borderRadius={"6px"}
        mx={"auto"}
      />
    </Td>
  );
}

function AliasesTags({ aliases }: AliasesTagsProps) {
  const processedAliases = getAliases(aliases);
  const moreTags = aliases ? aliases?.length - processedAliases?.length : 0;

  if (!processedAliases?.length && moreTags)
    return (
      <Tag
        fontSize={{ lg: "10px", xl: "12px" }}
        color={"gray.700"}
        bg={"gray.200"}
      >
        {aliases?.at(0)}
      </Tag>
    );

  return (
    <Flex align={"center"} w={"fit-content"} gap={1} flexWrap={"wrap"}>
      {processedAliases?.map((alias, index) => {
        return (
          <Tag
            key={"alias-" + index}
            fontSize={{ lg: "10px", xl: "12px" }}
            color={"gray.700"}
            bg={"gray.200"}
          >
            {alias}
          </Tag>
        );
      })}
      {!!moreTags && (
        <Text
          as={"span"}
          color={"gray.600"}
          fontSize={{ lg: "10px", xl: "12px" }}
          fontWeight={"500"}
        >
          {` and ${moreTags} more.. `}
        </Text>
      )}
    </Flex>
  );
}
