import { Flex, Text } from "@chakra-ui/react";
import { ExtraPapersProps } from "../helpers";
import { PaperProps } from "models/papers/PaperProps";

interface ReferencesHeaderProps {
  titles: (ExtraPapersProps | undefined)[];
  data: PaperProps[];
}

function ReferencesHeader({ titles, data }: ReferencesHeaderProps) {
  return (
    <Flex gap={1.5} w={"full"} justify={"space-between"} align={"center"}>
      {/* Additional Papers */}
      <Flex gap={1.5} align={"center"}>
        <Text
          fontSize={"12px"}
          fontWeight={"bold"}
          color={"gray.600"}
          lineHeight={1}
        >
          Additional Papers
        </Text>

        <Text
          fontSize={"11px"}
          fontWeight={"normal"}
          color={"gray.600"}
          lineHeight={1}
        >
          {titles.length}
        </Text>
      </Flex>

      {/* Total */}
      {data && data?.length > 0 && (
        <Flex gap={1.5} align={"center"}>
          <Text
            fontSize={"12px"}
            fontWeight={"normal"}
            color={"gray.600"}
            lineHeight={1}
          >
            Total
          </Text>
          <Text
            fontSize={"11px"}
            fontWeight={"normal"}
            color={"gray.600"}
            lineHeight={1}
          >
            {data?.length}
          </Text>
        </Flex>
      )}
    </Flex>
  );
}

export default ReferencesHeader;
