import { useEffect, useState } from "react";
import {
  Text,
  Flex,
  Tooltip,
  Icon,
  useClipboard,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import { getPaperColor } from "components/ui/helpers";

import { ExternalId, PaperProps } from "../../../models/papers/PaperProps";
import { BiSolidCopy } from "react-icons/bi";
import { FaCheck } from "react-icons/fa";
import TypeAndYear from "components/papers/paperDetails/TypeAndYear";
import Abstract from "components/papers/paperDetails/Abstract";
import Authors from "components/papers/paperDetails/Authors";
import Citation from "components/papers/paperDetails/Citation";
import LensId from "components/papers/paperDetails/LensId";
import Doi from "components/papers/paperDetails/Doi";

interface SelectedNodeDetailsProps {
  data: PaperProps;
}

export const classifiedTypes = [
  "interventional study",
  "experimental study",
  "review",
];

export default function SelectedNodeDetails({
  data,
}: SelectedNodeDetailsProps) {
  // Hooks
  const { onCopy } = useClipboard(data?.title ?? "");

  // State
  const [hasCopied, setHasCopied] = useState(false);

  // Handlers
  function handleCopy() {
    onCopy();
    setHasCopied(true);
  }

  const doi = data?.external_ids?.find(
    (id: ExternalId) => id?.type?.toLowerCase() === "doi"
  )?.value;

  useEffect(() => {
    setHasCopied(false);
  }, [data]);

  return (
    <>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent
        alignSelf={"center"}
        overflowY={"auto"}
        minW={{ base: "95%", sm: "460px", md: "700px" }}
        h={"fit-content"}
        maxH={{
          lg: "calc(100vh - 16px)",
          xl: "calc(100vh - 24px)",
          "2xl": "calc(100vh - 32px)",
        }}
        my={"auto"}
        bg={"background"}
        position={"absolute"}
      >
        <ModalCloseButton />
        <ModalBody pt={10} pb={6} px={{ base: 4, md: 8 }} color={"primary.800"}>
          <Flex direction={"column"} justify={"space-between"} h={"100%"}>
            <Flex direction={"column"} gap={3}>
              {/* paper type & publication year  */}
              <TypeAndYear data={data} />

              {/* title */}
              <Tooltip
                label={data?.title}
                placement={"left"}
                hasArrow
                display={
                  data?.title && data?.title.length < 121 ? "none" : "block"
                }
                bg={"gray.900"}
                color={"gray.100"}
                py={2}
                px={3}
                m={2}
                maxW={[null, null, null, "200px", "220px", "240px"]}
                borderRadius={"6px"}
                fontSize={"12px"}
                boxShadow={"none"}
                arrowSize={8}
              >
                <Text
                  fontSize={[null, null, null, "12px", "14px", "16px"]}
                  fontWeight={"500"}
                  color={getPaperColor(data.paper_type)}
                  lineHeight={"1.35"}
                  display={"inline"}
                  mb={1.5}
                >
                  {data?.title && data?.title.length < 121
                    ? data?.title
                    : data?.title?.slice(0, 120) + ".."}

                  <Icon
                    ml={2}
                    display={"inline"}
                    color={hasCopied ? "highlight.primary" : "gray.500"}
                    boxSize={"14px"}
                    onClick={handleCopy}
                    cursor={"pointer"}
                    as={hasCopied ? FaCheck : BiSolidCopy}
                  />
                </Text>
              </Tooltip>

              <Abstract data={data?.abstract ?? ""} />
              <Authors data={data?.authors ?? []} />
              <Citation data={data?.scholarly_citations_count} />
              <LensId data={data?.lens_id ?? ""} />
              <Doi data={doi ?? ""} />
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </>
  );
}
