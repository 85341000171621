import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Flex,
  SimpleGrid,
  Text,
  useBreakpointValue,
  useTheme,
} from "@chakra-ui/react";

import ToolCard from "./ToolCard";
import { CoreContext } from "views/library/core";
import { LayersProps } from "views/library/core/CoreLayersList";

import { ToolProps } from "models/tools/ToolsProps";

function ToolsSection() {
  // Hooks
  const navigate = useNavigate();
  const { layerName } = useParams();
  const { layers } = useContext(CoreContext);

  let layerTools: ToolProps[] = [] as ToolProps[];
  let layer: LayersProps = {} as LayersProps;

  if (!!layers && layers?.length) {
    layer = layers?.find(
      (layer: LayersProps) => layer?.name === layerName
    ) as LayersProps;
  }

  if (layer) {
    layerTools = layer?.tools as ToolProps[];
  }

  // Theme
  const { colors } = useTheme();

  // Handler
  function openTool(id: string) {
    if (id) {
      navigate(`/data/core/${layerName}/tools/${id}`);
    }
  }

  // Responsiveness: ~992px, ~1280px, ~1536px
  const templateColumns = useBreakpointValue({
    lg: "repeat(auto-fill, minmax(400px, 1fr))",
    xl: "repeat(auto-fill, minmax(420px, 1fr))",
    "2xl": "repeat(auto-fill, minmax(450px, 1fr))",
  });

  return (
    <Flex direction={"column"} gap={2}>
      <Text
        fontSize={"20px"}
        fontWeight={"500"}
        lineHeight={1}
        bgGradient={`linear(to-l, ${colors.highlight.primary} 0%, ${colors.secondary[600]} 100%)`}
        bgClip={"text"}
        color={"transparent"}
        w={"fit-content"}
        pl={2}
      >
        Tools
      </Text>

      <Box>
        {!!layerTools && (
          <SimpleGrid templateColumns={templateColumns} spacing={6} p={1}>
            {layerTools?.map((tool: ToolProps, index: number) => (
              <ToolCard key={index} tool={tool} onOpen={openTool} />
            ))}
          </SimpleGrid>
        )}
      </Box>
    </Flex>
  );
}

export default ToolsSection;
