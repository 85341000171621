import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNotificationsAPI } from "./useNotificationsAPI";

export const useMarkNotificationAsRead = () => {
    const { markNotificationsAsRead } = useNotificationsAPI()
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async () => {
            const res = await markNotificationsAsRead();
            return res;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['notifications'] });
        },
        onError: (error) => {
            console.error("Failed to mark notifications as read", error);
        },
    });
};