import { KeyboardEvent, useRef } from "react";
import {
  Button,
  Text,
  useTheme,
  Input,
  Icon,
  ModalFooter,
  ModalContent,
  Modal,
  ModalBody,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { hexToRgba } from "utils/helpers";
import { BiPlus } from "react-icons/bi";

interface NewFolderContentProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  handleCreateFolder?: (name: string) => void;
  creatingFolder?: boolean;
  small?: boolean;
}

function CreateNewFolderModal({
  isOpen,
  setIsOpen,
  handleCreateFolder,
  creatingFolder,
  small,
}: NewFolderContentProps) {
  // Ref
  const inputRef = useRef<HTMLInputElement>(null);

  // Theme
  const { colors } = useTheme();

  // Handler
  function handleClose() {
    setIsOpen(false);
    if (inputRef?.current) {
      inputRef.current.value = "";
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent
        alignSelf={"center"}
        p={1}
        w={"fit-content"}
        minW={"412px"}
        minH={"164px"}
        bg={"background"}
        borderRadius={"6px"}
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
          if (
            e.key === "Enter" &&
            handleCreateFolder &&
            inputRef.current?.value
          ) {
            handleCreateFolder(inputRef.current?.value);
          }
        }}
      >
        <ModalHeader display={"flex"} alignItems={"center"} gap={2}>
          <Icon
            as={BiPlus}
            bg={"highlight.primary"}
            color={"gray.50"}
            boxSize={"28px"}
            borderRadius={"6px"}
            p={"3px"}
          />
          <Text fontSize={"16px"} color={"gray.600"} fontWeight={"500"}>
            Create folder
          </Text>
        </ModalHeader>

        <ModalBody>
          <Input
            ref={inputRef}
            variant={"outline"}
            fontSize={small ? "12px" : "14px"}
            placeholder="Folder name..."
            _placeholder={{
              fontSize: small ? "12px" : "14px",
              color: "gray.400",
            }}
            borderRadius={small ? "6px" : "6px"}
            h={small ? 8 : 10}
            bg={"transparent"}
            borderColor={hexToRgba(colors.blue[700], 0.3)}
            borderWidth={1}
            _focus={{ boxShadow: "md", overflow: "auto" }}
            _focusVisible={{ outline: "none" }}
          />
        </ModalBody>

        <ModalFooter gap={2}>
          <Button
            bg={"gray.100"}
            color={"gray.500"}
            _hover={{ bg: "gray.200" }}
            pointerEvents={creatingFolder ? "none" : "auto"}
            borderRadius={"100px"}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            color={"gray.50"}
            bg={"highlight.primary"}
            border={"none"}
            borderRadius={"100px"}
            loadingText={"Creating..."}
            isLoading={creatingFolder}
            _hover={{ color: "secondary.700", bg: "transparent" }}
            _focus={{ color: "secondary.700", bg: "transparent" }}
            _active={{ color: "secondary.700", bg: "transparent" }}
            onClick={() => {
              if (handleCreateFolder && inputRef?.current?.value) {
                handleCreateFolder(inputRef?.current?.value.trim());
              }
            }}
          >
            Create
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default CreateNewFolderModal;
