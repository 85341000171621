import { SourceProps } from "models/chat/MessageProps";
import { SuggestedQuestionProps } from "./new/SuggestedQuestion";

export const suggestedQuestions: Omit<
  SuggestedQuestionProps,
  "onSendSuggestedQuestion"
>[] = [
  {
    question:
      "What alternatives exist for peginterferon/ribavirin treatment for hepatitis C?",
    header: "Hepatitis C Treatment Alternatives",
  },
  {
    question:
      "Which SGLT2 inhibitors have outperformed other diabetes treatments without compromising patient quality of life?",
    header: "Optimal SGLT2 Inhibitors in Diabetes Care",
  },
  {
    question:
      "What antihypertensive drugs have shown efficacy in reducing systolic blood pressure by more than 20 mmHg?",
    header: "Effective Antihypertensives for Major BP Reduction",
  },
  {
    question:
      "Which molecules have shown promise in targeting the Wnt signaling pathway in colorectal cancer therapies?",
    header: "Wnt Pathway Targeting in Colorectal Cancer Therapies",
  },
];

// Helper to be used in streaming mode: data cleanup
export function removeBraces(content: string[]): string[] {
  const result: string[] = [];

  // True when we have content between braces: used to ignore this content
  let insideBraces = false;

  content.forEach((item) => {
    if (item.includes("{{")) {
      insideBraces = true;
      const parts = item.split("{{");
      const usefulChunk = parts?.at(0);

      if (usefulChunk) {
        // if exists, add content before the opening braces
        result.push(usefulChunk);
      }
    } else if (item.includes("}}")) {
      insideBraces = false;
      const parts = item.split("}}");

      const usefulChunk = parts?.at(1);

      if (usefulChunk) {
        // if exists, add content after the closing braces
        result.push(usefulChunk);
      }
    } else if (!insideBraces) {
      // Add item if not inside braces
      result.push(item);
    }
  });

  return result;
}

export function getSourceId(sources: SourceProps[], id: string) {
  return sources.findIndex((src) => src.id === id);
}

export function addCitationsToReply(sources: SourceProps[], text: string) {
  // Regex to find citations IDs within {{[...]}}
  const sourcesIds = /\{\{\[([^\]]+)\]\}\}/g;

  const citedReply = text.replace(sourcesIds, (match, ids) => {
    // Split the comma-separated IDs into an array
    const idArray = ids
      .split(",")
      ?.map((id: string) => (id as string)?.trim()?.replace(/"/g, ""));

    // Filter the array to keep only those IDs that have a corresponding source
    const validIdArray = idArray.filter((id: string) => {
      // Find the corresponding source id
      const sourceId = getSourceId(sources, id);

      // Keep only IDs where source is found
      return sourceId !== -1;
    });

    // Create an array of span elements for valid IDs
    const citations = validIdArray.map((id: string) => `<span>${id}</span>`);

    // Join the span elements into a single string
    return citations.join(", ");
  });

  return citedReply;
}
