import {
  useNumberInput,
  HStack,
  Button,
  Input,
  FormLabel,
  FormControl,
} from "@chakra-ui/react";

interface MembersNumberProps {
  members: number;
  changeNumber: (value: number) => void;
}

export default function MembersNumberInput({
  members,
  changeNumber,
}: MembersNumberProps) {
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: members,
      min: 2,
      onChange: (valueAsString, valueAsNumber) => changeNumber(valueAsNumber),
    });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  return (
    <FormControl isRequired w="100%">
      <FormLabel
        color={"secondary.700"}
        fontSize={"12px"}
        fontWeight={"500"}
        fontFamily={"Poppins, sans-serif"}
        lineHeight={1}
        mb={1}
      >
        How many members?
      </FormLabel>
      <HStack maxW="160px">
        <Button {...dec}>-</Button>
        <Input
          {...input}
          w={"100%"}
          color={"gray.500"}
          fontSize={"14px"}
          placeholder={"How many members?"}
          _placeholder={{ fontSize: "14px", color: "gray.600" }}
          borderRadius={"6px"}
          bg={"background"}
          borderWidth={1}
          borderColor={"gray.200"}
          _hover={{ borderColor: "gray.300" }}
          _focusVisible={{ borderColor: "blue.300" }}
        />
        <Button {...inc}>+</Button>
      </HStack>
    </FormControl>
  );
}
