import { Box, Flex, Icon, Text, useTheme } from "@chakra-ui/react";
import { HashLink as Link } from "react-router-hash-link";

import { hexToRgba } from "utils/helpers";
import { IconType } from "react-icons";

interface MobileNavItemProps {
  to: string;
  title: string;
  icon: IconType;
  onClick: () => void;
  active: string;
}

export default function MobileNavItem({
  to,
  title,
  icon,
  onClick,
  active,
}: MobileNavItemProps) {
  // theme
  const { colors } = useTheme();

  return (
    <Box minW={"44px"}>
      <Link to={to} smooth>
        <Flex
          direction={"column"}
          justify={"center"}
          align={"center"}
          gap={0.5}
          onClick={onClick}
        >
          <Icon
            bg={"transparent"}
            p={2}
            borderRadius={"30%"}
            display="flex"
            boxSize={"34px"}
            transition={"all .3s ease"}
            _hover={{ bg: hexToRgba(colors.highlight.primary, 0.3) }}
            as={icon}
            color={active === to ? "highlight.primary" : "gray.700"}
          />
          <Text
            fontFamily={"Poppins, sans-serif"}
            fontSize={"10px"}
            letterSpacing={active ? "-0.012rem" : "0"}
            fontWeight={"500"}
            textAlign={"center"}
            color={active === to ? "highlight.primary" : "gray.800"}
            userSelect={"none"}
            transition={"all .3s ease"}
          >
            {title}
          </Text>
        </Flex>
      </Link>
    </Box>
  );
}
