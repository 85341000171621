import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import savedReducer from "./features/bookmarks/savedSlice";
import toolsReducer from "./features/tools/toolsSlice";
import guideReducer from "./features/guide/guideSlice";
import authReducer, { AuthState } from "./features/auth/authSlice";
import embeddingReducer, {
  EmbeddingState,
} from "./features/embedding/embeddingSlice";
import postsReducer, { PostsState } from "./features/posts/postsSlice";
import notificationsReducer, {
  NotificationsState,
} from "./features/notifications/notificationsSlice";

import { SavedStateProps } from "models/bookmarks/SavedProps";
import { GuideStateProps } from "models/guide";
import { ToolsStateProps } from "models/tools/ToolsProps";

export interface StoreState {
  auth: AuthState;
  saved: SavedStateProps;
  tools: ToolsStateProps;
  embedding: EmbeddingState;
  guide: GuideStateProps;
  posts: PostsState;
  notifications: NotificationsState;
}

export const store = configureStore({
  reducer: {
    auth: authReducer,
    saved: savedReducer,
    tools: toolsReducer,
    embedding: embeddingReducer,
    guide: guideReducer,
    posts: postsReducer,
    notifications: notificationsReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
});

setupListeners(store.dispatch);
