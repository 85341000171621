import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosClient } from "api/axios";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentAuthData,
  setAccessToken,
  setRefreshToken,
  setUser,
} from "redux/features/auth/authSlice";

import { useStripe } from "@stripe/react-stripe-js";
import { useStripeAPI } from "services/stripe.service";

import { Flex, Icon, Text, useToast } from "@chakra-ui/react";

import Loading from "components/ui/Loading";
import { ImInfo } from "react-icons/im";

export default function OAuth2View() {
  // State
  const { user } = useSelector(selectCurrentAuthData);

  // Stripe
  const stripe = useStripe();

  // APIs
  const { createCheckoutSession } = useStripeAPI();

  // Hooks
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useDispatch();

  useEffect(() => {
    const plan = localStorage.getItem("planFromHomePage");
    const planFromHomePage = plan ? JSON.parse(plan) : null;

    const CreateCheckoutSession = async () => {
      if (!stripe) {
        // Stripe.js has not yet loaded.
        // TODO: Handle errors
        return;
      }

      let session: any;
      const payload = {
        email: user?.email,
        username: `${user?.given_name}${user?.family_name}`,
        plan: planFromHomePage.plan,
        price: parseFloat(planFromHomePage.price),
        currency: planFromHomePage.currency,
        is_yearly: planFromHomePage.is_annual === "true" ? true : false,
        payment_mode: "subscription", // subscription => recurring, payment => one off payment
      };

      await createCheckoutSession(payload)
        .then(async (res) => {
          session = res;
        })
        .catch((error) => {
          console.log(error);
          toast({
            description: error.response.data.message
              ? error.response.data.message
              : "Error initializing checkout",
            status: "error",
            position: "top-right",
          });
        });

      if (!session) {
        // TODO: Handle errors
        return;
      }

      // Redirect to Checkout.
      const result = await stripe.redirectToCheckout({
        sessionId: session.sessionId,
      });

      if (result.error) {
        // Handle error here.
        console.error(result.error.message);
        toast({
          description: result.error.message
            ? result.error.message
            : "Error initializing checkout",
          status: "error",
          position: "top-right",
          duration: 6000,
        });
      }
    };

    const authenticateOAuth2 = async (code: string) => {
      const payload = {
        code,
        grant_type: "authorization_code",
      };
      try {
        const response = await axiosClient.post(
          "/api/oauth2",
          JSON.stringify(payload)
        );
        const { user, accessToken, refreshToken } = response?.data;
        dispatch(setUser(user));
        dispatch(setAccessToken(accessToken));
        dispatch(setRefreshToken(refreshToken));
      } catch (error: any) {
        const err_msg = error?.response?.data?.message;
        if (err_msg !== "INVALID_GRANT") {
          showToastMessage(err_msg, 6000);
          navigate("/login");
        }
      } finally {
        if (planFromHomePage && user) {
          if (planFromHomePage.plan.toLowerCase() === user.user_type) {
            navigate("/chat");
          } else {
            CreateCheckoutSession();
          }
          return;
        }
        navigate("/chat");
      }
    };

    const showToastMessage = (message: string, duration: number) => {
      toast({
        position: "bottom",
        duration: duration,
        render: () => (
          <Flex
            borderRadius={"6px"}
            borderWidth={1}
            borderColor={"red.500"}
            bg={"red.500"}
            color={"white"}
            p={3}
            align={"center"}
            gap={2}
          >
            <Icon as={ImInfo} boxSize={"18px"} />
            <Text
              fontFamily={"Poppins, sans-serif"}
              fontSize={{ lg: "13px", xl: "14px" }}
              lineHeight={"1.3"}
            >
              {message ? <>{message}</> : <>Sign in with Google failed</>}
            </Text>
          </Flex>
        ),
      });
    };

    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    const error_desc = searchParams.get("error_description");

    if (code) {
      authenticateOAuth2(code);
    } else {
      if (error_desc) {
        const errorMessage = error_desc;
        const errorIndex = errorMessage.indexOf("error");

        if (errorIndex !== -1) {
          let extractedError = errorMessage.substring(errorIndex + 6);
          if (
            extractedError.trim() === "User already exists with this email."
          ) {
            showToastMessage(
              `${extractedError} Sign in using username and password.`,
              10000
            );
          } else {
            showToastMessage("Sign in with Google failed!", 3000);
          }
        }

        if (error_desc.trim() === "User is not enabled") {
          showToastMessage(
            `Access revoked for this user. Contact admin for account activation`,
            10000
          );
        }
      } else {
        showToastMessage("Sign in with Google failed!", 3000);
      }
      navigate("/login");
    }
  }, [
    createCheckoutSession,
    dispatch,
    location.search,
    navigate,
    stripe,
    toast,
    user,
  ]);

  return (
    <Flex
      h={"100vh"}
      w={"full"}
      direction={"column"}
      align={"center"}
      justify={"center"}
      gap={"40px"}
      bg={"background"}
    >
      <Loading message={"Processing..."} />
    </Flex>
  );
}
