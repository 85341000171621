const hourMinuteFormat = (date: Date) => {
  return date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

export const formatDateTime = (date: Date) => {
  const now = new Date();
  const diff = now.getTime() - date.getTime();

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (minutes < 1) return "just now";
  if (minutes < 60) return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  if (hours < 24) return "Today " + hourMinuteFormat(date);
  if (days === 1) return "Yesterday " + hourMinuteFormat(date);
  if (days < 7)
    return (
      date.toLocaleDateString("en-US", { weekday: "long" }) +
      " " +
      hourMinuteFormat(date)
    );

  return (
    date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }) +
    " " +
    hourMinuteFormat(date)
  );
};
