import { Text, View } from "@react-pdf/renderer";
import { SectionHeader } from "./BookmarkToExport";
import { robot } from "assets/bookmarks/reports/icons";

import { SavedBotMessagesProps } from "models/bookmarks/SavedProps";
import { MessageProps } from "models/chat/MessageProps";

import { documentStyles, tableStyles } from "./helpers";

interface Props {
  botMessages: SavedBotMessagesProps[];
}

function BotMessagesToExport({ botMessages }: Props) {
  const messages: MessageProps[][] = botMessages?.map((item) => item?.messages);
  const flattened: MessageProps[] = messages?.flat();
  const questions = flattened?.map((m: MessageProps) => m?.human);

  return (
    <>
      {botMessages && botMessages?.length > 0 && (
        <View style={documentStyles.section}>
          {/* header */}
          <SectionHeader src={robot} name={"Assistant"} />

          {/* table */}
          <View style={tableStyles.container}>
            <View style={tableStyles.body}>
              {questions.map((question, index) => {
                return (
                  <View
                    key={`question-row-${index}`}
                    style={tableStyles.row}
                    wrap={false}
                  >
                    <Text
                      style={[
                        tableStyles.cell,
                        {
                          width: "100%",
                          lineHeight: 1.3,
                          fontSize: "11px",
                        },
                      ]}
                    >
                      {question}
                    </Text>
                  </View>
                );
              })}
            </View>
          </View>
        </View>
      )}
    </>
  );
}

export default BotMessagesToExport;
