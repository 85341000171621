import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Flex, Text } from "@chakra-ui/react";

import LogIn from "components/home/header/LogIn";
import Content from "components/home/header/Content";
import Promotion from "components/home/header/Promotion";
import MobileNav from "components/home/header/MobileNav";
import ResponsivenessInfo from "components/home/ResponsivenessInfo";

export default function MobileHeader() {
  // State
  const [showInfo, setShowInfo] = useState(false);

  // Hooks
  const navigate = useNavigate();

  return (
    <>
      <Box
        display={{ base: "block", lg: "none" }}
        minH={"min(100vh, 100%)"}
        w={"100%"}
        pb={0}
        zIndex={4}
        position={"relative"}
      >
        {/* Main Header Container */}
        <Flex
          w={"100%"}
          h={"100%"}
          direction={"column"}
          align={"center"}
          justify={"space-between"}
          gap={10}
          p={{ base: "20px", sm: "30px" }}
        >
          {/* logo + nav */}
          <Flex align={"center"} justify={"space-between"} w={"100%"} mb={6}>
            {/* logo */}
            <Text
              fontFamily={"monospace"}
              fontSize={{ base: "lg", md: "xl", lg: "2xl" }}
              color={"gray.800"}
              fontWeight={"500"}
              cursor={"pointer"}
              onClick={() => navigate("/")}
            >
              Lakesai
            </Text>

            {/* nav */}
            <Flex align="center" gap={6}>
              <MobileNav />
              <LogIn />
            </Flex>
          </Flex>

          <Content setShowInfo={setShowInfo} />

          <Promotion />

          {/* NOTE: to do not remove this comment */}
          {/* <Companies /> */}
        </Flex>
      </Box>

      {/* Responsiveness Info modal content */}
      <ResponsivenessInfo
        isOpen={showInfo}
        onClose={() => setShowInfo(false)}
      />
    </>
  );
}
