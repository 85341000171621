import { useEffect, useState } from "react";
import { Heading, Text, Flex, useTheme, Link } from "@chakra-ui/react";
import { GetGradients } from "utils/gradients";
import { hexToRgba } from "utils/helpers";
import ResendNewPassword from "./loginInputs/ResendNewPassword";
import ChangePassword from "./loginInputs/ChangePassword";
import Login from "./loginInputs/Login";
import { useLocation, useNavigate } from "react-router-dom";

export default function LoginForm() {
  // Theme
  const { colors } = useTheme();

  // Hook
  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from || null;
  if (from?.pathname === "/team/accept-invite") {
    // Cache invite link till user logs in or complete registration!
    localStorage.setItem("teamInviteLink", from.pathname + from.search);
  }

  // States
  const [changePassword, setChangePassword] = useState(false);
  const [expired, setExpired] = useState(false);
  const [resetPasswordSession, setResetPasswordSession] = useState<
    string | null
  >(null);

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  // Theme
  const { appTitleGradient } = GetGradients();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const planFromHomePage = searchParams.get("plan");
    const isPlanAnnual = searchParams.get("is_annual");
    const price = searchParams.get("price");
    const currency = searchParams.get("currency");

    if (planFromHomePage && isPlanAnnual && price && currency) {
      const planDetails = {
        plan: planFromHomePage,
        is_annual: isPlanAnnual,
        price,
        currency,
      };
      localStorage.setItem("planFromHomePage", JSON.stringify(planDetails));
    }
  }, [location.search]);

  return (
    <Flex
      direction="column"
      justify="center"
      align={{ base: "center", lg: "flex-start" }}
      gap={4}
      px={{ base: 4, md: 6, lg: 10 }}
      py={10}
      w={{ base: "100%", lg: "400px" }}
      minH={{ base: "100vh", lg: "unset" }}
      borderRadius={{ base: 0, lg: "10px" }}
      bg={hexToRgba(colors.white, 0.95)}
      boxShadow={{ base: "none", lg: "0 8px 32px 0 rgba( 31, 38, 80, 0.37 )" }}
      backdropFilter={"blur( 4.5px)"}
      borderWidth={1}
      borderColor={hexToRgba(colors.black, 0.18)}
      mx={{ base: "auto", lg: "0" }}
    >
      <Flex
        textAlign={{ base: "center", lg: "left" }}
        direction={"column"}
        gap={6}
        maxW={{ base: "380px", md: "410px" }}
      >
        <Flex direction={"column"} gap={2}>
          <Heading fontSize={{ base: "18px", md: "20px" }} color={"gray.600"}>
            Welcome to{" "}
            <Text
              as="span"
              fontWeight={"bold"}
              bgGradient={appTitleGradient}
              bgClip="text"
              cursor={"pointer"}
              onClick={() => navigate("/")}
            >
              Lakesai
            </Text>
          </Heading>

          <Text
            fontSize={{ base: "13px", md: "14px" }}
            color={"gray.600"}
            display={{ base: "inline-block", lg: "none" }}
            fontFamily={"Poppins, sans-serif"}
            mx={"auto"}
          >
            Your intelligent agent for accelerating scientific discoveries.
          </Text>
        </Flex>

        <Heading
          fontSize={{ base: "14px", md: "16px" }}
          color={"secondary.700"}
          mt={4}
        >
          Sign in to your account
        </Heading>
      </Flex>

      {changePassword ? (
        <ChangePassword
          username={formData.username}
          resetPasswordSession={resetPasswordSession}
          setResetPasswordSession={setResetPasswordSession}
        />
      ) : expired ? (
        <ResendNewPassword username={formData.username} />
      ) : (
        <Login
          setResetPasswordSession={setResetPasswordSession}
          setFormData={setFormData}
          formData={formData}
          setExpired={setExpired}
          setChangePassword={setChangePassword}
        />
      )}

      <Text mt={6} fontSize={"14px"}>
        Don't have an account?
        <Link
          pl={1}
          onClick={() => navigate("/register")}
          color={"primary.400"}
        >
          Sign up for free
        </Link>
      </Text>
    </Flex>
  );
}
