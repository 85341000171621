import { Button, Icon } from "@chakra-ui/react";
import useGuideTour from "hooks/guideTour/useGuideTour";
import { TbMessage2 } from "react-icons/tb";

interface FeedbackButtonProps {
  handleClick: () => void;
}

function FeedbackButton({ handleClick }: FeedbackButtonProps) {
  const { isHighlighted } = useGuideTour();
  let highlighted = isHighlighted("feedback");

  return (
    <Button
      id="feedback"
      zIndex={highlighted ? 1350 : 999}
      bg={"highlight.primary"}
      color={"background"}
      overflow={"hidden"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"flex-start"}
      h="40px"
      w="40px"
      py={"auto"}
      px={"10px"}
      borderRadius={"10px 10px 0 0"}
      alignSelf={"center"}
      fontWeight={"500"}
      fontSize={"14px"}
      letterSpacing={".05rem"}
      boxShadow="0px 10px 10px rgba(0, 0, 0, 0.1)"
      transition="all 0.3s ease-out"
      gap={2}
      leftIcon={<Icon as={TbMessage2} boxSize={"20px"} p={0} m={0} />}
      _hover={{ w: "125px", opacity: 0.8, bg: "highlight.primary" }}
      _focus={{ opacity: 1, bg: "highlight.primary" }}
      onClick={handleClick}
    >
      Feedback
    </Button>
  );
}

export default FeedbackButton;
