import { ReactNode } from "react";
import { Checkbox } from "@chakra-ui/react";

interface CheckboxStyledProps {
  children?: ReactNode;
  spacing?: string;
  rounded?: boolean;
  roundedFull?: boolean;
  large?: boolean;
  name?: string;
  key?: string | number;
  isChecked?: boolean;
  onChange?:
    | (() => void)
    | ((event: React.ChangeEvent<HTMLInputElement>) => void);
  isDisabled?: boolean;
  value?: string | number | undefined;
  forEmbeddings?: boolean;
  forTheme?: boolean;
}

const defaultClasses = ({
  iconSize = "9px",
  size = "14px",
  radius = "1px",
  controlRadius = "1px",
  px = "2px",
  py = "2px",
  forEmbeddings = false,
  forTheme = false,
}) => {
  const controlColor =
    forEmbeddings || forTheme ? "highlight.primary" : "primary.200";
  const focusColor =
    forEmbeddings || forTheme ? "highlight.primary" : "primary.400";

  return {
    minH: "inherit",
    px: px,
    py: py,
    w: "fit-content",
    h: "fit-content",

    _checked: {
      h: "fit-content",
      px: px,
      py: py,
      borderRadius: radius,
    },
    "span[class*='checkbox__control']": {
      borderRadius: controlRadius,
      borderColor: "gray.400",
      width: size,
      height: size,
      color: "gray.50",

      _disabled: {
        bg: controlColor,
        borderColor: controlColor,
        color: "white",
      },
      _focusVisible: {
        boxShadow: "none",
      },
      _focus: {
        boxShadow: "none",
      },
    },
    "span[class*='checkbox__control']:not([data-disabled])": {
      _checked: {
        bg: controlColor,
        borderColor: controlColor,
      },
      _after: {
        transitionProperty: "all",
        transitionDuration: "normal",
        content: `""`,
        position: "absolute",
        width: "0px",
        height: "0px",
        bg: `transparent`,
        borderRadius: radius,
      },
    },
    "span[class*='checkbox__control']  svg": {
      height: iconSize,
      width: iconSize,
    },
    "span[class*='checkbox__label']": {
      fontSize: [null, null, null, "12px", "12px", "14px"],
      color: "gray.500",
      _checked: {
        color: focusColor,
      },
    },
  };
};

export function CheckboxStyled({
  children,
  spacing = "10px",
  rounded,
  roundedFull,
  large,
  value,
  forEmbeddings = false,
  forTheme = false,
  name,
  ...props
}: CheckboxStyledProps) {
  let classes = defaultClasses({ forEmbeddings });

  if (roundedFull) {
    if (large) {
      classes = defaultClasses({
        radius: forEmbeddings ? "4px" : "99px",
        controlRadius: forEmbeddings ? "4px" : "99px",
        iconSize: forEmbeddings ? "10px" : "12px",
        size: forEmbeddings ? "14px" : "18px",
        px: "0",
        py: "0",
        forEmbeddings,
        forTheme,
      });
    } else {
      classes = defaultClasses({
        radius: forEmbeddings ? "4px" : "99px",
        controlRadius: forEmbeddings ? "4px" : "99px",
        forEmbeddings,
        forTheme,
      });
    }
  }

  if (rounded) {
    classes = defaultClasses({
      radius: "8px",
      controlRadius: "2px",
      forEmbeddings,
      forTheme,
    });
  }

  return (
    <Checkbox
      spacing={spacing}
      sx={classes}
      value={value}
      name={name}
      {...props}
    >
      {children}
    </Checkbox>
  );
}
