import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  Flex,
} from "@chakra-ui/react";

import CurrentPlan from "./CurrentPlan";
import Appearance from "./Appearance";
import UserAvatar from "components/ui/UserAvatar";

import SecuritySection from "./SecuritySection";
import GeneralSection from "./GeneralSection";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { useSelector } from "react-redux";

interface AccountSettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
  themeOnToggle: () => void;
}

export default function AccountSettingsModal({
  isOpen,
  onClose,
  themeOnToggle,
}: AccountSettingsModalProps) {
  const { user } = useSelector(selectCurrentAuthData);
  const isThirdPartyUser = user?.hasOwnProperty("identities");

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent
        bg={"background"}
        alignSelf={"center"}
        overflowY={"auto"}
        minW={{ base: "90%", lg: "750px" }}
        maxW={{ base: "90%", lg: "750px" }}
        h={{
          lg: "calc(100vh - 16px)",
          xl: "calc(100vh - 24px)",
          "2xl": "calc(100vh - 32px)",
        }}
        my={"auto"}
      >
        <ModalHeader
          display={"flex"}
          alignItems={"center"}
          color={"gray.700"}
          fontFamily={"Poppins, sans-serif"}
          justifyContent={"space-between"}
          borderBottomWidth={1}
          borderBottomColor={"gray.200"}
        >
          {/* header */}
          <Text fontSize={"16px"} fontWeight={"500"}>
            Account Settings
          </Text>

          {/* avatar & name */}
          <UserAvatar small />
        </ModalHeader>

        <ModalBody px={8} py={6} width={"100%"}>
          <Flex gap={10} direction={"column"} color={"secondary.700"}>
            {/* General section */}
            <GeneralSection />

            {/* Security section */}
            {!isThirdPartyUser && <SecuritySection />}

            {/* Subscription Plan section */}
            <CurrentPlan />

            {/* Appearance section - Theme */}
            <Appearance onToggle={themeOnToggle} />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
