import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Stack,
  Text,
  Icon,
  Divider,
  useTheme,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteNotification,
  selectCurrentNotificationsData,
} from "redux/features/notifications/notificationsSlice";
import { format, isToday, isYesterday, isSameYear } from "date-fns";
import {
  IoIosCheckmarkCircleOutline,
  IoMdInformationCircleOutline,
} from "react-icons/io";
import { CiWarning } from "react-icons/ci";
import { VscError } from "react-icons/vsc";
import { IoCloseCircleOutline } from "react-icons/io5";
import Loading from "./Loading";
import { NotificationProps } from "models/notifications/NotificationProps";
import { useDeleteNotificationMutation } from "api/notifications/useDeleteNotificationMutation";
import { hexToRgba } from "utils/helpers";
import { truncateString } from "./helpers";

export interface NotificationStyle {
  lightColor: string;
  darkColor: string;
  icon: React.ComponentType;
}

const NotificationsList = () => {
  const { notifications, isLoading, error } = useSelector(
    selectCurrentNotificationsData
  );
  const [visibleCount, setVisibleCount] = useState(5);

  const hasMoreNotifications = visibleCount < notifications.length;

  const loadMoreNotifications = () => {
    setVisibleCount((prevCount) => prevCount + 5);
  };

  const formatDate = (date: Date) => {
    const now = new Date();
    if (isToday(date)) {
      return `Today, ${format(date, "HH:mm")}`;
    } else if (isYesterday(date)) {
      return `Yesterday, ${format(date, "HH:mm")}`;
    } else if (isSameYear(date, now)) {
      return format(date, "MMMM dd, HH:mm");
    } else {
      return format(date, "MMM dd, yyyy HH:mm");
    }
  };

  const getNotificationProps = (type: string) => {
    switch (type) {
      case "info":
        return {
          lightColor: "blue.300",
          darkColor: "blue.500",
          icon: IoMdInformationCircleOutline,
        };
      case "success":
        return {
          lightColor: "green.300",
          darkColor: "green.500",
          icon: IoIosCheckmarkCircleOutline,
        };
      case "error":
        return {
          lightColor: "red.300",
          darkColor: "red.500",
          icon: VscError,
        };
      case "warning":
        return {
          lightColor: "orange.300",
          darkColor: "orange.500",
          icon: CiWarning,
        };
      default:
        return {
          lightColor: "blue.300",
          darkColor: "blue.500",
          icon: IoMdInformationCircleOutline,
        };
    }
  };

  return (
    <Box px={1}>
      <Text fontSize="lg" fontWeight="bold">
        Notifications
      </Text>
      <Divider borderColor="gray.200" borderWidth="1px" my={3} />
      {isLoading ? (
        <Loading message="Loading" small={true} />
      ) : error ? (
        <Text
          textAlign={"center"}
          fontStyle={"italic"}
          color={"red.400"}
          fontSize={"sm"}
        >
          Error loading notifications
        </Text>
      ) : notifications.length === 0 ? (
        <Text
          textAlign={"center"}
          fontStyle={"italic"}
          color={"gray.500"}
          fontSize={"sm"}
        >
          no notifications
        </Text>
      ) : (
        <>
          <NotificationContainer
            notifications={notifications}
            visibleCount={visibleCount}
            getNotificationProps={getNotificationProps}
            formatDate={formatDate}
          />
          {hasMoreNotifications && (
            <Flex mt={2} align={"center"} justify={"center"}>
              <Button
                py={1}
                onClick={loadMoreNotifications}
                size="xs"
                colorScheme="blue"
                variant="outline"
              >
                show more
              </Button>
            </Flex>
          )}
        </>
      )}
    </Box>
  );
};

export default NotificationsList;

interface NotificationContainerProps {
  notifications: NotificationProps[];
  visibleCount: number;
  getNotificationProps: (type: string) => NotificationStyle;
  formatDate: (date: Date) => string;
}

const NotificationContainer: React.FC<NotificationContainerProps> = ({
  notifications,
  visibleCount,
  getNotificationProps,
  formatDate,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [showTopShadow, setShowTopShadow] = useState(false);
  const [showBottomShadow, setShowBottomShadow] = useState(true);

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      setShowTopShadow(scrollTop > 0);
      setShowBottomShadow(scrollTop + clientHeight < scrollHeight - 1);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      handleScroll();
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, []);

  useEffect(() => {
    handleScroll();
  }, [visibleCount]);

  return (
    <Box position="relative" borderRadius="xl" overflow="hidden">
      <Stack
        ref={containerRef}
        spacing={2}
        maxHeight="350px"
        overflowY="auto"
        position="relative"
        className="custom-scrollbar"
        sx={{
          "&.custom-scrollbar::-webkit-scrollbar": {
            width: "8px",
          },
          "&.custom-scrollbar::-webkit-scrollbar-track": {
            background: "transparent",
          },
          "&.custom-scrollbar::-webkit-scrollbar-thumb": {
            background: "#CBD5E0",
            borderRadius: "4px",
          },
        }}
      >
        {notifications.slice(0, visibleCount).map((notification) => {
          const { lightColor, darkColor, icon } = getNotificationProps(
            notification.type
          );
          const mainColor = notification.is_read ? lightColor : darkColor;

          return (
            <NotificationItem
              key={notification.id}
              notification={notification}
              mainColor={mainColor}
              icon={icon}
              formatDate={formatDate}
            />
          );
        })}
      </Stack>

      <ShadowOverlay
        overlayPosition="top"
        opacity={showTopShadow ? 1 : 0}
        transition="opacity 0.3s ease-in-out"
      />
      <ShadowOverlay
        overlayPosition="bottom"
        opacity={showBottomShadow ? 1 : 0}
        transition="opacity 0.3s ease-in-out"
      />
    </Box>
  );
};

interface NotificationItemProps {
  notification: NotificationProps;
  mainColor: string;
  icon: React.ComponentType;
  formatDate: (date: Date) => string;
}

const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
  mainColor,
  icon,
  formatDate,
}) => {
  // Theme
  const { colors } = useTheme();

  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const isLight = colorMode === "light";
  const itemBg = isLight
    ? "background"
    : hexToRgba(colors.secondary[100], 0.15);

  const dispatch = useDispatch();
  const deleteNotificationMutation = useDeleteNotificationMutation();

  const title = truncateString(
    notification.title || notification.type.toUpperCase(),
    18
  );

  const handleNotificationRemove = (id: string) => {
    dispatch(deleteNotification(id));
    deleteNotificationMutation.mutate(id);
  };

  return (
    <Box
      p={2}
      // bg={notification.is_read ? "white" : "gray.100"}
      bg={notification.is_read ? itemBg : "lightBackground"}
      borderRadius="md"
      borderLeft="4px solid"
      borderColor={mainColor}
      boxShadow="0 1px 3px rgba(0,0,0,0.1)"
      transition="all 0.2s ease"
      _hover={{
        transform: "translateY(-1px)",
        boxShadow: "0 2px 4px rgba(0,0,0,0.15)",
        bg: notification.is_read
          ? hexToRgba(colors.secondary[100], 0.15)
          : "lightBackground",
      }}
    >
      <Flex justify="space-between" align="center" mb={2}>
        <Flex align="center" gap={1}>
          {/* <Icon as={icon} color={mainColor} boxSize={4} /> */}
          <Text
            fontSize="10px"
            color={mainColor}
            fontWeight={!notification.is_read ? "semibold" : "normal"}
            letterSpacing="tight"
          >
            {title}
          </Text>
        </Flex>
        <Flex align="center" gap={1}>
          <Text
            fontSize="10px"
            fontWeight={!notification.is_read ? "semibold" : "normal"}
            color={!notification.is_read ? "gray.600" : "gray.500"}
            fontStyle="italic"
            cursor="default"
          >
            {formatDate(new Date(notification.created_at))}
          </Text>
          <Icon
            as={IoCloseCircleOutline}
            boxSize={4}
            color="gray.400"
            cursor="pointer"
            title="remove notification"
            _hover={{ color: "red.400" }}
            transition="all 0.3s ease-in-out"
            onClick={() => handleNotificationRemove(notification.id)}
          />
        </Flex>
      </Flex>
      <Text
        fontSize="xs"
        fontWeight={!notification.is_read ? "semibold" : "normal"}
        color={!notification.is_read ? "gray.700" : "gray.500"}
        cursor={"default"}
      >
        {notification.message}
      </Text>
    </Box>
  );
};

interface ShadowOverlayProps {
  overlayPosition: "top" | "bottom";
  opacity: number;
  transition: string;
}

const ShadowOverlay: React.FC<ShadowOverlayProps> = ({
  overlayPosition,
  opacity,
  transition,
}) => {
  // Theme
  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const isLight = colorMode === "light";
  const shadowColor = isLight
    ? "rgba(255,255,255,0.9), rgba(255,255,255,0)"
    : "rgba(50,50,50,0.9), rgba(50,50,50,0)";

  return (
    <Box
      position="absolute"
      left={0}
      right={0}
      height="20px"
      pointerEvents="none"
      bgGradient={`linear(to-${
        overlayPosition === "top" ? "b" : "t"
      }, ${shadowColor})`}
      {...(overlayPosition === "top" ? { top: 0 } : { bottom: 0 })}
      zIndex={2}
      opacity={opacity}
      transition={transition}
    />
  );
};
