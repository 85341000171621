import { ChangeEvent, useRef, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Icon,
  Text,
  Input,
  FormControl,
  InputGroup,
  useToast,
  Box,
  RadioGroup,
  Stack,
  Radio,
} from "@chakra-ui/react";

import { errorHandler } from "utils/helpers";
import { useTeamsAPI } from "api/useTeamsAPI";
import { BsPeopleFill, BsPersonPlus } from "react-icons/bs";

interface NewTeamModalProps {
  isOpen: boolean;
  isInvite: boolean;
  onClose: () => void;
}

export default function NewTeamModal({
  isOpen,
  isInvite,
  onClose,
}: NewTeamModalProps) {
  // Ref
  const inputRef = useRef<HTMLInputElement>(null);
  // States
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(""); // Team member email
  const [name, setName] = useState(""); // Team name
  const [permission, setPermission] = useState("read");

  // Hooks
  const toast = useToast();
  const queryClient = useQueryClient();
  // APIS
  const { createTeam, inviteMember } = useTeamsAPI();

  // Handlers
  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const createTeamMutation = useMutation({
    mutationFn: createTeam,
    onSuccess: () => {
      toast({
        position: "top-right",
        duration: 3000,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={"highlight.primary"}
            borderRadius={"6px"}
          >
            Team created successfully
          </Box>
        ),
      });
      setLoading(false);
      onClose();
      queryClient.invalidateQueries({ queryKey: ["team"] });
    },
    onError: (error) => {
      toast({
        description: errorHandler(error).message,
        status: "error",
        position: "top-right",
      });
      setLoading(false);
    },
  });

  const inviteMemberMutation = useMutation({
    mutationFn: inviteMember,
    onSuccess: () => {
      toast({
        position: "top-right",
        duration: 3000,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={"highlight.primary"}
            borderRadius={"6px"}
          >
            Team member invited successfully
          </Box>
        ),
      });
      setLoading(false);
      onClose();
      queryClient.invalidateQueries({ queryKey: ["team"] });
    },
    onError: (error) => {
      toast({
        description: errorHandler(error).message,
        status: "error",
        position: "top-right",
      });
      setLoading(false);
    },
  });

  const handleSubmit = async () => {
    if (isInvite && !email) return;
    if (!isInvite && !name) return;

    setLoading(true);

    if (isInvite) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!re.test(email)) {
        toast({
          description: "Please provide a valid email",
          status: "error",
          position: "bottom",
        });
        setLoading(false);
        return;
      }
      inviteMemberMutation.mutate({ email, permission });
    } else {
      createTeamMutation.mutate({ name });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent
        alignSelf={"center"}
        p={1}
        w={"fit-content"}
        minW={"420px"}
        minH={"164px"}
        borderRadius={"6px"}
        bg={"background"}
      >
        <ModalHeader display={"flex"} alignItems={"center"} gap={2}>
          <Icon
            as={isInvite ? BsPersonPlus : BsPeopleFill}
            bg={"highlight.primary"}
            color={"gray.50"}
            boxSize={"28px"}
            borderRadius={"6px"}
            p={"3px"}
          />
          <Text fontSize={"16px"} color={"gray.600"} fontWeight={"500"}>
            {isInvite ? <>Add New Member</> : <>Create a Team</>}
          </Text>
        </ModalHeader>
        <ModalBody>
          {isInvite ? (
            <>
              <FormControl id="email">
                <InputGroup>
                  <Input
                    variant="outline"
                    value={email}
                    onChange={handleEmailChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Enter member email"
                    _placeholder={{ fontSize: "14px", color: "gray.400" }}
                    ref={inputRef}
                    bg={"transparent"}
                    _focus={{
                      outline: "none",
                      borderWidth: 1,
                      boxShadow: "md",
                      overflow: "auto",
                    }}
                    _focusVisible={{ outline: "none" }}
                  />
                </InputGroup>
              </FormControl>
              <FormControl id="permission">
                <Text color={"gray.600"} mt={2}>
                  Please select the user's folder permission:
                </Text>
                <RadioGroup onChange={setPermission} value={permission}>
                  <Stack direction="row">
                    <Radio
                      colorScheme="secondary"
                      value="read"
                      color={"gray.600"}
                    >
                      <Text color="gray.600">Read</Text>
                    </Radio>
                    <Radio
                      colorScheme="secondary"
                      value="write"
                      color={"gray.600"}
                    >
                      <Text color="gray.600">Write</Text>
                    </Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
            </>
          ) : (
            <>
              <FormControl id="name">
                <InputGroup>
                  <Input
                    variant="outline"
                    value={name}
                    onChange={handleNameChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Enter team name"
                    _placeholder={{ fontSize: "14px", color: "gray.400" }}
                    ref={inputRef}
                    bg={"transparent"}
                    _focus={{
                      outline: "none",
                      borderWidth: 1,
                      boxShadow: "md",
                      overflow: "auto",
                    }}
                    _focusVisible={{ outline: "none" }}
                  />
                </InputGroup>
              </FormControl>
            </>
          )}
        </ModalBody>

        <ModalFooter gap={2}>
          <Button
            isDisabled={false}
            bg={"gray.100"}
            color={"gray.500"}
            _hover={{ bg: "gray.200" }}
            borderRadius={"100px"}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            isDisabled={false}
            minW={"140px"}
            bg={"highlight.primary"}
            color={"gray.50"}
            border={"none"}
            _hover={{ opacity: 0.9 }}
            isLoading={loading ?? false}
            onClick={handleSubmit}
            pointerEvents={loading ? "none" : "auto"}
            loadingText={isInvite ? "Inviting..." : "Creating..."}
            borderRadius={"100px"}
          >
            {isInvite ? <>Invite</> : <>Create</>}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
