import { useToast } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useNotificationsAPI } from "api/notifications/useNotificationsAPI";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import { addNotification, setError, setNotifications, setNotificationsLoading } from "redux/features/notifications/notificationsSlice";


const useNotificationsWS = () => {
  const reconnectInterval = useRef<NodeJS.Timeout | null>(null);
  const notificationsWebSocket = useRef<WebSocket | null>(null);
  const [socketConnected, setSocketConnected] = useState(false);

  const dispatch = useDispatch();
  const toast = useToast();
  const { getNotifications } = useNotificationsAPI()
  const { user } = useSelector(selectCurrentAuthData);


  const { data, isLoading, error } = useQuery({
    queryKey: ["notifications"],
    queryFn: getNotifications,
    retry: false,
    enabled: !!user,
  });

  dispatch(setNotificationsLoading(isLoading))

  const handleConnection = () => {
    if (!user) return;

    const HOST_NAME = window.location.hostname;
    const HOST = !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "ws://127.0.0.1:8080"
      : HOST_NAME.trim() === "lakesai.vitafluence.com"
        ? "wss://mdpp.vitafluence.com"
        : "wss://mdps.vitafluence.com"

    const WS_URL = `${HOST}/ws/notifications/${user?.id}/`;

    notificationsWebSocket.current = new WebSocket(WS_URL);

    notificationsWebSocket.current.onopen = () => {
      setSocketConnected(true);
      if (reconnectInterval.current) {
        clearInterval(reconnectInterval.current);
        reconnectInterval.current = null;
      }
    };

    notificationsWebSocket.current.onmessage = (event: MessageEvent) => {
      const eventData = JSON.parse(event.data);
      const { notification } = eventData
      const { type, message, title } = notification
      dispatch(addNotification(notification));
      toast({
        title: `${type.charAt(0).toUpperCase()}${type.slice(1)}: ${title}`,
        description: message,
        status: type,
        duration: 5000,
        isClosable: true,
        position: "top-right"
      });
    };


    notificationsWebSocket.current.onerror = (error) => {
      console.error('___  Notifications WebSocket Error ___:', error);
      notificationsWebSocket.current?.close();
      attemptReconnect();
    };

    notificationsWebSocket.current.onclose = () => {
      setSocketConnected(false);
      if (reconnectInterval.current) {
        clearInterval(reconnectInterval.current);
      }
    };
  };

  const attemptReconnect = () => {
    if (!reconnectInterval.current) {
      reconnectInterval.current = setInterval(() => {
        handleConnection();
      }, 5000); // Attempt to reconnect every 5 seconds
    }
  };

  useEffect(() => {
    if (socketConnected === false && user) {
      handleConnection();
    }
  }, [user, socketConnected]);

  useEffect(() => {
    if (data) dispatch(setNotifications(data));
    if (error) dispatch(setError(error.message))
  }, [data, error])

  return notificationsWebSocket;
};

export default useNotificationsWS;