import { Icon } from "@chakra-ui/react";
import { FaHouse } from "react-icons/fa6";
import { IoLayers } from "react-icons/io5";

interface BreadcrumbHomeProps {
  inCore?: boolean;
}

export default function BreadcrumbHome({ inCore }: BreadcrumbHomeProps) {
  return (
    <Icon
      as={inCore ? IoLayers : FaHouse}
      boxSize={"18px"}
      color={"primary.200"}
    />
  );
}
