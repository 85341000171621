import { Box, Flex } from "@chakra-ui/react";
import { keyframes } from "@emotion/react";

const spinAnimation = keyframes`
  0%, 80%, 100% { opacity: 0; }
  40% { opacity: 1; }
`;

export default function ThreeDotSpinner() {
  // Theme
  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const isLight = colorMode === "light";

  return (
    <Flex justify="center" align="center" gap={1.5} mb={9}>
      <Box
        w="8px"
        h="8px"
        bg={isLight ? "blue.500" : "white"}
        borderRadius="full"
        sx={{
          animation: `${spinAnimation} 1.4s infinite ease-in-out`,
          animationDelay: "0s",
        }}
      />
      <Box
        w="8px"
        h="8px"
        bg={isLight ? "blue.500" : "white"}
        borderRadius="full"
        sx={{
          animation: `${spinAnimation} 1.4s infinite ease-in-out`,
          animationDelay: "0.2s",
        }}
      />
      <Box
        w="8px"
        h="8px"
        bg={isLight ? "blue.500" : "white"}
        borderRadius="full"
        sx={{
          animation: `${spinAnimation} 1.4s infinite ease-in-out`,
          animationDelay: "0.4s",
        }}
      />
    </Flex>
  );
}
