import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { useStripe } from "@stripe/react-stripe-js";
import { useStripeAPI } from "services/stripe.service";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";
import {
  Text,
  List,
  ListItem,
  ListIcon,
  Heading,
  Flex,
  Button,
  useToast,
} from "@chakra-ui/react";

import ChangePlanModal from "components/home/ChangePlanModal";
import VisitorEntrepriseRequest from "components/home/pricing/EntrepriseRequest";

import { FaCheck } from "react-icons/fa6";
import { FiMinusCircle } from "react-icons/fi";
import { LiaTimesSolid } from "react-icons/lia";
import { formatNumber } from "utils/helpers";
import ThreeDotSpinner from "components/ui/ThreeSpinnerDots";

export interface PlansProps {
  data: any;
  currency: string;
  currencySymbol: string;
  isAnnual: boolean;
  isLoading: boolean;
}

// TODO: refactoring required
function PlanOffers({
  data,
  isAnnual,
  isLoading,
  currency,
  currencySymbol,
}: PlansProps) {
  // Hooks
  const { user } = useSelector(selectCurrentAuthData);
  const navigate = useNavigate();
  const toast = useToast();

  // Stripe
  const stripe = useStripe();

  // States
  const [isProcessing, setIsProcessing] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // APIs
  const { createCheckoutSession } = useStripeAPI();

  // Theme
  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  const isLight = colorMode === "light";

  // Handlers
  function isBasicOrPremium(data: string) {
    if (!data) return;
    return data?.toLowerCase() === "basic" || data?.toLowerCase() === "premium";
  }

  const handleCheckout = async (plan: string, price: number) => {
    setIsProcessing(false);

    if (!user) {
      if (plan?.toLowerCase() === "free") {
        navigate("/login");
        return;
      } else if (plan?.toLowerCase() === "enterprise") {
        setShowModal(true);
        return;
      } else {
        // Basic & Premium plans
        navigate(
          `/login?plan=${plan}&price=${price}&currency=${currency}&is_annual=${isAnnual}`
        );
        return;
      }
    }

    if (!stripe) {
      // Stripe.js has not yet loaded.
      // TODO: Handle errors
      return;
    }

    setIsProcessing(true);
    if (
      plan?.toLowerCase() === "enterprise" ||
      plan?.toLowerCase() === "free"
    ) {
      setShowModal(true);
      setIsProcessing(false);
      return;
    }

    if (isBasicOrPremium(plan)) {
      let session: any;
      const payload = {
        email: user?.email,
        username: `${user?.given_name}${user?.family_name}`,
        plan,
        price,
        currency,
        is_yearly: isAnnual,
        payment_mode: "subscription", // subscription => recurring, payment => one off payment
      };

      await createCheckoutSession(payload)
        .then(async (res) => {
          session = res;
          setIsProcessing(false);
        })
        .catch((error) => {
          console.log(error);
          toast({
            description: error?.response?.data?.message
              ? error?.response?.data?.message
              : "Error initializing checkout",
            status: "error",
            position: "top-right",
          });
          setIsProcessing(false);
        });

      if (!session) {
        // TODO: Handle errors
        return;
      }

      // Redirect to Checkout.
      const result = await stripe.redirectToCheckout({
        sessionId: session.sessionId,
      });

      if (result.error) {
        // Handle error here.
        toast({
          description: result?.error?.message
            ? result?.error?.message
            : "Error initializing checkout",
          status: "error",
          position: "top-right",
          duration: 6000,
        });
      }
      return;
    }
  };

  const realPrice = isAnnual
    ? parseFloat(data?.plan_price) * 10
    : parseFloat(data?.plan_price);

  const promoPrice = isAnnual
    ? parseFloat(data?.promotion_price) * 10
    : parseFloat(data?.promotion_price);

  const actionButtonBg =
    data?.plan_name?.toLowerCase() === "premium"
      ? "highlight.primary"
      : isLight
      ? "#1D2440"
      : "secondary.400";

  const actionButtonText =
    data?.plan_name?.toLowerCase() === "enterprise"
      ? "Contact Us"
      : "Get Started";

  const isSelected = useMemo(() => {
    const planName = data?.plan_name?.toLowerCase();

    const userType = user?.user_type;
    let targetPlan = userType;

    if (userType === "teams") {
      targetPlan = "enterprise";
    }

    return planName === targetPlan;
  }, [data, user]);

  return (
    <>
      <Flex
        minH={"500px"}
        direction={"column"}
        px={3}
        gap={3}
        py={10}
        borderRadius={"6px"}
        boxShadow={`rgba(29,36,64,0.2) 0px 1px 4px`}
        borderWidth={isLight ? 0 : 1}
        borderColor={isLight ? "transparent" : "blue.100"}
        position={"relative"}
        bg={"background"}
      >
        {/* ribbon */}
        {data?.plan_name?.toLowerCase() === "premium" && (
          <Flex
            position={"absolute"}
            w={"fit-content"}
            h={"fit-content"}
            bg={"#FDAC01"}
            color={isLight ? "white" : "dark.400"}
            top={0}
            left={"50%"}
            transform={"translateX(-50%)"}
            mx={"auto"}
            borderRadius={"0 0 5px 5px"}
            fontSize={"10px"}
            letterSpacing={"0.05rem"}
            lineHeight={1}
            fontWeight={"500"}
            px={3}
            py={2}
            textTransform={"uppercase"}
          >
            most popular
          </Flex>
        )}

        {/* type & description */}
        <Flex direction={"column"} align={"center"} gap={3}>
          {/* offer type */}
          <Heading
            size="lg"
            fontWeight={600}
            color={"secondary.800"}
            textAlign={"center"}
          >
            {data?.plan_name}
          </Heading>

          {/* offer description */}
          <Text
            fontSize={"14px"}
            lineHeight={"1.45"}
            textAlign={"center"}
            fontFamily={"Poppins, sans-serif"}
            opacity={0.9}
            maxW={"100%"}
            minH={{
              base: "fit-content",
              md: "61px",
              lg: "fit-content",
              xl: "61px",
              "2xl": "fit-content",
            }}
          >
            {data?.description}
          </Text>
        </Flex>

        {/* price details */}
        <Flex
          direction={"column"}
          align={"center"}
          gap={2}
          mt={3}
          w={"fit-content"}
          mx={"auto"}
        >
          {data?.plan_name === "Enterprise" ? (
            <Heading
              size="xl"
              fontWeight={700}
              color={isLight ? "secondary.800" : "white"}
              textAlign={"center"}
            >
              Let's Talk
            </Heading>
          ) : (
            // price details
            <Heading
              size="xl"
              fontWeight={700}
              color={isLight ? "secondary.800" : "white"}
              textAlign={"center"}
            >
              {isLoading ? (
                <ThreeDotSpinner />
              ) : (
                <Flex gap={1.5}>
                  <Flex gap={1.5}>
                    <Text as={"span"} fontSize={"22px"}>
                      {currencySymbol}
                    </Text>
                    <>{formatNumber(promoPrice)}</>
                  </Flex>

                  {isBasicOrPremium(data?.plan_name) && currency !== "CHF" && (
                    <Text
                      w={"fit-content"}
                      fontSize={{ base: "14px", xl: "16px" }}
                      fontWeight="500"
                      color={"gray.500"}
                      textDecoration="line-through"
                      alignSelf={"flex-end"}
                      mb={"6px"}
                    >
                      {currencySymbol} {formatNumber(realPrice)}
                    </Text>
                  )}
                </Flex>
              )}
            </Heading>
          )}

          <Text
            fontSize={"14px"}
            opacity={0.9}
            lineHeight={1}
            fontFamily={"Poppins, sans-serif"}
            alignSelf={"center"}
          >
            {data?.plan_name === "Enterprise" ? (
              <>Talk to us about your requirements.</>
            ) : (
              <>per {isAnnual ? "year" : "month"}</>
            )}
          </Text>
        </Flex>

        {/* action button */}
        <Button
          h={"fit-content"}
          w={"100%"}
          mx={"auto"}
          py={3}
          bg={actionButtonBg}
          color={"#fff"}
          mt={1}
          opacity={isSelected ? 0.7 : 1}
          cursor={isSelected ? "not-allowed" : "pointer"}
          isDisabled={isSelected}
          transition={"all ease .3s"}
          _hover={{ opacity: "0.8" }}
          _active={{ opacity: "0.8" }}
          isLoading={isProcessing}
          loadingText="Processing"
          onClick={() => handleCheckout(data?.plan_name, data?.plan_price)}
        >
          {isSelected ? `Current Plan` : actionButtonText}
        </Button>

        {/* plan details */}
        <Flex direction={"column"} gap={3} px={2} mx={"auto"} mt={1} w={"100%"}>
          {data?.offers?.map((offer: any, indx: number) => (
            <Flex
              direction={"column"}
              gap={2}
              key={`${data?.plan_name}-${indx}`}
            >
              <Text
                fontSize={{ lg: "13px", xl: "14px" }}
                fontWeight={500}
                lineHeight={"1.2"}
                fontFamily={"Poppins, sans-serif"}
              >
                {offer.offer_name}
              </Text>

              <List spacing={1.5}>
                {offer.offers?.map((offr: any, idex: number) => (
                  <ListItem
                    key={idex}
                    display={"flex"}
                    alignItems={"flex-start"}
                    gap={1}
                    fontSize={"14px"}
                  >
                    <ListIcon
                      mt={0.5}
                      as={
                        offr.type === "unlimited"
                          ? FaCheck
                          : offr.type === "limited"
                          ? FiMinusCircle
                          : LiaTimesSolid
                      }
                      color={
                        offr.type === "unlimited"
                          ? "highlight.primary"
                          : "gray.500"
                      }
                      boxSize={"14px"}
                    />
                    <Text lineHeight={"1.3"}>{offr.offer}</Text>
                  </ListItem>
                ))}
              </List>
            </Flex>
          ))}
        </Flex>
      </Flex>

      {/* Confirm Cancel Plan modal */}
      {user && (
        <ChangePlanModal
          isOpen={showModal}
          onClose={() => {
            setShowModal(false);
          }}
          newPlan={data?.plan_name}
          isAnnual={isAnnual}
        />
      )}

      {/* Send Entreprise Plan request as new visitor */}
      {!user && (
        <VisitorEntrepriseRequest
          isOpen={showModal}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
}

export default PlanOffers;
