import { Flex, Icon, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { PiSealWarning } from "react-icons/pi";
import RateLimitModal from "./RateLimitModal";

interface MainPanelErrorProps {
  errorMessage?: string;
}

export default function MainPanelError({
  errorMessage = "Something went wrong. Please try again later.",
}: MainPanelErrorProps) {
  const [showRateModal, setShowRateModal] = useState(false);

  useEffect(() => {
    if (errorMessage === "Rate limit exceeded") {
      setShowRateModal(true);
    }
  }, [errorMessage]);

  return (
    <>
      <Flex
        h={"100%"}
        w={"100%"}
        overflow={"hidden"}
        align={"center"}
        justify={"center"}
        textAlign={"center"}
        color={"orange.400"}
        p={3}
        wrap={"wrap"}
        gap={3}
        direction={"column"}
      >
        <Icon as={PiSealWarning} boxSize={"40px"} />
        <Text fontWeight={"500"} fontSize={"14px"}>
          {errorMessage}
        </Text>
      </Flex>

      {/* Rate limit modal */}
      <RateLimitModal
        isOpen={showRateModal}
        onClose={() => {
          window.localStorage.setItem(
            "initialRateLimitSeen",
            JSON.stringify(true)
          );
          setShowRateModal(false);
        }}
      />
    </>
  );
}
