import { ItemProps } from "layouts/nav/Item";
import { useMemo } from "react";

import {
  BsChatRightQuote,
  BsGridFill,
  BsGrid,
  BsChatRightQuoteFill,
  BsPeople,
  BsPeopleFill,
} from "react-icons/bs";
import { LuPenSquare } from "react-icons/lu";

function useNavigationTabs() {
  const navItems: ItemProps[] = useMemo(
    () => [
      {
        id: "team",
        name: "Team",
        icon: BsPeople,
        activeIcon: BsPeopleFill,
        link: "/team",
        tooltip: "team",
        counter: true,
      },
      {
        id: "data",
        name: "Library",
        icon: BsGrid,
        activeIcon: BsGridFill,
        link: "/data",
        tooltip: "data",
      },
      {
        id: "assistant",
        name: "Assistant",
        icon: BsChatRightQuote,
        activeIcon: BsChatRightQuoteFill,
        link: "/chat",
        tooltip: "assistant",
        rightIcon: LuPenSquare,
      },
    ],
    []
  );

  return { navItems };
}

export default useNavigationTabs;
