import { useState } from "react";
import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useStripeAPI } from "services/stripe.service";
import { selectCurrentAuthData } from "redux/features/auth/authSlice";

import { FaUsers } from "react-icons/fa";
import { MdMoveUp } from "react-icons/md";
import RequestForm from "components/team/requestForm/RequestForm";
import SuccessToast from "./pricing/SuccessToast";

interface ChangePlanModalProps {
  isOpen: boolean;
  onClose: () => void;
  newPlan: string;
  isAnnual: boolean;
}

interface EnterpriseProps {
  name: string;
  members: number;
  message?: string;
  lmic?: string;
  email?: string;
  is_yearly: boolean;
  username: string;
}

function ChangePlanModal({
  isOpen,
  onClose,
  newPlan,
  isAnnual,
}: ChangePlanModalProps) {
  const { user } = useSelector(selectCurrentAuthData);

  // APIs
  const { cancelSubscription, requestTeamsPackage } = useStripeAPI();

  // State
  const [isProcessing, setIsProcessing] = useState(false);
  const [members, setMembers] = useState(2);
  const [name, setName] = useState(""); // Team name
  const [country, setCountry] = useState("");
  const [message, setMessage] = useState("");
  const [isLmic, setIsLmic] = useState("no");

  // Hooks
  const toast = useToast();
  const navigate = useNavigate();

  // Handlers
  const changePlan = async () => {
    if (toFree) cancelPlan();
    if (toEnterprise) submitTeamsRequest();
  };

  const cancelPlan = async () => {
    setIsProcessing(true);

    await cancelSubscription()
      .then(async (res) => {
        toast({
          position: "bottom-right",
          duration: 5000,
          render: () => (
            <SuccessToast isUser toFree={toFree} toEnterprise={toEnterprise} />
          ),
        });
        setIsProcessing(false);
        setTimeout(() => {
          navigate("/data?p=cancel-plan");
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        toast({
          description: error.response.data.message
            ? error.response.data.message
            : "An error occurred while cancelling the subscription",
          status: "error",
          position: "top-right",
        });
        setIsProcessing(false);
      });
  };

  const submitTeamsRequest = async () => {
    if (name === "" || Number.isNaN(members) || members < 2) {
      return;
    }

    if (isLmic === "yes" && country === "") return;

    setIsProcessing(true);

    const payload: EnterpriseProps = {
      name,
      members,
      email: user?.email,
      is_yearly: isAnnual,
      username: `${user?.given_name} ${user?.family_name}`,
    };

    if (isLmic === "yes") payload.lmic = country;
    if (message !== "") payload.message = message;

    await requestTeamsPackage(payload)
      .then(async (res) => {
        toast({
          position: "bottom-right",
          duration: 5000,
          render: () => (
            <SuccessToast isUser toFree={toFree} toEnterprise={toEnterprise} />
          ),
        });

        setIsProcessing(false);
        onClose();
      })
      .catch((error) => {
        console.log(error);
        let errorMessage = "An error occurred while sending the request";
        if (
          error.response.data.message &&
          error.response.data.message === "TEAM_NAME_EXISTS"
        ) {
          errorMessage = "You already submitted a request for this team name";
        }
        toast({
          description: errorMessage,
          status: "error",
          position: "top-right",
        });
        setIsProcessing(false);
      });
  };

  const toFree = newPlan?.toLowerCase() === "free";
  const toEnterprise = newPlan?.toLowerCase() === "enterprise";

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent
        p={2}
        alignSelf={"center"}
        minW={{ base: "100%", md: "580px" }}
        minH={"fit-content"}
        maxH={{
          base: "100%",
          md: "calc(100vh - 16px)",
          xl: "calc(100vh - 24px)",
          "2xl": "calc(100vh - 32px)",
        }}
        overflowY={"auto"}
        my={"auto"}
        bg={"background"}
        borderRadius={{ base: 0, md: "6px" }}
        as={"form"}
      >
        <ModalHeader display={"flex"} alignItems={"center"} gap={2}>
          <Icon
            as={toFree ? MdMoveUp : FaUsers}
            bg={"highlight.primary"}
            color={"gray.50"}
            boxSize={"28px"}
            borderRadius={"6px"}
            p={"3px"}
          />
          <Text fontSize={"16px"} color={"gray.600"} fontWeight={"500"}>
            <>
              {!!toFree && !toEnterprise ? (
                <>Cancel Subscription Plan</>
              ) : (
                <>Enterprise Plan Request Form</>
              )}
            </>
          </Text>
        </ModalHeader>

        <ModalBody mb={4} display={"flex"} flexDirection={"column"} gap={6}>
          <>
            {!!toFree && !toEnterprise ? (
              <Text fontFamily={"Poppins, sans-serif"} fontSize={"14px"}>
                Are you sure you want to change to{" "}
                <b>{`${toFree ? "Free" : "Enterprise"}`} plan</b>?
              </Text>
            ) : (
              <RequestForm
                name={name}
                setName={setName}
                members={members}
                setMembers={setMembers}
                country={country}
                setCountry={setCountry}
                message={message}
                setMessage={setMessage}
                isLmic={isLmic}
                setIsLmic={setIsLmic}
              />
            )}
          </>
        </ModalBody>

        <ModalFooter gap={1}>
          <Button
            size={"md"}
            bg={"gray.100"}
            color={"gray.500"}
            _hover={{ bg: "gray.200" }}
            pointerEvents={isProcessing ? "none" : "auto"}
            borderRadius={"100px"}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            size={"md"}
            bg={"highlight.primary"}
            color={"gray.50"}
            _hover={{ opacity: 0.9 }}
            isLoading={isProcessing ?? false}
            pointerEvents={isProcessing ? "none" : "auto"}
            loadingText={"Processing"}
            borderRadius={"100px"}
            onClick={() => changePlan()}
          >
            {toEnterprise ? <>Submit</> : <>Change</>}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ChangePlanModal;
