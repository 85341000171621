import { FormControl, FormLabel, Input, InputGroup } from "@chakra-ui/react";
import { ChangeEvent, useRef } from "react";

interface InstitutionProps {
  name: string;
  setName: (value: string) => void;
}

function Institution({ name, setName }: InstitutionProps) {
  // Ref
  const inputRef = useRef<HTMLInputElement>(null);

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  return (
    <FormControl id="institutionName" isRequired>
      <FormLabel
        htmlFor={"institutionName"}
        color={"secondary.700"}
        fontSize={"12px"}
        fontWeight={"500"}
        fontFamily={"Poppins, sans-serif"}
        lineHeight={1}
        mb={1}
      >
        Institution Name
      </FormLabel>
      <InputGroup>
        <Input
          value={name}
          onChange={handleNameChange}
          placeholder="Enter institution name"
          _placeholder={{ fontSize: "14px", color: "gray.400" }}
          ref={inputRef}
          bg={"background"}
          color={"gray.600"}
          fontSize={"16px"}
          type={"text"}
          w={"100%"}
          name={"institutionName"}
        />
      </InputGroup>
    </FormControl>
  );
}

export default Institution;
