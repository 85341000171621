import { useParams } from "react-router-dom";

import {
  Button,
  Flex,
  Icon,
  Text,
  Tooltip,
  useBreakpointValue,
  useTheme,
  Code,
  Stack,
} from "@chakra-ui/react";

import { hexToRgba } from "utils/helpers";

import { IoPlay } from "react-icons/io5";
import { TbSparkles } from "react-icons/tb";

import { ToolProps } from "models/tools/ToolsProps";
import { CustomScrollBar } from "components/ui/CustomScrollBar";
import { CoreContext } from "..";
import { useContext } from "react";
import { LayersProps } from "../CoreLayersList";
import { getMethodColor } from "./helpers";

function ToolView() {
  // Theme
  const { colors } = useTheme();

  // Hooks
  const { toolName } = useParams();

  // Responsiveness
  const listHeight = useBreakpointValue({
    lg: "calc(100vh - 154px)",
    xl: "calc(100vh - 162px)",
    "2xl": "calc(100vh - 178px)",
  });

  // Style
  const listStyle = {
    height: listHeight,
    width: "100%",
    padding: "6px 12px 6px 0",
  };

  // Hooks
  const { layers } = useContext(CoreContext);
  const { layerName } = useParams();

  let layerTools: ToolProps[] = [] as ToolProps[];
  let layer: LayersProps = {} as LayersProps;

  if (!!layers && layers?.length) {
    layer = layers?.find(
      (layer: LayersProps) => layer?.name === layerName
    ) as LayersProps;
  }

  if (layer) {
    layerTools = layer?.tools as ToolProps[];
  }

  const currentTool = layerTools?.find(
    (tool) => tool?.name === toolName
  ) as ToolProps;

  return (
    <>
      {!!currentTool ? (
        <CustomScrollBar style={listStyle}>
          <Flex direction={"column"} gap={"48px"} mt={"24px"}>
            <Flex direction={"column"} gap={3} color={"gray.700"}>
              {/* name */}
              <Text
                fontSize={[null, null, null, "12px", "14px", "16px"]}
                fontWeight={"500"}
                lineHeight={"1.35"}
                display={"inline"}
                mb={2}
                maxW={"800px"}
                whiteSpace={"nowrap"}
                overflow={"hidden"}
                textOverflow={"ellipsis"}
              >
                {currentTool?.name}
              </Text>

              {/* description */}
              <Flex
                maxW={"800px"}
                align={"flex-start"}
                gap={3}
                lineHeight={"1.5"}
                fontSize={[null, null, null, "12px", "12px", "14px"]}
              >
                <Text
                  minW={"fit-content"}
                  w={"110px"}
                  display={"inline-block"}
                  fontFamily={"Poppins, sans-serif"}
                >
                  ⬦ Description:
                </Text>
                <Text fontWeight={"400"} opacity={0.9} w={"100%"}>
                  {currentTool?.description}
                </Text>
              </Flex>

              {/* endpoint */}
              {layerName === "Private" && (
                <Flex
                  maxW={"800px"}
                  align={"flex-start"}
                  gap={3}
                  lineHeight={"1.5"}
                  fontSize={[null, null, null, "12px", "12px", "14px"]}
                >
                  <Text
                    minW={"fit-content"}
                    w={"110px"}
                    display={"inline-block"}
                    fontFamily={"Poppins, sans-serif"}
                  >
                    ⬦ Endpoint:
                  </Text>
                  <Text
                    fontWeight={"400"}
                    opacity={0.9}
                    w={"100%"}
                    fontStyle={"italic"}
                  >
                    {currentTool?.end_point_url}
                  </Text>
                </Flex>
              )}

              {/* method */}
              <Flex
                maxW={"800px"}
                align={"flex-start"}
                gap={3}
                lineHeight={"1.5"}
                fontSize={[null, null, null, "12px", "12px", "14px"]}
              >
                <Text
                  minW={"fit-content"}
                  w={"110px"}
                  display={"inline-block"}
                  fontFamily={"Poppins, sans-serif"}
                >
                  ⬦ Method:
                </Text>
                <Text
                  fontWeight={"500"}
                  opacity={0.9}
                  w={"100%"}
                  color={getMethodColor(
                    currentTool?.method?.toLowerCase() ?? ""
                  )}
                >
                  {currentTool?.method}
                </Text>
              </Flex>
              <CustomScrollBar
                style={{
                  width: "100%",
                  padding: "6px 12px 6px 0",
                }}
              >
                <Text
                  fontSize={[null, null, null, "14px", "16px", "20px"]}
                  fontWeight={"500"}
                  p={"0.2em"}
                  width={120}
                >
                  {"Input schema"}
                </Text>
                {currentTool.input_schema.map((schema, index) => {
                  return (
                    <Flex direction={"column"} mb={10} mt={5} key={index}>
                      <Flex direction={"column"} gap={3} color={"gray.700"}>
                        {/* name */}
                        <Stack direction="row">
                          <Code
                            fontWeight={"500"}
                            pr={"1em"}
                            pl={"1em"}
                            pb={"0.5"}
                            pt={"0.5"}
                            colorScheme={"blue"}
                          >
                            {schema?.name}
                          </Code>
                        </Stack>

                        {/* description */}
                        <Flex
                          maxW={"800px"}
                          align={"flex-start"}
                          gap={3}
                          lineHeight={"1.5"}
                          fontSize={[null, null, null, "12px", "12px", "14px"]}
                        >
                          <Text
                            minW={"fit-content"}
                            w={"110px"}
                            display={"inline-block"}
                            fontFamily={"Poppins, sans-serif"}
                          >
                            ⬦ Description:
                          </Text>
                          <Text fontWeight={"400"} opacity={0.9} w={"100%"}>
                            {schema?.description}
                          </Text>
                        </Flex>

                        <Flex
                          maxW={"800px"}
                          align={"flex-start"}
                          gap={3}
                          lineHeight={"1.5"}
                          fontSize={[null, null, null, "12px", "12px", "14px"]}
                        >
                          <Text
                            minW={"fit-content"}
                            w={"110px"}
                            display={"inline-block"}
                            fontFamily={"Poppins, sans-serif"}
                          >
                            ⬦ Optional:
                          </Text>
                          <Text
                            fontWeight={"400"}
                            color={"highlight.primary"}
                            w={"100%"}
                          >
                            {schema?.optional === false ? "false" : "true"}
                          </Text>
                        </Flex>
                        <Flex
                          maxW={"800px"}
                          align={"flex-start"}
                          gap={3}
                          lineHeight={"1.5"}
                          fontSize={[null, null, null, "12px", "12px", "14px"]}
                        >
                          <Text
                            minW={"fit-content"}
                            w={"110px"}
                            display={"inline-block"}
                            fontFamily={"Poppins, sans-serif"}
                          >
                            ⬦ Type:
                          </Text>
                          <Text
                            color={"highlight.primary"}
                            fontWeight={"400"}
                            w={"100%"}
                          >
                            {schema?.type}
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                  );
                })}
              </CustomScrollBar>
            </Flex>

            {/* action button */}
            {layerName === "Private" && (
              <Flex w={"100%"} alignItems="center" gap={2} py={1}>
                <Tooltip
                  label={
                    <Flex direction={"column"} gap={2}>
                      <Flex align={"center"} gap={1.5} color={"purple.400"}>
                        <Icon as={TbSparkles} />
                        <Text
                          as={"span"}
                          fontWeight={"600"}
                          fontFamily={"Poppins, sans-serif"}
                        >
                          COMING SOON
                        </Text>
                      </Flex>
                      <Text>You'll be able to test endpoint validity</Text>
                    </Flex>
                  }
                  placement={"right"}
                  bg={"gray.900"}
                  color={"gray.100"}
                  hasArrow
                  py={2}
                  px={3}
                  m={2}
                  maxW={[null, null, null, "200px", "220px", "240px"]}
                  borderRadius={"6px"}
                  fontSize={"12px"}
                  boxShadow={"none"}
                  arrowSize={8}
                  whiteSpace={"normal"}
                >
                  <Button
                    iconSpacing={3}
                    leftIcon={<IoPlay />}
                    fontSize={"14px"}
                    fontWeight={"500"}
                    w={"fit-content"}
                    h={"fit-content"}
                    px={5}
                    py={3}
                    borderRadius={"6px"}
                    letterSpacing={"-0.012rem"}
                    borderWidth={1}
                    borderColor={hexToRgba(colors.highlight.primary, 0.16)}
                    color={"highlight.primary"}
                    bg={hexToRgba(colors.highlight.primary, 0.12)}
                    _hover={{
                      borderColor: "transparent",
                      bg: hexToRgba(colors.highlight.primary, 0.2),
                    }}
                    _focus={{
                      borderColor: "transparent",
                      bg: hexToRgba(colors.highlight.primary, 0.2),
                    }}
                  >
                    Test Endpoint
                  </Button>
                </Tooltip>
              </Flex>
            )}
          </Flex>
        </CustomScrollBar>
      ) : (
        <>No tool found!</>
      )}
    </>
  );
}

export default ToolView;
