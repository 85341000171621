import {
  FormControl,
  FormErrorMessage,
  Textarea,
  useTheme,
} from "@chakra-ui/react";
import { ChangeEvent } from "react";
import { hexToRgba } from "utils/helpers";

interface MessageFormControlProps {
  message: string;
  isInvalid: boolean;
  onMessageChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

function MessageFormControl({
  message,
  isInvalid,
  onMessageChange,
}: MessageFormControlProps) {
  // Theme
  const { colors } = useTheme();

  return (
    <FormControl isRequired isInvalid={isInvalid}>
      <Textarea
        isRequired
        name="message"
        value={message}
        onChange={onMessageChange}
        placeholder="Write message here..."
        resize={"none"}
        minW={["100%", "100%", "220px", "240px", "260px", "260px"]}
        fontSize={{ base: "12px", md: "14px" }}
        color={"white"}
        _placeholder={{ fontSize: "14px", color: "whiteAlpha.700" }}
        borderRadius={"24px"}
        bg={hexToRgba(colors.white, 0.08)}
        borderWidth={1}
        borderColor={"blue.500"}
        _hover={{ borderColor: "blue.600" }}
        _focusVisible={{ borderColor: "white" }}
      />
      <FormErrorMessage color={"whiteAlpha.700"}>
        Message is required. Should be longer than 3 characters.
      </FormErrorMessage>
    </FormControl>
  );
}

export default MessageFormControl;
