import { Flex, Heading, Text } from "@chakra-ui/react";
import { HashLink as Link } from "react-router-hash-link";

export default function Company() {
  return (
    <Flex
      direction={"column"}
      gap={{ base: 1, lg: 3 }}
      minW={{ base: "fit-content", md: "150px" }}
    >
      <Heading size={"md"}>Company</Heading>
      <Text
        fontSize={{ base: "13px", sm: "14px", lg: "16px" }}
        opacity={0.75}
        transition={"all 0.3s ease"}
        _hover={{ opacity: 1 }}
      >
        <Link to={"#about"} smooth>
          About
        </Link>
      </Text>

      <Text
        fontSize={{ base: "13px", sm: "14px", lg: "16px" }}
        opacity={0.75}
        transition={"all 0.3s ease"}
        _hover={{ opacity: 1 }}
      >
        <Link to={"#contact"} smooth>
          Contact us
        </Link>
      </Text>

      <Text
        as={"a"}
        href={"https://www.vitafluence.ai"}
        target={"_blank"}
        fontSize={{ base: "13px", sm: "14px", lg: "16px" }}
        opacity={0.75}
        transition={"all 0.3s ease"}
        _hover={{ opacity: 1, textDecoration: "none" }}
      >
        Vitafluence Website
      </Text>
    </Flex>
  );
}
