import { Box, Button, Flex, Icon, Text } from "@chakra-ui/react";
import { BsLinkedin } from "react-icons/bs";

export function FollowUs() {
  return (
    <Flex
      direction="column"
      align={"center"}
      h={"100%"}
      justify={"flex-end"}
      pb={10}
      gap={10}
    >
      <Text
        display="inline-block"
        transform="rotate(-90deg)"
        transformOrigin={"center"}
        whiteSpace="nowrap"
        color={"white"}
        lineHeight={1}
        fontFamily={"Poppins, sans-serif"}
        fontSize={{ lg: "12px", xl: "14px", "2xl": "15px" }}
      >
        follow us
      </Text>
      <FollowArrow />
    </Flex>
  );
}

export function LinkedInButton() {
  return (
    <Button
      p={0}
      zIndex={2}
      w={{ lg: "40px", xl: "46px", "2xl": "50px" }}
      h={{ lg: "40px", xl: "46px", "2xl": "50px" }}
      borderRadius={"50%"}
      bg={"highlight.primary"}
      color={"white"}
      boxShadow={"lg"}
      transition={"all .3s ease"}
      _hover={{ opacity: 0.8 }}
      _focus={{ opacity: 0.8 }}
      _focusWithin={{ bg: "highlight.primary" }}
      onClick={() =>
        window.open("https://www.linkedin.com/company/vitafluence", "_blank")
      }
    >
      <Icon
        as={BsLinkedin}
        boxSize={{ lg: "20px", xl: "22px", "2xl": "24px" }}
      />
    </Button>
  );
}

function FollowArrow() {
  return (
    <Box width="1px" height="80px" bg="white" position="relative" opacity={0.7}>
      <Box
        opacity={0.7}
        position="absolute"
        top="100%"
        left="50%"
        transform="translateX(-50%)"
        width="0"
        height="0"
        borderLeft="5px solid transparent"
        borderRight="5px solid transparent"
        borderTop="6px solid white"
      />
    </Box>
  );
}
