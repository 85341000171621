import {
  Flex,
  FormControl,
  FormLabel,
  InputGroup,
  Radio,
  RadioGroup,
  Select,
} from "@chakra-ui/react";
import { LMIC } from "components/team/requestForm/helpers";
import { ChangeEvent } from "react";

interface LmicProps {
  setIsLmic: (value: string) => void;
  setCountry: (value: string) => void;
  isLmic: string;
  country: string;
}

export default function Lmic({
  setIsLmic,
  isLmic,
  country,
  setCountry,
}: LmicProps) {
  const handleRadioChange = (nextValue: string) => {
    setIsLmic(nextValue);
  };

  const onCountryChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setCountry(e.target.value);
  };

  return (
    <>
      <FormControl id="lmicRadio" isRequired>
        <FormLabel
          htmlFor="lmicRadio"
          color={"secondary.700"}
          fontSize={"12px"}
          fontWeight={"500"}
          fontFamily={"Poppins, sans-serif"}
          lineHeight={1}
          mb={1}
        >
          Is the institution in LMIC country?
        </FormLabel>
        <RadioGroup
          defaultValue="no"
          name="lmicRadio"
          onChange={handleRadioChange}
          mt={2}
        >
          <Flex gap={2}>
            <Radio size={"sm"} value="yes" _checked={{ bg: "gray.200" }}>
              Yes
            </Radio>
            <Radio size={"sm"} value="no" _checked={{ bg: "gray.200" }}>
              No
            </Radio>
          </Flex>
        </RadioGroup>
      </FormControl>

      {isLmic === "yes" && (
        <FormControl id={"countryName"} isRequired>
          <FormLabel
            htmlFor={"countryName"}
            color={"secondary.700"}
            fontSize={"12px"}
            fontWeight={"500"}
            fontFamily={"Poppins, sans-serif"}
            lineHeight={1}
            mb={1}
          >
            Country
          </FormLabel>

          <InputGroup>
            <Select
              value={country}
              onChange={onCountryChange}
              placeholder="Select country"
              _placeholder={{ fontSize: "14px", color: "gray.400" }}
              sx={{
                option: { backgroundColor: "background", color: "gray.600" },
              }}
              bg={"background"}
              color={"gray.600"}
              fontSize={"14px"}
              w={"100%"}
              name="countryName"
            >
              {LMIC.map((country, index) => (
                <option key={index} value={country.name}>
                  {country.name}
                </option>
              ))}
            </Select>
          </InputGroup>
        </FormControl>
      )}
    </>
  );
}
