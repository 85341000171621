import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { selectCurrentAuthData } from "redux/features/auth/authSlice";

import useLogout from "hooks/auth/useLogout";
import UserAccess from "components/home/UserAccess";
import { GoSignIn, GoSignOut } from "react-icons/go";

export default function LogIn() {
  // Navigation
  const navigate = useNavigate();

  // Custom hooks
  const { logout, loggingOut } = useLogout();
  const { user } = useSelector(selectCurrentAuthData);

  // Handlers
  async function handleLogout() {
    await logout();
  }

  return (
    <>
      {user ? (
        <UserAccess
          icon={GoSignOut}
          state={"Logout"}
          isLoading={loggingOut}
          onAccess={() => handleLogout()}
        />
      ) : (
        <UserAccess
          icon={GoSignIn}
          state={"Login"}
          onAccess={() => {
            navigate("/login");
          }}
        />
      )}
    </>
  );
}
