import { StyleSheet } from "@react-pdf/renderer";

// --------------------- Shared -------------------------
export const documentStyles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#ffffff",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "24px",
    padding: "32px 16px",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "6px",
    alignItems: "center",
  },
  header: {
    maxWidth: "95%",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#3182ce",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  section: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  sectionHeader: {
    color: "#2D3748",
    fontSize: "14px",
  },
  viewer: {
    width: "100%",
    height: "400px",
    backgroundColor: "#ffffff",
  },
});

export const tableStyles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    borderColor: "#EDF2F7",
    borderBottomWidth: 1,
  },
  headerCell: {
    textAlign: "left",
    padding: "6px",
    fontSize: "10px",
    letterSpacing: "1px",
    fontWeight: "bold",
    color: "#3182ce",
    textTransform: "uppercase",
  },
  body: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    minHeight: "50px",
    alignItems: "center",
    color: "#2D3748",
    borderColor: "#EDF2F7",
    borderBottomWidth: 1,
  },
  cell: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    height: "100%",
    padding: "6px",
  },
});

// --------------------- Molecules -------------------------
export const smileStyles = StyleSheet.create({
  container: {
    width: "25%",
  },
  image: {
    maxHeight: "100px",
    maxWidth: "100px",
    objectFit: "cover",
    margin: "0 auto",
    padding: "6px",
  },
});

export const moleculeStyles = StyleSheet.create({
  name: {
    height: "100%",
    padding: "6px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    wordBreak: "break-all",
    whiteSpace: "nowrap",
    width: "35%",
  },
});

export const tagsStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    gap: "2px",
    flexWrap: "wrap",
    width: "20%",
    height: "100%",
    padding: "6px",
  },
  tag: {
    fontSize: "10px",
    backgroundColor: "#E2E8F0",
    color: "#2D3748",
    padding: "4px",
    borderRadius: "3px",
  },
  moreTagsText: {
    color: "#718096",
    fontSize: "9px",
  },
});

export const moleculesCols = [
  { name: "Molecule", width: "25%" },
  { name: "ID", width: "20%" },
  { name: "Name", width: "35%" },
  { name: "Aliases", width: "20%" },
];

// 64-base encoded fallback image
export const smileFallbackSrc =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAIAAAC2BqGFAAACsElEQVR4nO3csUrrUByA8bTUVpCiLm5WAkIhggoudREj+AwFJ59PH0CQgiiKgS6i0MHioh1EHYo4mFLTO/Tivdy13i+C32/qOZR/Dh+h6ZTCaDQK9P8V8z7AT2FoiKEhhoYYGmJoiKEhhoYYGmJoiKEhhoYYGmJoiKEhhoYYGmJoiKEhhoYYGmJoiKEhhoYYGmJoiKEhhoYYGmJoiKEhhoYYGmJoiKEhhoYYGmJoiKEhhoYYGmJoiKEhhoYYGmJoiKEhhoYYGmJoiKEhhoYYGmJoiKEhhoYYGlLK68KPj483Nze7u7tBEGRZdnZ2dn9/X6lUNjc3a7VakiS3t7fVajWO47m5uVwGfq187uirq6uLi4ssy8bLbrdbLBb39vbiOD4/P+/1es/Pz81mc319/fLyMpeBXy6f0LOzszs7O5/Ler2+tbVVKpXm5+fL5fLT09Py8nKlUgnD8PX19TNfp9NptVpBEJycnHQ6nckHkvIJHYZhoVD4Z3MwGLRarY2NjcFgMD09Pd6cmpoaDofjz1EUjUaj4+Pj4XAYRdHkA0nf5WHY7/ePjo6iKArDsFwup2k63k/TtFT68yBZW1t7eHhYXV39qoGYbxH67e3t9PQ0juOlpaUgCBYWFrrd7vv7+93dXbVaLRZ/HzLLsiRJGo1GkiQfHx+TDyTl9q/jb9fX1y8vL4eHh+Pl/v5+r9c7ODiYmZnZ3t7+/Fq73V5cXFxZWUnTtN1uNxqNCQeSCr6om/Etfjp+AkNDDA0xNMTQEENDDA0xNMTQEENDDA0xNMTQEENDDA0xNMTQEENDDA0xNMTQEENDDA0xNMTQEENDDA0xNMTQEENDDA0xNMTQEENDDA0xNMTQEENDDA0xNMTQEENDDA0xNMTQEENDDA0xNMTQEENDDA0xNMTQEENDfgHE6gxEOzG2HwAAAABJRU5ErkJggg==";

// --------------------- Assays -------------------------
export const assaysStyles = StyleSheet.create({
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px",
    wordBreak: "break-word",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

export const assaysCols = [
  { name: "Name", width: "40%" },
  { name: "ID", width: "10%" },
  { name: "Indication", width: "20%" },
  { name: "Types", width: "30%" },
];

// --------------------- Literature -------------------------
export const literatureStyles = StyleSheet.create({
  type: {
    color: "#fff",
    fontSize: "10px",
    lineHeight: 1.2,
    textAlign: "center",
    padding: "4px",
    borderRadius: "3px",
  },
});

interface literatureColProps {
  name: string;
  width: string;
}
export const literatureCols: literatureColProps[] = [
  { name: "Name", width: "50%" },
  { name: "Lens ID", width: "20%" },
  { name: "Year", width: "10%" },
  { name: "Type", width: "20%" },
];
