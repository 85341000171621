import { memo } from "react";
import { Box } from "@chakra-ui/react";

import Loading from "components/ui/Loading";
import SourcesTabPanel from "./SourcesTabPanel";
import MainPanelError from "components/ui/MainPanelError";
import { PapersNetworkProps } from "./helpers";

interface SourcesComProps {
  sources: PapersNetworkProps[];
  allFetched: boolean;
  allFetchFailed: boolean;
}

function Sources({ sources, allFetched, allFetchFailed }: SourcesComProps) {
  // loading
  if (!allFetched)
    return (
      <Box my={6}>
        <Loading message={"Loading sources..."} />
      </Box>
    );

  if (allFetchFailed) {
    return (
      <Box my={3}>
        <MainPanelError errorMessage={"Error loading sources"} />
      </Box>
    );
  }

  return <SourcesTabPanel sources={sources} />;
}

export default memo(Sources);
